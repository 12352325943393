import React from "react";
import "../../style/footer.css";

import appStore from "../../assets/appDownload.png";
import googlePlay from "../../assets/gdownload.png";

import phoneSvg from "../../assets/phoneSvg.svg";

import locationSvg from "../../assets/locationSvg.svg";

import mail from "../../assets/mail.png";

import facebook from "../../assets/facebbok-foot-ico.png";
import linkdink from "../../assets/linkdin-foot-ico.png";
import tweet from "../../assets/tweet-foot-ico.png";
import insta from "../../assets/insta-foot-ico.png";
import logoWhite from "../../assets/logo-white.svg";
function Footer() {
  return (
    <div>
      <div className="container-fluid bg-black footer">
        <div className="container">
          <div className="row text-left md:text-center text-md-start text-[12px] flex justify-between">
            {/* <div className='col d-flex flex-column gap-3'> */}
            <div className="col-12 col-md-12 col-lg-4 d-flex flex-column gap-3">
              <img
                src={logoWhite}
                width={150}
                alt="zokudo logo"
                className="mt-[30px]"
              />

              {/* <div className="mb-3 flex">
                <a
                  className="text-decoration-none border-white"
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.zokudo"
                >
                  {" "}
                  <img
                    className="border rounded object-contain"
                    src={googlePlay}
                    alt="google play"
                  />{" "}
                </a>
                <a
                  className="text-decoration-none border-white"
                  target="_blank"
                  href="https://apps.apple.com/in/app/zokudo/id1553087937"
                >
                  <img className="border rounded object-contain" src={appStore} alt="app store" />{" "}
                </a>
              </div> */}
              <div className="d-flex mt-3">
                <span className="me-3">
                  <img className="pr-1" src={locationSvg} width={20} />
                </span>
                <p className="text-secondary text-[12px]">
                  Premium eBusiness Venture Pvt. Ltd.
                  <br />
                  Plot No 33, Second Floor,
                  <br />
                  Marol Industrial Area,
                  <br />
                  Near Tunga Paradise, Andheri(E) MIDC,
                  <br />
                  Chakala Midc, Andheri (East),
                  <br />
                  Mumbai – 400093
                </p>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-4 flex md:justify-center">
              <div>
                <h5 className="text-white my-4 ">Get In Touch</h5>
                <div className="flex gap-3">
                  <div className="mt-1">
                    <span className="me-3">
                      <img src={phoneSvg} width={15} />
                    </span>
                  </div>
                  <div>
                    <p className="mb-1">
                      <span className="text-secondary">
                        For Customer Queries:
                      </span>
                      <a
                        href="tel:+918655000444"
                        className="text-light text-decoration-none ms-2"
                      >
                        +91 86550 00444
                      </a>
                    </p>
                    <p>
                      <span className="text-secondary">
                        For Business Queries:
                      </span>
                      <a
                        href="tel:+917506048939"
                        className="text-light text-decoration-none ms-2"
                      >
                        +91 75060 48939
                      </a>
                    </p>
                  </div>
                </div>
                <div className="flex gap-3">
                  <span className=" mb-4 mt-1">
                    <img src={mail} width={15} alt="Mail Icon" />
                  </span>

                  <p>
                    <span className="text-secondary">Email:</span>
                    <a
                      href="mailto:care@zokudo.com"
                      className="text-light text-decoration-none ms-2"
                    >
                      care@zokudo.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-3 flex md:justify-center">
              <div className="">
                <h5 className="text-white my-4 ">Services</h5>
                <ul className="pl-8 flex flex-col gap-2 list-disc">
                  <li className="text-gray-500 cursor-pointer hover:text-white duration-300">
                    <a
                      href="/terms-and-conditions"
                      className="no-underline text-gray-500 hover:text-white"
                    >
                      Terms and conditions
                    </a>
                  </li>
                  <li className="text-gray-500  cursor-pointer hover:text-white duration-300">
                    <a
                      href="/privacy-policy"
                      className="no-underline text-gray-500 hover:text-white"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li className="text-gray-500  cursor-pointer hover:text-white duration-300">
                    <a
                      href="/customer-grievance"
                      className="no-underline text-gray-500 hover:text-white"
                    >
                      Customer Grievance redressal
                    </a>
                  </li>
                  <li className="text-gray-500  cursor-pointer hover:text-white duration-300">
                    <a
                      href="/customer-liability-policy"
                      className="no-underline text-gray-500 hover:text-white"
                    >
                      Customer liability policy
                    </a>
                  </li>
                  <li className="text-gray-500  cursor-pointer hover:text-white duration-300">
                    <a
                      href="/investor-relations"
                      className="no-underline text-gray-500 hover:text-white"
                    >
                      Annual Financial Report
                    </a>
                  </li>
                  <li className="text-gray-500  cursor-pointer hover:text-white duration-300">
                    FAQ
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <hr className="text-white"></hr>
          <div className="text-[12px] text-left md:text-center text-md-start text-secondary d-flex flex-column flex-md-row justify-content-between align-items-center align-items-md-start">
            <p>
              <a
                className="text-gray-500  cursor-pointer hover:text-white duration-300 no-underline"
                href="/privacy-policy"
              >
                Privacy Policy
              </a>{" "}
              |{" "}
              <a
                href="/terms-and-conditions"
                className="text-gray-500  cursor-pointer hover:text-white duration-300 no-underline"
              >
                Terms & Conditions
              </a>{" "}
            </p>
            <p>Copyright 2024 © Zokudo</p>
            <p className="flex gap-2">
              {/* <img className="px-2 social-ico" src={facebook} /> */}
              <img className="px-2 social-ico" src={linkdink} />
              {/* <img className="px-2 social-ico" src={tweet} /> */}
              {/* <img className="px-2 social-ico" src={insta} /> */}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
