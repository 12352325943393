import React from 'react'
import indusindBank from "../../assets/indusind-bank.svg";
import dalalDoctors from "../../assets/dalal-doctor.svg";
import azure from "../../assets/azure.svg";
import hyperVerge from "../../assets/hyperverge.svg";
import rupay from "../../assets/rupay.svg";
import tala from "../../assets/tala.png";
import m2p from "../../assets/m2p.png";
import lex from "../../assets/lex.png";
import triLegal from "../../assets/tri-legal.png";
import npcl from "../../assets/npcl.png";
import izoo from "../../assets/izoo.png";
import aadhaar from "../../assets/adhaar.svg";

export default function () {
  return (
    <>
    <img className="shadow-md p-3 smooth-resize rounded object-contain bg-white" src={rupay} />
        <img className="shadow-md p-3 smooth-resize rounded object-contain bg-white" src={hyperVerge} />
        <img className="shadow-md p-3 smooth-resize rounded object-contain bg-white" src={aadhaar} />
        <img className="shadow-md p-3 smooth-resize rounded object-contain bg-white" src={azure} />
        <img
          className="shadow-md p-3 smooth-resize rounded object-contain bg-white"
          src={dalalDoctors}
        />
        <img
          className="shadow-md p-3 smooth-resize rounded object-contain bg-white"
          src={lex}
        />
        <img
          className="shadow-md p-3 smooth-resize rounded object-contain bg-white"
          src={indusindBank}
        />
         <img className="shadow-md p-3 smooth-resize rounded object-contain bg-white" src={tala} />
        <img className="shadow-md p-3 smooth-resize rounded object-contain bg-white" src={m2p} />
        <img className="shadow-md p-3 smooth-resize rounded object-contain bg-white" src={triLegal} />
        <img className="shadow-md p-3 smooth-resize rounded object-contain bg-white" src={npcl} />
        <img className="shadow-md p-3 smooth-resize rounded object-contain bg-white" src={izoo} />
        </>
  )
}
