import React from "react";
import "../style/privacy-policy.css";

import languageTranslate from "../assets/language-translate.svg";

import NavbarV2 from "./NavbarV2";
import Footer from "./common/Footer";
import LanguageDropdown from "./ui/LanguageDropdown";

function PrivacyPolicy() {
  return (
    <div className="mt-[40px] md:mt-[80px] privacy-page">
      <div className="container py-4 text-left text-sm">
        <div className="row mt-5">
          <div className="col-12 col-md-6">
            <h3 className="text-warning">Privacy Policy</h3>
          </div>
          <div className="col-12 col-md-6 d-flex justify-content-md-end my-3 m-md-0">
            <LanguageDropdown/>
          </div>
<div className="border rounded-xl p-4">

          <div className="col-12 text-left">
            <p>
              Zokudo as a brand is a wholly owned by Premium Ebusiness Venture
              Private Limited and powers / manages the website{" "}
              <a href="www.zokudo.com" className="font-medium text-green-500">
                www.zokudo.com
              </a>{" "}
              (Website) and its mobile application (App) (Platform).
            </p>

            <p>
              Zokudo respects the privacy of its users and is committed to
              protecting it in all respects. Zokudo designs its products and
              services, keeping in mind such commitments, with a view to offer
              most enriching and holistic experience to its users. We involve
              experts from various fields, including legal, security,
              engineering, product design, and others, to ensure that our
              decisions are taken with the utmost respect for your privacy. The
              information about the user as collected by Zokudo is: (a)
              information supplied by users voluntarily; and (b) information
              automatically tracked while using Zokudo products/services{" "}
              <b>(Information)</b>.
            </p>

            <p>
              By using Zokudo’s Platform or its affiliated services, you: (a)
              provide your free, specific, informed, explicit, clear,
              unconditional and unambiguous consent to collection, storage,
              processing and use of the personal information you provide
              (including any changes thereto as provided by you) for any of the
              services that we offer; and (b) consent to this Privacy Policy and
              our terms and conditions set out at{" "}
              <a
                href="https://www.zokudo.com/terms-condition/"
                className="font-medium text-green-500"
              >
                https://www.zokudo.com/terms-condition/
              </a>
              .
            </p>

            <ul className="list-disc pl-0">
              <p>
                By choosing to provide your personal information via the
                Platform, including but not limited to the categories of
                personal information mentioned in this Privacy Policy, you
                expressly consent to Zokudo’s use of your personal information
                for the purposes listed in this Privacy Policy.
              </p>
            </ul>

            <p>
              Zokudo (we or us or our) respects the privacy of its users (user
              or you) and has implemented necessary technical and organizational
              safeguards and measures in this regard, to ensure its compliance
              with applicable data protection laws. This Privacy Policy seeks to
              explain: (a) what information we collect, use, disclose, process,
              store, safeguard; (b) the manner and purpose of, including our
              policies and procedures for, collecting, using, disclosing,
              processing, storing and safeguarding your information when you
              visit our Website; (c) your rights in relation to the Information
              and the manner in which you may exercise them; and (d) the manner
              in which you may raise any complaints/grievances. Please read this
              Privacy Policy carefully. If you do not agree with the terms of
              this Privacy Policy, please do not access the Platform.
            </p>
          </div>
        <section>
          <ul className="list-disc">
            <li>
              <strong>Collection of your Information</strong>
              <br />
              We may collect information about you in a variety of ways. When
              you use the Platform, we may ask you to provide certain
              information in connection with your use of the Platform and to
              avail yourself of certain services on the Platform. We may collect
              this information in various places, such as account registration
              forms, contact us forms, or otherwise when you interact with us,
              including customer support. The information we may collect via the
              Website and/or the App depends on the content and materials you
              use, and includes:
            </li>
            <li>
              <strong>Personal Data</strong>
              <br />
              Demographic and other personally identifiable information (such as
              your name, email address, contact details, Aadhaar card number,
              permanent account number and customer identification number) that
              you voluntarily give to us when choosing to participate in various
              activities related to the Website/App, such as chat, posting
              messages in comment sections or in our forums, liking posts,
              sending feedback, and responding to surveys or for availing our
              services. If you choose to share data about yourself via your
              profile, online chat, or other interactive areas of the
              Website/App, please be advised that all data you disclose in these
              areas is public and your data will be accessible to anyone who
              accesses the Website/App.
            </li>
            <li>
              <strong>Derivative Data</strong>
              <br />
              Information our servers automatically collect when you
              access/visit the Website or the App, such as your native actions
              that are integral to the Website, including liking, re-blogging,
              or replying to a post, as well as other interactions with the
              Website or App and other users via server log files. This
              information does not reveal your specific identity (like your name
              or contact information) but may include device and usage
              information, such as your IP address, browser and device
              characteristics, operating system, language preferences, referring
              URLs, time-spent, device name, country, location, information
              about how and when you use our services and other technical
              information. We may also collect information about the
              screens/pages you view within our Platform and other actions you
              take while using our Platform. This information is primarily
              needed to maintain the security and operation of our services, and
              for our internal analytics and reporting purposes. We also collect
              information through cookies and similar technologies.
            </li>
            <li>
              <strong>Financial Data</strong>
              <br />
              Financial information, such as data related to your payment method
              that we may collect when you purchase, order, return, exchange, or
              request information about our services from the Website. We store
              only very limited, if any, financial information that we collect,
              as we are required to store in accordance with applicable law and
              other regulatory requirement. Otherwise, all financial information
              is stored by our payment processor, and you are encouraged to
              review their privacy policy and contact them directly for
              responses to your questions. [LC Note: Zokudo to confirm on the
              collection of financial data.]
            </li>
            <li>
              <strong>Push Notifications</strong>
              <br />
              We may send you push notifications regarding your account on the
              App. If you wish to opt-out from receiving these types of
              communications, you may choose to do so by turning them off from
              the device settings.
            </li>
            <li>
              <strong>Additional information</strong>
              <br />
              Pursuant to the services you avail yourself of, from time to time,
              we may require you to provide additional information, as and when
              required, to comply with any applicable regulatory requirement or
              to provide additional services/products via the Platform, as and
              when offered. You may choose to provide such information if you
              wish to use the services provided on the Platform. We also
              maintain a record of the information you provide to us when using
              customer support services. Conversations during customer support
              services may also be retained on an anonymous basis for training
              and quality purposes.
            </li>
            <br />
          </ul>
          <ul className="list-disc">
            <li>
              <strong>Use and Processing of your Information</strong>
            </li>
            <br />
            <ul className="list-disc">
              <li>
                <strong>Accuracy of Information</strong>
                <br />
                All personal information that you provide to us must be true,
                complete and accurate, and you must notify us of any changes to
                such personal information. Having accurate information about you
                permits us to provide you with a smooth, efficient, and
                customized experience.
              </li>
              <li>
                <strong>Purpose of Processing Personal Information</strong>
                <br />
                We use and process your personal information to carry out our
                contractual commitments towards you in connection with the
                Platform, for purposes based on legitimate business interests
                and to provide better services. In addition, we use your
                personal information to comply with legal obligations and/or
                your consent.
              </li>
              <li>
                <strong>
                  Specific Purposes of Processing Personal Information
                </strong>
                <br />
                We use and process your personal information to:
                <ol className="list-[upper-roman]">
                  <li>
                    Provide, measure, and improve our services and Platform;
                  </li>
                  <li>
                    Create and maintain a trusted and safe and secure
                    environment (such as complying with our legal obligations
                    under applicable law and complying with our policies).
                  </li>
                </ol>
                The information collected from our Platform allows us to collect
                statistics about our Platform to assess usage and effectiveness
                and to enhance the functionality of our services. We also use
                your information to perform internal operations necessary to
                provide our services, such as troubleshooting software bugs and
                operational problems, conducting data analysis, testing, and
                research to improve services, and monitoring and analyzing usage
                and activity trends. We also use your information to contact
                select users to obtain feedback about our services and for
                statistical consumer research purposes.
              </li>
              <li>
                <strong>Additional Purposes</strong>
                <br />
                Further, we may use and process information collected about you
                via the Website/App to:
                <br />
                <ul class="list-disc">
                  <li>Administer sweepstakes, promotions, and contests.</li>
                  <li>
                    Assist law enforcement and respond to subpoena/legal
                    notices.
                  </li>
                  <li>
                    Compile anonymous statistical data and analysis for use
                    internally or with third parties.
                  </li>
                  <li>Create and manage your account.</li>
                  <li>
                    Deliver targeted advertising, coupons, newsletters, and
                    other information regarding promotions.
                  </li>

                  <li>Email you regarding your account or order.</li>
                  <li>
                    Increase the efficiency and operation of the Website and the
                    App.
                  </li>
                  <li>
                    Monitor and analyze usage and trends to improve your
                    experience with the Website/App.
                  </li>
                  <li>
                    Offer new products, services, mobile applications, and/or
                    recommendations to you.
                  </li>
                  <li>Perform other business activities as needed.</li>
                  <li>
                    Prevent fraudulent transactions, monitor against theft, and
                    protect against criminal activity.
                  </li>
                  <li>Process payments and refunds.</li>
                  <li>
                    Request feedback and contact you about your use of the
                    Website/App.
                  </li>
                  <li>Resolve disputes and troubleshoot problems.</li>
                  <li>Respond to product and customer service requests.</li>
                  <li>Send you a newsletter.</li>
                  <li>
                    Conduct know your customer (KYC) checks and other customer
                    background checks required to be performed as per applicable
                    laws and regulations.
                  </li>
                </ul>
              </li>
            </ul>
          </ul>
          <ul className="list-disc flex flex-col gap-2">
            <li>
              <strong>Disclosure/sharing of your Information</strong>
              <br />
              <p>
                We will only disclose your personal information as provided in
                this Privacy Policy. We may be required to disclose your
                personal information to third parties if there is a legal
                obligation. We share your information with our authorized
                service providers and partners who assist us in providing our
                services or verifying the user identity on the Platform. We only
                share such information under conditions requiring the
                confidentiality and security of the information that meets an
                adequate level of protection in compliance with applicable
                regulations. We do not sell your personal information to any
                third party. Except where you are expressly informed on the site
                or as described in this Privacy Policy, we do not sell, rent,
                share, trade or give away any of your personal information.
              </p>
              <p>
                {" "}
                We may share information we have collected about you in certain
                situations with your consent, to comply with laws, to provide
                you with services, to protect your rights, or to fulfill
                business obligations. Your information may be disclosed as
                follows:
              </p>
              <ul className="list-disc">
                <li>
                  <strong>By Law or to Protect Rights</strong>
                  <br />
                  If we believe the release of information about you is
                  necessary to respond to legal process, to investigate or
                  remedy potential violations of our policies, or to protect the
                  rights, property, and safety of others, we may share your
                  information as permitted or required by any applicable law,
                  rule, or regulation, with or without your consent (to the
                  extent permissible under applicable law). This includes
                  exchanging information with other entities for fraud
                  protection and credit risk reduction. We may disclose your
                  information where we are legally required to do so in order to
                  comply with applicable law, governmental requests, a judicial
                  proceeding, court order, or legal process, such as in response
                  to a court order or a subpoena (including in response to
                  public authorities to meet national security or law
                  enforcement requirements).
                </li>

                <li>
                  <strong>Third-Party Service Providers</strong>
                  <br />
                  <p>
                    {" "}
                    We use third-party service providers to provide website and
                    application development, hosting, maintenance, backup,
                    storage, payment processing, analysis and other services for
                    us in connection with the Platform, which may require them
                    to access or use certain information about you to the extent
                    required to perform such function. Our service providers and
                    partners agree to strict confidentiality and data protection
                    obligations.
                  </p>
                  <p>
                    Information provided by you in connection with mandatory
                    verification checks of identity, such as a government
                    identifier, mobile number, or email address, must be
                    accurate and correct. Only required information will be
                    shared with authorized third parties for the purposes of
                    such verification.
                  </p>
                  <p>
                    We may share your information with third parties that
                    perform services for us or on our behalf, including payment
                    processing, data analysis, email delivery, hosting services,
                    customer service, and marketing assistance.
                  </p>
                  <p>
                    We may share aggregated information with third parties to
                    carry out research and analysis to help us improve the user
                    experience of the App and test new technologies and
                    processes designed to enhance and improve the Platform. We
                    only use such information to analyse how users are
                    communicating with the Platform in an effort to continuously
                    improve it for you.
                  </p>
                </li>
                <li>
                  <strong>Marketing Communications</strong>
                  <br />
                  With your explicit consent, or with an opportunity for you to
                  withdraw consent, we may share your information with third
                  parties for marketing purposes, as permitted by applicable
                  law.
                </li>
                <li>
                  <strong>Third-Party Advertisers</strong>
                  <br />
                  We may use third-party advertising companies to serve ads when
                  you visit the Website/App. These companies may use information
                  about your visits to the Website/App and other websites that
                  are contained in web cookies in order to provide
                  advertisements about goods and services of interest to you.
                </li>
                <li>
                  <strong>Affiliates</strong>
                  <br />
                  We may share your information with our affiliates, in which
                  case we will require those affiliates to honor this Privacy
                  Policy. Affiliates include our parent company and any
                  subsidiaries, joint venture partners or other companies that
                  we control or that are under common control with us.
                </li>
                <li>
                  <strong>Business Partners</strong>
                  <br />
                  We may share your information with our business partners to
                  offer you certain products, services or promotions.
                </li>
                <li>
                  <strong>Sale or Bankruptcy</strong>
                  <br />
                  If we reorganize or sell all or a portion of our assets,
                  undergo a merger, or are acquired by another entity, we may
                  transfer your information to the successor entity. If we go
                  out of business or enter bankruptcy, your information would be
                  an asset transferred or acquired by a third party. You
                  acknowledge that such transfers may occur, and that the
                  transferee may decline honor commitments we made in this
                  Privacy Policy.
                </li>
                <li>
                  <strong> Performance of a Contract.</strong>
                  <br />
                  <p>
                    Where we have entered into a contract with you, we may
                    process your personal information to fulfill the terms of
                    our contract,
                  </p>
                  <p>
                    We are not responsible for the actions of third parties with
                    whom you share personal or sensitive data, and we have no
                    authority to manage or control third-party solicitations. If
                    you no longer wish to receive correspondence, emails or
                    other communications from third parties, you are responsible
                    for contacting the third party directly.
                  </p>
                </li>
                <li>
                  <strong> Other Users</strong>
                  <br />
                  <p>
                    When you share personal information (for example, by posting
                    comments, contributions or other content to the services) or
                    otherwise interact with public areas of the services, such
                    personal information may be viewed by all users and may be
                    publicly made available outside the services in perpetuity.
                    Similarly, other users will be able to view descriptions of
                    your activity, communicate with you within our services, and
                    view your profile. As a precautionary measure, you are
                    required to be cautious about sharing your information with
                    other users.
                  </p>
                  <p>
                    If someone submits a report involving you (such as a claim
                    that you violated our terms and conditions), we may
                    communicate to such reporter the actions (if any) we took
                    against you as a result of their report.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <strong>
                Storage, retention and deletion of Your Information
              </strong>
              <br />
              <ul class="list-disc mt-2">
                <li>
                  We will retain your information only for as long as it is
                  necessary for providing you with the services available on the
                  Platform, to comply with legal obligations, or until you
                  request the erasure or deletion of the information or cease us
                  to process the information, whichever is earlier, but in any
                  case, we do not retain any of your information beyond 10
                  years, except where required by applicable law or regulatory
                  requirement.
                </li>
                <li>
                  Post-termination of your account, we may continue to use your
                  anonymised data aggregated or combined with other users’
                  anonymised data. In cases where a user has violated the terms
                  and conditions of using our Platform or submits a request that
                  his/her personal information be deleted from the Platform, we
                  will promptly take necessary actions to delete your
                  information, in accordance with Paragraph 6.5.
                </li>
                <li>
                  If you wish to withdraw your consent for processing your
                  information, you may submit a request at care@zokudo.com.
                  However, we will continue to retain information which is
                  required to be retained under the applicable laws.
                </li>
              </ul>
            </li>
            <li>
              <strong>Tracking Technologies</strong>
              <br />
              <ul className="list-disc mt-2">
                <li>
                  <strong>Cookies and Web Beacons</strong>
                  <br />
                  We may use cookies, web beacons, when you access the Platform
                  your personal information is not collected through the use of
                  technology. Most browsers are set to accept cookies by
                  default. You can remove or reject cookies but be aware that
                  such action could affect the availability and functionality of
                  the Website. You may not decline web beacons. However, they
                  can be rendered ineffective by declining all cookies or by
                  modifying your web browser’s settings to notify you each time
                  a cookie is tendered, permitting you to accept or decline
                  cookies on an individual basis.
                </li>
                <li>
                  <strong>Internet-Based Advertising</strong>
                  <br />
                  Additionally, we may use third-party software to serve ads on
                  the Platform, implement email marketing campaigns, and manage
                  other interactive marketing initiatives. This third-party
                  software may use cookies or similar tracking technology to
                  help manage and optimize your online experience with us
                </li>
                <li>
                  <strong>Security of Your Information</strong>
                  <br />
                  We use administrative, technical, and physical security
                  measures to help protect your personal information. While we
                  have taken reasonable steps to secure the personal information
                  you provide to us, please be aware that despite our efforts,
                  no security measures are perfect or impenetrable, and no
                  method of data transmission can be guaranteed against any
                  interception or other type of misuse. Any information
                  disclosed online is vulnerable to interception and misuse by
                  unauthorized parties. Therefore, we cannot guarantee complete
                  security if you provide personal information.
                </li>
              </ul>
            </li>
            <li>
              <strong>Your Rights and Choices</strong>
              <br />
              <ul className="list-disc mt-2">
                <li>
                  <strong>Access/rectify:</strong>
                  <br /> Tools and account settings on the Platform can help you
                  access, rectify, or remove the information you provided to us
                  and associated with your account directly within the service,
                  apart from the information which cannot be edited as provided
                  in the terms and conditions of the Platform. You can also
                  change the information you provided by editing your profile
                  directly in the Platform. In relation to the information which
                  is not editable, if you believe that such information we hold
                  about you is inaccurate, you may also contact us to rectify
                  it.
                </li>

                <li>
                  <strong>Device Permission:</strong>
                  <br /> Your device can have a permission system for specific
                  types of device data and notifications, such as phone
                  contacts, pictures, location services, push notifications, and
                  advertising identifiers. You can change the settings on your
                  device to either consent to or oppose the collection or use of
                  any such corresponding information or the display of the
                  corresponding notifications. Of course, if you do that,
                  certain services may lose functionality.
                </li>

                <li>
                  <strong>Uninstall and Account closure:</strong>
                  <br /> You can stop all information collection by uninstalling
                  the App using the standard uninstall process for your device.
                  Remember that uninstalling an app does not close your account.
                  You can close your account by using the corresponding
                  functionality directly on the service.
                </li>

                <li>
                  <strong>Reviewing your information:</strong>
                  <br /> You may have the right to review the personal
                  information we keep about you, which may be called the right
                  of access, right of portability, or variations of those terms.
                  You can also request a copy of your personal information by
                  putting in such a request on the Platform.
                </li>

                <li>
                  <strong>Delete/erase:</strong>
                  <br /> You may request that we delete the personal information
                  we keep about you. You can exercise your right to delete by
                  submitting a request on the Platform or contacting us anytime
                  at care@zokudo.com. Please note that this right to delete will
                  be subject to our retention requirements under applicable law
                  and regulatory requirements. <br />
                </li>

                <li>
                  <strong>Nominate:</strong>
                  <br /> You shall have the right to nominate any other
                  individual who shall, in the event of your death or
                  incapacity, exercise your rights in accordance with applicable
                  data protection laws. <br />
                </li>
              </ul>
            </li>
            <li>
              <strong>Your duties</strong>
              <br />
              <ul class="list-disc mt-2">
                <li>
                  Comply with the provisions of applicable data protection laws:
                  Ensure adherence to all relevant data protection regulations
                  when handling personal data. <br />
                </li>

                <li>
                  Do not impersonate: Ensure not to impersonate another person
                  while providing your personal data for a specified purpose.{" "}
                  <br />
                </li>

                <li>
                  Do not suppress material information: Ensure not to suppress
                  any material information while providing your personal data
                  for any document, unique identifier, proof of identity, or
                  proof of address issued by the State or any of its
                  instrumentalities. <br />
                </li>

                <li>
                  Do not register false or frivolous grievances: Ensure not to
                  register a false or frivolous grievance or complaint with us
                  or the legal authorities. <br />
                </li>

                <li>
                  Furnish verifiable information: Provide only such information
                  as is verifiably authentic when exercising the right to
                  correction or erasure under applicable data protection laws.{" "}
                  <br />
                </li>
              </ul>
            </li>
            <li>
              <strong>Information Security</strong>
              <br />
              <ul className="list-disc mt-2">
                <li>
                  We adopt reasonable security measures designed to protect
                  against unauthorised access to your information. These include
                  periodic internal reviews of our data collection, storage and
                  processing practices and security measures, including
                  appropriate encryption and physical security measures to guard
                  against unauthorised access to our systems where we store your
                  personal information. All information gathered on our Platform
                  is securely stored within a controlled database. Access to the
                  servers is password-protected, controlled, and is strictly
                  limited.
                </li>
                <li>
                  We also audit our security practices and procedures annually
                  or as and when the Platform goes through a significant upgrade
                  of its process.
                </li>
              </ul>
            </li>
            <li>
              <strong>No Children</strong>
              <br />
              <p className="mt-2">
                Our services are restricted to individuals who are 18 years of
                age or older. We do not permit individuals under the age of 18
                to access our platform. If you suspect that a member is under
                the age of 18, please use the reporting mechanism available on
                the service.
              </p>
            </li>
            <li>
              <strong>
                Cookies and Do Not Track / Incognito mode settings
              </strong>
              <br />
              <ul className="list-disc mt-2">
                <li>
                  For the sake of clarification, we and our third-party partners
                  use cookies, which are text files containing small amounts of
                  information that are downloaded to your device when you visit
                  a website, to provide a personalised browsing experience.
                  These cookies help us identify unique users and sessions,
                  gather information, and store information. No personally
                  identifiable information is retrieved or stored.
                </li>
                <li>
                  Cookies allow users to navigate between pages efficiently,
                  remember their preferences, and generally improve their
                  browsing experience. These cookies collect information
                  analytics about how users use a website, for instance, the
                  often visited pages, the time spent on each page, etc.
                </li>
                <li>
                  All information collected by third-party cookies is aggregated
                  and, therefore, anonymous. By using the Platform, the user
                  agrees that these cookies can be placed on their device. The
                  user is free to disable/ delete these cookies by changing
                  their web browser settings.{" "}
                  <span>
                    [LC Note: We understand that Zokudo shares targeted /
                    customized advertisements and offers to its customers. To be
                    discussed how the same is based on aggregated and anonymized
                    data.]
                  </span>
                </li>
              </ul>
            </li>
            <li>
              <strong>Changes to Privacy Policy</strong>
              <br />
              <ul className="list-disc mt-2">
                <li>
                  We reserve the right to unconditionally modify, make changes
                  to or update this Privacy Policy, at our discretion, at any
                  time and without assigning any reason whatsoever. Any changes
                  shall be effective immediately upon the posting of the revised
                  Privacy Policy. We are constantly looking for new ways to
                  improve our services, including updating our Privacy Policy.
                </li>
                <li>
                  You are encouraged to periodically review this Privacy Policy
                  to stay informed of any such modifications, changes or
                  updates. You will be deemed to have been made aware of, will
                  be subject to, and will be deemed to have accepted and
                  consented to the changes in any revised Privacy Policy by your
                  continued use of the Website and/or the App after the date
                  such revised Privacy Policy is posted.
                </li>
                <li>
                  The date on this Privacy Policy reflects the last amended
                  date.
                </li>
              </ul>
            </li>
            <li>
              <strong>Privacy Questions and Access</strong>
              <br />
              <p className="mt-2">
                If you have questions, concerns, or suggestions regarding our
                Privacy Policy, we can be reached at care@zokudo.com. In certain
                cases, you may have the ability to view or edit your personal
                information online. In the event your information is not
                accessible online and you wish to obtain a copy of particular
                information you provided to us, or if you become aware that the
                information is incorrect and you would like us to correct it, or
                if you would like to exercise any of your rights in relation to
                your personal information, please contact our customer support
                services at care@zokudo.com. In the event where your
                queries/complaints are not resolved within 30 days, you may
                contact our grievance officer immediately in terms of our
                customer grievance redressal policy available at
                <a
                  href="https://www.zokudo.com/customer-grievance-redressal/"
                  className="font-medium text-green-500"
                >
                  https://www.zokudo.com/customer-grievance-redressal/
                </a>
                .
              </p>
            </li>
            <li>
              <strong>Withdrawal of Consent</strong>
              <br />
              <ul className="list-disc mt-2">
                <li>
                  Subject to legal and contractual requirements for providing
                  services to you, you can refuse to consent to our collection,
                  use, storage, retention, processing or disclosure of any
                  personal information about you, or you may withdraw your
                  consent to our further collection, use, processing, storage,
                  retention or disclosure of information at any time in the
                  future by writing to our grievance officer.{" "}
                </li>
                <li>
                  In the event you have a grievance regarding our privacy policy
                  or data usage policy, or data usage practices, you may reach
                  out to our customer support services at care@zokudo.com. In
                  the event where your queries/complaints are not resolved
                  within 30 days, you may contact our grievance officer
                  immediately in terms of our customer grievance redressal
                  policy available at{" "}
                  <a
                    href="https://www.zokudo.com/customer-grievance-redressal/"
                    className="text-green-500 font-medium"
                  >
                    {" "}
                    https://www.zokudo.com/customer-grievance-redressal/
                  </a>
                  :<br />
                  <ul className="list-disc mt-2">
                    <li>Grievance Officer: Mr. Ratnesh Jain</li>
                    <li>
                      Email:{" "}
                      <a
                        href="ratneshkjain@hmgroupindia.com"
                        className="font-medium text-green-500"
                      >
                        ratneshkjain@hmgroupindia.com
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Upon the user opting out to withdraw such consent, Zokudo will
                  not be under any obligation to provide the services/goods.
                </li>
              </ul>
            </li>
          </ul>
        </section>
</div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
