import React,{useEffect} from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import BaasSolution from "./component/BaasSolution";
import AboutUs from "./component/AboutUs";
import CorporateSolution from "./component/CorporateSolution";
import Demo from "./component/Demo";
import ContactUs from "./component/ContactUs";
import LogIn from "./component/LogIn";
import InvestorRelation from "./component/NvestorRelation";
import TermAndCondition from "./component/TermAndConditons";
import PrivacyPolicy from "./component/PrivacyPolicy";
import CustomerGreivience from "./component/CustomerGrevience";
import CustomerLiablity from "./component/CustomerLiability";
import FAQ from "./component/FAQ";
import Career from "./component/Career";
import Home from "./component/Home";
import Layout from "./component/common/Layout";
import Header from "./component/common/Header";
import Footer from "./component/common/Footer";
import TermsHindi from "./component/TermsHindi";
import PrivacyPolicyHindi from "./component/PrivacyPolicyHindi";
import JobPostings from "./component/JobPostings";
import TransactionHistory from "./component/TransactionHistory";


function App({ children }) {

  useEffect(() => {
    const isFirstLoad = sessionStorage.getItem('firstLoad') === null;

    if (isFirstLoad) {
      sessionStorage.clear();
      sessionStorage.setItem('firstLoad', 'false');
    }
  }, []);

  return (
    <div className="App">
      <Router>
        <Layout>
          <Routes>
            <Route index element={<Home />} />
            <Route path="/baas-solutions" element={<BaasSolution />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/corporate-solutions" element={<CorporateSolution />} />
            <Route path="/demo" element={<Demo />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/login" element={<LogIn />} />
            <Route path="/investor-relations" element={<InvestorRelation />} />
            <Route path="/terms-and-conditions" element={<TermAndCondition />} />
            <Route path="/terms-and-conditions/hindi" element={<TermsHindi />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/privacy-policy/hindi" element={<PrivacyPolicyHindi />} />
            <Route path="/admin" element={<LogIn />} />
            <Route
              path="/customer-grievance"
              element={<CustomerGreivience />}
            />
            <Route path="/customer-liability-policy" element={<CustomerLiablity />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/career" element={<Career />} />
            <Route path="/job-postings" element={<JobPostings />} />
            <Route path="/transaction-history" element={<TransactionHistory />} />
          </Routes>
        </Layout>
      </Router>
    </div>
  );
}

export default App;
