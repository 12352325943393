import React from "react";

import "../style/about-us.css";
import "../App.css";

import Footer from "./common/Footer";
import NavbarV2 from "./NavbarV2";

import aboutUs1 from "../assets/about-us-1.webp";
import aboutUs2 from "../assets/about-us-2.webp";
import aboutUs3 from "../assets/about-us-3.webp";
import aboutUs4 from "../assets/about-us-4.webp";

import aboutUsBg1 from "../assets/about-us-bg-1.svg";

import ourVision from "../assets/Our Vision.svg";
import ourMission from "../assets/Our Mission.svg";

import aboutUsRocket from "../assets/about-us-rocket.svg";

import imageWrapper from "../assets/image-wrapper.svg";

import yashMehtaImage from "../assets/yash-mehta.svg";
import ratneshImage from "../assets/ratnesh-jain.svg";
import amarpritGill from "../assets/amarprit.svg";
import AshishImage from "../assets/ashish.svg";
import nitinImg from "../assets/nitin.svg";
import raunakImg from "../assets/raunak.svg";

import jmFinancialImage from "../assets/jm-financial.svg";
import tataStellImage from "../assets/tata-steel.svg";
import cedarImage from "../assets/cedar.png";

import bankOfAmeric from "../assets/bank-of-america.svg";
import rajTravles from "../assets/raj-travels.svg";
import yoImage from "../assets/YO.svg";

import rbSource from "../assets/rbonesource.png";
import jioMoney from "../assets/jio-money.svg";
import airMoney from "../assets/airpay.svg";
import dataMatic from "../assets/datamatic.svg";

import upGrad from "../assets/upgrad.svg";
import frontRow from "../assets/frontrow.svg";
import epic from "../assets/epic.svg";

import mohan from "../assets/mohan.svg";
import swift from "../assets/swift.svg";
import indianBankAssociation from "../assets/indian-bank-ass.svg";
import unionBank from "../assets/union-bank.svg";
import pnb from "../assets/pnb.svg";

import apHota from "../assets/ap-hota.svg";
import npci from "../assets/npci.svg";
import rbi from "../assets/rbi-img.svg";
import iibanking from "../assets/ii-banking.svg";

import gauravGulati from "../assets/gaurav-gulati.svg";
import arora from "../assets/arora.svg";
import windlas from "../assets/windlas.svg";
import innova8 from "../assets/innov8.svg";

import journey from "../assets/road-map.svg";

import indusindBank from "../assets/indusind-bank.svg";
import lexConsulting from "../assets/lex-consulting.svg";
import dalalDoctors from "../assets/dalal-doctor.svg";
import azure from "../assets/azure.svg";
import hyperVerge from "../assets/hyperverge.svg";
import rupay from "../assets/rupay.svg";
import tala from "../assets/tala.png";
import m2p from "../assets/m2p.png";
import lex from "../assets/lex.png";
import triLegal from "../assets/tri-legal.png";
import npcl from "../assets/npcl.png";
import izoo from "../assets/izoo.png";
import aadhaar from "../assets/adhaar.svg";
import lgDots from "../assets/lg-dots.svg";
import waves from "../assets/waves.svg";
import bgImg from "../assets/bg.png";

import Collaborate from "./Collaborate";
import { motion } from "framer-motion";
import AboutUsPartners from "./ui/AboutUsPartners";
import Marquee from "./ui/Marquee";

function AboutUs() {
  return (
    <div>
      <div
        className="container-fluid  about-fluid py-10
       2xl:py-20 bg-[#FEFCF5]"
      >
        <div className="relative pt-10 pb-0 md:py-20 md:h-[450px]">
          <img
            src={lgDots}
            className="absolute left-0 z-[1] overflow-hidden top-5 h-[400px]"
          />
          <img
            src={waves}
            className="absolute left-[50%] z-[1] top-[50%] -translate-x-2/4 -translate-y-2/4 h-[400px]"
          />
          <div className="container relative grid grid-cols-1 md:grid-cols-2 z-20 py-10 md:py-0">
            <div className="text-left flex flex-col justify-center">
              <div className="pr-4">
                <motion.h3
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0 }}
                  className="font-semibold about-us-heading"
                >
                  
                  <span className="text-warning">
                    <strong>Revolutionize</strong>{" "}
                  </span>
                  Prepaid Digital
                  <span className="text-warning">
                    <strong> Payments with Zokudo </strong>
                  </span>
                  
                </motion.h3>

                <motion.p
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 1 * 0.1 }}
                  className="text-md text-gray-500"
                >
                  At Zokudo, we empower businesses to embrace the future of payments with our seamless prepaid solutions. From customizable RuPay prepaid cards and corporate gifting to scalable BaaS platforms, we simplify payment operations while enhancing efficiency and engagement. Whether you're rewarding employees or building a cutting-edge payment infrastructure, Zokudo offers the tools to transform your financial ecosystem.
                </motion.p>
                <a href="/contact-us">
                  <motion.button
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: 2 * 0.1 }}
                    className="fs-14 text-dark bg-[#E4C221] rounded-2 border-0 py-2 px-3 fs-600 pulse align-self-start"
                  >
                    Get Started
                  </motion.button>
                </a>
              </div>
            </div>
            <div className="flex gap-1 relative pt-5 md:p-5 about-rect-imgs">
              <motion.div
                initial={{ opacity: 0, y: 0 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 1, delay: 5 * 0.1 }}
                className="w-[60px] h-[60px] bg-[#E4C221] rect-1 absolute z-10 left-8 lg:left-2 top-[20%] 2xl:left-8"
              ></motion.div>
              <motion.div
                initial={{ opacity: 0, y: 0 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 1, delay: 5 * 0.1 }}
                className="w-[50px] h-[50px] bg-[#E4C221] rect-2 absolute z-10 top-[12%] right-[18%] lg:right-[20%] lg:top-[6%] 2xl:top-[8%] 2xl:right-[24%]"
              ></motion.div>
              <div className="flex flex-col gap-2 justify-end z-20">
                <motion.img
                  initial={{ opacity: 0, y: -40 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 1, delay: 1 * 0.1 }}
                  loading="lazy"
                  src={aboutUs1}
                  alt="about-us"
                  width="200"
                  height="100"
                  className="object-cover about-us-1"
                />
                <motion.img
                  initial={{ opacity: 0, y: 40 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 1, delay: 2 * 0.1 }}
                  loading="lazy"
                  src={aboutUs2}
                  className="object-cover about-us-2"
                />
              </div>
              <div className="flex flex-col justify-end z-20">
                <motion.img
                  initial={{ opacity: 0, y: 40 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 1, delay: 3 * 0.1 }}
                  loading="lazy"
                  src={aboutUs3}
                  className="object-cover about-us-3"
                />
              </div>
              <div className="flex flex-col justify-end">
                <motion.img
                  initial={{ opacity: 0, y: -40 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 1, delay: 4 * 0.1 }}
                  loading="lazy"
                  src={aboutUs4}
                  className="h-[200px] object-cover about-us-4"
                />
              </div>
              <motion.div
                initial={{ opacity: 0, y: 0 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 1, delay: 6 * 0.1 }}
                className="flex flex-col justify-end"
              >
                <div className="w-3 bg-[#EF5C51] h-[100px] lg:h-[120px]"></div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container-fluid mt-5">
            <div className="row d-flex justify-content-around flex-wrap">
                <div className="col-12 col-md-6 mb-3 mb-md-0 text-center ">
                    <h1>Know more <span className="text-warning"><strong> About us </strong></span></h1>
                    <h1><strong>Welcome to Zokudo</strong></h1>
                    <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt <br />
                        ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
                    <button className="btn btn-warning">Get Started Today</button>
                </div>

                <div className=" col-12 col-md-6 position-relative ">
                    <img className="position-absolute about-us-1 " src={aboutUs1} alt="About Us 1" />
                    <img className="position-absolute about-us-2 " src={aboutUs2} alt="About Us 2" />
                    <img className="position-absolute about-us-3 " src={aboutUs3} alt="About Us 3" />
                    <img className="position-absolute about-us-4 " src={aboutUs4} alt="About Us 4" />
                </div>
            </div>
        </div> */}

      <div className="clearfix clear"></div>
      <div className="container-fluid about-2-fluid">
        <div className="container d-flex flex-column align-items-center py-10 md:py-20 gap-3">
          
          <motion.h3
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0 }}
            className="text-warning fw-bold"
          >
            Redefining Prepaid Payments with Innovation, Trust, and Scalability
          </motion.h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 1 * 0.1 }}
              className="flex justify-between md:flex-row flex-col"
            >
              <img
                className="placeholder-glow w-[70%] md:w-4/5 m-auto col-md-4"
                src={aboutUsBg1}
              />
              <div className="d-flex flex-column justify-content-center gap-3 mt-4 md:mt-0 col-md-7 col-12">
                <div className="wrapper-data rounded-tr-xl">
                  <p className="text-sm">
                    RBI issued PPI license holder since 2014 (1 of 34)
                  </p>
                </div>
                <div className="wrapper-data rounded-tr-xl">
                  <p className="text-sm">
                    Perpetual license since 2022 (1 of 20)
                  </p>
                </div>
              </div>
            </motion.div>
            <div className="grid place-items-center">
              <motion.p
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 2 * 0.1 }}
                className="text-left text-gray-500 mx-auto w-full md:mt-5 mb-0"
              >
               Since our inception in 2014, Zokudo has been at the forefront of transforming prepaid payment systems in India. With an RBI-issued PPI license and the distinction of holding a perpetual license since 2022, we’ve built a reputation for reliability and innovation. Our mission is to simplify payment complexities with scalable, secure, and adaptable solutions tailored to the evolving needs of businesses.

              </motion.p>
              <motion.p
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 3 * 0.1 }}
                className="text-left text-gray-500 mx-auto w-full md:mt-5 mb-0"
              >
               We specialize in streamlining employee benefits, enhancing digital payments, and simplifying expense management. By seamlessly integrating into your operations, Zokudo delivers efficiency, compliance, and adaptability, empowering businesses to navigate the dynamic landscape of financial solutions with confidence.
              </motion.p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid about-bg p-0">
        <div className="bg-[#000000ea] h-full w-full flex flex-col pt-10 pb-4 md:py-20">
          <div className="container">
            <motion.button
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0 * 0.1 }}
              className="lg:m-auto bg-[#282828] text-gray-500 fs-600 align-self-start p-2 rounded mb-3"
            >
              Mission
            </motion.button>
            <motion.h3
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 1 * 0.1 }}
              className="text-warning font-semibold text-center"
            >
              Transforming Prepaid Payments with Precision
            </motion.h3>
            <motion.p
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 3 * 0.1 }}
              className="text-center text-gray-400"
            >
              We aim to revolutionise prepaid card payments in India by offering
              versatile,
              <br /> user-friendly, and secure solutions.
            </motion.p>
            <div className="row d-flex justify-content-center flex-wrap lg:mt-0 md:mt-5">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 4 * 0.1 }}
                className="col-12 text-left col-md-6 col-lg-5 text-white p-3 mb-3 d-flex flex-column justify-content-center"
              >
                <h5 className="text-[#E4C221]">
                  Innovating Payment Solutions for Tomorrow's Needs
                </h5>
                <p className="text-gray-500 text-sm">
                As industry leaders, we provide a comprehensive range of prepaid solutions designed to simplify transactions, enhance customer engagement, and drive business growth.
                </p>
                <div className="flex flex-col gap-3">
                  <div className="bg-[#252525] d-flex align-items-center gap-2 p-2 rounded-lg">
                    <img
                      src={ourVision}
                      alt="Our Vision"
                      className="img-fluid"
                    />
                    <div className="mt-1">
                      <h5 className="text-warning text-[14px] lg:text-[18px]">
                        Our Vision
                      </h5>
                      <p className="text-gray-400 text-[10px] lg:text-sm">
                      To redefine the future of financial transactions by becoming the premier global provider of innovative prepaid payment solutions. Through continuous innovation and a commitment to excellence, we aim to set new benchmarks in the industry while upholding the highest standards of integrity and trust.

                      </p>
                    </div>
                  </div>
                  <div className="bg-[#252525] d-flex align-items-center gap-2 p-2 rounded-lg">
                    <img
                      src={ourMission}
                      alt="Our Mission"
                      className="img-fluid"
                    />
                    <div className="mt-1">
                      <h5 className="text-warning text-[14px] lg:text-[18px]">
                        Our Mission
                      </h5>
                      <p className="text-gray-400 text-[10px] lg:text-sm">
                      To create meaningful and lasting value for all our partners by fostering collaboration, driving growth, and delivering tailored solutions. We are dedicated to empowering businesses to thrive in an ever-evolving financial ecosystem.

                      </p>
                    </div>
                  </div>
                </div>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, x: -80, y: 80 }}
                whileInView={{ opacity: 1, x: -0, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 4 * 0.1 }}
                className="col-12 col-md-6 col-lg-6 d-flex justify-content-center align-items-center md:p-3"
              >
                <img
                  src={aboutUsRocket}
                  alt="About Us Rocket"
                  className="img-fluid w-[70%] m-auto md:w-auto p-0"
                />
              </motion.div>
            </div>
          </div>
        </div>
      </div>
      <div className="teams-bg py-5">
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 0 * 0.1 }}
          className="container d-flex flex-column align-items-center gap-2"
        >
          <button className="m-auto solution-btn text-warning fs-600 align-self-start p-2 rounded mb-3">
            Meet Team Zokudo
          </button>
          <h3 className="text-warning font-semibold">
            A Fusion of Expertise and Innovation
          </h3>
          <p className="text-center text-sm md:w-3/5 lg:w-2/5">
            With expertise in finance, technology, and customer service, we work
            collaboratively to ensure that you receive the best support and
            guidance.
          </p>
        </motion.div>

        <div className="container mt-4">
          <div className="flex team-section overflow-y-hidden mx-auto md:justify-center gap-4">
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 1 * 0.1 }}
              className="card pt-4 p-0 person-card overflow-hidden md:w-[240px] w-[240px]"
            >
              <div className="card-body bg-white text-center p-0 relative">
                <div className="relative flex justify-center w-full">
                  <img className="img-wrapper-circle" src={imageWrapper} />
                  <img
                    className="w-[90px] absolute rounded-full left-[50%] top-[50%] -translate-x-2/4 -translate-y-2/4"
                    src={yashMehtaImage}
                  />
                </div>
                <br></br>
                <div className="person-info">
                  <p className="text-warning">Mr. Yash Mehta</p>
                  <p className="fw-bold">CEO and Founder</p>
                  <p className="pb-4 mb-4" style={{ fontSize: "13px" }}>
                    Qualified engineer from University of Michigan, BA from
                    Kingston University, MBA from SP Jain, Mumbai
                  </p>
                </div>
                <div className="bg-[#FFF3CB] flex justify-center gap-2 py-2 px-2 h-[30px] absolute bottom-0 w-full left-0 overflow-hidden">
                  <img src={jmFinancialImage} className="object-contain" />
                  <img src={tataStellImage} className="object-contain" />
                  <img src={cedarImage} className="object-contain" />
                </div>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 2 * 0.1 }}
              className="rounded-lg pt-4 p-0 person-card overflow-hidden md:w-[240px] w-[240px]"
            >
              <div className="card-body bg-white h-full text-center p-0 relative">
                <div className="relative flex justify-center w-full">
                  <img className="img-wrapper-circle" src={imageWrapper} />
                  <img
                    className="w-[90px] absolute rounded-full left-[50%] top-[50%] -translate-x-2/4 -translate-y-2/4"
                    src={ratneshImage}
                  />
                </div>
                <br></br>
                <div className="person-info">
                  <p className="text-warning">Mr. Ratnesh Jain</p>
                  <p className="fw-bold">Business Head & Nodal Officer</p>
                  <p className="pb-4 mb-4" style={{ fontSize: "13px" }}>
                    {" "}
                    (MBA (Marketing) CRKIMR, MEP (General Management) IIM
                    Ahmedabad{" "}
                  </p>
                </div>
                <div className="bg-[#FFF3CB] flex justify-center gap-2 py-2 px-2 h-[30px] absolute bottom-0 w-full left-0 overflow-hidden">
                  <img src={bankOfAmeric} className="object-contain" />
                </div>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 3 * 0.1 }}
              className="card pt-4 p-0 person-card overflow-hidden md:w-[240px] w-[240px]"
            >
              <div className="card-body bg-white text-center p-0">
                <div className="relative flex justify-center w-full">
                  <img className="img-wrapper-circle" src={imageWrapper} />
                  <img
                    className="w-[90px] absolute rounded-full left-[50%] top-[50%] -translate-x-2/4 -translate-y-2/4"
                    src={amarpritGill}
                  />
                </div>
                <br></br>
                <div className="person-info">
                  <p className="text-warning">Amarprit Kaur Gill</p>
                  <p className="fw-bold">Operations Lead</p>
                  <p className="pb-4 mb-4" style={{ fontSize: "13px" }}>
                    {" "}
                    PGDM Marketing & Certified Course - Operations Management
                    Program (NMIMS) with 10 years of Operations & Fintech
                    experience.{" "}
                  </p>
                </div>
                {/* <div className="bg-[#FFF3CB] flex justify-center gap-2 py-1 px-2"> */}
                {/* <img src={bankOfAmeric} className="object-contain" /> */}
                {/* </div> */}
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 4 * 0.1 }}
              className="card pt-4 p-0 person-card overflow-hidden md:w-[240px] w-[240px]"
            >
              <div className="card-body bg-white text-center p-0">
                <div className="relative flex justify-center w-full">
                  <img className="img-wrapper-circle" src={imageWrapper} />
                  <img
                    className="w-[90px] absolute rounded-full left-[50%] top-[50%] -translate-x-2/4 -translate-y-2/4"
                    src={AshishImage}
                  />
                </div>

                <br></br>
                <div className="person-info">
                  <p className="text-warning">CA Ashish Jain</p>
                  <p className="fw-bold">Finance & Compliance Head</p>
                  <p className="pb-4 mb-4" style={{ fontSize: "13px" }}>
                    BCOM and CA with 19 years of experience
                  </p>
                </div>
                <div className="bg-[#FFF3CB] flex justify-center gap-2 py-2 px-2 h-[30px] absolute bottom-0 w-full left-0 overflow-hidden">
                  <img src={rajTravles} className="object-contain h-4" />
                  <img src={yoImage} className="object-contain h-4" />
                  <img src={rbSource} className="object-contain h-4" />
                </div>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 5 * 0.1 }}
              className="card pt-4 p-0 person-card overflow-hidden md:w-[240px] w-[240px]"
            >
              <div className="card-body bg-white text-center p-0 relative">
                <div className="relative flex justify-center w-full">
                  <img className="img-wrapper-circle" src={imageWrapper} />
                  <img
                    className="w-[90px] absolute rounded-full left-[50%] top-[50%] -translate-x-2/4 -translate-y-2/4"
                    src={nitinImg}
                  />
                </div>

                <br></br>
                <div className="person-info">
                  <p className="text-warning">Mr. Nitin Shitole</p>
                  <p className="fw-bold">Engineering Lead</p>
                  <p className="pb-4 mb-4" style={{ fontSize: "13px" }}>
                    Master of Science in Information Technology from Mumbai
                    University
                  </p>
                </div>

                <div className="bg-[#FFF3CB] flex justify-center gap-2 py-2 px-2 h-[30px] absolute bottom-0 w-full left-0 overflow-hidden">
                  <img src={jioMoney} className="object-contain 2xl:h-5" />
                  <img src={airMoney} className="object-contain 2xl:h-5" />
                  <img src={dataMatic} className="object-contain 2xl:h-5" />
                </div>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 6 * 0.1 }}
              className="card pt-4 p-0 person-card overflow-hidden md:w-[240px] w-[240px]"
            >
              <div className="card-body bg-white text-center p-0 relative">
                <div className="relative flex justify-center w-full">
                  <img className="img-wrapper-circle" src={imageWrapper} />
                  <img
                    className="w-[90px] absolute rounded-full left-[50%] top-[50%] -translate-x-2/4 -translate-y-2/4"
                    src={raunakImg}
                  />
                </div>
                <br></br>
                <div className="person-info">
                  <p className="text-warning">Raunak Kumar</p>
                  <p className="fw-bold">Senior Product Manager </p>
                  <p className="pb-4 mb-4" style={{ fontSize: "13px" }}>
                    {" "}
                    Engineer with 6+ years of experience in B2B & B2C Industry,
                    with PGP in Product Management from Duke University, USA.{" "}
                  </p>
                </div>

                <div className="bg-[#FFF3CB] flex justify-center gap-2 py-2 px-2 h-[30px] absolute bottom-0 w-full left-0 overflow-hidden">
                  <img src={upGrad} className="object-contain 2xl:h-4" />
                  <img src={frontRow} className="object-contain 2xl:h-4" />
                  <img src={epic} className="object-contain 2xl:h-4" />
                </div>
              </div>
            </motion.div>
          </div>
        </div>

        <motion.div
          initial={{ opacity: 0, y: 0 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 0 * 0.1 }}
          className="container d-flex flex-column align-items-center mt-5 gap-3"
        >
          <button className="m-auto solution-btn text-warning fs-600 align-self-start p-2 rounded mb-3">
            Our Flagbearers of Excellence and Experience
          </button>
          <h3 className="text-warning font-semibold">
            Guiding Excellence with Experience
          </h3>
          <p className="text-center xl:w-2/5">
            Comprising seasoned experts with extensive industry experience, our
            seniors bring invaluable insights and guidance that drive Zokudo.
          </p>
        </motion.div>

        <div className="container senior-team overflow-y-hidden flex md:justify-center gap-3 pt-[100px] lg:pb-20">
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 1 * 0.1 }}
            className="card pt-4 p-0 person-card  mx-3 md:mb-[100px] lg:mb-0 w-[300px] md:md:w-[240px] w-[240px]"
            style={{ backgroundColor: "#FCF4E2" }}
          >
            <div className="card-body-ad text-center p-0 relative z-[1000]">
              <div className="absolute top-[-100px] flex justify-center w-full">
                <img className="img-wrapper-circle" src={imageWrapper} />
                <img
                  className="w-[90px] absolute rounded-full left-[50%] top-[50%] -translate-x-2/4 -translate-y-2/4"
                  src={mohan}
                />
              </div>
              <div className="person-info mt-[60px]">
                <p className="fw-bold">Mr. Mohan Tanksale</p>
                <p className="text-warning">Senior Advisor </p>
                <p className="pb-4 mb-4" style={{ fontSize: "13px" }}>
                  {" "}
                  (ex) Chairman & Managing Director of Central Bank of
                  India,(ex) CEO of the Indian Banks Association (IBA), (ex)
                  Executive Director Punjab National Bank, (ex) GM Union Bank of
                  India{" "}
                </p>
              </div>
              <div
                className=" bg-white flex justify-center gap-2 py-2 px-2 h-[30px] absolute bottom-0 w-full left-0 overflow-hidden"
                style={{
                  borderBottomLeftRadius: "12px",
                  borderBottomRightRadius: "12px",
                }}
              >
                <img src={swift} />
                <img src={indianBankAssociation} />
                <img src={unionBank} />
                <img src={pnb} />
              </div>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 10 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 2 * 0.1 }}
            className="card pt-4 p-0 person-card  mx-3 md:mb-[100px] lg:mb-0 w-[300px] md:md:w-[240px] w-[240px]"
            style={{ backgroundColor: "#FCF6DC" }}
          >
            <div className="card-body text-center p-0 relative">
              <div className="absolute top-[-100px] flex justify-center w-full">
                <img className="img-wrapper-circle" src={imageWrapper} />
                <img
                  className="w-[90px] absolute rounded-full left-[50%] top-[50%] -translate-x-2/4 -translate-y-2/4"
                  src={apHota}
                />
              </div>

              <div className="person-info mt-[60px]">
                <p className="fw-bold">Mr. A.P. Hota</p>
                <p className="text-warning">Senior Advisor </p>
                <p className="pb-4 mb-4" style={{ fontSize: "13px" }}>
                  {" "}
                  (ex) MD & CEO NPCI, (ex) Chief GM: Payments and Settlements at
                  Reserve Bank of India{" "}
                </p>
              </div>
              <div className="rounded-b-lg bg-white flex justify-center gap-2 py-2 px-2 h-[30px] absolute bottom-0 w-full left-0 overflow-hidden" style={{
                  borderBottomLeftRadius: "12px",
                  borderBottomRightRadius: "12px",
                }}>
                <img src={npci} />\
                <img src={rbi} />
                <img src={iibanking} />
                <img src={swift} />
              </div>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 10 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 3 * 0.1 }}
            className="card pt-4 p-0 person-card mb-0  mx-3 mb-[20px] md:mb-[100px] lg:mb-0 w-[300px] md:md:w-[240px] w-[240px]"
            style={{ backgroundColor: "#FCF4E2" }}
          >
            <div className="card-body text-center p-0 relative">
              <div className="absolute top-[-100px] flex justify-center w-full">
                <img className="img-wrapper-circle" src={imageWrapper} />
                <img
                  className="w-[90px] absolute rounded-full left-[50%] top-[50%] -translate-x-2/4 -translate-y-2/4"
                  src={gauravGulati}
                />
              </div>
              <div className="person-info mt-[60px]">
                <p className="fw-bold">Mr. Gaurav Gulati</p>
                <p className="text-warning">Senior Advisor </p>
                <p className="pb-4 mb-4" style={{ fontSize: "13px" }}>
                  {" "}
                  Founder & Managing Partner Aroa Ventures, (ex) COO Innov8
                  Coworking (Acquired by Oyo), (ex) Founder & COO Purist
                  (Acquired by Cure.Fit)){" "}
                </p>
              </div>
              <div className="rounded-b-lg bg-white flex justify-center gap-2 py-2 px-2 h-[30px] absolute bottom-0 w-full left-0 overflow-hidden" style={{
                  borderBottomLeftRadius: "12px",
                  borderBottomRightRadius: "12px",
                }}>
                <img src={arora} />
                <img src={windlas} />
                <img src={innova8} />
              </div>
            </div>
          </motion.div>
        </div>
      </div>

      <div className="container-fluid bg-black d-flex flex-column justify-content-around overflow-hidden text-center px-4 py-10 lg:pt-16 lg:pb-0">
        <div className="container journey-container px-0 md:px-auto" >
          <h3 className="text-warning fw-bold">
          Zokudo’s Journey and Achievements So Far
          </h3>
          <p className="text-center text-gray-400">
            Cumulative Traction Since Jan ’21
          </p>
          <div className="w-full h-full relative h-[600px]">
            <motion.div
              initial={{ opacity: 1 }}
              whileInView={{ opacity: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 1.4, delay: 0.5 }}
              className="bg-black w-full h-[300px] md:h-[600px] absolute top-0 left-0 z-[10]"
            ></motion.div>
            <img
              className="mx-auto scale-[1.08] md:scale-[1] d-block relative img-fluid md:w-4/5 object-contain lg:mt-[-50px] z-[1]"
              src={journey}
            />
          </div>
        </div>
      </div>

      <div className="container d-flex flex-column align-items-center mt-5 gap-1 md:gap-3">
        {/* <button className="m-auto solution-btn text-warning fs-600 align-self-start p-2 rounded mb-3">
          Partners
        </button> */}
        <h3 className="text-warning font-semibold">
          Transforming India’s Payment Landscape Together
        </h3>
        <p className="text-center text-gray-500 2xl:w-2/5 xl:w-3/5 lg:w-4/5 ">
          By collaborating with leading financial institutions, technology
          providers, and industry experts, we ensure that Zokudo remains at the
          forefront of prepaid payment solutions.
        </p>
      </div>
      <Marquee>
        <AboutUsPartners/>
      </Marquee>
      <Collaborate />
    </div>
  );
}

export default AboutUs;
