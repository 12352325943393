import React, { useState } from "react";
import { FaAngleDown } from "react-icons/fa6";
import { BsArrowLeft } from "react-icons/bs";
import axios from "axios";

export default function Transactions({ close }) {
  const [formData, setFormData] = useState({
    cardNumber: "",
    expiry: "",
    cvv: "",
    kitNumber: "",
  });

  const [balance, setBalance] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [responseMessage, setResponseMessage] = useState("");
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent page reload

    try {
      const response = await axios.post(
        "https://customer.zokudo.com/no-auth/get-card-details-by-kit",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const details = response.result;
      setBalance(details[0]);
      setTransactions(details[1]);
      // setResponseMessage("Data fetched successfully!");
      setError("");
    } catch (err) {
      setError("Failed to fetch card details.");
      setResponseMessage("");
    }
  };

  return (
    <div>
      <div className="add-new-job-form mt-[70px] md:mt-[150px] rounded-lg mb-20 w-full mx-4 md:w-4/5 lg:w-3/5 mx-auto border border-gray-200 overflow-hidden">
        <form className="space-y-4 text-left" onSubmit={handleSubmit}>
          <div className="bg-[#F7F7F7] py-3 px-4 text-left flex justify-between">
            <p className="font-medium text-md mb-0">Card Details</p>
            <div className="flex gap-2">
              <p className="mb-0 text-gray-400 font-semibold">Balance:</p>
              <p className="mb-0 font-semibold">Rs. {balance}</p>
            </div>
          </div>
          <div className="p-6">
            <div>
              <BsArrowLeft
                onClick={() => close()}
                className="mt-1 cursor-pointer my-2"
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="flex flex-col text-left">
                <label className="text-sm font-medium text-gray-500 mb-1">
                  Card Number*
                </label>
                <input
                  type="text"
                  name="cardNumber"
                  value={formData.cardNumber}
                  onChange={handleChange}
                  className="border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                />
              </div>
              <div className="flex flex-col text-left">
                <label className="text-sm font-medium text-gray-500 mb-1">
                  Card Expiry*
                </label>
                <input
                  type="text"
                  name="expiry"
                  value={formData.expiry}
                  onChange={handleChange}
                  className="border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-3">
              <div className="flex flex-col text-left">
                <label className="text-sm font-medium text-gray-500 mb-1">
                  Card CVV*
                </label>
                <input
                  name="cvv"
                  value={formData.cvv}
                  onChange={handleChange}
                  className="border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                />
              </div>
              <div className="flex flex-col text-left">
                <label className="text-sm font-medium text-gray-500 mb-1">
                  Proxy Card Number*
                </label>
                <input
                  name="kitNumber"
                  value={formData.kitNumber}
                  onChange={handleChange}
                  className="border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                />
              </div>
            </div>

            <div className="border border-gray-100 rounded-lg overflow-hidden mt-5">
              <div className="bg-[#F7F7F7] py-3 px-4 flex justify-between">
                <p className="font-semibold text-gray-400 mb-0">Transaction History</p>
                <FaAngleDown className="text-gray-400" />
              </div>

              <div className="overflow-x-auto">
                <table className="w-full border-collapse">
                  <thead className="bg-[#F7F7F7]">
                    <tr>
                      <th className="text-left py-3 px-4 text-gray-600 font-semibold">Date</th>
                      <th className="text-left py-3 px-4 text-gray-600 font-semibold">Merchant Name</th>
                      <th className="text-left py-3 px-4 text-gray-600 font-semibold">Amount (Rs.)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactions != null && transactions.length > 0 ? (
                      transactions.slice(0, 5).map((transaction, index) => (
                        <tr key={index} className="border-b border-gray-100">
                          <td className="py-6 px-8 text-gray-500">{transaction.createdAt.split("T")[0]}</td>
                          <td className="py-3 px-4 text-gray-500">{transaction.transactionAmount}</td>
                          <td className="py-3 px-4 text-gray-500">{transaction.merchantName}</td>        
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" className="py-3 px-4 text-gray-500 text-center">
                          No transactions available.
                       </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
