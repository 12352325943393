import React from "react";
import "../style/investor-relation.css";
import NavbarV2 from "./NavbarV2";
import Footer from "./common/Footer";

import investorRelation1 from "../assets/investor-relation-1.svg";
import investorRelation2 from "../assets/investor-relation-2.svg";
import investorRelationPdfIcon from "../assets/investor-relation-pdf-icon.svg";
import { GoDownload } from "react-icons/go";
import { motion } from "framer-motion";

function InvestorRelation() {
  return (
    <div className="container">
      <motion.section
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        viewport={{ once: true }}
        className="text-left md:px-4 investor-relation-section 2xl:w-[80%] mx-auto d-flex flex-column mt-[100px] mb-10"
      >
        <h3 className="text-warning text-start mt-4 mb-1">
          Transparency with Financial Reporting
        </h3>
        <p className="text-[14px] text-gray-500 md:w-3/5">
          Our detailed reports offer clear insights into our operations, helping
          you stay informed and confident in our commitment to responsible
          financial management.{" "}
        </p>
        <div className="bg-[#E7EDFE] border border-[#4263C1] rounded-[20px]  flex md:flex-row flex-col gap-2 justify-between p-4 mt-2">
          <img
            className="img-fluid w-[60%] md:w-[25%] m-auto"
            style={{ height: "210px" }}
            src={investorRelation1}
          />
          <div className="grow flex flex-col justify-center gap-4 md:pl-10">
            <div className="financial flex flex-col lg:flex-row justify-between align-items-center gap-2 lg:gap-4 rounded px-3 lg:px-4">
              <div className="flex gap-2">
                {" "}
                <img className="w-4" src={investorRelationPdfIcon} />{" "}
                <p className="mb-0 mt-1 text-sm">2021-2022 Annual Financial</p>{" "}
              </div>
              <a
                href="/investor-relation/Auditor Opinio,BS & PL-PEBV-21-22.pdf"
                target="_blank"
                className="no-underline"
              >
                <button className="bg-[#E4C221] rounded-full border px-3 py-1 text-black flex gap-1 text-sm">
                  {" "}
                  <GoDownload className="mt-1" /> Download Pdf
                </button>
              </a>
            </div>
            <div className="financial flex flex-col lg:flex-row justify-between align-items-center gap-2 lg:gap-4 rounded px-3 lg:px-4">
              <div className="flex gap-2">
                {" "}
                <img className="w-4" src={investorRelationPdfIcon} />{" "}
                <p className="mb-0 mt-1 text-sm">2022-2023 Annual Financial</p>{" "}
              </div>
              <a
                href="/investor-relation/PEV FInancial 2022-23.pdf"
                target="_blank"
                className="no-underline"
              >
                <button className="bg-[#E4C221] rounded-full border px-3 py-1 text-black flex gap-1 text-sm">
                  {" "}
                  <GoDownload className="mt-1" /> Download Pdf
                </button>
              </a>
            </div>
            <div className="financial flex flex-col lg:flex-row justify-between align-items-center gap-2 lg:gap-4 rounded px-3 lg:px-4">
              <div className="flex gap-2">
                {" "}
                <img className="w-4" src={investorRelationPdfIcon} />{" "}
                <p className="mb-0 mt-1 text-sm">2023-2024 Annual Financial</p>{" "}
              </div>
              <a
                href="/investor-relation/PEV FInancial 2023-24.pdf"
                target="_blank"
                className="no-underline"
              >
                <button className="bg-[#E4C221] rounded-full border px-3 py-1 text-black flex gap-1 text-sm">
                  {" "}
                  <GoDownload className="mt-1" /> Download Pdf
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className="whiteboard flex flex-col text-center md:text-left md:flex-row justify-between rounded align-items-center mt-10 px-4 py-4">
          <div className="md:text-left">
            <h6 className="font-bold">Whitepapers</h6>
            <p className="text-sm text-gray-500 mb-0">
              Downloadable product detail in PDF for in-depth knowledge
            </p>
          </div>
          <a
            href="/investor-relation/Zokudo Product Deck.pdf"
            target="_blank"
            className="no-underline"
          >
            <button className="bg-[#E4C221] rounded-full border px-3 py-1 text-white flex gap-1 text-sm mt-2 md:mt-0">
              {" "}
              <GoDownload className="mt-1" /> Download Pdf
            </button>
          </a>
        </div>
      </motion.section>
    </div>
  );
}

export default InvestorRelation;

//https://ladakibahin.maharashtra.gov.in/
