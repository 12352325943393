export default function TermsTable1() {
    const fees = [
      { service: "Card Issuance Fee", charges: "Rs. 250 (Excluding Taxes)" },
      { service: "Card Replacement Fees", charges: "Rs. 250 (Excluding Taxes)" },
      { service: "Card Block / Unblocking Fee", charges: "Rs. 50 (Excluding Taxes)" },
      { service: "Card Closure Fees", charges: "Rs. 100 (Excluding Taxes)" },
      { service: "Account Inactive Fee", charges: "Rs. 50 (Excluding Taxes)" },
      { service: "Annual Fee", charges: "Rs. 100 (Excluding Taxes)" },
      { service: "Registration fee", charges: "NIL" },
      { service: "Issuance fees", charges: "NIL" },
      { service: "Loading fees", charges: "NIL" },
      { service: "Plastic card fees", charges: "INR 200 per card" },
      { service: "Lost card fees", charges: "INR 200 per card" },
      { service: "Lost PIN fees", charges: "NIL" },
      { service: "Reissuance of digital card", charges: "NIL" },
      { service: "Refund fees", charges: "NIL" }
    ];
  
    return (
      <div className="w-full overflow-x-auto">
        <table className="min-w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-100">
              <th className="px-6 py-3 border border-gray-300 text-left text-sm font-semibold text-gray-700 min-w-[200px]">
                Service Charge/Fee
              </th>
              <th className="px-6 py-3 border border-gray-300 text-left text-sm font-semibold text-gray-700 min-w-[200px]">
                Charges
              </th>
            </tr>
          </thead>
          <tbody>
            {fees.map((fee, index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                <td className="px-6 py-4 border border-gray-300 text-sm text-gray-600">
                  {fee.service}
                </td>
                <td className="px-6 py-4 border border-gray-300 text-sm text-gray-600">
                  {fee.service === "Plastic card fees" || fee.service === "Lost card fees" ? (
                    <span className="text-blue-600">{fee.charges}</span>
                  ) : fee.charges === "NIL" ? (
                    <span className="text-blue-600">{fee.charges}</span>
                  ) : (
                    fee.charges
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
  