export default function TermsTable1Hindi() {
    const fees = [
        { service: "कार्ड जारी करने की फीस", charges: "रु. 250 (करों को छोड़कर)" },
        { service: "कार्ड प्रतिस्थापन शुल्क", charges: "रु. 250 (करों को छोड़कर)" },
        { service: "कार्ड ब्लॉक/अनब्लॉकिंग शुल्क", charges: "रु. 50 (करों को छोड़कर)" },
        { service: "कार्ड समापन शुल्क", charges: "रु. 100 (करों को छोड़कर)" },
        { service: "खाता निष्क्रिय शुल्क", charges: "रु. 50 (करों को छोड़कर)" },
        { service: "वार्षिक शुल्क", charges: "रु. 100 (करों को छोड़कर)" },
        { service: "पंजीकरण शुल्क", charges: "शून्य" },
        { service: "जारी करने का शुल्क", charges: "शून्य" },
        { service: "लोडिंग शुल्क", charges: "शून्य" },
        { service: "प्लास्टिक कार्ड शुल्क", charges: "प्रति कार्ड INR 200" },
        { service: "खोया हुआ कार्ड शुल्क", charges: "प्रति कार्ड INR 200" },
        { service: "खोया हुआ पिन शुल्क", charges: "शून्य" },
        { service: "डिजिटल कार्ड का पुनः जारी करना", charges: "शून्य" },
        { service: "वापसी शुल्क", charges: "शून्य" }
    ];
  
    return (
      <div className="w-full overflow-x-auto">
        <table className="min-w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-100">
              <th className="px-6 py-3 border border-gray-300 text-left text-sm font-semibold text-gray-700 min-w-[200px]">
                
सेवा प्रभार / शुल्क
              </th>
              <th className="px-6 py-3 border border-gray-300 text-left text-sm font-semibold text-gray-700 min-w-[200px]">
              शुल्क
              </th>
            </tr>
          </thead>
          <tbody>
            {fees.map((fee, index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                <td className="px-6 py-4 border border-gray-300 text-sm text-gray-600">
                  {fee.service}
                </td>
                <td className="px-6 py-4 border border-gray-300 text-sm text-gray-600">
                  {fee.service === "Plastic card fees" || fee.service === "Lost card fees" ? (
                    <span className="text-blue-600">{fee.charges}</span>
                  ) : fee.charges === "NIL" ? (
                    <span className="text-blue-600">{fee.charges}</span>
                  ) : (
                    fee.charges
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
  