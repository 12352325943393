import React, {useEffect,useState} from 'react'
import Toggle from "react-toggle";
import { FaCirclePlus } from "react-icons/fa6";
import List from "./List";
import { Link } from "react-router-dom";
import JSEncrypt from "jsencrypt";
import axios from "axios";
import CryptoJS from "crypto-js";


export default function JobsList({postJob}) {

  const [isVerified, setIsVerified] = useState(false)

  useEffect(() => {
    getSessionData();
  }, []);

  const getSessionData = () => {
    let email = sessionStorage.getItem("email");
    let token = sessionStorage.getItem("token");

    if (email != null && token != null) {
      const bytes = CryptoJS.AES.decrypt(token, "mySecretKey");
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      console.log("decrypted data ",decryptedData);
          if (decryptedData == email) {
            setIsVerified(true);
          }
  }};

  return (
    <div>
        <div className="flex justify-between mb-6">
          <p className="mb-0 py-2 text-gray-500">Current Job</p>
          {
            isVerified == true ?  <button className="flex gap-2 add-new-job-btn text-black px-4 rounded py-2" onClick={postJob}>
            <FaCirclePlus className="text-black mt-1" /> Add New
          </button> : ""
          }
        </div>
        <List publicView={!isVerified}/>
    </div>
  )
}
