import React from "react";
import { AiFillCaretRight } from "react-icons/ai";
import TermsTable1 from "./TermsTable1";
import TermsTable2 from "./TermsTable2";
import TermsTable3 from "./TermsTable3";
export default function GPCardsTerms() {
  return (
    <div className="text-left">
      <h6 className="text-warning flex gap-1">
        {" "}
        <AiFillCaretRight className="text-[#E4C221]" />
        TERMS & CONDITIONS FOR GENERAL PURPOSE RELOADABLE CARD
      </h6>
      <p>
        The terms and conditions (hereinafter referred to as "Terms and
        Conditions") mentioned herein are applicable to the usage of the "GPR
        Prepaid Card" and required to be agreed to and accepted before its
        usage. The Terms and Conditions specified below govern and constitute
        the entire agreement and/or arrangement between you and Premium
        eBusiness Venture Private Limited in relation to the GPR Prepaid Card,
        as amended from time to time, at the sole discretion of Premium
        eBusiness Venture Private Limited.
      </p>
      <p>
        By completing the sign-up process for availing the GPR Prepaid Card, you
        are deemed to have expressly read, understood and accepted each of the
        Terms and Conditions mentioned herein. You agree to be bound by all the
        Terms and Conditions mentioned herein, as may be amended from time to
        time, at the sole discretion of Premium eBusiness Venture Private
        Limited.
      </p>
      <p>
        These Terms and Conditions, read with the privacy policy
        <a
          href="https://www.zokudo.com/privacy-policy"
          className="text-green-500"
        >
          (https://www.zokudo.com/privacy-policy)
        </a>{" "}
        and the customer grievance redressal policy{" "}
        <a
          href="https://www.zokudo.com/customer-grievance-redressal"
          className="text-green-500"
        >
          (https://www.zokudo.com/customer-grievance-redressal)
        </a>
        and additional terms (if any) on the Application Form, form the entire
        contract between the Customer and PEV for usage of the GPR Prepaid Card.
      </p>

      <div class="accordion tnc-accordion" id="accordionExample">
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              1.Definitions
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <p>
                In these Terms and Conditions, unless the contrary intention
                appears and/or the context otherwise requires, capitalized terms
                defined by:
              </p>
              <ol className="list-[upper-roman]">
                <li>
                  inclusion in quotations and/ or parenthesis have the meanings
                  so ascribed
                </li>
                <li>
                  the following terms shall have the meanings assigned to them
                  herein below:
                </li>
              </ol>
              <ol class="list-decimal">
                <li>
                  <strong>"Card Account"</strong>: Refers to a prepaid account
                  with an account balance equivalent to the amount loaded on
                  such PPI, for the purpose of monitoring the limits available
                  on such PPI.
                </li>
                <li>
                  <strong>"Application Form"</strong>: Means, as the context may
                  permit or require, the GPR Prepaid Card application form
                  submitted by the Customer to PEV for the purpose of applying
                  for and/or availing the GPR Prepaid Card along with all
                  requisite information, particulars, clarifications and
                  declarations, if any, furnished by the Customer from time to
                  time in connection with the GPR Prepaid Card.
                </li>
                <li>
                  <strong>"GPR Prepaid Card"</strong>: Means a Prepaid
                  Instrument ("PPIs") (issued as card whether physical or
                  virtual), being semi-closed or open loop payment instruments
                  that facilitate purchase of goods and services, including
                  financial services, etc. against the value stored on such
                  instruments, as defined in the Master Direction on Issuance
                  and Operation of Prepaid Payment Instruments dated December
                  29, 2017, as amended from time to time.
                </li>
                <li>
                  <strong>"Charges"</strong>: Mean such levy, costs and charges
                  levied by PEV for usage of the GPR Prepaid Card, as amended
                  from time to time.
                </li>
                <li>
                  <strong>"Customer"</strong>: Means any individual, limited
                  liability partnership firm, partnership, society, company or
                  any other organization or entity under applicable laws, who
                  obtain/purchase PPIs from PEV and use the same for purchase of
                  goods and services, including financial services, etc. against
                  the value stored on such PPIs.
                </li>
                <li>
                  <strong>"Customer Care Centre"</strong>: Refers to Customer
                  Service provided by PEV for addressing all queries, complaints
                  raised by the Customer or any details or information sought by
                  the Customer in relation to the GPR Prepaid Card.
                </li>
                <li>
                  <strong>"KYC"</strong>: Means the Know Your Customer
                  guidelines adopted by PEV for the purpose of identification
                  and verification of the Customer, pursuant to the guidelines,
                  circular and notifications, issued by the Reserve Bank of
                  India, from time to time, including but not limited to the
                  Master Direction on Issuance and Operation of Prepaid Payment
                  Instruments dated December 29, 2017, as amended from time to
                  time.
                </li>
                <li>
                  <strong>"Merchant Establishments"</strong>: Mean such
                  establishments including but not limited to stores, shops,
                  restaurants, hotels, POS located in India, which accept GPR
                  Prepaid Card or any card on the RuPay network. 
                </li>
                <li>
                  <strong>"Personal Identification Number (PIN)"</strong>: Is a
                  numeric password which has to set by the Customer, basis
                  instructions provided by PEV along with the PPI.
                </li>
                <li>
                  <strong>"PEV"</strong>: Means Premium eBusiness Venture
                  Private Limited, a company within the meaning of the Companies
                  Act, 1956 having its registered office at 42, Navketan
                  Industrial Estate, Mahakali Caves Road, Andheri (East), Mumbai
                  - 400093.
                </li>
                <li>
                  <strong>"POS" or "Point of Sale"</strong>: Means electronic
                  terminals maintained by Merchant Establishments in India at
                  which the Customer can use the GPR Prepaid Card for the
                  purpose of purchasing the goods and services, including
                  financial services, etc.
                </li>
                <li>
                  <strong>"Program"</strong>: Means PEV’s program to offer the
                  GPR Prepaid Card to the Customer.
                </li>
                <li>
                  <strong>"Transaction"</strong>: Means any transaction
                  initiated on the GPR Prepaid Card by the Customer at any RuPay
                  affiliated Merchant Establishment.
                </li>
                <li>
                  <strong>"Website"</strong>: Means the website owned,
                  established and maintained by PEV located at the URL:
                  www.zokudo.com including any modifications or amendments
                  thereto from time to time.
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              2.FEATURES OF GPR PREPAID CARDS
            </button>
          </h2>
          <div
            id="collapseTwo"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <ol className="list-decimal">
                <li>
                  <strong>Full KYC GPR Prepaid Card:</strong> <br />
                  <ol class="list-decimal">
                    <li>
                      The amount outstanding in a full KYC GPR Prepaid Card at
                      any point in time shall not exceed Rs. 2,00,000 (Indian
                      Rupees Two Lac only).
                    </li>
                    <li>
                      The daily load limit of the full KYC GPR Prepaid Card
                      shall be Rs. 4,00,000 (Indian Rupees Four Lac only).
                    </li>
                    <li>
                      While no separate limits are applicable, PEV reserves the
                      right to impose such limits (within the overall limit
                      provided above or prescribed by the Reserve Bank of India
                      from time to time) as it may deem fit.
                    </li>
                    <li>
                      The Customer shall be issued the full KYC GPR Prepaid Card
                      only on the completion of all the KYC requirements in
                      conformity with the Master Direction on Issuance and
                      Operation of Prepaid Payment Instruments dated December
                      29, 2017 (and other directions referred thereunder), as
                      amended from time to time.
                    </li>
                    <li>
                      PEV shall provide the option of incorporating the
                      pre-designated bank account at the time of issuing the GPR
                      card/wallet for full KYC customers.
                    </li>
                    <li>
                      The Customer may close the full KYC GPR Prepaid Card at
                      any time, by giving a prior written notice of 30 (thirty)
                      days to PEV, save and except, upon request by the Customer
                      for hot listing or blocking the GPR Prepaid Card, in which
                      case the GPR Prepaid Card shall be terminated and/or
                      cancelled with immediate effect. The balance shall be
                      transferred as per the applicable limits of the full KYC
                      GPR Prepaid Card to their pre-designated bank account.
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>
                    Terms applicable to full KYC GPR Prepaid Cards:
                  </strong>{" "}
                  <br />
                  <ol class="list-decimal">
                    <li>
                      The GPR Prepaid Card shall be issued by PEV, subject to
                      the Customer fulfilling the eligibility criteria as
                      stipulated by PEV, from time to time.
                    </li>
                    <li>
                      The GPR Prepaid Card shall not be transferable to other
                      individual or party.
                    </li>
                    <li>
                      The GPR Prepaid Card shall be valid only within the
                      territory of India and for transactions in Indian Rupees
                      only. The GPR Prepaid Card cannot be used outside the
                      territory of India or for any transactions denominated in
                      foreign currency.
                    </li>
                    <li>
                      The GPR Prepaid Card is the exclusive property of PEV.
                    </li>
                    <li>
                      The Customer shall be required to sign on the reverse side
                      of the GPR Prepaid Card (as may be applicable) immediately
                      upon its receipt. PEV reserves the right to reject or
                      cancel any Transaction in absence of the signature or
                      mismatch of the signature, without further notice or
                      intimation to the Customer.
                    </li>
                    <li>
                      PEV shall not be liable to pay any interest on any balance
                      maintained in the GPR Prepaid Card or the Card Account at
                      any point in time.
                    </li>
                    <li>
                      Cash-out or refund or funds transfer shall not be
                      permitted on GPR Prepaid Card.
                    </li>
                    <li>
                      PEV will inform or intimate the Customer 45 (forty five)
                      days prior to the expiry of the GPR Prepaid Card through
                      SMS on the registered mobile number of the Customer.
                      Customer needs to utilize the entire credit balance
                      available on the GPR Prepaid Card prior to the expiry of
                      the GPR Prepaid Card. In case the Customer does not
                      utilize the credit balance available on the GPR Prepaid
                      Card within the validity period, the Customer can approach
                      PEV for refund/transfer of outstanding balance to a
                      pre-designated account or any other bank account of the
                      Customer.
                    </li>
                    <li>
                      The yearly load limit of the full KYC GPR Prepaid Card
                      shall be Rs. 25,00,000 (Indian Rupees Twenty-Five Lacs
                      Only).
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              3.ISSUANCE AND USAGE OF THE GPR PREPAID CARD
            </button>
          </h2>
          <div
            id="collapseThree"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <ol class="list-decimal">
                <li>
                  The services are not available to any Customer previously
                  suspended or removed from services by PEV. The Customer shall
                  not impersonate any person or entity, or falsely state or
                  otherwise misrepresent identity, age or affiliation with any
                  person or entity.
                </li>
                <li>
                  The Customer unconditionally and irrevocably authorizes PEV to
                  deliver and handover the GPR Prepaid Card to the Customer or
                  the entity (such as a co-brand partner or a delivery service)
                  which will distribute it to the Customer(s). PEV shall not be
                  liable or responsible for any act or omission on the part of
                  the entity, in connection with the delivery of the GPR Prepaid
                  Card to the Customer.
                </li>
                <li>
                  PEV and the Merchant Establishments, reserve the right to
                  refuse the acceptance of the GPR Prepaid Card at any time for
                  any reasons whatsoever, without any prior intimation to the
                  Customer.
                </li>
                <li>
                  The GPR Prepaid Card shall be used only for bona fide personal
                  or official purposes. It is clarified that charges incurred
                  may, in case of certain Merchant Establishments, include a
                  charge for availing certain additional service or other
                  facility being provided by such Merchant Establishments.
                </li>
                <li>
                  The Customer shall sign and retain all the charge slips
                  generated for each of the Transaction consummated at a
                  Merchant Establishment (as may be applicable). PEV shall not
                  be obliged to provide copies of the charge slips or
                  Transaction slips to the Customer. Any such request by the
                  Customer will be at the sole discretion of PEV and provided
                  such requests have been made by the Customer within 45 (forty
                  five) days from the date of the Transaction. The Customer
                  agrees that PEV shall be entitled to charge additional cost or
                  charge for providing copies of the charge or Transaction
                  slips.
                </li>
                <li>
                  Any charge or cost levied by the Merchant Establishment in
                  relation to any Transaction, shall be directly settled by the
                  Customer with the Merchant Establishment. PEV shall not be
                  liable or responsible, either directly or indirectly, for any
                  act or omission on the part of the Merchant Establishment or
                  the charges or cost levied by them in relation to the
                  Transaction.
                </li>
                <li>
                  All refunds and adjustments due to any Merchant Establishment
                  on account of device error or communication link will be
                  processed manually and the Card Account will be credited after
                  due verification by PEV pursuant to the applicable rules,
                  regulations and internal policies of PEV. The Customer agrees
                  that any subsequent Transactions will be accepted or honoured
                  only basis the available credit balance in the Card Account(s)
                  without taking into account any disputed amount under
                  consideration by PEV. The Customer shall unconditionally keep
                  PEV indemnified against any and all loss or damage caused to
                  PEV on account of dishonouring the payment instructions as a
                  result of insufficient funds in the Card Account. The Customer
                  agrees that PEV shall also be entitled to deduct the amount of
                  such loss or damage caused to PEV, directly from the Card
                  Account.
                </li>
                <li>
                  The Customer acknowledges that all refunds in case of failed,
                  returned, rejected or cancelled Transactions done by the
                  Customer using any other payment instrument cannot be credited
                  in the GPR Prepaid Card.
                </li>
                <li>
                  The Customer undertakes to act in good faith at all times in
                  relation to all dealings in relation to the GPR Prepaid Card.
                  The Customer accepts full responsibility for any illegal or
                  wrongful use of the GPR Prepaid Card in contravention of the
                  Terms and Conditions contained herein and, undertakes and
                  agrees to indemnify PEV against any and all loss, damage,
                  interest, conversion, any other financial charge that PEV may
                  incur and/or suffer, whether directly or indirectly, as a
                  result of the Customer committing any violations of the Terms
                  and Conditions contained herein.
                </li>
                <li>
                  The Customer agrees that he/she will not use the GPR Prepaid
                  Card for making payment(s) of any goods and services, which is
                  illegal under the applicable laws. The GPR Prepaid Card cannot
                  be used for making purchases of prohibited or contraband
                  products or services, including but not limited to, lottery
                  tickets, banned or prohibited magazines, participation in
                  sweepstakes, purchase of bitcoins, payment for call-back
                  services, etc.
                </li>
                <li>
                  The Customer hereby agrees to receive SMS or emailing alerts
                  from PEV for all Transactions done using the GPR Prepaid Card.
                  The SMS or emailing alerts from PEV shall stipulate debit and
                  credit Transactions, balance available or remaining on the GPR
                  Prepaid Card or such other information or details as
                  stipulated by PEV, from time to time.
                </li>
                <li>
                  The Customer agrees and confirms that the PIN shall under no
                  circumstances be revealed by the Customer to any relative or
                  family members or third party. The Customer shall be solely
                  responsible and liable for the consequences arising out of
                  such unauthorized disclosure of PIN and/or unauthorized usage
                  of the GPR Prepaid Card. The Customer disclaims all
                  responsibility and liability of PEV arising out of or in
                  connection with the unauthorized usage of the GPR Prepaid Card
                  and/or the Customer shall indemnify PEV for any and all losses
                  or damages, whether direct or indirect, incurred by PEV as a
                  result of such misuse. If the Customer forgets or misplaces
                  the PIN, the Customer should make an application in writing or
                  contact the Customer Care Centre for a new PIN immediately.
                  The new PIN will be sent to the Customer’s registered email
                  address (or through such other mode as may be specified by PEV
                  from time to time), provided to or available with the PEV. 
                </li>
                <li>
                  The Customer agrees that PEV may, at its sole discretion,
                  utilize the services of external service provider/s or agent/s
                  and on such terms as required or necessary, in relation to its
                  services.
                </li>
                <li>
                  The Customer shall have access to Frequently Asked Questions
                  as uploaded by PEV on its Website, and as amended from time to
                  time.
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              4.BREACH
            </button>
          </h2>
          <div
            id="collapseFour"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <ol class="list-decimal">
                <li>
                  In the event of any breach of these Terms and Conditions, or
                  of terms and conditions otherwise applicable to it by the
                  Customer, PEV shall have the sole right to forthwith cancel or
                  terminate the GPR Prepaid Card, without any further claim,
                  demand or dispute upon PEV.
                </li>
                <li>
                  The Customer undertakes and agrees to indemnify PEV against
                  any and all loss, damage, claim, penalty, cost, charges or
                  expenses (including legal counsel fees) that PEV may incur
                  and/or suffer, whether directly or indirectly, as a result of
                  the Customer committing any breach of the Terms and Conditions
                  contained herein, or terms and conditions otherwise applicable
                  to it.
                </li>
              </ol>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
              5.VALIDITY AND CANCELLATION OF GPR PREPAID CARDS
            </button>
          </h2>
          <div
            id="collapseFive"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <ol class="list-decimal">
                <li>
                  The GPR Prepaid Card shall be valid for a period of 3 (three)
                  years from date of printing or until the expiry date printed
                  on the face of the GPR Prepaid Card. Without prejudice to the
                  foregoing, PEV shall be entitled to specify a longer validity
                  period at its sole discretion.
                </li>
                <li>
                  The Customer hereby acknowledges and agrees that if there are
                  no Transactions for a consecutive period of 1 (one) year on
                  the GPR Prepaid Card, subject to validity of the GPR Prepaid
                  Card, the GPR Prepaid Card shall be made inactive by PEV after
                  sending a notice to the Customer. The GPR Prepaid Card can
                  only be reactivated by PEV after validations and requisite due
                  diligence, and upon payment of necessary Charges, as
                  stipulated by PEV, from time to time.
                </li>
                <li>
                  The Customer agrees and undertakes to destroy the GPR Prepaid
                  Card (in case of physical GPR Prepaid Cards) upon its expiry
                  or earlier cancellation to prevent any third party from using
                  it.
                </li>
                <li>
                  The Customer understands that such cancellation or expiry will
                  not take effect (in case of physical GPR Prepaid Cards) until
                  the GPR Prepaid Card has been defaced by cutting off the top
                  right-hand corner, ensuring that both the hologram and the
                  magnetic strip have been cut and has been destroyed or
                  received by PEV. The Customer agrees that the Customer shall
                  continue to be liable for any Charges incurred on the GPR
                  Prepaid Card prior to the termination of the GPR Prepaid Card,
                  irrespective of the fact whether the Customer has, or claims
                  to have destroyed the GPR Prepaid Card (in case of physical
                  GPR Prepaid Cards).
                </li>
                <li>
                  PEV may at its sole discretion terminate or cancel the GPR
                  Prepaid Card:
                  <br />
                  <ol class="list-decimal">
                    <li>
                      In the event the Customer is declared insolvent or upon
                      death of the Customer;
                    </li>
                    <li>
                      In the event of the Customer committing breach of any of
                      the terms, conditions, stipulations or its obligations
                      under these Terms and Conditions or terms and conditions
                      otherwise applicable to it;
                    </li>
                    <li>
                      In the event of any restriction imposed on the Customer by
                      an order of a competent court or order issued by any
                      regulatory or statutory authority in India or any
                      investigating agency;
                    </li>
                    <li>
                      In the event the Program becomes illegal under the
                      applicable laws, rules, guidelines or circulars;
                    </li>
                    <li>In the event the entire Program is terminated.</li>
                  </ol>
                </li>
                <li>
                  PEV, at its sole discretion, reserves the right to, either
                  temporarily or permanently, withdraw the privileges on the GPR
                  Prepaid Card and/or terminate the GPR Prepaid Card at any time
                  without giving any notice or assigning any reason thereof. In
                  case of a temporary withdrawal, the privileges attached to the
                  GPR Prepaid Card shall be reinstated by PEV at its sole
                  discretion. In case of a permanent withdrawal, PEV has a right
                  to cancel the GPR Prepaid Card permanently. However, it is
                  made distinctly clear that withdrawal (temporary or permanent)
                  shall constitute automatic withdrawal of all benefits,
                  privileges and services attached to the GPR Prepaid Card. The
                  Customer agrees that in the event of temporary or permanent
                  withdrawal of the GPR Prepaid Card, the Customer shall
                  continue to be fully liable for all Charges incurred on the
                  GPR Prepaid Card prior to such withdrawal, together with all
                  other applicable Charges thereon, unless otherwise specified
                  by PEV.
                </li>
                <li>
                  If PEV temporarily or permanently, withdraw the privileges or
                  terminates the GPR Prepaid Card, PEV reserves its right, on
                  best effort basis, to promptly notify the Customer. PEV shall
                  not be held liable or responsible for any such delays or
                  laches in receipt of such notification.
                </li>
                <li>
                  Upon termination of the GPR Prepaid Card as stated above, the
                  balance amount, if any lying in the Card Account shall be
                  refunded to the Customer subject to the deduction of
                  applicable Charges, by way of Pay Order / Demand Draft drawn
                  in favour of the Customer and receipt of written request from
                  the Customer, subject further at all times, to the extant
                  regulations of the Reserve Bank of India in this regard.
                  Customer shall submit a request with PEV for refund of the
                  balance amount.
                </li>
                <li>
                  The customer can ask for a refund of remaining balance amount
                  any time after the expiry period is over for 3 years. If the
                  customer does not ask for refund within 3 years after expiry
                  of the card, then PEV will transfer the remaining amount to
                  its P & L.
                </li>
              </ol>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSeven"
              aria-expanded="false"
              aria-controls="collapseSeven"
            >
              6.REGISTRATION DATA, DATA PROTECTION AND ACCOUNT SECURITY
            </button>
          </h2>
          <div
            id="collapseSeven"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <ol class="list-decimal">
                <li>
                  The Customer agrees to:
                  <ol className="list-[upper-roman]">
                    <li>
                      provide accurate, current and complete information about
                      him as may be prompted by any registration forms on the
                      Website or the Application Form (“Registration Data”);
                    </li>
                    <li>maintain the security of the PIN;</li>
                    <li>
                      maintain and promptly update the Registration Data and any
                      other information that he provides to PEV; and
                    </li>
                    <li>
                      be fully responsible for all use of his GPR Prepaid Card.
                    </li>
                  </ol>
                </li>
                <li>
                  PEV will process the Customers’ personal data (including
                  Registration Data) in order to provide a Card in accordance
                  with the Customer’s instructions, to administer the GPR
                  Prepaid Card, to deal with any queries the Customer has
                  concerning the GPR Prepaid Card or its use, for statistical
                  and regulatory reporting and fraud prevention purposes.
                </li>
                <li>
                  PEV will confidentially and only to the extent permitted under
                  applicable data protection and bank secrecy laws, give
                  Customer information (including Registration Data) to other
                  companies and third parties including those providing
                  application processing, fraud monitoring, customer service,
                  card production and technology processing services and each of
                  their agents and subcontractors.
                </li>

                <li>
                  PEV may disclose the Customer’s personal data (including
                  Registration Data) to:
                  <ol className="list-[upper-roman]">
                    <li>the Customer,</li>
                    <li>
                      fraud prevention agencies if false or inaccurate
                      information is provided and fraud is suspected, and
                    </li>
                    <li>
                      statutory/regulatory authorities, law enforcement or fraud
                      prevention agencies where we are required to do so by
                      applicable law or court order.
                    </li>
                  </ol>
                </li>
                <li>
                  PEV may record and/or monitor telephone calls to help maintain
                  high quality service and for security, data collection and
                  training purposes, or as required by applicable law. All
                  recordings belong to PEV.
                </li>
                <li>
                  The Customer consents to the processing (including transfer)
                  of its personal data (including Registration Data) by PEV, and
                  the other recipients identified above, for the purposes set
                  out above.
                </li>
                <li>
                  The Customer hereby consents to PEV and/or its affiliates
                  retaining all data, records and history in relation to the GPR
                  Prepaid Card (including the Registration Data) and grants them
                  all rights related to the data, including access to such data
                  by archiving its copy and the right to share such data in
                  de-identified form with any third party. PEV, its affiliates
                  or any third party with whom such data is shared may process
                  and use the data in perpetuity in de-identified form for any
                  purpose, including for commercial and research purposes, by
                  way of aggregation of data to create statistical, predictive,
                  clinical or other data models or any other way.
                </li>
              </ol>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseNine"
              aria-expanded="false"
              aria-controls="collapseNine"
            >
              7.Communication Policy
            </button>
          </h2>
          <div
            id="collapseNine"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <ol class="list-decimal">
                <li>
                  The Customer accepts that PEV may send requisite alerts to the
                  mobile phone number provided by the Customer while registering
                  for availing the services or to any such number replaced as
                  informed by the Customer. The Customer acknowledges that the
                  alerts will be received only if the mobile phone is in “ON”
                  mode to receive the alerts via SMS. If the mobile phone is in
                  “OFF” mode then the Customer may not receive or receive after
                  delay any alerts sent during such period.
                </li>
                <li>
                  The Customer also agrees to receive information regarding what
                  PEV perceives to be of their interest based on usage history
                  via SMS, email & phone call. This includes offers, discounts
                  and general information. In case the Customer does not want
                  such information, they have to explicitly ask to be excluded.
                </li>
              </ol>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTen"
              aria-expanded="false"
              aria-controls="collapseTen"
            >
              8.NOTICES
            </button>
          </h2>
          <div
            id="collapseTen"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <ol class="list-decimal">
                <li>
                  Any notice to be given by PEV hereunder shall be deemed to
                  have been received by the Customer, within 7 (seven) days from
                  the date of the notice (when sent physically on the mailing
                  address) or immediately when sent via email at the registered
                  email provided to, or available with, PEV.
                </li>
                <li>
                  Any notice to be given by the Customer to PEV hereunder shall
                  be deemed to have been received by PEV only upon
                  acknowledgement of receipt of such notice by PEV at its
                  Corporate Office Address at – 42, Navketan Industrial estate,
                  Mahakali Caves Road, Andheri (East), Mumbai 400093. Any notice
                  or communication from the Customer hereunder shall not be
                  binding on PEV unless the same is in writing and shall have
                  been served and acknowledged by PEV or if by registered post,
                  acknowledgement card.
                </li>
              </ol>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseEleven"
              aria-expanded="false"
              aria-controls="collapseEleven"
            >
              9.CHARGES
            </button>
          </h2>
          <div
            id="collapseEleven"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <ol class="list-decimal">
                <li>
                  Charges shall include: <br />
                  <ol className="list-decimal">
                    <li>
                      Any fees charged by PEV in respect of the GPR Prepaid
                      Card, including replacement, renewal, handling, and other
                      fees, if any. These fees are non-refundable,
                      non-transferrable and non-assignable in nature.
                    </li>
                    <li>
                      Service charges on specific types of Transactions. The
                      method of computation of such Charges will be as notified
                      by PEV from time to time on its Website.
                    </li>
                  </ol>
                </li>
                <li>
                  All Charges, in the absence of manifest error, shall be final
                  and binding on the Customer and shall be conclusive in nature.
                </li>
                <li>
                  All statutory taxes, goods and service tax, all other imposts,
                  duties (including stamp duty and relevant registration
                  charges, if any, in connection with the GPR Prepaid Card) and
                  taxes (of any description whatsoever) as may be levied from
                  time to time by the government or other authority in respect
                  of or in connection with the GPR Prepaid Card, will be borne
                  by the Customer.
                </li>
              </ol>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOneTwo"
              aria-expanded="false"
              aria-controls="collapseOneTwo"
            >
              10.SCHEDULE OF CHARGES
            </button>
          </h2>
          <div
            id="collapseOneTwo"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
                <br/>
              <TermsTable1/>
              <p>Nothing contained herein shall, however, prevent PEV from deducting tax deductible at source as required under the applicable laws, from any Transaction through the GPR Prepaid Card. </p>
              <p>This schedule may be also be otherwise displayed on the Website, and may be amended from time to time at PEV’s sole discretion.</p>
              <TermsTable2/>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOneThree"
              aria-expanded="false"
              aria-controls="collapseOneThree"
            >
              11.LOST, STOLEN OR MISUSED GPR PREPAID CARD
            </button>
          </h2>
          <div
            id="collapseOneThree"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
            <ol class="list-decimal">
<li>If the GPR Prepaid Card is lost or stolen, or otherwise misused, the Customer must immediately report such loss or theft to Customer Care Centre and request for immediate suspension/blocking/hot listing of the GPR Prepaid Card.</li>
<li>The Customer acknowledges that once the GPR Prepaid Card is reported lost, stolen, misused or damaged, and the same is suspended/blocked/hot listed, such GPR Prepaid Card cannot be used again, even if found subsequently (in case of physical cards). 

The Customer is responsible for the security of the GPR Prepaid Card and shall take all steps towards ensuring that the GPR Prepaid Card is not misused. In the event PEV determines or receives any information that the Customer has neglected or refused or failed to take steps as indicated above, in case of loss, theft or destruction of the GPR Prepaid Card, PEV reserves the sole right to cancel or terminate such GPR Prepaid Card.</li>
<li>No liability shall accrue upon PEV for any unauthorized transactions done on the GPR Prepaid Card, after such GPR Prepaid Card has been reported lost, stolen, misused or damaged by the Customer and before the Card being blocked by PEV. However, in case of any dispute relating to the time of reporting and/ or transaction/s made on the GPR Prepaid Card, post reporting of the GPR Prepaid Card being lost, stolen, misused or damaged, all decisions of PEV shall be final and binding on the Customer.</li>
</ol>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne12"
              aria-expanded="false"
              aria-controls="collapseOneFour"
            >
              12.EXCLUSION OF LIABILITY
            </button>
          </h2>
          <div
            id="collapseOne12"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <ol className="list-decimal">
<li>Without prejudice to the foregoing, PEV shall be under no liability or responsibility to the Customer or any third party, in respect of any loss or damage arising, directly or indirectly out of:<br/>
<ol class="list-decimal">
<li>Any defect in any goods or services provided or supplied by Merchant Establishment;</li>
<li>Any disputes regarding the quality, value warranty, delay of delivery, non-delivery, non-receipt of any goods or services provided or supplied by Merchant Establishment;</li>
<li>The refusal of any person to honour or accept the GPR Prepaid Card;</li>
<li>Non-functioning of GPR Prepaid Card in the desired manner for whatsoever reason;</li>
<li>The malfunction of any computer terminal;</li>
<li>Any force majeure events;</li>
<li>Transfer of the GPR Prepaid Card to any third party;</li>
<li>Termination of the GPR Prepaid Card by the Customer;</li>
<li>Any loss or damage caused to the Customer alleged to have been caused by the repossession of the GPR Prepaid Card by PEV;</li>
<li>Any discrepancy in the amount allocated to a GPR Prepaid Card, where such amount has been allocated upon receipt of instructions in this regard from the Customer;</li>
<li>Any reversals done on the GPR Prepaid Card on request of the Customer.</li>
</ol>
</li>
<li>It is hereby clarified that the liability of PEV shall at all times be restricted to the extent of the amount lying in the Card Account of the Customer at the relevant point of time.</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne13"
              aria-expanded="false"
              aria-controls="collapseOneFour"
            >
              13.DISPUTED TRANSACTIONS
            </button>
          </h2>
          <div
            id="collapseOne13"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
            <ol class="list-decimal">
<li>Any Charge or Transaction slip or other payment requisition (whether electronically or otherwise) received by PEV and/or the Customer 

for payment shall be conclusive proof of such Charge, unless the GPR Prepaid Card is lost, stolen or fraudulently misused and evidenced by the Customer.</li>
<li>All disputed Transactions in relation to the GPR Prepaid Card shall be required to be raised with the Customer Care Centre maintained by PEV, within 15 (fifteen) days from the date of the disputed Transaction. The Customer acknowledges that any request, after 15 (fifteen) days from the date of the disputed Transaction shall not be accepted by PEV. In this regard, please see the customer grievance redressal policy available here:<a href=" https://www.zokudo.com/customer-grievance-redressal" className="text-green-500"> https://www.zokudo.com/customer-grievance-redressal</a>. </li>
</ol>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne14"
              aria-expanded="false"
              aria-controls="collapseOneFour"
            >
              14.QUALITY OF GOODS AND SERVICES
            </button>
          </h2>
          <div
            id="collapseOne14"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
            <p>Any dispute with or complaint against any Merchant Establishment regarding any goods purchased or services availed must be directly resolved by the Customer with the Merchant Establishment.</p>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne15"
              aria-expanded="false"
              aria-controls="collapseOneFour"
            >
              15.GOVERNING LAW, JURISDICTION AND DISPUTE RESOLUTION
            </button>
          </h2>
          <div
            id="collapseOne15"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
            <ol class="list-decimal">
<li>All disputes arising in relation to these Terms and Conditions shall be governed by and construed in accordance with the laws

 of India and shall be subject to the exclusive jurisdiction of the courts of Mumbai.</li>
<li>Without prejudice to anything contained above, in the event of a dispute, difference or claim between PEV and the Customer, arising out of these Terms and Conditions or the construction or interpretation hereof or otherwise in relation hereto, the Customer and PEV shall first endeavour to settle such difference, dispute, claim or question by mutual discussion within 15 (fifteen) days of notice received from one of the parties to the other, failing which the same shall be finally settled in accordance with the provisions of the Arbitration and Conciliation Act, 1996 as may be amended or re-enacted from time to time. The dispute shall be resolved by an arbitral tribunal composed of a sole arbitrator selected by the mutual agreement of both PEV and the Customer. The arbitration proceedings shall be conducted in the English language. The arbitration proceedings shall be seated in Mumbai, and the award of the tribunal should be reasoned and shall be final.</li>
</ol>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne16"
              aria-expanded="false"
              aria-controls="collapseOneFour"
            >
              16.CHANGING THESE TERMS AND CONDITIONS
            </button>
          </h2>
          <div
            id="collapseOne16"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
            <ol class="list-decimal">
<li>PEV reserves the sole right to change, at any time and from time to time, these Terms and Conditions, features and benefits offered on the GPR Prepaid Card, including but not limited to Charges or rates, offers, privileges, limits and methods of calculation.</li>
<li>PEV shall communicate the amended Terms and Conditions by hosting them on its Website, or in any other manner as may be decided by PEV at its sole discretion.</li>
<li>The Customer shall be responsible for reviewing these Terms and Conditions, including amendments thereto as may be posted on the PEV Website and shall be deemed to have accepted the amended Terms and Conditions by continuing to use the GPR Prepaid Card or within 24 (twenty four) hours of the amended Terms and Conditions becoming available, whichever comes first.</li>
</ol>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne17"
              aria-expanded="false"
              aria-controls="collapseOneFour"
            >
              17.CASHBACK
            </button>
          </h2>
          <div
            id="collapseOne17"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
            <ol class="list-decimal">
<li><strong>Terms & conditions for Cashback:</strong><br/>
<ol class="list-decimal">
<li>Cashback will be provided against Merchant Establishments which are shown on the offers/brand pages of the application/Website or as advertised by the specific Merchant Establishment on its portal/store.</li>
<li>Cashback value is shown as part of the offer and can be a percentage value based on transaction amount (Example 5% (Five Per Cent)) or flat value (Example Rs. 150 (Indian Rupees One Hundred Fifty only)).</li>
<li>Cashback is instant cashback which means as soon as the authorization is successful it will be credited to the GPR Prepaid Card instantly.</li>
<li>Cashback is to the GPR Prepaid Card balance/pocket which can be reused as normal balance.</li>
<li>Cashback will be provided in the same GPR Prepaid Card that is used to make the purchase.</li>
<li>Cashback will be provided in the same pocket that is used to make the purchase – example, travel pocket (see below for further details on “pocket”).</li>
<li>Cashback is calculated on transaction amount and not the billing amount (which includes taxes, GST, etc).</li>
<li>In case of refund or reversal, the complete cashback will be reversed, even if the refund is for a partial value.</li>
<li>In case of refund or reversal any shortfall in recovering, cashback will be deducted against the outstanding balance of the GPR Prepaid Card/pocket.</li>
<li>It is clarified that the provision of cashback shall at all times be subject to the sole and absolute discretion of PEV. The Customer shall have no claims whatsoever in respect of such cashback against PEV.</li>
</ol>

</li>
<li><strong>Details on how cashback will be provided if limits are crossed:</strong><br/>
<ol className="list-decimal">
    <li><strong>KYC Limit:</strong> There are two limits which describe the KYC limits for Cashback – Anytime balance limit & Monthly/Annual Load Limit. Described below are details how cashback will be provided if a Customer comes under any of the instances:<br/>
    <ol className="list-decimal">
        <li>Anytime balance limit: <br/>
        <p>Cashback will be calculated on transaction value. If a Customer has Rs. 10,000 (Indian Rupees Ten Thousand only) balance on the GPR Prepaid Card and he will receive cashback as per the (%) set on the backend and will receive it depending on the Transaction he has done. For e.g.: He has balance of Rs. 10,000/- (Indian Rupees Ten Thousand only) on the GPR Prepaid Card and made a Transaction worth Rs. 500/- (Indian Rupees Five Hundred only), cashback set on the backend is 1% (One Per Cent) then the Customer will get cashback of Rs. 5/- (Indian Rupees Five only) and the balance available on the GPR Prepaid Card will be Rs. 9505/- (Indian Rupees Nine Thousand Five Hundred Five only).</p>

        </li>
        <li>Monthly/Annual Load Limit:<br/>
        <p>Cashback will be calculated by adding up all loads to the GPR Prepaid Card in last 30 (thirty) days or last 1 (one) year.</p>
        </li>
    </ol>
    </li>
    <li><strong>Pocket Limit:</strong> <br/>
    <ol className="list-decimal">
        <li>Cashback is provided to the same pocket which has been used to perform the Transaction.

        </li>
        <li>Even if (pocket balance + cashback amount) is greater than  max pocket limit, PEV will provide the cashback. Also, the pocket balance will be updated for future use.
        </li>
    </ol>
    </li>
</ol>

</li>
</ol>
<p>For the purpose of these Terms and Conditions, “pocket” shall mean a group of similar Merchant Establishments, which are grouped based on the merchant category codes (“MCC”). Transaction against the Merchant Establishments with the MCC in the pocket are limited to the specific pocket balance. A GPR Prepaid Card will have either a default balance (which can be used across MCC’s) or balance by pockets. </p>
<p><strong>Illustratively:</strong><br/>
A corporate wants their user to use the GPR Prepaid Card at certain MCC only like food, travel and so on, then they can upload the balance to the GPR Prepaid Card by defining these pockets. 
</p>
<p>Pocket A – Food – 2000/-</p>
<p>Pocket B – Travel – 6000/-</p>
<p>Pocket C – Shopping – 2000/-</p>
<p>For more details, please reach out to the Customer Care Centre.</p>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne18"
              aria-expanded="false"
              aria-controls="collapseOneFour"
            >
              18.REDEMPTION OF GPR CARDS
            </button>
          </h2>
          <div
            id="collapseOne18"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
            <p>The GPR Prepaid Cards can be redeemed at any of the Merchant Establishments which accept RuPay instruments as and by way of payment. However, there are a few MCCs, as mentioned below, wherein the GPR Prepaid Cards cannot be redeemed.</p>
            <TermsTable3/>
            </div>
          </div>
        </div>
        <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne19"
                        aria-expanded="false"
                        aria-controls="collapseOneThree"
                      >
                        19.Help Line
                      </button>
                    </h2>
                    <div
                      id="collapseOne19"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p>
                          Call us at our Customer Care Centre No.{" "}
                          <a
                            href="telto:8655000444"
                            className="text-green-500 font-medium"
                          >
                            8655000444
                          </a>{" "}
                          or e-mail us at{" "}
                          <a
                            href="mailto:care@zokudo.com"
                            className="text-green-500 font-medium"
                          >
                            care@zokudo.com
                          </a>{" "}
                          or write to us at 42, Navketan Industrial estate,
                          Mahakali Caves Road, Andheri (east), Mumbai – 400 093.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne20"
                        aria-expanded="false"
                        aria-controls="collapseOneThree"
                      >
                        20.NODAL OFFICER
                      </button>
                    </h2>
                    <div
                      id="collapseOne20"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p>
                        Name :- Mr. Ratnesh Jain
                        </p>
                        <p>Email :- <a href="mailto:ratneshkjain@hmgroupindia.com">ratneshkjain@hmgroupindia.com</a></p>
                        <p>Address :- Premium eBusiness Venture Pvt. Ltd., 42, Navketan Industrial Estate, Mahakali Caves Road, Andheri (East), Mumbai – 40009300000000</p>
                      <p>Phone number :- <a href="telto:91-22-66756002" className="text-green-600 text-lg font-medium">91-22-66756002</a> Ext: <span className="text-green-600 text-lg font-medium"> 128</span></p>
                      </div>

                    </div>
                  </div>
      </div>
    </div>
  );
}
