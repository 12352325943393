import React from "react";
import "../style/collaborate.css";

import baas1Bottom from "../assets/baas-1-bottom.svg";
import baas2Bottom from "../assets/baas-2-bottom.svg";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
function Collaborate() {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.8 }}
      whileInView={{ opacity: 1, scale: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
      className="container-fluid my-5"
    >
      <div className="baas-bottom-banner flex relative justify-center py-4 md:py-10">
        <img
          className="md:block hidden absolute left-10 top-6 opacity-70 z-10"
          src={baas1Bottom}
        />
        <img
          className="md:block hidden absolute bottom-0 right-10 opacity-70 z-10"
          src={baas2Bottom}
        />
        <div className="align-self-center text-center z-20">
          <p className="text-white font-bold text-md md:text-xl">
            Don't just keep up with the competition—get ahead.
          </p>
          <div className="bg-white inline-block py-2 w-[200px] cursor-pointer relative duration-300 hover:w-[220px] hover:py-3">
            <Link to="/contact-us">
              <button className="btn btn-warning z-20 relative">
                Get In Touch
              </button>
            </Link>
            <div className="bg-black w-[98%] h-[90%] absolute left-[50%] top-[50%] -translate-x-2/4 -translate-y-2/4 p-1"></div>
            <div className="absolute h-full bg-black left-[50%] w-[80%] top-0 z-10 -translate-x-2/4"></div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Collaborate;
