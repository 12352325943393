import React, {useState} from 'react'
import Transactions from './Transactions'
import AddCardForm from './AddCardForm'

export default function TransactionHistory() {
    const [showTransactions, setShowTransactions] = useState(false)
  return (
    <div>
        {
            showTransactions ?  <Transactions close={() => setShowTransactions(false)}/> :<AddCardForm submitCard={() => setShowTransactions(true)}  />
        }
      </div>
    
  )
}
