import React, { useEffect } from "react";

import "../style/navbar.css";
import { TypeAnimation } from "react-type-animation";

import zokudoCard1 from "../assets/zokudo_card_1.png";
import zokudoCard2 from "../assets/zokudo_card_2.png";
import svgIcon from "../assets/chip.svg";
import googlePlay from "../assets/g-play-final.svg";
import googlePlayIcon from "../assets/g-play-icon.png";
import appleStoreIcon from "../assets/apple-play-icon.png";
import appStore from "../assets/appDownload.png";
import dashboard from "../assets/iph.png";
import objects from "../assets/objects.png";
import d1 from "../assets/1.png";
import d2 from "../assets/2.png";
import d3 from "../assets/3.png";
import d4 from "../assets/4.png";
import d5 from "../assets/5.png";
import d6 from "../assets/6.png";
import d7 from "../assets/7.png";
import d8 from "../assets/8.png";
import d9 from "../assets/9.png";
import d10 from "../assets/10.png";
import d11 from "../assets/11.png";
import d12 from "../assets/12.png";
import rbiImage from "../assets/rbilogo.png";
import bassImg1 from "../assets/bass-group-1.png";
import bassImg2 from "../assets/bass-group-2.png";
import bassImg3 from "../assets/bass-group-3.png";
import bassImg4 from "../assets/bass-group-4.png";
import bassImg5 from "../assets/bass-group-5.png";
import bassImg6 from "../assets/bass-group-6.png";
import bassImg7 from "../assets/bass-group-7.png";
import arcImg from "../assets/arc.png";

import iphone1 from "../assets/iPhone 11 Pro.png";
import iphone2 from "../assets/iph2.png";
import lap1 from "../assets/lap3.png";

import blog1 from "../assets/blog1.webp";
import blog2 from "../assets/blog2.png";
import blog3 from "../assets/blog3.png";
import blog4 from "../assets/blog4.png";

import fb from "../assets/contact-fb.png";
import insta from "../assets/contact-insta.png";
import linked from "../assets/contact-linked.png";
import mail from "../assets/contact-mail.png";

import phone from "../assets/contact-phone.png";
import twitter from "../assets/contact-twitter.png";
import contactImg from "../assets/contact.png";
import circleImg from "../assets/circle.svg";
import linkdink from "../assets/linkdin-foot-ico.png";

import bannerBackground from "../assets/contact-us-banner.png";
import iphoneCut2Img from "../assets/iphone-cut.png";
import NavbarV2 from "./NavbarV2";
import { Link } from "react-router-dom";
import Form from "./ui/Form";
import { FaLinkedin } from "react-icons/fa";
import { motion } from "framer-motion";
import vergado_wallet from "../assets/vergado_wallet.png";
function Home() {
  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    transition: { duration: 0.5 },
  };

  return (
    <div>
      <div className="Navbar h-[85vh] lg:h-[100vh] 2xl:h-[85vh]">
        <section className="h-full section1">
          <div className="container h-full">
            <div className="grid grid-cols-1  md:grid-cols-2 text-left h-full xl:mt-0 mt-4">
              <div className="pt-[50px] pb-[20px] md:py-0 flex flex-col justify-center mt-10 2xl:mt-0">
                <motion.div
                  initial={{ opacity: 0, y: 30 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                  viewport={{ once: true }}
                >
                  <motion.p
                    variants={itemVariants}
                    className="main-h text-[25px] lg:text-[35px] fw-bold text-white hl"
                  >
                    
                    <span className="text-warning text-[30px] lg:text-[40px]">
                    Your{" "} 
                    </span>
                    Partner for Seamless
                    <br></br> <span className="text-warning text-[30px] lg:text-[40px]">Prepaid Card Solutions</span>
                  </motion.p>
                  <motion.p
                    variants={itemVariants}
                    className="text-secondary hl"
                  >
                    We issue Rupay prepaid cards under our RBI-approved PPI license, ensuring reliable network-branded solutions across India.
                  </motion.p>
                  <div className="flex gap-3">
                  <Link to="/contact-us">
                    <motion.button
                      variants={itemVariants}
                      className="mt-3 fs-14 text-dark default-gredint rounded-2 border-0 py-2 px-3 fs-600 pulse hl"
                    >
                      Get Started
                    </motion.button>
                    
                  </Link>
                  <Link to="/transaction-history">
                    <motion.button
                      variants={itemVariants}
                      className="mt-3 fs-14 text-yellow-400 rounded-2 py-2 px-3 fs-600 pulse hl"
                      style={{border:"1px solid #E4C221"}}
                    >
                      Check Card Balance
                    </motion.button>
                    
                  </Link>
                  </div>
                  
                </motion.div>
              </div>
              <div className="h-full hidden md:block">
                <div className="grid grid-cols-2 h-full">
                  <div className="grid place-items-center">
                    <img
                      src={svgIcon}
                      className="ml-[40px] 2xl:mt-0 mt-[50px]"
                      alt="Zokudo Card 1"
                    />
                  </div>
                  <div className="flex flex-col justify-center">
                    <div className="group">
                      <motion.div
                        initial={{ y: -30 }}
                        whileInView={{ y: 20 }}
                        transition={{ duration: 0.5 }}
                        viewport={{ once: true }}
                      >
                        <img
                          src={zokudoCard1}
                          className="scale-[1.5] duration-300 mt-[20px] 2xl:ml-[-60px] ml-[-40px] group-hover:mt-[30px]"
                          alt="Zokudo Card 1"
                        />
                      </motion.div>
                      <motion.div
                        initial={{ y: 60 }}
                        whileInView={{ y: 0 }}
                        transition={{ duration: 0.5 }}
                        viewport={{ once: true }}
                      >
                        <img
                          src={zokudoCard2}
                          className="scale-[1.5] duration-300 mt-[-110px] 2xl:ml-[-60px] ml-[-40px] group-hover:mt-[10px]"
                          alt="Zokudo Card 2"
                        />
                      </motion.div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="flex justify-center hidden-xs-px-3">
        <div className="bg-[#F6B83A] download-banner flex justify-center gap-2 sm:gap-3 py-2">
          <div className="md:flex gap-4">
            <div className="flex flex-col justify-center download-text-1">
              <span className="text-[12px] md:text-md text-black fw-semibold">
                Download Official
              </span>
            </div>
            <div className="flex flex-col justify-center download-text-2">
              <span className="text-sm md:text-md text-white fw-semibold">
                Zokudo App
              </span>
            </div>
          </div>
          <div className="flex gap-2">
            <a
              className="text-decoration-none text-white"
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.zokudo"
            >
              <div className="bg-black py-1 px-2 rounded-md text-left flex gap-1">
                <img src={googlePlayIcon} className="object-contain w-4 md:w-6" />
                <div className="custom-text">
                  <p className="mb-0 text-[8px] font-light">GET IT ON</p>
                  <p className="mb-0 text-[10px] md:text-[12px]">Google Play</p>
                </div>
              </div>
            </a>
            <a
              className="text-decoration-none text-white"
              target="_blank"
              href="https://apps.apple.com/in/app/zokudo/id1553087937"
            >
              <div className="bg-black py-1 px-2 rounded-md text-left flex gap-1 overflow-hidden">
                <img src={appleStoreIcon} className="object-contain w-4 md:w-[26px]" />
                <div>
                  <p className="mb-0 text-[8px] font-light">Download on the</p>
                  <p className="mb-0 text-[10px] md:text-[12px]">App Store</p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </section>

      <div className="container lg:py-16 pt-10 pb-3">
        <div className="row rounded-pills-container">
          <div className="col-12 col-lg-12 col-xl-5 d-flex flex-column justify-content-center">
            <motion.button
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
              className="solution-btn text-warning fs-600 align-self-start p-2 rounded"
            >
              Solutions
            </motion.button>
            <motion.h2
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
              className="text-warning text-left text-2xl fw-semibold linear-gredint pt-3 pb-2 md:w-4/5"
            >
              Prepaid Solutions for Corporates
            </motion.h2>
            <motion.p
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
              className="text-secondary text-left gray-txt"
            >
              With Zokudo, you can easily allocate funds through secure,
              user-friendly prepaid cards, providing a seamless payment
              experience for all business-related expenses. Whether you are
              disbursing salaries, overseeing travel costs, or offering employee
              benefits, our prepaid card solutions help you easily manage
              everything.
            </motion.p>
            {/* <p className="text-secondary text-left gray-txt">
              With our Rupay prepaid solutions, you have full control over
              budget allocation, allowing you to set precise limits for
              transactions. This ensures that funds are used exactly as
              intended, providing both security and flexibility for your
              financial management.
            </p> */}
            <div className="flex justify-start">
              <Link to="/corporate-solutions">
                <motion.button
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ delay: 0.2 }}
                  viewport={{ once: true }}
                  className="custom-button align-self-start border-0 p-1 rounded px-3"
                >
                  Know More <i className="bi bi-arrow-right fw-bold"></i>
                </motion.button>
              </Link>
            </div>
          </div>

          <div class="col-12 col-lg-12 col-xl-5 2xl:scale-[1] circle-wrapper group">
            <div className="circle outer w-[400px] h-[400px]">
              <div className="circle outer w-[250px] h-[250px]">
                <motion.img
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ delay: 0.2 }}
                  viewport={{ once: true }}
                  src={dashboard}
                  className="w-4/5 object-contain absolute z-20 top-[40%] left-[50%] -translate-x-[50%] -translate-y-[40%]"
                />
                <img
                  src={objects}
                  className="object-contain absolute z-10 top-[40%] left-[50%] -translate-x-[50%] -translate-y-[40%]"
                />
              </div>
            </div>
            <div class="dashboard-menu-container absolute h-full fw-medium py-[200px]">
              <div className="group-hover:shadow-xl duration-300 dashboard-menu rounded-pill pill-1 top-[12%] left-[16%] 2xl:px-4 border px-2 2xl:text-[12px] text-[10px] p-1 border-[#F9B73D]">
                <img src={d1} alt="dashboard" />
                &nbsp; Rewards Programs
              </div>
              <p className="group-hover:shadow-xl duration-300 dashboard-menu rounded-pill pill-2 top-[27%] left-[16%] 2xl:px-4 border px-2 2xl:text-[12px] text-[10px] p-1 border-[#F9B73D]">
                <img src={d2} alt="dashboard" />
                &nbsp; Meal Card
              </p>
              <p className="group-hover:shadow-xl duration-300 dashboard-menu rounded-pill pill-3 top-[42%] left-[-5%] 2xl:px-4 border px-2 2xl:text-[12px] text-[10px] p-1 border-[#F9B73D]">
                <img src={d3} alt="dashboard" />
                &nbsp; Petrol, Medical, Jewelry Card
              </p>
              <p className="group-hover:shadow-xl duration-300 dashboard-menu rounded-pill pill-4 top-[57%] left-[-10%] 2xl:px-4 border px-2 2xl:text-[12px] text-[10px] p-1 border-[#F9B73D]">
                <img src={d4} alt="dashboard" />
                &nbsp; Payroll & Corporate Disbursements
              </p>
              <p className="group-hover:shadow-xl duration-300 dashboard-menu rounded-pill pill-5 top-[72%] left-[-5%] 2xl:px-4 border px-2 2xl:text-[12px] text-[10px] p-1 border-[#F9B73D]">
                <img src={d5} alt="dashboard" />
                &nbsp; Health Savings Account Cards
              </p>
              <p className="group-hover:shadow-xl duration-300 dashboard-menu rounded-pill pill-6 top-[90%] left-[18%] 2xl:px-4 border px-2 2xl:text-[12px] text-[10px] p-1 border-[#F9B73D]">
                <img src={d6} alt="dashboard" />
                &nbsp; Incentive Solutions
              </p>
              <p className="group-hover:shadow-xl duration-300 dashboard-menu rounded-pill pill-7 top-[90%] right-[-15%] 2xl:px-4 border px-4 2xl:text-[12px] text-[10px] p-1 border-[#F9B73D]">
                <img src={d7} alt="dashboard" />
                &nbsp; Tax Saving solution with Pockets
              </p>
              <p className="group-hover:shadow-xl duration-300 dashboard-menu rounded-pill pill-8 top-[72%] right-[-18%] 2xl:px-4 border px-2 2xl:text-[12px] text-[10px] p-1 border-[#F9B73D]">
                <img src={d8} alt="dashboard" />
                &nbsp; Prepaid Health/ Insurance Cards
              </p>
              <p className="group-hover:shadow-xl duration-300 dashboard-menu rounded-pill pill-9 top-[57%] right-[-20%] 2xl:px-4 border px-4 2xl:text-[12px] text-[10px] p-1 border-[#F9B73D]">
                <img src={d9} alt="dashboard" /> &nbsp;Students Cards/ Wearables
              </p>
              <p className="group-hover:shadow-xl duration-300 dashboard-menu rounded-pill pill-10 top-[42%] right-[-12%] 2xl:px-4 border px-4 2xl:text-[12px] text-[10px] p-1 border-[#F9B73D]">
                <img src={d10} alt="dashboard" />
                &nbsp; Travel & Expense
              </p>
              <p className="group-hover:shadow-xl duration-300 dashboard-menu rounded-pill pill-11 top-[27%] right-[-2%] 2xl:px-4 border px-4 2xl:text-[12px] text-[10px] p-1 border-[#F9B73D]">
                <img src={d11} alt="dashboard" />
                &nbsp; Gift Cards
              </p>
              <p className="group-hover:shadow-xl duration-300 dashboard-menu rounded-pill pill-12 top-[12%] right-[0] 2xl:px-4 border px-4 2xl:text-[12px] text-[10px] p-1 border-[#F9B73D]">
                <img src={d12} alt="dashboard" />
                &nbsp; Forex Services
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid home-baas baas-solution h-auto">
        <div className="container px-0 md:px-initial mx-auto grid grid-cols-1 xl:grid-cols-2">
          <div className="flex gap-2 text-left py-10 md:py-[60px] 2xl:py-[120px]">
            <div className="mt-1 md:block hidden">
              <img src={arcImg} width={150} />
            </div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
            >
              <h3 className="color-yellow mx-sm-1 linear-gredint">
                Seamless Prepaid BaaS for Your Business
              </h3>
              <p className="color-grey text-left my-2 text-sm">
              Transform your payment processes with ease. Whether you need to issue RuPay prepaid cards, launch a custom payment platform, or introduce cobranded corporate gift cards, our BaaS (Banking-as-a-Service) solutions have you covered. We provide the tools and support to enhance your payment infrastructure and help you start your digital payment journey today.
              </p>
              <div className="d-flex flex-wrap justify-content-between baas-resp pre-brass-anim mt-[10px] md:mt-10 ml-[-10px]">
                <div className="gap-[5px] md:gap-4 grid grid-cols-2 md:grid-cols-3 xl:grid-cols-2">
                  <div className="child-class flex">
                    <div className="outer-wrapper-circle">
                      <img src={bassImg1} className="object-contain" />
                    </div>
                    <a
                      href="/baas-solutions"
                      id="style-2"
                      data-replace="Low barriers to entry"
                    >
                      <span className="text-white circle-details">
                        Low barriers to entry
                      </span>
                    </a>
                  </div>
                  <div className="child-class flex">
                    <div className="outer-wrapper-circle">
                      <img src={bassImg2} className="object-contain" />
                    </div>
                    <a
                      href="/baas-solutions"
                      id="style-2"
                      data-replace="Easier customer onboarding"
                    >
                      <span className="text-white circle-details ">
                      Easier customer onboarding
                      </span>
                    </a>
                  </div>
                  <div className="child-class flex col-span-2">
                    <div className="outer-wrapper-circle">
                      <img src={bassImg3} className="object-contain" />
                    </div>
                    <a
                      href="/baas-solutions"
                      id="style-2"
                      data-replace="Room to innovate and build new age solutions"
                    >
                      <span className="text-white circle-details ">
                        Room to innovate and build new age solutions
                      </span>
                    </a>
                  </div>
                  <div className="child-class flex col-span-2">
                    <div className="outer-wrapper-circle">
                      <img src={bassImg4} className="object-contain" />
                    </div>
                    <a
                      href="/baas-solutions"
                      id="style-2"
                      data-replace="Lower compliance burden & better returns"
                    >
                      <span className="text-white circle-details ">
                      Lower compliance burden & better returns
                      </span>
                    </a>
                  </div>
                  <div className="child-class flex">
                    <div className="outer-wrapper-circle">
                      <img src={bassImg5} className="object-contain" />
                    </div>
                    <a
                      href="/baas-solutions"
                      id="style-2"
                      data-replace="Agile and flexible banking solution"
                    >
                      <span className="text-white circle-details ">
                        Agile and flexible banking solution
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="flex justify-start mt-5">
                <Link to="/baas-solutions">
                  <button className="bg-white text-black align-self-start border-0 p-1 rounded px-3">
                    Know More <i className="bi bi-arrow-right fw-bold"></i>
                  </button>
                </Link>
              </div>
            </motion.div>
          </div>
          <div className="hidden xl:block relative 2xl:pl-4">
            <motion.div
              initial={{ y: -40, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ delay: 0 * 0.5, duration: 0.5 }}
              className="absolute 2xl:w-[300px] w-[250px] top-[-50px]"
            >
              <img className="object-contain" src={iphone1} />
            </motion.div>
            <motion.img
              initial={{ y: 60, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ delay: 1 * 0.5, duration: 0.5 }}
              className="absolute w-[250px] 2xl:w-[280px] bottom-[-35%] 2xl:bottom-[-30%] left-[15%]"
              src={vergado_wallet}
            />
            <motion.div
              className="absolute top-[22%] right-[-80px] 2xl:right-[-50px]"
              initial={{ x: -40, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ delay: 2 * 0.5, duration: 0.5 }}
            >
              <img className="lap-img" src={lap1} />
            </motion.div>
          </div>
        </div>
      </div>

      <div className="container pt-10 md:pt-10 pb-0 md:pb-10">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          className="flex relative justify-center w-full md:w-[60%] m-auto rbi-container"
        >
          <img
            className="rounded-full z-20 w-[120px] md:w-[100px] mt-1 object-contain"
            src={rbiImage}
          />
          <div className="flex flex-col justify-center w-full grow">
            <div className="pr-5 flex rounded-[50px] flex-col justify-center bg-black h-[100px] xl:h-[70px] pl-[80px] md:pl-[100px] text-left text-gray-400 text-[10px] xl:text-lg z-10 ml-[-80px] mt-2">
              <p className="mb-0 text-sm">
                Our <span className="color-yellow"> RBI Licensed </span> Prepaid
                stack has got you covered step of the way- From onboarding to
                KYC to vendor Management
              </p>
            </div>
          </div>
        </motion.div>
      </div>

      <div
        className="container-fluid blog-fluid position-relative mt-5 mb-4 md:my-5 pt-5 pb-20"
        style={{ background: "#fcf6dc" }}
      >
        <div className="container">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className="header md:w-[85%] mx-auto"
          >
            <h3 className="color-yellow text-start fw-bold linear-gredint">
              Explore Insights
            </h3>
            <h5 className="text-start text-secondary">
              Scour our articles and stay connected with cutting-edge
              developments that can help your business grow.
            </h5>
          </motion.div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="container mt-[-100px]">
          <div className="blog-container grid grid-cols-1 md:grid-cols-3 gap-3 lg:grid-cols-4 md:w-[85%] mx-auto">
            <motion.div
              initial={{ opacity: 0, y: 40 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: 0 }}
              viewport={{ once: true }}
              className="cards shadow"
            >
              <img
                src={blog1}
                className="h-[150px] object-cover object-top"
                alt="Card 1"
              />
              <div className="card-content grow flex flex-col justify-between">
                <p className="text-secondary">
                  If you are someone who is looking for a smooth payment method
                  without holding a bank account
                </p>
                <a
                  href="https://zokudo.com/blog/single.php?id=26"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-dark"
                >
                  <h6>Read More</h6>
                </a>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 40 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: 1 * 0.1 }}
              viewport={{ once: true }}
              className="cards shadow"
            >
              <img
                src={blog2}
                className="h-[150px] object-cover object-top"
                alt="Card 2"
              />
              <div className="card-content grow flex flex-col justify-between">
                <p className="text-secondary">
                  Getting a credit card comes with a lot of hassle. You must
                  have a bank account <br></br>
                </p>
                <a
                  href="https://zokudo.com/blog/single.php?id=26"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-dark read-more-blog"
                >
                  <h6>Read More</h6>
                </a>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 40 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: 2 * 0.1 }}
              viewport={{ once: true }}
              className="cards shadow"
            >
              <img
                src={blog3}
                className="h-[150px] object-cover object-top"
                alt="Card 3"
              />
              <div className="card-content grow flex flex-col justify-between">
                <p className="text-secondary">
                  Prepaid cards are a good way to spend your money wisely and
                  keep your expenses in check.{" "}
                </p>
                <a
                  href="https://zokudo.com/blog/single.php?id=26"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-dark read-more-blog"
                >
                  <h6>Read More</h6>
                </a>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 40 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: 3 * 0.1 }}
              viewport={{ once: true }}
              className="cards shadow"
            >
              <img
                src={blog4}
                className="h-[150px] object-cover object-top"
                alt="Card 4"
              />
              <div className="card-content grow flex flex-col justify-between">
                <p className="text-secondary">
                  Your prepaid debit card works like any other card for
                  withdrawing cash.
                  <br />
                </p>
                <a
                  href="https://zokudo.com/blog/single.php?id=26"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-dark read-more-blog"
                >
                  <h6>Read More</h6>
                </a>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
      <div className="contact-us">
        <div className="container">
          <div className="flex lg:flex-row flex-col gap-4 contact-us-container w-[85%] mx-auto">
            <motion.div
              initial={{ opacity: 0, y: 40 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: 0 }}
              viewport={{ once: true }}
              className="grid grid-cols-1 md:grid-cols-2 gap-4 contact-left rounded-lg p-4 relative overflow-hidden"
            >
              <div
                className="grid place-items-center absolute w-[350px] h-[350px] border-[16px] border-white z-30 bottom-[-120px] right-[-100px] opacity-30"
                style={{ borderRadius: "50%" }}
              >
                <div
                  className="w-[250px] h-[250px] border-[16px] border-white z-30"
                  style={{ borderRadius: "50%" }}
                ></div>
              </div>
              <div className="flex flex-col justify-center">
                <div className="relative py-[100px] md:py-[160px]">
                  <div className="h-[200px] w-[200px] md:w-[300px] md:h-[300px] z-10 rounded-full absolute contact-circle">
                    <img src={contactImg} className="relative z-20" />
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-center">
                <div>
                  <div className="">
                    <h6 className="opacity-70 text-left text-lg-start">
                      Scale your Business with Zokudo!
                    </h6>
                    <h5 className="text-start fw-bold">
                    Talk to our Experts
                    </h5>
                    <p className="opacity-70 text-start fs-14">
                    Simplify expenses, empower payments, and boost employee satisfaction—all with Zokudo's prepaid cards. Designed for security, convenience, and scalability, a smarter way to manage your business finances in India!
                    </p>
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="max-w-[250px] p-2 border border-1 rounded-[30px] bg-[#f4e6a6] flex gap-2">
                      <img src={phone} />
                      <a
                        href="telto:+91 75060 48939"
                        className="text-black no-underline mt-1"
                      >
                        +91 75060 48939
                      </a>
                    </div>

                    <div className="max-w-[250px] p-2 border border-1 rounded-[30px] bg-[#f4e6a6] flex gap-2">
                      <img src={mail} />
                      <a
                        href="mailto:care@zokudo.com"
                        className="text-black no-underline mt-1"
                      >
                        care@zokudo.com
                      </a>
                    </div>
                    <div className="flex justify-start pl-1 mt-3 relative z-40 group">
                      <a href="https://www.linkedin.com/company/zokudo/" target="_blank" className="no-underline text-black">
                      <div className="flex gap-2">
                        <span className="w-6 h-6 rounded-full bg-white grid place-items-center cursor-pointer group-hover:scale-[1.3] duration-300">
                          <FaLinkedin color="#E4C221" />
                        </span>
                        <p className="text-sm mb-0 grid place-items-center">Follow us on linkedin</p>
                      </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 40 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: 1 * 0.1 }}
              viewport={{ once: true }}
              className="col-12 col-lg-4"
            >
              <Form />
            </motion.div>
          </div>
        </div>
      </div>

      <div className="container-fluid rounded-lg pt-[40px] md:pt-[150px] pb-[30px] md:pb-[60px]">
        <div className="mx-auto banner-container-bt rounded-lg h-auto md:h-[200px]">
          <div className="flex relative justify-start gap-4 h-full p-4 md:px-10">
            <div className="flex xl:flex-row flex-col md:gap-5 gap-1 md:py-0 md:py-5">
              <div className="text-left grid place-items-center">
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.3, delay: 0 }}
                  viewport={{ once: true }}
                >
                  <h5 className="fw-bold">Download the Zokudo Application</h5>
                  <p className="opacity-70 fs-12">
                  Experience seamless payments and rewards management— <br/>download the Zokudo App today!
                  </p>
                </motion.div>
              </div>
              <div className="flex flex-col justify-center xl:pl-[100px] download-btns">
              <div className="flex gap-2">
            <a
              className="text-decoration-none text-white"
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.zokudo"
            >
              <div className="bg-black py-1 px-2 rounded-md text-left flex gap-1">
                <img src={googlePlayIcon} className="object-contain w-6" />
                <div>
                  <p className="mb-0 text-[8px] font-light">GET IT ON</p>
                  <p className="mb-0 text-[12px]">Google Play</p>
                </div>
              </div>
            </a>
            <a
              className="text-decoration-none text-white"
              target="_blank"
              href="https://apps.apple.com/in/app/zokudo/id1553087937"
            >
              <div className="bg-black py-1 px-2 rounded-md text-left flex gap-1 overflow-hidden">
                <img src={appleStoreIcon} className="object-contain w-[26px]" />
                <div>
                  <p className="mb-0 text-[8px] font-light">Download on the</p>
                  <p className="mb-0 text-[12px]">App Store</p>
                </div>
              </div>
            </a>
          </div>
              </div>
            </div>
            <motion.img
              initial={{ opacity: 0, x: 40 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3, delay: 0 }}
              viewport={{ once: true }}
              src={iphoneCut2Img}
              className="absolute hidden lg:block right-[50px] bottom-0 mb-3 mb-lg-0"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
