import React, { useState, useEffect } from "react";
import { IoMdMenu } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa6";
import DropdownMenu from "./ui/DropdownMenu";
import { IoMdClose } from "react-icons/io";
import logoWhite from "../assets/logo-white.svg";
import logoBlack from "../assets/logo-black.svg";
import { checkSession, letLogOut } from "../helper";
export default function MobileNavMenu() {
  const servicesMenuItems = [
    {
      text: "Corporate Solutions",
      linkTo: "/corporate-solutions",
    },
    {
      text: "BaaS Solution",
      linkTo: "/baas-solutions",
    },
  ];
  const legalMenuItems = [
    {
      text: "Terms & Conditions",
      linkTo: "/terms-and-conditions",
    },
    {
      text: "Privacy Policy",
      linkTo: "/privacy-policy",
    },
    {
      text: "Customer Grievance Redressal",
      linkTo: "/customer-grievance",
    },
    {
      text: "Customer Liability Policy",
      linkTo: "/customer-liability-policy",
    },
    {
      text: "Investor Relations",
      linkTo: "/investor-relations",
    },
    {
      text: "FAQ's",
      linkTo: "/faq",
    },
  ];

  const loginMenuItems = [
    {
      text: "As Admin",
      linkTo: "/admin",
    },
    {
      text: "As Customer",
      linkTo: "https://customer.zokudo.com",
      isExternal: "true",
    },
  ];

  const [isLoggedin, setIsLoggedin] = useState(checkSession());
  const location = useLocation();
  const { pathname } = location;

  const [showServicesMenu, setShowServicesMenu] = useState(false);
  const [showLegalMenu, setShowLegalMenu] = useState(false);
  const [showLoginMenu, setShowLoginMenu] = useState(false);

  const toggleServices = () => {
    setShowServicesMenu(!showServicesMenu);
  };
  const toggleLegal = () => {
    setShowLegalMenu(!showLegalMenu);
  };
  const toggleLoginMenu = () => {
    setShowLoginMenu(!showLoginMenu);
  };
  const isLight = [
    "/about-us",
    "/corporate-solutions",
    "/baas-solutions",
  ].includes(pathname);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(false);
    setShowLegalMenu(false);
    setShowServicesMenu(false);
    setShowLoginMenu(false);
  }, [pathname]);

  return (
    <div className="w-full lg:hidden flex justify-between px-4 py-3">
      <Link
        className={`navbar-brand fs-2 ${isLight ? "text-black" : "text-white"}`}
        to="/"
      >
        <img
          src={isLight ? logoBlack : logoWhite}
          width={150}
          alt="zokudo logo"
          className="mt-1"
        />
      </Link>
      <div className="flex flex-col justify-center">
        <IoMdMenu
          className={`text-3xl cursor-pointer ${
            isLight ? "text-black" : "text-white"
          }`}
          onClick={() => setShow(true)}
        />
      </div>
      {show && (
        <div className="w-full bg-[#00000041] h-[100vh] fixed left-0 top-0 z-[100]">
          <div className="w-[300px] bg-white h-full absolute">
            <div className="absolute right-3 top-3">
              <IoMdClose
                onClick={() => setShow(false)}
                className="text-3xl cursor-pointer"
              />
            </div>
            <ul className="flex flex-col text-black text-left gap-2 xl:gap-4 mb-0 pt-5">
              <li className="nav-item p-2">
                <Link
                  className={`text-left text-black no-underline  ${
                    pathname === "/" ? "fw-semibold " : ""
                  }`}
                  aria-current="page"
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li className="nav-item p-2">
                <Link
                  className={`text-left text-black no-underline ${
                    pathname === "/about" ? "fw-semibold" : "fw-light"
                  }`}
                  to="/about-us"
                >
                  About Us
                </Link>
              </li>
              <li className="nav-item relative p-2">
                <div
                  className="d-flex gap-2 text-black fw-light cursor-pointer"
                  onClick={toggleServices}
                >
                  <span>Services</span>
                  <FaAngleDown
                    className={`mt-1 text-black duration-300 ${
                      showServicesMenu ? "rotate-180" : ""
                    } `}
                  />
                </div>
                {showServicesMenu && <DropdownMenu data={servicesMenuItems} />}
              </li>
              <li className="nav-item dropdown p-2">
                <div
                  className="d-flex gap-2 text-black cursor-pointer"
                  onClick={toggleLegal}
                >
                  <span>Legal</span>
                  <FaAngleDown
                    className={`mt-1 text-black duration-300 ${
                      showLegalMenu ? "rotate-180" : ""
                    } `}
                  />
                </div>
                {showLegalMenu && <DropdownMenu data={legalMenuItems} />}
              </li>
              <li className="nav-item p-2">
                <Link
                  className={`text-left text-black no-underline ${
                    pathname === "/career" ? "fw-semibold" : "fw-light"
                  }`}
                  to="/career"
                >
                  Career
                </Link>
              </li>
              <li className="nav-item p-2">
                <Link
                  className={`text-left text-black no-underline ${
                    pathname === "/contact-us" ? "fw-semibold" : "fw-light"
                  }`}
                  to="/contact-us"
                >
                  Contact
                </Link>
              </li>

              {/* <li className="nav-item p-2">
                <Link className="yellow-background px-10 py-2" to="/logIn">
                  <button>Login</button>
                </Link>
              </li> */}
              {!isLoggedin && (
                <li className="nav-item relative p-2">
                  <div
                    className="d-flex gap-2 text-black fw-light cursor-pointer"
                    onClick={toggleLoginMenu}
                  >
                    <span>Login</span>
                    <FaAngleDown
                      className={`mt-1 text-black duration-300 ${
                        showLoginMenu ? "rotate-180" : ""
                      } `}
                    />
                  </div>
                  {showLoginMenu && <DropdownMenu data={loginMenuItems} />}
                </li>
              )}
              {isLoggedin && (
                <li className="nav-item p-2">
                  <div className="cursor-pointer" onClick={letLogOut}>
                    Logout
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}
