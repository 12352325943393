export const checkSession = () => {
  if (sessionStorage.getItem("email") && sessionStorage.getItem("token")) {
    return true;
  }
  return false;
};

export const getSessionData = () => {
  let email = sessionStorage.getItem("email");
  let token = sessionStorage.getItem("token");
  return { email, token };
};

export const letLogOut = () => {
  sessionStorage.removeItem("email");
  sessionStorage.removeItem("token");
  window.location.href = "/";
};
