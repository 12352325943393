import React, { useState } from "react";
import { FaAngleDown } from "react-icons/fa6";
import { BsArrowLeft } from "react-icons/bs";
import axios from "axios"; // Ensure you have axios imported
import CaptchaForm from "./Captcha";

export default function AddCardForm({ submitCard }) {
  const [formData, setFormData] = useState({
    cardNumber: "",
    expiry: "",
    cvv: "",
    kitNumber: "",
  });

  const [balance, setBalance] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [responseMessage, setResponseMessage] = useState("");
  const [error, setError] = useState("");

  const handleChange = (e) => {
  
    let { name, value } = e.target;

    if (name === 'expiry') {
      value = value.replace(/\D/g, '');
      
      if (value.length > 2) {
        value = value.slice(0, 2) + '/' + value.slice(2);
      }
      
      if (value.length > 5) {
        value = value.slice(0, 5);
      }
      e.target.value = value.replace("/","");
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

    const handleSubmit = async (e) => {
      e.preventDefault(); // Prevent page reload

      const sanitizedFormData = {
        ...formData,
        expiry: formData.expiry.replace("/", ""), // Sanitize the expiry value
      };

      try {
        const response = await axios.post(
          "https://customer.zokudo.com/no-auth/get-card-details-by-kit",
          sanitizedFormData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        // Check response status
        if (response.data.status === "OK") {
          const details = response.data.result;

          // Update state with extracted data
          setBalance(details.balance);
          setTransactions(details.transactions);
          // setResponseMessage("Data fetched successfully!");
          setError("");
        } else {
          // Handle API errors
          setError(response.data.result || "An error occurred");
          setResponseMessage("");
        }
      } catch (err) {
        console.error("API Error: ", err); // Debug log

        // Handle unexpected errors (network issues, etc.)
        setError("Failed to fetch card details. Please try again later.");
        setResponseMessage("");
      }
    };

  return (
    <div>
      <div className="mt-[70px] md:mt-[150px] rounded-lg mb-20 w-full mx-4 md:w-4/5 lg:w-3/5 mx-auto border border-gray-200 overflow-hidden">
        <div className="bg-[#F7F7F7] py-3 px-4 text-left">
          <p className="font-medium text-md mb-0">Enter Card Details</p>
          <div className="flex gap-2">
            <p className="mb-0 text-gray-400 font-semibold">Balance:</p>
            <p className="mb-0 font-semibold">Rs. {balance}</p>
          </div>
        </div>
        <div className="p-6">
          <div className="add-new-job-form p-4 w-[94%] rounded-lg mx-auto">
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="flex flex-col text-left">
                  <label className="text-sm font-medium text-gray-500 mb-1">
                    Card Number*
                  </label>
                  <input
                    type="text"
                    name="cardNumber"
                    value={formData.cardNumber}
                    onChange={handleChange}
                    placeholder="Enter Number"
                    className="border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                    required
                  />
                </div>
                <div className="flex flex-col text-left">
                  <label className="text-sm font-medium text-gray-500 mb-1">
                    Card Expiry*
                  </label>
                  <input
                    type="text"
                    // length = "4"
                    name="expiry"
                    value={formData.expiry}
                    onChange={handleChange}
                    placeholder="MMYY"
                    className="border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                    required
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="flex flex-col text-left">
                  <label className="text-sm font-medium text-gray-500 mb-1">
                    Card CVV*
                  </label>
                  <input
                    type="password"
                    placeholder="***"
                    name="cvv"
                    value={formData.cvv}
                    onChange={handleChange}
                    className="border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                    required
                  />
                </div>
                <div className="flex flex-col text-left">
                  <label className="text-sm font-medium text-gray-500 mb-1">
                    Kit Number*
                  </label>
                  <input
                    type="text"
                    name="kitNumber"
                    value={formData.kitNumber}
                    onChange={handleChange}
                    placeholder="Enter Number"
                    className="border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                    required
                  />
                </div>
                <CaptchaForm />
              </div>

              <div>
                <button
                  type="submit"
                  className="text-black font-medium py-2 px-4 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
                >
                  Submit
                </button>
              </div>
                <div className="border border-gray-100 rounded-lg overflow-hidden mt-5">
                  <div className="bg-[#F7F7F7] py-3 px-4 flex justify-between">
                    <p className="font-semibold text-gray-400 mb-0">Transaction History</p>
                    {/* <FaAngleDown className="text-gray-400" /> */}
                  </div>

                  <div className="overflow-x-auto">
                    <table className="w-full border-collapse text-[12px]">
                      <thead className="bg-[#F7F7F7]">
                        <tr>
                          {/* <th className="text-left py-3 pl-3 text-gray-600 font-semibold">Date</th>
                          <th className="text-left py-3 px-4 text-gray-600 font-semibold">Amount (Rs.)</th>
                          <th className="text-left py-3 px-4 text-gray-600 font-semibold">Type</th>
                          <th className="text-left py-3 px-4 text-gray-600 font-semibold">Reference No.</th>
                          <th className="text-left py-3 px-4 text-gray-600 font-semibold">Status</th>
                          <th className="text-left py-3 px-4 text-gray-600 font-semibold">Comments</th> */}
                              <th className="text-center py-3 px-4 text-gray-600 font-semibold">Date</th>
                              <th className="text-center py-3 px-4 text-gray-600 font-semibold">Reference Number</th>
                      <th className="text-center py-3 px-4 text-gray-600 font-semibold">Merchant Name</th>
                      <th className="text-center py-3 px-4 text-gray-600 font-semibold">Amount (Rs.)</th>
                      <th className="text-center py-3 px-4 text-gray-600 font-semibold">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactions != null && transactions.length > 0 ? (
                          transactions.slice(0, 5).map((transaction, index) => (
                            <tr key={index} className="border-b border-gray-100">
                              {/* <td className="py-3 pl-3 text-gray-500">{transaction.createdAt.split("T")[0]}</td>
                              <td className="py-3 px-4 text-gray-500">{transaction.transactionAmount}</td>
                              <td className="py-3 px-4 text-gray-500">{transaction.transactionType}</td>
                              <td className="py-3 px-4 text-gray-500">{transaction.transactionRefNo}</td>
                              <td className="py-8 px-10 text-gray-500">{transaction.status}</td>
                              <td className="py-3 px-4 text-gray-500">{transaction.comments || "N/A"}</td> */}
                                <td className="py-6 px-4 text-gray-500">{transaction.createdAt.split("T")[0]}</td>
                                <td className="py-3 px-2 text-gray-500">{transaction.transactionRefNo}</td>
                                <td className="py-3 px-2 text-gray-500">{transaction.merchantName}</td>     
                                <td className="py-3 px-2 text-gray-500">{transaction.transactionAmount}</td>
                                <td className="py-3 px-2 text-gray-500">{transaction.status}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6" className="py-3 px-4 text-gray-500 text-center">
                              No transactions available.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
            </form>
            {responseMessage && (
              <p className="text-green-500 mt-4">{responseMessage}</p>
            )}
            {error && <p className="text-red-500 mt-4">{error}</p>}
          </div>
        </div>
      </div>
    </div>
  );
}
