import React, { useEffect, useState } from "react";
import Toggle from "react-toggle";
import { FaCirclePlus } from "react-icons/fa6";
// import axios from "axios";
import ApplyForCareerModal from "./ui/ApplyForCareerModal";
import axios from "axios";

export default function List({ publicView, getData=()=>{} }) {
  const handleChange = async (id) => {
    try {
      setData((prevData) => prevData.filter((item) => item.id !== id));

      const result = await axios.delete(
        `https://admin.zokudo.com/customer-service/appuser/api/v1/jobPosition?id=${id}`
      );

      await getJobs();
    } catch (err) {
      setError("Something went wrong! Please try again.");
      console.error(err);

      // Optionally, revert the optimistic update in case of an error
      getJobs();
    }
  };

  const [show, setShow] = useState(false);
  const [expandedId, setExpandedId] = useState(null);
  const [showFullDescription, setShowFullDescription] = useState(false);

  const closeModal = () => {
    setShow(false);
  };

  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [expandedDescriptions, setExpandedDescriptions] = useState({}); // Track expanded descriptions
  const [isActiveJobs, setIsActiveJobs] = useState(false);

  useEffect(() => {
    getJobs();
  }, []);

  const getJobs = async () => {
    try {
      const result = await axios.get(
        "https://admin.zokudo.com/customer-service/appuser/api/v1/jobPosition"
        // "http://localhost:9092/customer-service/appuser/api/v1/jobPosition"
      );
      //          const jobData = result.json()
      console.log(result.data);
      setData(result.data.body);
      const hasActiveJobs = result.data.body.some((item) => item.status === "ACTIVE");
      setIsActiveJobs(hasActiveJobs);
      getData({list: result.data.body, hasActiveJobs});
    } catch (err) {
      setError("Something went wrong! Please try again.");
      console.log("error ", error);
    }
  };

  const toggleDescription = (id) => {
    setExpandedDescriptions((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };
  if (data.length && !isActiveJobs && publicView) return <p>No jobs available</p>;
  return (
    <>
      {show && <ApplyForCareerModal close={closeModal} />}

      <div className="flex flex-col gap-2">
        {data.map((item, index) => {
          if (item.status === "INACTIVE" && publicView) return null;
          return (
            <div
              key={index}
              className="d-flex w-full flex-column p-3 mb-3 gap-2 text-left border-0 bg-[#FFFDF8] rounded-lg"
            >
              <h6 className="font-semibold">{item.jobTitle} </h6>
              <div className="flex md:flex-row flex-col justify-between">
                <div className="flex gap-2 text-sm">
                  {item.employmentType && (
                    <button className="bg-[#E4C221] px-3 py-1 text-sm rounded">
                      {item.employmentType}
                    </button>
                  )}
                </div>
                {!publicView && (
                  <label className="flex gap-3 md:mt-0 mt-3">
                    <p className="mr-2 mb-0 text-[14px]">
                      Enable/<span className="text-gray-400">Disable</span>
                    </p>
                    <Toggle
                      defaultChecked={item.status === "INACTIVE" ? false : true}
                      icons={false}
                      // onChange={handleChange(item.id)}
                      onChange={() => handleChange(item.id)}
                    />
                  </label>
                )}
              </div>

              <div>
                <div className="grid grid-cols-1 md:grid-cols-2">
                  <p className="mb-0">
                    <b>Job Title: </b>
                    {item.jobTitle || "NA"}
                  </p>
                  <p className="mb-0">
                    <b>Location: </b>
                    {item.location || "NA"}
                  </p>
                  <p className="mb-0">
                    <b>Experience Level: </b>
                    {item.experienceLevel || "NA"}
                  </p>
                  <p className="mb-0">
                    <b>Employment Type: </b>
                    {item.employmentType || "NA"}
                  </p>
                  <p className="mb-0">
                    <b>Salary: </b>
                    {item.salaryRange || "NA"}
                  </p>
                  <p className="mb-0">
                    <b>Educational Qualification: </b>
                    {item.educationalQualification || "NA"}
                  </p>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2">
                  <p className="mb-0">
                    <b>Skills: </b>
                    {item.skillsRequired || "NA"}
                  </p>

                  <div>
                    <p className="mb-0">
                      <b>Job Description: </b>
                      {expandedDescriptions[item.id] ||
                      (item.jobDescription || "").length <= 20
                        ? item.jobDescription || "NA"
                        : `${item.jobDescription.substring(0, 20)}...`}
                    </p>
                    {item.jobDescription && item.jobDescription.length > 20 && (
                      <button
                        className="text-blue-500 mt-1"
                        onClick={() => toggleDescription(item.id)}
                      >
                        {expandedDescriptions[item.id]
                          ? "Show Less"
                          : "See More Details"}
                      </button>
                    )}
                  </div>
                  <div className="flex justify-start mt-2">
                    <button
                      onClick={() => setShow(true)}
                      className="custom-button align-self-start border-0 py-2 px-3 rounded px-3"
                    >
                      Apply <i className="bi bi-arrow-right fw-bold"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
