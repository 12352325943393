import React, { useState, useEffect } from "react";
import "../style/career.css";
import NavbarV2 from "./NavbarV2";
import Footer from "./common/Footer";
import List from "./List";
import { checkSession } from "../helper";
import teamWork from "../assets/Teamwork.svg";
import securedFuture from "../assets/securedFuture.svg";
import learningOportunity from "../assets/learningOportunity.svg";
import upgradeSkills from "../assets/upgradeSkill.svg";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import ApplyForCareerModal from "./ui/ApplyForCareerModal";

function Career() {
  const [show, setShow] = useState(false);
  const [jobList, setJobList] = useState(0);
    const [hasSession, setHasSession] = useState(false);
  const closeModal = () => {
    setShow(false);
  };
  const getListData = (data) => {
    setJobList(data.list.filter((item) => item.status === "ACTIVE"));
  };
    useEffect(() => {
      setHasSession(checkSession())
    }, []);
  return (
    <div>
      <section className="container-fluid career-top d-flex flex-column justify-content-center align-items-center px-3 mt-[70px] md:mt-[70px] py-[50px] lg:py-[100px]">
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0 }}
          viewport={{ once: true }}
          className="container"
        >
          <h1 className="text-white">JOIN US</h1>
          <p className="text-gray-200 opacity-80 text-sm md:w-4/5 lg:w-3/5 mx-auto">
            At Zokudo, we’re driving the future of fintech with innovative
            payment solutions. If you’re passionate about creating impactful
            products and want to be part of a dynamic team that values
            collaboration, creativity, and growth, we want to hear from you.
          </p>
          <p className="text-gray-200 opacity-80 text-sm md:w-4/5 lg:w-3/5 mx-auto">
            Explore exciting career opportunities where your skills will help
            shape the future of digital finance. Let’s build the next big
            thing—together.
          </p>
          <div className="flex gap-2 justify-center">
            <a href="#jobs">
              <button className="btn btn-warning ">Join the team</button>
            </a>
            <a href="/about-us">
              <button className="btn btn-outline-light">About us</button>
            </a>
          </div>
        </motion.div>
      </section>

      <section className="container-fluid py-5">
        <div className="container d-flex flex-column g-5 flex-md-row justify-content-md-around">
          <div className="col-12 col-md-6 grid place-items-center text-left">
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 1 * 0.1 }}
              viewport={{ once: true }}
            >
              <button className="solution-btn text-warning fs-600 align-self-start p-2 rounded">
                Join Team
              </button>
              <h3 className="text-warning mt-3 font-semibold lg:w-[60%] mb-4">
                Grow with us. Innovate with us. Make an impact.
              </h3>
              <p className="text-sm text-gray-400 md:w-[90%]">
                We’re not just offering jobs; we’re building careers. Our
                employees are our greatest asset, and we want to foster a
                dynamic, inclusive work environment where innovation thrives.
                Our team is driven by a shared passion for redefining digital
                payments, and we’re always on the lookout for talented
                individuals to join us in this exciting journey.
              </p>
              {/* <div className="flex justify-start">
                <Link to="/corporate-solutions">
                  <button className="custom-button align-self-start border-0 p-1 rounded px-3">
                    Drop Your CV
                  </button>
                </Link>
              </div> */}
            </motion.div>
          </div>
          <div className="col-12 col-md-6 d-flex flex-wrap mt-3 font-size-13px ">
            <div className="row justify-content-center text-left">
              <motion.div
                initial={{ opacity: 0, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0 }}
                viewport={{ once: true }}
                className="col-6"
              >
                <img className="career-img bg-[#E7F2FF]" src={teamWork} />
                <p className="mt-2">
                  <strong>Innovate and Impact</strong>
                </p>
                <p className="text-sm text-gray-400">
                  If you are passionate about pushing boundaries and making an
                  impact, Zokudo is the place for you.
                </p>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 1 * 0.1 }}
                viewport={{ once: true }}
                className="col-6"
              >
                <img className="career-img bg-[#F1F7E8]" src={securedFuture} />
                <p className="mt-2">
                  {" "}
                  <strong>Collaborate and Thrive</strong>{" "}
                </p>
                <p className="text-sm text-gray-400">
                  Our collaborative environment encourages creativity and
                  innovation, where every team member's voice is heard and
                  valued. YOU are important.
                </p>
              </motion.div>
            </div>
            <div className="row justify-content-center text-left">
              <motion.div
                initial={{ opacity: 0, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 2 * 0.1 }}
                viewport={{ once: true }}
                className="col-6"
              >
                <img
                  className="career-img bg-[#EFF2F5]"
                  src={learningOportunity}
                />
                <p className="mt-2">
                  <strong>Learn and Evolve</strong>{" "}
                </p>
                <p className="text-sm text-gray-400">
                  We invest in your professional development through mentorship,
                  training, and career advancement opportunities throughout your
                  time here.
                </p>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 3 * 0.1 }}
                viewport={{ once: true }}
                className="col-6"
              >
                <img className="career-img bg-[#FFEEED]" src={upgradeSkills} />
                <p className="mt-2">
                  <strong>Grow with Us</strong>
                </p>
                <p className="text-sm text-gray-400">
                  You'll have access to competitive salaries, comprehensive
                  benefits, and a stable work environment. We are committed to
                  your long-term growth as we continue to expand.
                </p>
              </motion.div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-[#FFF8EC] jobs-section py-5">
        <div
          class="container d-flex flex-column flex-md-row  align-items-start"
          id="jobs"
        >
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0 }}
            viewport={{ once: true }}
            class="nav flex-column nav-pills me-3 col-12 col-md-4 col-lg-2 shadow p-3 bg-white text-left rounded mb-4"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <button
              className="nav-link text-left active"
              id="v-pills-home-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-home"
              type="button"
              role="tab"
              aria-controls="v-pills-home"
              aria-selected="true"
            >
              All Position ({jobList.length>0?String(jobList.length).padStart(2,0):0})
            </button>
            {/* <button
              class="nav-link"
              id="v-pills-home-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-home"
              type="button"
              role="tab"
              aria-controls="v-pills-home"
              aria-selected="true"
            >
              Engineering
            </button>
            <button
              class="nav-link"
              id="v-pills-profile-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-profile"
              type="button"
              role="tab"
              aria-controls="v-pills-profile"
              aria-selected="false"
            >
              Product
            </button>
            <button
              class="nav-link"
              id="v-pills-disabled-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-disabled"
              type="button"
              role="tab"
              aria-controls="v-pills-disabled"
              aria-selected="false"
            >
              Design
            </button>
            <button
              class="nav-link"
              id="v-pills-messages-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-messages"
              type="button"
              role="tab"
              aria-controls="v-pills-messages"
              aria-selected="false"
            >
              Operation
            </button>
            <button
              class="nav-link"
              id="sales-t-tab"
              data-bs-toggle="pill"
              data-bs-target="#sales-tab"
              type="button"
              role="tab"
              aria-controls="sales-tab"
              aria-selected="false"
            >
              Marketing
            </button>
            <button
              class="nav-link"
              id="v-pills-settings-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-settings"
              type="button"
              role="tab"
              aria-controls="v-pills-settings"
              aria-selected="false"
            >
              Sales
            </button> */}
            <p className="d-inline mt-4 text-[12px] text-gray-400">
              We are always seeking talented people. In case you cannot find
              your desired position here, please send us your LinkedIn profile
              and give us your contact information. We will be in touch.{" "}
            </p>
            {/* <button className="py-2 text-[10px] 2xl:text-[12px] border border-gray-800 rounded-full">
              Share your LinkedIn profile
            </button> */}
          </motion.div>
          <div class="tab-content w-[inherit]" id="v-pills-tabContent">
            {/* <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab" tabindex="0">...</div> */}
            <div
              class="tab-pane fade"
              id="v-pills-profile"
              role="tabpanel"
              aria-labelledby="v-pills-profile-tab"
              tabindex="0"
            >
              ...
            </div>
            <div
              class="tab-pane fade"
              id="v-pills-disabled"
              role="tabpanel"
              aria-labelledby="v-pills-disabled-tab"
              tabindex="0"
            >
              ...
            </div>
            <div
              class="tab-pane fade"
              id="v-pills-messages"
              role="tabpanel"
              aria-labelledby="v-pills-messages-tab"
              tabindex="0"
            >
              ...
            </div>
            <div
              class="tab-pane fade"
              id="v-pills-settings"
              role="tabpanel"
              aria-labelledby="v-pills-settings-tab"
              tabindex="0"
            >
              ...
            </div>
            <div
              class="tab-pane fade show active font-size-13px"
              id="v-pills-home"
              role="tabpanel"
              aria-labelledby="v-pills-home-tab"
              tabindex="0"
            >
              <List publicView={true} getData={getListData} />
            </div>
            <div
              class="tab-pane fade"
              id="sales-tab"
              role="tabpanel"
              aria-labelledby="sales-t-tab"
              tabindex="0"
            >
              ...
            </div>
           {hasSession && <div className="flex justify-start">
              <Link to="/job-postings">
                <button className="custom-button align-self-start border-0 py-2 px-3 rounded px-3">
                  See All Position <i className="bi bi-arrow-right fw-bold"></i>
                </button>
              </Link>
            </div>}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Career;
