import React from 'react'
import cp1 from "../../assets/Logo/cp111.jpg";
import cp2 from "../../assets/Logo/cp2.png";
import cp3 from "../../assets/Logo/cp3.png";
import cp4 from "../../assets/Logo/cp4.png";
import cp5 from "../../assets/Logo/cp5.png";
import cp6 from "../../assets/Logo/cp6.png";
import cp7 from "../../assets/Logo/cp7.png";
import cp8 from "../../assets/Logo/cp8.png";
import cp9 from "../../assets/Logo/cp9.png";
import cp10 from "../../assets/Logo/cp10.png";
import cp11 from "../../assets/Logo/cp11.png";
import cp12 from "../../assets/Logo/cp12.png";
import cp13 from "../../assets/Logo/cp13.png";
import cp14 from "../../assets/Logo/cp14.png";
import cp15 from "../../assets/Logo/cp15.png";
import cp16 from "../../assets/Logo/cp16.png";
import cp17 from "../../assets/Logo/cp17.png";



export default function () {
    const images = [
        cp1, cp2, cp3, cp4, cp5, cp6, cp7, cp8, cp9, cp10,
        cp11, cp12, cp13, cp14, cp15, cp16, cp17
      ];
  return (
    <>
   {
     images.map((item, i) => {
        return (
            <img className={`shadow-md m-3 p-2 object-contain w-[100px] h-[50px] duration-300 hover:scale-[1.5] bg-white`} src={item} key={i}/>
        )
    })
   }
         
        </>
  )
}
