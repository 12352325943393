import React from "react";
import { AiFillCaretRight } from "react-icons/ai";
import TermsTable1 from "./TermsTable1";
import TermsTable2 from "./TermsTable2";
import TermsTable3 from "./TermsTable3";
import TermsTable1Hindi from "./TermsTable1Hindi";
import TermsTable2Hindi from "./TermsTable2Hindi";
import TermsTable3Hindi from "./TermsTable3Hindi";
export default function GPCardTermsHindi() {
  return (
    <div className="text-left">
      <h6 className="text-warning flex gap-1">
        {" "}
        <AiFillCaretRight className="text-[#E4C221]" />
        रीलोडेबल कार्ड के उद्देश्य से नियम तथा शर्तें:
      </h6>
      <p>
        यहां पर उल्लेखित नियम और शर्तें(यहां के बाद इसे "नियम और शर्तें" के तौर
        पर संदर्भित किया जाएगा) "जीपीआर प्रीपेड कार्ड" के उपयोग पर लागू हैं और
        इसके उपयोग से पहले इन्हें स्वीकार करना और सहमत होना आवश्यक है। नीचे
        उल्लेखित नियम और शर्तें आपके और प्रीमियम ईबिजनेस वेंचर प्राइवेट
        लिमिटेड/Premium eBusiness Venture Private Limited के बीच जीपीआर प्रीपेड
        कार्ड के संबंध में, जैसा कि समय-समय प्रीमियम ईबिजनेस वेंचर प्राइवेट
        लिमिटेड/ Premium eBusiness Venture Private Limited के स्वविवेक पर संशोधन
        किया जाता है, संपूर्ण एग्रीमेंट और/अथवा व्यवस्था को शासित और निर्मित
        करती हैं।
      </p>
      <p>
        जीपीआर प्रीपेड कार्ड प्राप्त करने के लिए साइन-अप प्रक्रिया को पूरा करने
        पर, मान लिया जाएगा कि आपने यहां पर उल्लेखित सभी नियमों और शर्तों को
        स्पष्ट रूप पढ़,समझ और स्वीकार कर लिया है। आप यहां उल्लेखित सभी नियमों और
        शर्तों, जैसा कि समय-समय प्रीमियम ईबिजनेस वेंचर प्राइवेट लिमिटेड/ Premium
        eBusiness Venture Private Limited के स्वविवेक पर संशोधन किया जाता है, से
        सहमत और बाध्य हाते हैं।
      </p>
      <p>
        <span>ये नियम और शर्ते, निजता नीति (</span>
        <a href="https://www.zokudo.com/privacy-policy">
          <span>https://www.zokudo.com/privacy-policy</span>
        </a>
        <span>) से और ग्राहक शिकायत निवारण नीति (</span>
        <a href="https://www.zokudo.com/customer-grievance-redressal">
          <span>https://www.zokudo.com/customer-grievance-redressal</span>
        </a>
        <span>
          ) और आवेदन फॉर्म पर अन्य शर्तों (यदि कोई हो तो)के साथ पढ़ें, जीपीआर
          प्रीपेड कार्ड के उपयोग के लिए ग्राहक और पीईवी के बीच संपूर्ण अनुबंध का
          निर्माण करती हैं।
        </span>
      </p>

      <div class="accordion tnc-accordion" id="accordionExample">
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              1.परिभाषाएं:
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <p>
                इन नियमों और शर्तों में, जब तक कि विपरीत उद्देश्य नहीं प्रकट
                होते हैं और/या अन्यथा संदर्भ की आवश्यकता नहीं होती है, प्रमुख
                पदों को निम्न के द्वारा परिभाषित किया जाता हैः
              </p>
              <ol className="list-[upper-roman]">
                <li>
                  कोष्ठकों में और/या उपवाक्यों में समावेशनों का अर्थ इस प्रकार
                  उत्तरदायी हैं;
                </li>
                <li>
                  निम्नलिखित शर्तों का अर्थ यहां नीचे एसाइन किए गए अनुसार होगाः
                </li>
              </ol>
              <ol className="list-decimal">
                <li>
                  <span>
                    " कार्ड अकाउंट " इस पीपीआई में उपलब्ध सीमा की निगरानी करने
                    के उद्देश्य से, इस प्रकार के पीपीआई(PPI) में लोड की गई राशि
                    के बराबर अकाउंट बैलेंस के साथ एक प्रीपेड खाते को संदर्भित
                    करता है।
                  </span>
                </li>
                <li>
                  <span>
                    " आवेदन पत्र " का अर्थ है, जैसा कि संदर्भ अनुमति देता है या
                    आवश्यक हो, ग्राहक द्वारा सभी आवश्यक सूचनाओं, विवरणों,
                    स्पष्टीकरणों और घोषणाओं के साथ, यदि कोई हो तो, पीईवी(PEV) को
                    आवेदन के लिए और/या जीपीआर प्रीपेड कार्ड प्राप्त करने के लिए
                    जमा किया गया जीपीआर(GPR) प्रीपेड कार्ड आवेदन फॉर्म, जीपीआर
                    प्रीपेड कार्ड के संबंध में ग्राहक द्वारा समय-समय पर दिया
                    जाएगा।
                  </span>
                </li>
                <li>
                  <span>
                    " जीपीआर प्रीपेड कार्ड " का अर्थ है एक प्रीपेड
                    उपकरण("पीपीआई/PPIs")(कार्ड के तौर पर जारी किए गए,चाहे भौतिक
                    हो या वर्चुअल), जो सेमी-क्लोज्ड या ओपन लूप भुगतान उपकरण होने
                    के कारण, इस प्रकार के उपकरण में स्टोर किए गए मूल्य के
                    विरुद्ध वित्तीय सेवाओं आदि सहित वस्तुओं और सेवाओं की खरीद को
                    सुगम करती है,जैसा कि 29 दिसंबर,2017 दिनांकित प्रीपेड भुगतान
                    उपकरण के जारीकरण तथा संचालन पर मास्टर निर्देश में परिभाषित
                    है, जिसे कि समय-समय पर संशोधित किया जाता।
                  </span>
                </li>
                <li>
                  <span>
                    " शुल्क " का अर्थ जीपीआर प्रीपेड कार्ड के उपयोग के लिए पीईवी
                    द्वारा लगाए इस प्रकार की आरोपित राशि, लागतें और शुल्क हैं,
                    जैसा कि समय-समय पर संशोधित किया जाता है।
                  </span>
                </li>
                <li>
                  <span>
                    " ग्राहक " का अर्थ कोई व्यक्ति, सीमित दायित्व साझीदार
                    फर्म,साझेदारी, समाज, कंपनी अथवा लागू कानूनों के तहत कोई अन्य
                    संस्थाएं या निकाय है, जो पीईवी से पीपीआई प्राप्त करते
                    हैं/खरीदते हैं और उसका उपयोग इस प्रकार के पीपीआई में स्टोर
                    मूल्य के विरुद्ध वित्तीय सेवाओं आदि सहित,वस्तुओं और सेवाओं
                    की खरीद के लिए करते हैं।
                  </span>
                </li>
                <li>
                  <span>
                    "ग्राहक सेवा केन्द्र" ग्राहक द्वारा उठाए गए सभी प्रश्नों,
                    शिकायतों या ग्राहक द्वारा जीपीआर प्रीपेड कार्ड के संबंध में
                    ग्राहक द्वारा मांगी गई जानकारी प्रदान करने को संबोधित करने
                    के लिए पीईवी द्वारा प्रदान की गई ग्राहक सेवा को संदर्भित
                    करता है।
                  </span>
                </li>
                <li>
                  <span>
                    "केवाईसी " का अर्थ ग्राहक की पहचान और पुष्टिकरण के लिए,
                    भारतीय रिजर्व बैंक द्वारा, समय-समय पर जारी दिशानिर्देशों के
                    आधार पर, दिशानिर्देशों, इश्तिहार और अधिसूचनाओं के अनुसार
                    पीईवी द्वारा स्वीकार्य अपने ग्राहक को जानें दिशानिर्देश
                    हैं,जिसमें 29 दिसंबर,2017 दिनांकित प्रीपेड भुगतान निर्देश के
                    संचालन और जारीकरण पर मास्टर निर्देश शामिल हैं पर इन तक ही
                    सीमित नहीं हैं, जैसा कि समय-समय पर संशोधित किया जाता है।
                  </span>
                </li>
                <li>
                  <span>
                    "व्यापारिक अधिष्ठान" का अर्थ स्टोर, दुकानों,
                    रेस्टोरेंट,होटल, भारत में स्थित पीओएस(POS) सहित इस प्रकार के
                    अधिष्ठान हैं, परंतु इन तक ही सीमित नहीं, जो जीपीआर प्रीपेड
                    कार्ड या RuPayनेटवर्क पर कोई भी कार्ड स्वीकार करते
                    हैं।&nbsp;
                  </span>
                </li>
                <li>
                  <span>
                    "व्यक्तिगत पहचान संख्या(पीआईएन)" एक संख्यात्मक पासवर्ड है,
                    जिसे पीपीआई के साथ पीईवी द्वारा प्रदान किए मूलभूत निर्देशों
                    के आधार पर ग्राहक द्वारा सेट किया जाना चाहिए।
                  </span>
                </li>
                <li>
                  <span>
                    "पीईवी" का अर्थ प्रीमियम ईबिजनेस वेंचर प्राइवेट लिमिटेड/
                    Premium eBusiness Venture Private Limitedहै, कंपनी
                    अधिनियम,1956 के अर्थ के भीतर एक कंपनी, इसका पंजीकृत कार्यालय
                    42,नवकेतन इंडस्ट्रियल एस्टेट, महाकाली केव्स रोड,
                    अंधेरी(ईस्ट),मुंबई-400093।{" "}
                  </span>
                </li>
                <li>
                  <span>
                    "पीओएस" या "बिक्री बिंदु" का अर्थ भारत में व्यापारिक
                    अधिष्ठापन द्वारा व्यवस्थापित इलेक्ट्रॉनिक टर्मिनल है जहां पर
                    ग्राहक वित्तीय सेवाओं अदि सहित, वस्तुएं और सेवाएं खरीदने के
                    लिए जीपीआर प्रीपेड कार्ड का उपयोग कर सकता है।
                  </span>
                </li>
                <li>
                  <span>
                    "प्रोग्राम" का अर्थ है ग्राहक को जीपीआर कार्ड प्रदान करने के
                    लिए पीईवी का कार्यक्रम।{" "}
                  </span>
                </li>
                <li>
                  <span>
                    "हस्तांतरण" का अर्थ है ग्राहक द्वारा किसी RuPay
                    मान्यताप्राप्त व्यापारिक अधिष्ठापन में जीपीआर प्रीपेड कार्ड
                    पर किया गया कोई हस्तांतरण।{" "}
                  </span>
                </li>
                <li>
                  <span>
                    "वेबसाइट" का अर्थ है यूआरएल:www.zokudo.com पर पीईवी के
                    स्वामित्व वाली, इसके द्वारा स्थापित और बनाए रखी गई वेबसाइट,
                    जिसमें समय-समय पर कोई सुधार और संशोधन शामिल हैं।
                  </span>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              2.जीपीआर प्रीपेड कार्डों के लक्षण:
            </button>
          </h2>
          <div
            id="collapseTwo"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <ol className="list-decimal">
                <u>
                  <li>
                    <strong>
                      संपूर्ण केवायसीजीपीआर(KYC GPR) प्रीपेड कार्ड
                    </strong>
                    <span>:</span>
                  </li>
                </u>
                <ol className="list-decimal">
                  <li>
                    <span>
                      संपूर्ण केवाई जीपीआर प्रीपेड कार्ड में किसी भी समय बकाया
                      राशि 2,00,000(भारतीय रुपयों में केवल दो लाख रुपए) रुपए से
                      अधिक नहीं होगी।
                    </span>
                  </li>
                  <li>
                    <span>
                      संपूर्ण केवाईसी जीपीआर प्रीपेड कार्ड की दैनिक लोड सीमा
                      रुपये होगी। 4,00,000 (भारतीय रुपये केवल चार लाख)।
                    </span>
                  </li>
                  <li>
                    <span>
                      हालांकि कोई भी सैपरेट सीमा लागू नहीं है, जैसा भी उचित
                      मालूम पड़ सकता है,पीईवी इस प्रकार की सीमा(ऊपर प्रदान की गई
                      सीमा अवधि के भीतर या समय-समय पर भारतीय रिजर्व बैंक द्वारा
                      निर्धारित) लागू करने का अधिकार सुरक्षित रखती है।
                    </span>
                  </li>
                  <li>
                    <span>
                      ग्राहक को संपूर्ण केवाईसी जीपीआर प्रीपेड कार्ड केवल 29
                      दिसंबर,2017 दिनांकित प्रीपेड भुगतान उपकरण के जारीकरण और
                      संचालन पर मास्टर दिशानिर्देशों(और इस आधार पर अन्य
                      निर्देशों) के साथ पुष्टि में सभी केवाईसी आवश्यकताओं को
                      पूरा करने के बाद ही जारी किया जाएगा, जैसा कि समय-समय पर
                      संशोधित किया जाता है।
                    </span>
                  </li>
                  <li>
                    <span>
                      पीईवी संपूर्ण केवाईसी वाले ग्राहकों के लिए जीपीआर
                      कार्ड/वॉलेट जारी करते समय पूर्व-निर्धारित बैंक खाते को
                      निगमित करने का विकल्प प्रदान करेगी।
                    </span>
                  </li>
                  <li>
                    <span>
                      ग्राहक पीईवी को 30(तीस) दिनों पहले एक पूर्व लिखित नोटिस
                      देकर, ग्राहक किसी भी समय संपूर्ण केवाईसी जीपीआर प्रीपेड
                      कार्ड को बंद कर सकता है,इसके अलावा, ग्राहक द्वारा जीपीआर
                      प्रीपेड कार्ड को हॉट लिस्ट या ब्लॉक किए जाने के अनुरोध पर,
                      जिस मामले में जीपीआर प्रीपेड कार्ड को त्वरित प्रभाव से
                      समाप्त और/या निरस्त कर दिया जाएगा।बैलेंस को संपूर्ण केवाई
                      जीपीआर प्रीपेड कार्ड की लागू सीमा के अनुसार उनके
                      पूर्व-निर्धारित बैंक अकाउंट वालेट में हस्तांतरित कर दिया
                      जाएगा, जैसा कि निर्धारित किया जा सकता है।
                    </span>
                  </li>
                </ol>
                <u>
                  <li>
                    <strong>
                      न्यूनतम और संपूर्ण केवाईसी जीपीआर प्रीपेड कार्डों दोनों पर
                      लागू शर्तें
                    </strong>
                    <span>:</span>
                  </li>
                </u>

                <ol className="list-decimal">
                  <li>
                    <span>
                      जीपीआर प्रीपेड कार्ड, ग्राहक के पीईवी द्वारा, समय-समय पर
                      निर्धारित योग्यता मापदंडों को पूरा करने के अधीन पीईवी
                      द्वारा जारी किया जाएगा।
                    </span>
                  </li>
                  <li>
                    <span>
                      जीपीआर प्रीपेड कार्ड को अन्य व्यक्तियों और पक्षों को
                      हस्तांतरण योग्य नहीं होंगे।
                    </span>
                  </li>
                  <li>
                    <span>
                      (जीपीआर प्रीपेड कार्ड केवल भारत राज्य के अंतर्गत और केवल
                      भारतीय रुपए में हस्तांतरण के लिए मान्य है। जीपीआर प्रीपेड
                      कार्ड का उपयोग भारत राज्य के बाहर अथवा विदेशी मुद्रा
                      प्रमुख हस्तांतरण के लिए नहीं किया जा सकता है।
                    </span>
                  </li>
                  <li>
                    <span>
                      जीपीआर प्रीपेड कार्ड विशेष रूप से पीईवी की संपत्ति है।
                    </span>
                  </li>
                  <li>
                    <span>
                      ग्राहक को जीपीआर प्रीपेड कार्ड की रसीद के तुरंत बाद इसके
                      उल्टी ओर हस्ताक्षर करने की जरूरत होगी(जैसा भी लागू हो सकता
                      है)। हस्ताक्षर न होने या हस्ताक्षर न मिलने की स्थिति में,
                      पीईवी ग्राहक को कोई सूचना या नोटिस दिए बिना किसी भी
                      हस्तांतरण को अस्वीकार या निरस्त करने का अधिकार सुरक्षित
                      रखती है।
                    </span>
                  </li>
                  <li>
                    <span>
                      पीईवी किसी भी समय जीपीआर प्रीपेड कार्ड या कार्ड अकाउंट में
                      किसी बैलेंस पर किसी भी ब्याज का भुगतान करने के लिए
                      जिम्मेदार नहीं होगी।
                    </span>
                  </li>
                  <li>
                    <span>
                      जीपीआर प्रीपेड कार्ड पर कैश समाप्त होने या वापसी अथवा राशि
                      के हस्तांरण की अनुमति नहीं होगी।
                    </span>
                  </li>
                  <li>
                    <span>
                      जीपीआर प्रीपेड कार्ड की समाप्ति के 45(पैतालीस) दिनों पहले
                      पीईवी ग्राहक को उसके पंजीकृत मोबाइल नंबर पर एसएमएस के जरिए
                      सूचित करेगी। ग्राहक को जीपीआर प्रीपेड कार्ड की समाप्ति
                      होने से पहले जीपीआर प्रीपेड कार्ड पर मौजूद संपूर्ण क्रेडिट
                      बैलेंस का उपयोग करने की जरूरत होगी। ग्राहक द्वारा जीपीआर
                      प्रीपेड कार्ड में मौजूद क्रेडिट बैलेंस को वैद्यता अवधि में
                      न उपयोग करने की स्थिति में, बकाया बैलेंस को पूर्वनिर्धारित
                      अकाउंट या ग्राहक द्वारा बनाए रखे गए एक अन्य
                      पूर्व-निर्धारित अकाउंट में वापसी/हस्तांतरण के लिए ग्राहक
                      पीईवी से आग्रह कर सकता है।{" "}
                    </span>
                  </li>
                  <li>
                    <span>
                      पूर्ण केवाईसी जीपीआर प्रीपेड कार्ड की वार्षिक लोड सीमा
                      रुपये होगी। 25,00,000 (भारतीय रुपये पच्चीस लाख केवल).{" "}
                    </span>
                  </li>
                </ol>
              </ol>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              3.जीपीआर प्रीपेड कार्ड का जारीकरण और उपयोग:
            </button>
          </h2>
          <div
            id="collapseThree"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <ol className="list-decimal">
                <li>
                  <span>
                    पीईवी द्वारा पहले निलंबित किए गए या सेवा से हटाए गए किसी भी
                    ग्राहक के लिए सेवा उपलब्ध नहीं होगी।ग्राहक किसी व्यक्ति या
                    निकाय का भेष नहीं बनाएगा, न ही असत्य किसी भी व्यक्ति या
                    निकाय के साथ गलत पहचान, आयु अथवा मान्यता नहीं प्रदर्शित
                    करेगा।
                  </span>
                </li>
                <li>
                  <span>
                    ग्राहक बिना किसी शर्त के और अपरिवर्तनीय रूप से पीईवी को
                    जीवीआर प्रीपेड कार्ड को ग्राहक अथवा किसी निकाय(जैसे एक
                    सह-ब्रांड पार्टनर या एक डिलीवरी सर्विस) को डिलीवर करने तथा
                    सौंपने के लिए अधिकृत करती है, जो कि इसे ग्राहक(ग्राहकों) में
                    वितरित कर देंगे।
                  </span>
                </li>
                <li>
                  <span>
                    पीईवी और व्यापारिक अधिष्ठान, किसी भी समय किसी भी कारण से,
                    गिना ग्राहक को पूर्व सूचना दिए जीपीआर प्रीपेड कार्ड को
                    स्वीकार करने का अधिकार सुरक्षित रखते हैं।
                  </span>
                </li>
                <li>
                  <span>
                    जीपीआर प्रीपेड कार्ड का उपयोग केवल प्रमाणिक कर्मचारियों या
                    आधिकारिक उद्देश्यों के लिए किया जाएगा। यह स्पष्ट किया जाजा
                    है कि कुछ व्यापारिक अधिष्ठानों के मामले में,व्युत्पन्न
                    प्रभारों में, अतिरिक्त सेवाओं या इस प्रकार के व्यापारिक
                    अधिष्ठानों द्वारा प्रदान की जा रही सेवाओं को प्राप्त करने के
                    लिए शुल्क शामिल है।
                  </span>
                </li>
                <li>
                  <span>
                    ग्राहक द्वारा व्यापारिक अधिष्ठानों द्वारा संपूर्ण किए गए
                    प्रत्येक हस्तांतरणों के लिए जनरेट की गई सभी स्लिपों (जैसा भी
                    लागू होगा) पर हस्ताक्षर करेगा और बनाए रखेगा।पीईवी ग्राहक को
                    शुल्क स्लिप या हस्तांतरण स्लिप की प्रति प्रदान करने के लिए
                    मजबूर नहीं है। ग्राहक द्वारा किया गया ऐसा कोई अनुरोध पीईवी
                    के स्व-विवेक पर होगा और इस तरह के अनुरोध किसी भी हस्तांतरण
                    से 45(पैतालीस) दिनों के भीतर किए जाने चाहिए। ग्राहक सहमत
                    होता है कि पीईवी शुल्क और हस्तांतरण की प्रति प्रदान करने के
                    लिए अतिरिक्त मूल्य या शुल्क लेने की हकदार होगी।
                  </span>
                </li>
                <li>
                  <span>
                    किसी भी हस्तांतरण के संबंध में व्यापारिक अधिष्ठानों द्वारा
                    लगाया गया कोई भी प्रभार, ग्राहक द्वारा व्यापारिक अधिष्ठानों
                    के साथ सीधे निबटाया जाएगा। व्यापारिक अधिष्ठान की ओर से किसी
                    कार्यवाही अथवा चूक के लिए अथवा हस्तांतरण के संबंध में उनके
                    द्वारा लगाए गए किसी प्रभार या शुल्क के लिए पीईवी न तो
                    प्रत्यक्ष और न ही अप्रत्यक्ष दोनों ही तरह से जिम्मेदार नही
                    होगी।
                  </span>
                </li>
                <li>
                  <span>
                    उपकरण त्रुटि या संवाद लिंक के कारण व्यापारिक अधिष्ठान को
                    बकाया सभी वापसी और एडजस्टमेंटों का प्रसंस्करण मैन्युअली किया
                    जाएगा और बकाया को पीईवी द्वारा लागू कानूनों, विनियमनों तथा
                    पीईवी की आंतरिक नीतियों के अनुरूप विधिवत पुष्टि करने के बाद
                    कार्ड अकाउंट में जमा कर दिया जाएगा। ग्राहक सहमत होता है कि
                    कोई भी आगामी हस्तांतरण पीईवी द्वारा किसी विवादास्पद राशि को
                    संज्ञान में लिए बिना कार्ड अकाउंट(अकाउंटों) में उपलब्ध जमा
                    बैलेंस के आधार पर स्वीकार और मान्य किया जाएगा।कार्ड अकाउंट
                    में अपर्याप्त राशि के परिणामस्वरूप पीईवी द्वारा भुगतान
                    निर्देशों का सम्मान करने के कारण पीईवी को हुए सभी नुकसानों
                    या क्षति के विरुद्ध, ग्राहक बिना किसी शर्त के पीईवी को
                    क्षतिपूर्ति करेगा। ग्राहक सहमत होता है कि पीईवी को हुए इस
                    नुकसान या क्षति को पीईवी सीधे कार्ड अकाउंट से काटने की भी
                    हकदार होगी।
                  </span>
                </li>
                <li>
                  <span>
                    ग्राहक मंजूरी देता है कि ग्राहक द्वारा अन्य भुगतान उपकरणों
                    का प्रयोग करने पर असफल, अस्वीकृत अथवा निरस्त सभी हस्तांतरणों
                    की वापसी को जीपीआर क्रेडिट कार्ड में जमा नहीं किया जा सकता
                    है।
                  </span>
                </li>
                <li>
                  <span>
                    ग्राहक जीपीआर प्रीपेड कार्ड से संबंधित सभी सौदों के संबंध
                    में हर समय अच्छी भावना के साथ काम करने का उत्तरदायित्व लेता
                    है। ग्राहक यहां पर दिए नियमों और शर्तों के उल्लंघन में
                    जीपीआर प्रीपेड कार्ड के गलत और गैरकानूनी उपयोग की पूरी
                    जिम्मेदारी लेना स्वीकार करता है, और पीईवी को हुए और/या
                    व्युत्पन्न किसी नुकसान,क्षति, ब्याज, विपरीत प्रभाव, और अन्य
                    वित्तीय प्रभारों के लिए पीईवी को क्षतिपूर्ति करने का
                    उत्तरदायित्व लेता है तथा सहमत होता है, चाहे यह प्रत्यक्ष रूप
                    से हुआ हो अप्रत्यक्ष रूप से अथवा ग्राहक द्वारा यहां दी गई
                    किसी शर्त के नियम और शर्त के उल्लंघन के परिणामस्वरूप।
                  </span>
                </li>
                <li>
                  <span>
                    ग्राहक सहमत होता है कि वह जीपीआर प्रीपेड को उपयोग किसी ऐसी
                    वस्तु या सेवा के भुगतान(नों) को करने के लिए नहीं करेगा जो कि
                    लागू कानूनों के तहत गैरकानूनी है। जीपीआर प्रीपेड कार्ड का
                    उपयोग प्रतिबंधित या निषिद्ध उत्पाद या सेवा की खरीद के लिए
                    नहीं किया जा सकता, जिसमें शामिल है, लॉटरी टिकट,प्रतिबंधित या
                    निषिद्ध पत्रिकाएं, स्वीपस्टैक(घोड़ों की रेस) में भाग
                    लेना,बिटक्वाइनों की खरीददारी, कॉल-बैक सेवाओं आदि के लिए
                    भुगतान, लेकिन ये इन तक ही सीमित नहीं हैं।
                  </span>
                </li>
                <li>
                  <span>
                    एतद्द्वारा ग्राहक जीपीआर प्रीपेड कार्ड का उपयोग करके किए सभी
                    हस्तांतरणों के लिए पीईवी से एसएमएस या ईमेल एलर्ट प्राप्त
                    करने के लिए सहमत होता है। पीईवी से प्राप्त एसएमएस एलर्टों
                    में जमा और निकासी हस्तांतरण, जीपीआर प्रीपेड कार्ड में मौजूद
                    शेष बैलेंस या पीईवी द्वारा समय-समय पर नियत की गई इस तरह की
                    अन्य जानकारी नियत होगी।
                  </span>
                </li>
                <li>
                  <span>
                    ग्राहक सहमत होता है और पुष्टि करता है कि किसी भी परिस्थिति
                    में ग्राहक द्वारा पिन(PIN) का खुलासा परिवार के किसी सदस्य या
                    तृतीय पक्ष से नहीं करेगा।पिन के इस प्रकार के अनाधिकृत खुलासे
                    और/या जीपीआर प्रीपेड कार्ड के अनाधिकृत उपयोग से उत्पन्न होने
                    वाले परिणामों के लिए ग्राहक पूरी तरह से जिम्मेदार होगा।
                    ग्राहक जीपीआर प्रीपेड कार्ड के अनाधिकृत प्रयोग के संबंध में
                    या इससे पीवीआर के लिए उत्पन्न होने वाले सभी दायित्वों और
                    उत्तरदायित्वों का खंडन करता है और/या ग्राहक इस प्रकार के गलत
                    उपयोग के परिणामस्वरूप पीवीआर द्वारा व्युत्पन्न किसी या सभी
                    नुकसानों या क्षतियों के लिए, चाहे ये प्रत्यक्ष हों या
                    अप्रत्यक्ष, पीवीआर को सुरक्षित करेगा।यदि ग्राहक पिन भूल जाता
                    है या खो देता है, नई पिन के लिए ग्राहक तुरंत लिखित में एक
                    आवेदन देगा या ग्राहक सेवा केन्द्र से संपर्क करेगा। नई पिन,
                    पीईवी को उपलब्ध करवाए गए या पीईवी के पास मौजूद ग्राहक के
                    पंजीकृत पते(या ऐसे तरीके से जैसा कि समय-समय पर पीईवी द्वारा
                    निर्दिष्ट किया जाएगा) पर भेज दी जाएगी।&nbsp;
                  </span>
                </li>
                <li>
                  <span>
                    ग्राहक सहमत होता है कि इन शर्तों पर जैसा कि आवश्यक या जरूरी
                    हो, पीईवी, अपने स्व-विवेक पर, सेवा के संदर्भ में बाहरी सेवा
                    प्रदाता/प्रदाताओं या एजेंट/एजेंटों की सेवाओं का उपयोग कर
                    सकती है।
                  </span>
                </li>
                <li>
                  <span>
                    ग्राहक के पास पीईवी द्वारा इस वेबसाइट पर अपलोड किए गए, और
                    समय-समय पर संशोधित किए गए, अक्सर पूछे जाने वाले प्रश्नों की
                    एक्सेस होगी।
                  </span>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              4.उल्लंघन:
            </button>
          </h2>
          <div
            id="collapseFour"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <ol className="list-decimal">
                <li>
                  <span>
                    इन नियमों और शर्तों, या अन्यथा इस पर ग्राहक द्वारा लागू
                    नियमों और शर्तों के उल्लंघन की स्थिति में,पीईवी के पास
                    जीपीआर प्रीपेड कार्ड को बिना किसी आगे के दावे, मांग या पीईवी
                    पर विवाद के तुरंत निरस्त अथवा प्रतिबंधित करने का एकमात्र
                    अधिकार होगा।
                  </span>
                </li>
                <li>
                  <span>
                    ग्राहक किसी भी और सभी हानियों, नुकसानों,दावों,जुर्मानों,
                    लागतों, मूल्यों, प्रभारों या खर्चों के विरुद्ध पीईवी को
                    क्षतिपूर्ति (कानूनी परामर्श फीस सहित)के लिए सहमत होता है और
                    वचन देता है, जो ग्राहक के यहां पर दिए गए नियमों और शर्तों
                    के, अथवा अन्यथा इस पर लागू नियमों और शर्तों का उल्लंघन करने
                    के परिणामस्वरू, चाहे प्रत्यक्ष या अप्रत्यक्ष व्युत्पन्न होते
                    हैं और/या उठाने पड़ सकते हैं।
                  </span>
                </li>
              </ol>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
              5.जीपीआर प्रीपेड कार्डों की वैधता और निरस्तीकरण:
            </button>
          </h2>
          <div
            id="collapseFive"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <ol className="list-decimal">
                <li>
                  <span>
                    जीपीआर प्रीपेड कार्ड प्रिंट होने की तिथि या जीपीआर प्रीपेड
                    कार्ड के फेस पर प्रिंट की गई समाप्ति तिथि से 3(तीन) वर्ष की
                    अवधि के लिए वैध होगा।आगे होने वाले पर बिना किसी पूर्वाग्रह
                    के,पीईवी अपने स्वविवेक पर अधिक समय की वैधता अवधि निर्दिष्ट
                    करने की हकदार होगी।
                  </span>
                </li>
                <li>
                  <span>
                    एतद्द्वारा ग्राहक मंजूरी देता है और सहमत होता है कि यदि
                    जीपीआर प्रीपेड कार्ड पर लगातार 1(एक) वर्ष की अवधि के लिए कोई
                    भी हस्तांतरण नहीं होता है, जीपीआर प्रीपेड कार्ड की वैधता के
                    अधीन, पीईवी द्वारा ग्राहक को एक नोटिस भेज देने के बाद जीपीआर
                    प्रीपेड कार्ड निष्क्रिय कर दिया जाएगा। जीपीआर प्रीपेड कार्ड
                    का पुनः सक्रियण पीईवी द्वारा केवल पुष्टिकरण और अपेक्षित
                    सम्यक तत्परता, और आवश्यक प्रभारों के भुगतान पर किया जाएगा,
                    जैसा कि समय-समय पर, पीईवी द्वारा निर्धारित किया जाएगा।
                  </span>
                </li>
                <li>
                  <span>
                    ग्राहक जीपीआर प्रीपेड कार्ड के किसी तृतीय पक्ष द्वारा इसके
                    उपयोग को रोकने के लिए इसकी समापन या निरस्तीकरण से पूर्व इसे
                    नष्ट करने के लिए सहमत होता है और वचन देता है(भौतिक जीपीआर
                    प्रीपेड कार्ड के मामले में)।
                  </span>
                </li>
                <li>
                  <span>
                    ग्राहक समझते हैं कि इस प्रकार का निरस्तीकरण या समापन तब तक
                    प्रभावी नहीं होगा जब तक कि जीपीआर प्रीपेड कार्ड के शीर्ष
                    दांये कोने को काटकर नष्ट न कर दिया जाए, सुनिश्चित करते हुए
                    कि दोनों होलोग्राम और मैग्नेटिक पट्टी नष्ट कर दिए गए हों या
                    पीईवी द्वारा प्राप्त कर लिए हैं। ग्राहक सहमत होता है कि
                    ग्राहक जीपीआर प्रीपेड कार्ड के समापन से पहले जीपीआर प्रीपेड
                    कार्ड पर व्युत्पन्न होने वाले किसी भी प्रभार के लिए
                    जिम्मेदार रहना जारी रहेगा, इस तथ्य की परवाह किए बिना कि क्या
                    ग्राहक ने, अथवा जीपीआर प्रीपेड कार्ड(भौतिक प्रीपेड कार्ड के
                    मामले में) को नष्ट करने का दावा किया है।
                  </span>
                </li>
                <li>
                  <span>
                    पीईवी अपने स्व-विवेक के आधार पर जीपीआर प्रीपेड कार्ड को
                    समाप्त या निरस्त कर सकती है :
                  </span>
                </li>

                <ol className="list-decimal">
                  <li>
                    <span>
                      ग्राहक के दिवालिया घोषित किए जाने की स्थिति में या ग्राहक
                      की मृत्यु पर;
                    </span>
                  </li>
                  <li>
                    <span>
                      ग्राहक के द्वारा किसी नियम, शर्त का उल्लंघन, इन नियमों और
                      शर्तों अथवा अन्यथा इस पर लागू नियमों और शर्तों के लिए इसके
                      दायित्वों या वचनों का उल्लंघन करने की स्थिति में;
                    </span>
                  </li>
                  <li>
                    <span>
                      किसी सक्षम न्यायालय या भारत में किसी नियामक या विनियामक
                      प्राधिकरण अथवा किसी जांच एजेंसी द्वारा जारी आदेश के कारण
                      ग्राहक पर लगाए गए प्रतिबंध की स्थिति में;
                    </span>
                  </li>
                  <li>
                    <span>
                      लागू कानूनों, नियमों, निर्देशों या इश्तिहारों के तहत
                      कार्यक्रम के गैरकानूनी होने की घटना में;
                    </span>
                  </li>
                  <li>
                    <span>
                      संपूर्ण कार्यक्रम के समाप्त कर दिए जाने की स्थिति में।
                    </span>
                  </li>
                </ol>

                <li>
                  <span>
                    पीईवी, किसी भी समय बिना कोई नोटिस दिए और इसके लिए कोई कारण
                    बताए बिना किसी भी समय स्वविवेक से अस्थाई या स्थाई दोनों तरह
                    से जीपीआर प्रीपेड कार्ड से विशेषाधिकारों को वापस ले सकता है
                    और/या जीपीआर प्रीपेड कार्ड को समाप्त कर सकती है। अस्थाई
                    वापसी की स्थिति में, जीपीआर प्रीपेड कार्ड को पीईवी द्वारा
                    इसके एकमात्र विवेक पर फिर से बहाल किया जाएगा। स्थाई वापसी की
                    स्थिति में, पीईवी के पास जीपीआर प्रीपेड कार्ड को स्थाई रूप
                    से निरस्त करने का अधिकार होगा।हालांकि, यह स्पष्ट रूप से साफ
                    कर दिया गया है कि वापसी(अस्थाई या स्थाई) जीपीआर प्रीपेड
                    कार्ड से जुड़े विशेषाधिकारों और सेवाओं, विशेषाधिकारों और
                    लाभों के स्वतः वापसी का निर्माण करेगी। ग्राहक सहमत होता है
                    कि जीपीआर प्रीपेड कार्ड के अस्थाई अथवा स्थाई वापसी की स्थिति
                    में, इस प्रकार की वापसी से पहले जीपीआर प्रीपेड कार्ड पर अन्य
                    सभी लागू शुल्कों सहित,व्युत्पन्न सभी प्रभारों के लिए पूरी
                    तरह से उत्तरदायी रहेगा,जबतक कि अन्यथा पीईवी द्वारा निर्दिष्ट
                    न किया जाए।
                  </span>
                </li>
                <li>
                  <span>
                    यदि पीईवी अस्थाई या स्थाई रूप से, विशेषाधिकारों को वापस ले
                    सकती है अथवा जीपीआर प्रीपेड कार्ड को समाप्त कर सकती है,
                    पीईवी, अपने सर्वोत्तम प्रयासों के आधार पर, ग्राहक को तुरंत
                    सूचित करने का अधिकार सुरक्षित रखती है।पीईवी इस प्रकार की
                    अधिसूचना की प्राप्ति में देरी अथवा विलंब की स्थिति में पीईवी
                    को उत्तरदायी नहीं ठहराया जाएगा।
                  </span>
                </li>
                <li>
                  <span>
                    जीपीआर प्रीपेड कार्ड के समापन पर जैसा कि ऊपर कहा गया है,
                    बैलेश राशि, यदि कार्ड अकाउंट में कोई बचा है, लागू प्रभारों
                    को घटाने के अधीन और ग्राहक के लिखित अनुरोध की रसीद पर, हर
                    समय इस संबंध में भारतीय रिजर्व बैंक के विनियिमनों की सीमा
                    तक, ग्राहक के पक्ष में निकाले गए पे आर्डर/डिमांड ड्रॉफ्ट के
                    जरिए ग्राहक को वापस कर दिया जाएगा।बैलेंस राशि की वापसी के
                    लिए ग्राहक पीईवी के पास एक अनुरोध जमा करेगा।
                  </span>
                </li>
                <li>
                  <span>
                    3 वर्ष की एक्सपायरी अवधि के समाप्त होने के बाद ग्राहक किसी
                    भी समय शेषराशि की वापसी की मांग कर सकता/सकती है। यदि कार्ड
                    के एक्सपायर होने के बाद 3 वर्ष के भीतर, ग्राहक वापसी की मांग
                    नहीं करता/करती है तो पीईवी शेषराशइ को इसके पीएंडएल(P&amp;L)
                    में हस्तांतरित कर देगी।
                  </span>
                </li>
              </ol>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSeven"
              aria-expanded="false"
              aria-controls="collapseSeven"
            >
              6.पंजीकरण डेटा,डेटा सुरक्षा और अकाउंट सुरक्षा :
            </button>
          </h2>
          <div
            id="collapseSeven"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <ol className="list-decimal">
                <li>
                  <span>
                    ग्राहक सहमत होता है (i)अपने बारे में सटीक,वर्तमान और संपूर्ण
                    सूचना प्रदान करने के लिए, जैसा कि आवेदन पत्र("पंजीकरण डेटा")
                    और वेबसाइट पर किसी पंजीकरण फॉर्म द्वारा प्रयोजित किया जा
                    सकता है; (ii)पिन की सुरक्षा बनाए रखने के लिए; (iii) पंजीकरण
                    डेटा और पीईवी को प्रदान की गई अन्य सूचना को बनाए रखना और
                    तुरंत अद्यतन करना; और(iv) अपने जीपीआर प्रीपेड कार्ड के उपयोग
                    के लिए पूरी तरह से उत्तरदायी रहने के लिए।&nbsp;
                  </span>
                </li>
                <li>
                  <span>
                    ग्राहक के निर्देशों के अनुसार एक कार्ड प्रदान करने के
                    लिए,जीपीआर प्रीपेड कार्ड को प्रबंधित करने के लिए, जीपीआर
                    प्रीपेड कार्ड या इसके उपयोग से संबंधित ग्राहक के किन्ही
                    प्रश्नों के साथ निबटने के लिए, सांख्यिकीय या विनियामक
                    रिपोर्टिंग और धोखाधड़ी बचाव प्रक्रिया उद्देश्यों के लिए पीईवी
                    ग्राहक के व्यक्तिगत डेटा(पंजीकरण डेटा सहित) का प्रसंस्करण
                    करेगा।
                  </span>
                </li>
                <li>
                  <span>
                    पीईवी गोपनीयता के साथ और केवल लागू बैक गोपनीयता कानून और
                    डेटा सुरक्षा कानूनों में अनुमत सीमा तक, आवेदन प्रसंस्करण
                    प्रदान करने वाले, धोखाधड़ी की निगरानी करने वाले, ग्राहक सेवा,
                    कार्ड उत्पादन और तकनीकि प्रसंस्करण सेवाओं और उनके एजेंटों और
                    उपसंविदाकारों सहित उन तृतीय पक्षों और अन्य कंपनियों को
                    ग्राहक की जानकारी(पंजीकरण डेटा सहित) प्रदान करेगी।
                  </span>
                </li>
                <li>
                  <span>
                    पीईवी व्यक्तिगत डेटा(पंजीकरण डेटा सहित) का प्रकटन इन्हें कर
                    सकता हैः (i) ग्राहक को, (ii) यदि गलत और अशुद्ध सूचना प्रदान
                    की गई है या धोखाधड़ी का संदेह है, तो धोखाधड़ी बचाव एजेंसी को,
                    और (iii)विधिक/विनियामक प्राधिकरणों, कानून लागूकरण या धोखाधड़ी
                    बचाव एजेंसी को जहां पर हमें ऐसा लागू कानूनों या न्यायालय के
                    आदेश से करने की जरूरत होगी। पीईवी उच्च गुणवत्ता की सेवा और
                    सुरक्षा प्रदान करने में सहायता के लिए, डेटा संग्रह और
                    प्रशिक्षण उद्देश्य, या जैसा कि लागू कानूनों द्वारा आवश्यकता
                    हो सकती है, टेलीफोन कॉल रिकॉर्ड और/या निगरानी कर सकती है।
                    सभी रिकॉर्डिंग पीईवी से संबंधित हैं। ऊपर स्थापित किए गए
                    उद्देश्यों लिए,पीईवी और अन्य ऊपर पहचाने गए प्राप्तकर्ताओं
                    द्वारा इस व्यक्तिगत डेटा(पंजीकरण डेटा सहित) के प्रसंस्करण के
                    लिए ग्राहक सहमति देता है।
                  </span>
                </li>
                <li>
                  <span>
                    एतद्द्वारा ग्राहक पीईवी और/या इसके सभी सहयोगियों को जीपीआर
                    प्रीपेड कार्ड(पंजीकरण डेटा सहित) के संबंध में सभी डेटा,
                    रिकॉर्डऔर इतिहास बनाए रखने की सहमति देता है और इस प्रकार के
                    डेटा की प्रति को आर्काइव करके इसे एक्सेस करने और इस प्रकार
                    के डेटा को तृतीय पक्ष से पहचाने न जा सकने योग्य रूप में साझा
                    करने के अधिकार सहित, उन्हें डेटा से संबंधित सभी अधिकार
                    प्रदान करता है। पीईवी, इसके सहयोगी अथवा कोई अन्य तृतीय पक्ष
                    जिनके साथ इस प्रकार का डेटा साझा किया गया है, डेटा को
                    बिना-पहचाने जाने वाले रूप में वाणिज्यिक और अनुसंधान
                    उद्देश्यों सहित, सांख्यिकीय,भविष्यसूचक,चिकित्सकीय याअन्य
                    डेटा मॉडल अन्यथा किसी अन्य तरीके से उपयोग करने के लिए डेटा
                    का समेकन करके अनंत समय तक उपयोग कर सकता है।
                  </span>
                </li>
              </ol>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseNine"
              aria-expanded="false"
              aria-controls="collapseNine"
            >
              7.संवाद नीति:
            </button>
          </h2>
          <div
            id="collapseNine"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <ol className="list-decimal">
                <li>
                  <span>
                    ग्राहक स्वीकार करता है कि पीईवी सेवाओं को प्राप्त करने के
                    लिए पंजीकृत करते समय ग्राहक द्वारा प्रदान किए गए नंबर पर या
                    इस तरह के प्रतिस्थापित अन्य नंबर पर, जैसा कि ग्राहक द्वारा
                    सूचित गया है आवश्यक एलर्ट भेज सकती है। ग्राहक मंजूरी देता है
                    कि एलर्ट केवल तभी प्राप्त की जाएंगी यदि मोबाइल फोन एसएमएस के
                    जरिए एलर्ट रिसीव करने के लिए "ऑन" मोड पर होगा। यदि मोबाइल
                    फोन "ऑफ" मोड पर है तो इस अवधि के दौरान भेजे गए कोई एलर्ट
                    ग्राहक को नहीं प्राप्त हो सकते हैं या विलंब से प्राप्त हो
                    सकते हैं।
                  </span>
                </li>
                <li>
                  <span>
                    ग्राहक उस बारे में एसएमएस,ईमेल और फोन कॉल के जरिए सूचना
                    प्राप्त करने के लिए भी सहमति प्रदान करता है जिसे पीईवी उनके
                    उपयोगकर्ता इतिहास के आधार पर उनकी रुचि का मानती है। इसमें
                    ऑफर,छूटें और सामान्य जानकारियां शामिल हैं। ग्राहक के द्वारा
                    इन सूचनाओं के न चाहने की स्थिति उन्हें स्पष्टतया निकाले जाने
                    के लिए कहना होगा।
                  </span>
                </li>
              </ol>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTen"
              aria-expanded="false"
              aria-controls="collapseTen"
            >
              8.सूचना :
            </button>
          </h2>
          <div
            id="collapseTen"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <ol className="list-decimal">
                <li>
                  <span>
                    नीचे दी गई पीईवी द्वारा द्वारा दी गई कोई भी नोटिस ग्राहक
                    द्वारा नोटिस दिए जाने के 7(सात) दिनों के भीतर या आपके
                    द्वारा, पीईवी को प्रदान की गई या उसके पास मौजूद ईमेल पर भेजे
                    जाने पर तुरंत स्वीकार्य मान ली जाएगी। ग्राहक के द्वारा इन
                    सूचनाओं के न चाहने की स्थिति उन्हें स्पष्टतया निकाले जाने के
                    लिए कहना होगा।&nbsp;
                  </span>
                </li>
                <li>
                  <span>
                    यहां पर नीचे दी गई ग्राहक द्वारा पीईवी को दी गई कोई भी नोटिस
                    केवल इस नोटिस के इसके कारपोरेट ऑफिस के पते-42,नवीन
                    इंडस्ट्रियल स्टेट, महाकाली केव्स रोड, अंधेरी(पूर्व), मुंबई
                    400093 पर रसीद की पुष्टि पर ही पीईवी द्वारा प्राप्त मानी
                    जाएगी।नीचे दिए गए अनुसा ग्राहक द्वारा कोई संवाद या नोटिस
                    पीईवी पर तब तक बाध्य नहीं होगा जब तक कि यह लिखित में न हो और
                    पीईवी द्वारा अथवा यदि पंजीकृत पोस्ट, मंजूरी कार्ड के जरिए
                    प्रदान अथवा मंजूर न किया गया हो।
                  </span>
                </li>
              </ol>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseEleven"
              aria-expanded="false"
              aria-controls="collapseEleven"
            >
              9.प्रभार:
            </button>
          </h2>
          <div
            id="collapseEleven"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <ol className="list-decimal">
                <li>
                  <span>प्रभारों में शामिल है:&nbsp;</span>
                </li>

                <ol className="list-decimal">
                  <li>
                    <span>
                      बदलने, नवीनीकरण, संभालने, और अन्य शुल्कों सहित, यदि कोई हो
                      तो के संबंध में जीपीआर प्रीपेड कार्ड के संबंध में प्रभारित
                      कोई शुल्क। ये शुल्क प्रकृति में गैर-वापसी योग्य,
                      गैर-हस्तांतरणीय और गैर-एसाइन किए जाने योग्य हैं।
                    </span>
                  </li>
                  <li>
                    <span>
                      विशेष प्रकार के हस्तांतरणों पर सेवा शुल्क। इस प्रकार के
                      शुल्कों के उपभोग की विधि उसी प्रकार होगी जैसा कि पीईवी
                      द्वारा इसकी वेबसाइट पर समय-समय पर अधिसूचित किया जाएगा।
                    </span>
                  </li>
                </ol>
                <li>
                  <span>
                    मालसूची में त्रुटि की अनुपस्थिति में सभी शुल्क, अंतिम होंगे
                    और ग्राहक पर बाध्य होंगे तथा प्रकृति में निष्कर्षात्मक
                    होंगे।
                  </span>
                </li>
                <li>
                  <span>
                    सभी विधिक कर, वस्तु और सेवा कर, अन्य सभी आयात कर,
                    चुंगियां(जीपीआर प्रीपेड कार्ड के संबंध में,स्टैंप ड्यूटी और
                    संबंधित पंजीकरण शुल्क, यदि कोई हो) और कर(किसी भी वर्णन का या
                    जो भी हो उसका), जीपीआर प्रीपेड कार्ड के संबंध जैसा कि
                    समय-समय पर सरकार या अन्य प्राधिकरण द्वारा लगाए जा सकते हैं,
                    ग्राहक द्वारा वहन किए जाएंगे।
                  </span>
                </li>
              </ol>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOneTwo"
              aria-expanded="false"
              aria-controls="collapseOneTwo"
            >
              10.प्रभारों की समय सारिणी:
            </button>
          </h2>
          <div
            id="collapseOneTwo"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <br />
              <TermsTable1Hindi />
              <p>
                यहां पर दिया गया कुछ भी, हालांकि, पीईवी को जीपीआर प्रीपेड कार्ड
                के जरिए किसी हस्तांतरण से स्रोत पर काटे जाने योग्य कर से कर
                काटने से नहीं बचाएगा, जैसा कि लागू कानूनों के तहत आवश्यक होगा।
              </p>
              <p>
                यह सारिणी अन्यथा वेबसाइट पर भी प्रदर्शित की जा सकती है, और पीईवी
                के एकल स्वविवेक पर समय-समय पर संशोधित की जा सकती है।
              </p>
              <TermsTable2Hindi />
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOneThree"
              aria-expanded="false"
              aria-controls="collapseOneThree"
            >
              11.खोया, चोरी हुआ और गलत उपयोग हुआ जीपीआर प्रीपेड कार्ड:
            </button>
          </h2>
          <div
            id="collapseOneThree"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <ol className="list-decimal">
                <li>
                  <span>
                    यदि जीपीआर प्रीपेड कार्ड चोरी हो गया है अथवा खो गया है, या
                    अन्यथा इसका गलत उपयोग किया गया है, ग्राहक को इस प्रकार के
                    नुकसान, चोरी की रिपोर्ट तुरंत ग्राहक सेवा केन्द्र को करनी
                    चाहिए और जीपीआर प्रीपेड कार्ड के तुरंत
                    निलंबीकरण/ब्लॉकिंग/हॉट लिस्टिंग के लिए अनुरोध करना चाहिए।{" "}
                  </span>
                </li>
                <li>
                  <span>
                    ग्राहक मंजूरी देता है कि एक बार जीपीआर प्रीपेड कार्ड के
                    खोने, चोरी होने, गलत उपयोग होने या क्षतिग्रस्त होने की
                    रिपोर्ट कर दी है, और उसे निलंबित/ब्लॉक/हॉट लिस्ट कर दिया गया
                    है, इस प्रकार जीपीआर प्रीपेड कार्ड का फिर से उपयोग नहीं किया
                    जा सकता है, यहां तक कि बाद में पाए जाने के बाद भी(भौतिक
                    कार्ड की स्थिति में)। ग्राहक जीपीआर प्रीपेड कार्ड की सुरक्षा
                    के लिए जिम्मेदार है और जीपीआर प्रीपेड कार्ड का फिर से उपयोग
                    न किया जाए इसे सुनिश्चित करने के लिए सभी कदम उठाएगा।यदि
                    पीईवी को ऐसी सूचना प्राप्त होती है या वह निधार्रित करती है
                    कि जीपीआर प्रीपेड कार्ड के खोन, चोरी या नष्ट होने की स्थिति
                    में,ग्राहक ऊपर उल्लेखित कदम उठाने में असफल रहा है या इनकी
                    अनदेखी की है, पीईवी के पास इस प्रकार के जीपीआर प्रीपेड कार्ड
                    को निरस्त या समाप्त करने का एकल अधिकार सुरक्षित रखती है।{" "}
                  </span>
                </li>
                <li>
                  <span>
                    ग्राहक द्वारा जीपीआर प्रीपेड कार्ड के इस प्रकार के खोने,
                    चोरी होने, गलत उपयोग होने या क्षतिग्रस्त होने की रिपोर्ट
                    अथवा कार्ड के पीवीआर द्वारा ब्लॉक होने से पहले, जीपीआर
                    प्रीपेड कार्ड पर किए गए किसी भी अनाधिकृत हस्तांतरण के लिए
                    पीईवी पर कोई दायित्व नहीं होगा। हालांकि, रिपोर्टिंग और/या
                    जीपीआर प्रीपेड कार्ड पर किए गए हस्तांतरण/हस्तांतरणों, जीपीआर
                    प्रीपेड कार्ड के खोने, चोरी होने, गलत उपयोग होने या
                    क्षतिग्रस्त होने की रिपोर्ट होने के बाद रिपोर्ट करने के समय
                    में किसी विवाद की स्थिति में, पीईवी के सभी निर्णय अंतिम
                    होंगे और ग्राहक पर बाध्य होंगे।
                  </span>
                </li>
              </ol>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne12"
              aria-expanded="false"
              aria-controls="collapseOneFour"
            >
              12.दायित्वों का अपवाद :
            </button>
          </h2>
          <div
            id="collapseOne12"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <ol className="list-decimal">
                <li>
                  <span>
                    अग्रिम पर पूर्वाग्रह के बिना, निम्न के कारण उत्पन्न होने
                    वाली किसी हानि या क्षति, के संबंध में प्रत्यक्ष और
                    अप्रत्यक्ष रूप से पीईवी ग्राहक या किसी तृतीय पक्ष के प्रति
                    किसी जिम्मेदारी या उत्तरदायित्व के अधीन नहीं होगाः
                  </span>
                </li>

                <ol>
                  <li>
                    <span>
                      व्यापारिक अधिष्ठान द्वारा प्रदान की गई वस्तु या सेवा में
                      किसी खराबी;
                    </span>
                  </li>
                  <li>
                    <span>
                      व्यापारिक अधिष्ठान द्वारा प्रदान की गई वस्तु या सेवा में
                      गुणवत्ता, डिलीवरी में देरी, डिलीवरी न होनें व्यापारिक
                      अधिष्ठान द्वारा प्रदान की गई या आपूर्ति की रसीद न मिलने के
                      संबंध में किसी विवाद के संबंध में;
                    </span>
                  </li>
                  <li>
                    <span>
                      किसी व्यक्ति द्वारा जीपीआर प्रीपेड कार्ड का सम्मान करने या
                      स्वीकार करने से मना करने पर;
                    </span>
                  </li>
                  <li>
                    <span>
                      जीपीआर प्रीपेड कार्ड के इच्छित तरीके से काम न करने या फिर
                      जिस भी कारण से हो;
                    </span>
                  </li>
                  <li>
                    <span>किसी कम्प्यूटर टर्मिनल की खराबी;</span>
                  </li>
                  <li>
                    <span>किसी अप्राकृतिक घटना में;</span>
                  </li>
                  <li>
                    <span>
                      जीपीआर प्रीपेड कार्ड का किसी तृतीय पक्ष को हस्तांतरण;
                    </span>
                  </li>
                  <li>
                    <span>ग्राहक द्वारा जीपीआर प्रीपेड कार्ड का समापन;</span>
                  </li>
                  <li>
                    <span>
                      ग्राहक को हुआ कोई नुकसान या क्षति जो पीईवी द्वारा जीपीआर
                      प्रीपेड कार्ड के पुनःधारण के कारण होना अभिकथित है;
                    </span>
                  </li>
                  <li>
                    <span>
                      जीपीआर प्रीपेड कार्ड के लिए आवंटित की गई राशि में कोई
                      विसंगति, जहां पर ऐसी राशि ग्राहक से निर्देश प्राप्त होने
                      पर आवंटित की गई है;&nbsp;
                    </span>
                  </li>
                  <li>
                    <span>
                      ग्राहक के अनुरोध पर जीपीआर प्रीपेड कार्ड में की गई कोई
                      वापसी।
                    </span>
                  </li>
                </ol>

                <li>
                  <span>
                    एतद्द्वारा यह स्पष्ट किया जाता है कि पीईवी का दायित्व हर समय
                    उस प्रासंगिक समय के दौरान ग्राहक के कार्ड अकाउंट में बनी हुई
                    राशि की सीमा तक प्रतिबंधित होगा।
                  </span>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne13"
              aria-expanded="false"
              aria-controls="collapseOneFour"
            >
              13.विवादास्पद हस्तांतरण:
            </button>
          </h2>
          <div
            id="collapseOne13"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <ol className="list-decimal">
                <li>
                  <span>
                    (a) पीईवी और/या ग्राहक द्वारा भुगतान के लिए प्राप्त कोई
                    प्रभार या हस्तांतरण स्लिप या अन्य भुगतान अधियाचन इस प्रकार
                    के प्रभार का निष्कर्षात्मक प्रमाण होगा, जब तक कि जीपीआर
                    प्रीपेड कार्ड खोया, चोरी न हुआ हो अथवा धोखाधड़ीपूर्वक गलत
                    उपयोग न किया गया हो और ग्राहक द्वारा प्रमाण न दिया गया हो।
                  </span>
                </li>
                <li>
                  <span>
                    (b) जीपीआर प्रीपेड कार्ड के संबंध में सभी विवादास्पद
                    हस्तांतरणों को पीईवी द्वारा बनाए रखे गए ग्राहक देखभाल
                    केन्द्र में, विवादास्पद हस्तांतरणों के किए जाने की तिथि के
                    15(पन्द्रह) दिनों के भीतर उठाने की आवश्यकता होगी। ग्राहक
                    मंजूरी देता है कि पीईवी द्वारा विवादास्पद हस्तांतरण के
                    15(पन्द्रह) दिनों के बाद कोई भी अनुरोध स्वीकार नहीं किया
                    जाएगा। इस संबंध में, कृपया यहां:{" "}
                  </span>
                  <a href="https://www.zokudo.com/customer-grievance-redressal">
                    <span>
                      https://www.zokudo.com/customer-grievance-redressal
                    </span>
                  </a>
                  <span>पर मौजूद ग्राहक शिकायत निवारण नीति देखें। &nbsp;</span>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne14"
              aria-expanded="false"
              aria-controls="collapseOneFour"
            >
              14.वस्तुओं और सेवाओं की गुणवत्ता:
            </button>
          </h2>
          <div
            id="collapseOne14"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <p>
                किसी व्यापारिक अधिष्ठान पर खरीदी गई किसी वस्तु अथवा प्राप्त की
                गई सेवा के विरुद्ध की गई किसी शिकायत या विवाद का समाधान ग्राहक
                द्वारा व्यापारिक अधिष्ठान के साथ सीधे-सीधे किया जाएगा।
              </p>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne15"
              aria-expanded="false"
              aria-controls="collapseOneFour"
            >
              15.प्रशासन नियम, न्यायाधिकरण और विवादों का समाधान:
            </button>
          </h2>
          <div
            id="collapseOne15"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <ol className="list-decimal">
                <li>
                  <span>
                    इन नियमों और शर्तों के संबंध में उत्पन्न होने वाले विवादों
                    को भारत के कानूनों के अनुसार निर्मित और प्रशासित किया जाएगा
                    तथा मुंबई न्यायालय के विशेष न्यायाधिकार का विषय होगा।
                  </span>
                </li>
                <li>
                  <span>
                    ऊपर दिए गई किसी भी चीज से पूर्वाग्रह के बिना, इन नियमों और
                    शर्तों के कारण उठने वाले या यहां इनके निर्माण अथवा विवेचन
                    अथवा अन्यथा यहां दिए गए संबंध में, पीईवी और ग्राहक के बीच
                    विवाद, मतभेद या दावे के मामले में, ग्राहक और पीईवी पहले इस
                    प्रकार के मतभेद, विवादों, दावों या प्रश्नों का निबटान एक
                    पक्ष से दूसरे पक्ष को ऐसी नोटिस प्राप्त होने के 15(पन्द्रह)
                    दिनों के भीतर आपसी बात-चीत से करने का प्रयास करेंगे, इसमें
                    असफल होने पर इसका निबटान मध्यस्थता और समाधान अधिनियम,1996 के
                    प्रावधानों के अनुसार किया जाएगा, जैसा कि समय-समय पर संशोधित
                    तथा पुनः-अभिनीत किया जाता है। विवाद का निबटान पीईवी और
                    ग्राहक दोनों के आपसी समझौते द्वारा चुने गए एकल मध्यस्थ के
                    बने मध्यस्थता अधिकरण द्वारा किया जाएगा। मध्यस्थता कार्यवाही
                    अंग्रेजी भाषा में की जाएगी। मध्यस्थता कार्यवाही की सिटिंग
                    मुंबई में होगी, अधिकरण का फैसला तर्कसंगत और अंतिम होगा।
                  </span>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne16"
              aria-expanded="false"
              aria-controls="collapseOneFour"
            >
              16.इन नियमों और शर्तों को बदलना:
            </button>
          </h2>
          <div
            id="collapseOne16"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <ol className="list-decimal">
                <li>
                  <span>
                    पीईवी इन नियमों और शर्तों को, जीपीआर प्रीपेड कार्ड पर प्रदान
                    की जा रही सुविधाओं और लाभों को किसी भी समय-समय पर बदलने का
                    एकल अधिकार सुरक्षित रखती है, जिसमें प्रभार या मूल्य,
                    ऑफर,विशेषाधिकार, सीमाएं और गणना की विधियां शामिल हैं पर इन
                    तक ही सीमित नहीं है।
                  </span>
                </li>
                <li>
                  <span>
                    पीईवी द्वारा नियमों और शर्तों में संशोधन को वेबसाइट पर होस्ट
                    करके, या किसी अन्य तरीके से बताया जाएगा जिसका निर्णय पीईवी
                    द्वारा इसके स्वविवेक के आधार पर लिया जाएगा।
                  </span>
                </li>
                <li>
                  <span>
                    ग्राहक संशोधनों सहित इन नियमों और शर्तों की समीक्षा करने के
                    लिए जिम्मेदार होंगे,जैसा कि समय-समय पर पीईवी वेबसाइट पर
                    पोस्ट किया जाएगा और जीपीआर प्रीपेड कार्ड का उपयोग जारी रखने
                    के द्वारा अथवा संशोधित नियमों और शर्तों के उपलब्ध होने के
                    24(चौबीस) घंटों के भीतर, जो भी पहले आता हो,संशोधित नियमों और
                    शर्तों को स्वीकार हुआ मान लिया जाएगा।
                  </span>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne17"
              aria-expanded="false"
              aria-controls="collapseOneFour"
            >
              17.कैशबैक:
            </button>
          </h2>
          <div
            id="collapseOne17"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <ol className="list-decimal">
                <li>
                  <strong>
                    <strong>कैशबैक के लिए नियम और शर्तें:</strong>
                  </strong>
                </li>

                <ol className="list-decimal">
                  <li>
                    <span>
                      कैशबैक उन व्यापारिक अधिष्ठानों के विरुद्ध प्रदान किया
                      जाएगा जिन्हें एप्लीकेशन/वेबसाइट के ऑफर/ब्रांड पृष्ठों पर
                      दिखाया गया है या जैसा कि विशेष व्यापारिक अधिष्ठान द्वारा
                      इसके पोर्टल/स्टोर पर विज्ञापित किया गया है।
                    </span>
                  </li>
                  <li>
                    <span>
                      कैशबैक का मूल्य ऑफर के भाग के रूप में दिखाया गया है और
                      हस्तांतरण राशि के आधार पर एक प्रतिशत मान(उदाहरण 5 %(पांच
                      प्रतिशत)) अथवा या एक फ्लैट मूल्य(उदाहरण 150 रुपए(भारतीय
                      रुपयों में एक सौ पचास रुपए केवल)) हो सकता है।{" "}
                    </span>
                  </li>
                  <li>
                    <span>
                      कैशबैक तुरंत नगद वापसी है जिसका मतलब है कि जैसे ही
                      पुष्टिकरण सफल होता है इसे तुरंत जीपीआर प्रीपेड कार्ड में
                      जमा कर दिया जाएगा।
                    </span>
                  </li>
                  <li>
                    <span>
                      जीपीआर प्रीपेड कार्ड बैलेंस/पॉकेट में कैशबैक जिसे सामान्य
                      बैलेंस की तरह उपयोग किया जा सकता है।
                    </span>
                  </li>
                  <li>
                    <span>
                      कैशबैक उसी जीपीआर प्रीपेड कार्ड में प्रदान किया जाएगा
                      जिसका उपयोग खरीद करने के लिए किया गया है।
                    </span>
                  </li>
                  <li>
                    <span>
                      कैशबैक उसी पॉकेट में प्रदान किया जाएगा जिसका उपयोग खरीद
                      करने के लिए किया गया है-उदाहरण, यात्रा,पॉकेट(आगे के विवरण
                      के लिए नीचे "पॉकेट" देखें)
                    </span>
                  </li>
                  <li>
                    <span>
                      कैशबैक की गणना हस्तांतरण राशि पर की जाती है और न कि बिलिंग
                      एमाउंट पर (जिसमें कर,जीएसटी, आदि शामिल है)।
                    </span>
                  </li>
                  <li>
                    <span>
                      वापसी या उलटाव की स्थिति में, संपूर्ण कैशबैक को उलटा कर
                      दिया जाएगा, चाहे वापसी आंशिक मूल्य के लिए क्यों न हो।
                    </span>
                  </li>
                  <li>
                    <span>
                      वापसी या उलटाव की स्थिति में पुनरावेष्टन, कैशबैक में किसी
                      कमी को जीपीआर प्रीपेड कार्ड/पॉकेट में से बकाया बैलेंस में
                      घटाया जाएगा।
                    </span>
                  </li>
                  <li>
                    <span>
                      प्रावधानों में यह स्पष्ट किया गया है कि कैशबैक हर समय पूरी
                      तरह से पीईवी के एकमात्र विवेक के अधीन होगा। पीईवी के
                      विरुद्ध इस प्रकार के कैशबैक या जो भी हो उसके संबंध में
                      ग्राहकों का कोई दावा नहीं होगा।
                    </span>
                  </li>
                </ol>

                <li>
                  <strong>
                    <strong>
                      यदि सीमाएं पार हो गईं तो कैशबैक कैसे प्रदान किया जाएगा इस
                      पर विवरण:
                    </strong>
                  </strong>
                </li>

                <ol className="list-decimal">
                  <li>
                    <strong>केवाईसी (KYC) सीमा: </strong>
                    <span>
                      कैशबैक के लिए केवाईसी सीमा का वर्णन करने वाली दो सीमाएं
                      हैं-किसी भी समय बैलेंस सीमा और मासिक/वार्षिक भार सीमा।
                      नीचे विस्तार में वर्णन किया गया है कि यदि एक ग्राहक किसी
                      भी स्थिति के अंतर्गत आता है तो कैशबैक कैसे प्रदान किया
                      जाएगाः
                    </span>
                  </li>

                  <ol>
                    <li>
                      <span>किसी भी समय बैलेंस सीमा:&nbsp;</span>
                    </li>

                    <p>
                      <span>
                        कैशबैक की गणना हस्तातरण मूल्य पर की जाएगी। यदि एक ग्राहक
                        के पास जीपीआर प्रीपेड कार्ड पर 10,000 रुपए(भारतीय रुपयों
                        में दस हजार मात्र) का बैलेंस है और उसे बैकएंड पर सेट (%)
                        के अनुसार कैशबैक प्राप्त होगा और यह उसे उसके द्वारा किए
                        गए हस्तांतरण के आधार पर प्राप्त होगा।उदाहरण के लिएः उसके
                        पास जीपीआर प्रीपेड कार्ड पर 10,000 रुपए(भारतीय रुपयों
                        में दस हजार केवल) का बैलेंस है और उसने 500 रुपए(भारतीय
                        रुपयों में दस हजार मात्र) की कीमत का एक हस्तांतरण किया
                        है, बैकएंड पर सेट कैशबैक1% (एक प्रतिशत) है तो ग्राहक को
                        5/- रुपए(भारतीय रुपयों में पांच रुपए मात्र) का कैशबैक
                        मिलेगा और जीपीआर प्रीपेड कार्ड पर उपलब्ध बैलेंस 9505/-
                        रुपए(भारतीय रुपयों में नौ हजार पांच सौ पांच मात्र) होगा।
                      </span>
                    </p>

                    <li>
                      <span>मासिक/वार्षिक ऋण सीमा:</span>
                    </li>

                    <p>
                      <span>
                        कैशबैक की गणना जीपीआर प्रीपेड कार्ड में अंतिम 30(तीस)
                        दिनों या अंतिम 1(एक) वर्ष में सभी ऋणों को जोड़ने के बाद
                        की जाएगी।{" "}
                      </span>
                    </p>
                  </ol>
                  <li>
                    <strong>पॉकेट सीमा:</strong>
                  </li>
                  <ol>
                    <li>
                      <span>
                        कैशबैक उसी पॉकेट में प्रदान किया जाता है जिसका उपयोग
                        हस्तांतरण करने के लिए किया गया था।{" "}
                      </span>
                    </li>
                    <li>
                      <span>
                        भले ही(पॉकेट बैलेंस+ कैशबैक राशि) &gt;अधिकतम पॉकेट सीमा,
                        पीईवी कैशबैक प्रदान करेगी। इसके साथ ही, पॉकेट बैलेंस
                        भविष्य के उपयोग के लिए अपडेट किया जाएगा।{" "}
                      </span>
                    </li>
                  </ol>
                </ol>
              </ol>
              <p>
                इन नियमों और शर्तों के उद्देश्य से, "पॉकेट" का अर्थ समान
                व्यापारिक अधिष्ठानों का एक समूह होगा, जिन्हें व्यापारिक वर्ग
                संहिता("एमसीसी") के आधार पर समूहित किया गया है।पॉकेट में एमसीसी
                के साथ व्यापारिक अधिष्ठान के विरुद्ध हस्तांतरण विशिष्ट पॉकेट
                बैलेंस तक सीमित है। एक जीपीआर प्रीपेड कार्ड में या तो एक डिफॉल्ट
                बैलेंस(जिसे एमसीसी में उपयोग किया सकता है) होगा या बैलेंस बाई
                पॉकेट।
              </p>
              <p>
                <strong>उदाहरण के तौर पर:</strong>
                <br />
                एक कारपोरेट अपने जीपीआर कार्ड का उपयोग केवल कुछ एमसीसी जैसे
                भोजन,यात्रा तथा और भी अधिक के लिए करना चाहता है,तो वह इन पॉकेटों
                को निर्धारित करके, जीपीआर प्रीपेड कार्ड में बैलेंस को अपलोड कर
                सकता है।
              </p>
              <p>पॉकेट A – भोजन – 2000/-</p>
              <p>पॉकेट B – यात्रा – 6000/-</p>
              <p>पॉकेटC – शॉपिंग – 2000/-</p>
              <p>अधिक विवरण के लिए, कृपया ग्राहक सेवा केन्द्र तक पहुंचें।</p>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne18"
              aria-expanded="false"
              aria-controls="collapseOneFour"
            >
              18.जीपीआर कार्ड का विमोचन:
            </button>
          </h2>
          <div
            id="collapseOne18"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <p>
                जीपीआर प्रीपेड कार्ड को किसी भी ऐसे व्यापारिक अधिष्ठान में रीडीम
                किया जा सकता है जो भुगतान के तरीके के रूप में RuPay उपकरणों का
                उपयोग करते हैं। हालांकि, कुछ एमसीसी(MCCs) हैं, जैसा कि नीचे
                उल्लेख किया गया है, जहां जीपीआर प्रीपेड कार्डों को रीडीम नहीं
                किया जा सकता है।
              </p>
              <TermsTable3Hindi />
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne19"
              aria-expanded="false"
              aria-controls="collapseOneThree"
            >
              19.हेल्प लाइन:
            </button>
          </h2>
          <div
            id="collapseOne19"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <p>
                <span>
                  हमें हमारे ग्राहक सेवा केन्द्र नंबर 8655000444 पर कॉल करें
                  अथवा{" "}
                </span>
                <a href="mailto:care@zokudo.com">
                  <span>care@zokudo.com</span>
                </a>
                <span>
                  {" "}
                  पर ईमेल करें या हमें इस पते पर लिखें-42,नवीन इंडस्ट्रियल
                  स्टेट, महाकाली केव्स रोड, अंधेरी(पूर्व), मुंबई 400093 ।
                </span>
                <strong></strong>
              </p>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne20"
              aria-expanded="false"
              aria-controls="collapseOneThree"
            >
              20.नोडल अधिकारी:
            </button>
          </h2>
          <div
            id="collapseOne20"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <p>
                नाम:- श्री रत्नेश जैन
                <br />
                ईमेल:-{" "}
                <a href="mailto:ratneshkjain@hmgroupindia.com">
                  ratneshkjain@hmgroupindia.com
                </a>
                <br />
                पता: - प्रीमियम ई-बिजनेस वेंचर प्रा। लिमिटेड, 42, नवकेतन
                इंडस्ट्रियल एस्टेट, महाकाली केव्स रोड, अंधेरी (पूर्व), मुंबई -
                400093
                <br />
                फ़ोन नंबर: - <b>91-22-66756002</b> एक्सटेंशन: <b>128</b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
