import React from "react";
import "../style/customer-greiviene.css";

import languageTranslate from "../assets/language-translate.svg";

import NavbarV2 from "./NavbarV2";
import Footer from "./common/Footer";

function CustomerGreivience() {
  return (
    <div className="mt-[40px] md:mt-[80px]">
      <div className="container py-4 text-left text-sm">
        <div className="row flex-column flex-md-row  mt-5">
          <div className="col-12 col-md-6">
            <h3 className="text-warning">Customer Grievance Redressal</h3>
          </div>
          
          <div className="col-12 border border-gray-500 text-sm rounded-xl p-4">
            <p>
              PEV will keep Customer’s rights in the highest priority and focus,
              especially related to personal privacy, clarity relating to rights
              and obligations, preservation of customer records, maintaining
              confidentiality of customer information, and fair practices in
              overall business conduct.
            </p>

            <ul className="pl-0">
              <li>Right to privacy</li>
              <li>Customer confidentiality</li>
              <li>Redressal of Grievances</li>
            </ul>

            <p>
              Grievances between System Participants in respect of any matter
              connected with the operation of the payment system shall be
              resolved in accordance with the provisions of the Payment and
              Settlement Systems Act, 2007, as may be amended from time to time.
            </p>

            <p>
              The Company will establish a dedicated Call Centre facility for
              effective resolution of all issues & grievances related to the
              business of ZEV. This facility will serve all PEV stakeholders.
            </p>

            <p>
              Call Center will be operational 12-hours (10am - 7pm) on all 7
              days of the week and will be responsible for effective and speedy
              resolution of all queries/issues/grievances.
            </p>
            <ul className="pl-0">
              <li>
                All Customer-facing staff will be trained adequately to
                competently & efficiently handle all customer complaints.
              </li>
              <li>
                PEV will have an escalation mechanism with clearly defined time
                frames at different levels, and the details of such a mechanism
                & responsible people with contacts will be put on the portal.
              </li>
            </ul>

            <h6 className="text-md font-semibold">
              All grievances will be addressed at 3 levels:
            </h6>
            <ol className="pl-0">
              <li>1. Automatic IVR</li>
              <li>2. Help Desk - Personal Assistance</li>
              <li>3. Nodal Officer</li>
              <li>
                4. Escalation Team – High Level Customer Grievance Redressal
                Team
              </li>
              <li>
                **issues = operational, product, process, transaction,
                settlement related – queries, issues or grievances.
              </li>
              <li>
                **issues = operational, product, process, transaction,
                settlement related – queries, issues or grievances
              </li>
              <li>
                **caller/customer = ZOKUDO Electronic Pre-paid gift voucher/card
                gifter, recipient & merchants
              </li>
              <li>**call = email, phone call, personal visit</li>
            </ol>
            <ol className="pl-0 flex flex-col gap-2">
              <li>
                <strong>1. Automatic IVR</strong>
                <br></br>
                For an instant and speedy resolution of queries, a smart and
                effective IVR system will be designed to resolve the most common
                issues. If the IVR system is unable to solve the caller issue
                and the caller chooses "Personal Assistance," the call will be
                transferred to the relevant "Help Desk".
              </li>
              <li>
                <strong>2. Help Desk</strong>
                <br></br>
                <ul className="mt-2">
                  <li>
                    <strong>Ticket Number</strong>
                    <br></br>
                    <ul className="list-disc">
                      <li>
                        On transfer to the "Help Desk," a unique ticket number
                        will be allocated to each issue.
                      </li>
                      <li>
                        Each "Ticket Number" will be tracked twice a day by the
                        "Help Desk" supervisor.
                      </li>
                      <li>
                        A query register will be maintained with full
                        information of each "Ticket Number".
                      </li>
                    </ul>
                  </li>
                  <li>
                    The call will then be transferred to the relevant subject
                    matter expert help desk:
                    <ul className="list-disc">
                      <li>Gifter Help Desk</li>
                      <li>Recipient/Redemption Help Desk</li>
                      <li>Merchant Help Desk</li>
                    </ul>
                  </li>
                  <li>
                    Each relevant Help Desk will further identify the purpose of
                    the call:
                    <ul className="list-disc">
                      <li>Portal related</li>
                      <li>Transaction related</li>
                      <li>Redemption related</li>
                      <li>Process related (Others)</li>
                    </ul>
                  </li>
                  <li>
                    The relevant Help Desk will make all efforts to resolve the
                    issue on-call/instantly. Failing this, the issue will be
                    handed over to the "Backroom Operations Team".
                  </li>
                </ul>
              </li>
              <li>
                <strong>3. Backroom Operations Team</strong>
                <br></br>
                The Backroom Operations Team will acknowledge each issue within
                48 hours of receipt of the issue. The team will resolve all
                queries within a maximum of 7 working days of receipt of the
                issue.
              </li>
              <li>
                <strong>4. Nodal Officer</strong>
                <br></br>
                <p>
                  Name: Mr. Ratnesh Jain<br></br>
                  Email:{" "}
                  <a href="mailto:ratneshkjain@hmgroupindia.com">
                    ratneshkjain@hmgroupindia.com
                  </a>
                  <br></br>
                  Address: Premium eBusiness Venture Pvt. Ltd., 42, Navketan
                  Industrial Estate, Mahakali Caves Road, Andheri (East), Mumbai
                  – 400093<br></br>
                  Phone number: +91-22-66756002 Ext: 128
                </p>
              </li>
              <li>
                <strong>5. Escalation Team</strong>
                <br></br>
                <p>
                  On the insistence of the caller or if the "Backroom Operations
                  Team" is unable to resolve an issue in the stipulated time,
                  the issue will automatically be referred to an escalation
                  tree.
                </p>
                <p>
                  The Escalation Tree will comprise of 3 levels, each level will
                  get an opportunity to resolve the issue within 7 days of
                  receipt of the issue, failing which the issue will
                  automatically get escalated to the next escalation level.
                </p>
                <ul className="pl-0">
                  <li>
                    Level-1 Escalation: Department Head / HOD /
                    Functional Head: Amarprit Gill (Email:{" "}
                    <a href="mailto:amarprit@zokudo.com">amarprit@zokudo.com</a>{" "}
                    
                  </li>
                  <li className="text-wrap">
                    Level-2 Escalation: Business Head / CEO /
                    COO: Ratnesh Jain (Email:{" "}
                    <a href="mailto:ratneshkjain@hmgroupindia.com">
                      ratneshkjain@hmgroupindia.com
                    </a>{" "}
                    
                  </li>
                  <li>
                    Level-3 Escalation: Directors / Managing
                    Committee: Yash Mehta (Email:{" "}
                    <a href="mailto:yashmehta@hmgroupindia.com">
                      yashmehta@hmgroupindia.com
                    </a>{" "}
                    
                  </li>
                </ul>
                <br/>
                <p className="mb-0"><b>For Customer Queries:</b>+91 86550 00444</p>
                <p><b>For Business Queries:</b>+91 75060 48939</p>
              </li>
              <li>
                <strong>6. Issue Resolved</strong>
                <br></br>
                <p>
                  An issue will be considered resolved only in the following
                  cases:
                </p>
                <ul className="pl-0">
                  <li>
                    a. Where the Customer has communicated his acceptance of the
                    Company's decision on redressal of grievance communicated by
                    the Company; or
                  </li>
                  <li>
                    b. Where the Customer has not communicated his acceptance of
                    the Company's decision, within 2 (two) months from the date
                    of communication of the decision by the Company.
                  </li>
                </ul>
              </li>
            </ol>

            <p>
              <strong>Note:</strong> Issues mentioned above can be related to
              operational, product, process, transaction, or settlement-related
              queries, issues, or grievances. The term "caller/customer" refers
              to ZOKUDO Electronic Pre-paid gift voucher/card gifter, recipient
              & merchants. The term "call" refers to email, phone call, or
              personal visit.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerGreivience;
