import React from 'react'

export default function LanguageDropdown() {
    const changeLanguage = (e) => {
      const pathname = window.location.pathname
        if(e.target.value === "Hindi") window.location.pathname = `${pathname}/hindi`
          else window.location.pathname = pathname.includes("privacy-policy") ? "/privacy-policy" : "/terms-and-conditions"
      }
  return (
    <select
              class="form-select custom-select align-self-start"
              aria-label="Default select example"
              onChange={(val) => changeLanguage(val)}
            >
              <option selected>
                Choose your language
              </option>
              <option value="English">
                <a href="/terms-and-conditions">English</a>
              </option>
              <option value="Hindi">
                <a href={`${window.location.pathname}/hindi`}>Hindi</a>
              </option>
            </select>
  )
}
