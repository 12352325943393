import React, { useState, useEffect, useRef } from "react";
import "../style/navbar-v2.css";
import { Link, useLocation } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa6";
import DropdownMenu from "./ui/DropdownMenu";
import MobileNavMenu from "./MobileNavMenu";
import logoWhite from "../assets/logo-white.svg";
import logoBlack from "../assets/logo-black.svg";
import { checkSession, letLogOut } from "../helper";
function NavbarV2(props) {
  const servicesMenuItems = [
    {
      text: "Corporate Solutions",
      linkTo: "/corporate-solutions",
    },
    {
      text: "BaaS Solution",
      linkTo: "/baas-solutions",
    },
  ];
  const legalMenuItems = [
    {
      text: "Terms & Conditions",
      linkTo: "/terms-and-conditions",
    },
    {
      text: "Privacy Policy",
      linkTo: "/privacy-policy",
    },
    {
      text: "Customer Grievance Redressal",
      linkTo: "/customer-grievance",
    },
    {
      text: "Customer Liability Policy",
      linkTo: "/customer-liability-policy",
    },
    {
      text: "Investor Relations",
      linkTo: "/investor-relations",
    },
    {
      text: "FAQ's",
      linkTo: "/faq",
    },
  ];
  const loginMenuItems = [
    {
      text: "As Admin",
      linkTo: "/admin",
    },
    {
      text: "As Customer",
      linkTo: "https://customer.zokudo.com",
      isExternal:"true"
    },
  ];
  const location = useLocation();
  const { pathname } = location;
  let backgroundDarkCss = ``;
  let anchorLinkCss = ``;

  const [showServicesMenu, setShowServicesMenu] = useState(false);
  const [showLegalMenu, setShowLegalMenu] = useState(false);
  const [showLoginMenu, setShowLoginMenu] = useState(false);
const [isLoggedin, setIsLoggedin] = useState(checkSession());


// useEffect(() => {
// if (checkSession()) {
//   setIsLoggedin(true)
//     }
//     setIsLoggedin(false);
// }, []);

  const toggleServices = () => {
    setShowServicesMenu(!showServicesMenu);
  };
  const toggleLegal = () => {
    setShowLegalMenu(!showLegalMenu);
  };
  const toggleLoginMenu = () => {
    setShowLoginMenu(!showLoginMenu);
  };

  const isLight = ["/about-us", "/corporate-solutions", "/baas-solutions"].includes(pathname);

  useEffect(() => {
    setShowServicesMenu(false);
    setShowLegalMenu(false);
    setShowLoginMenu(false);
  }, [pathname]);

  const dropdownRef = useRef(null);
  const dropdown2Ref = useRef(null);
  const dropdown3Ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowServicesMenu(false);
      }
    };
    const handleClick2Outside = (event) => {
      if (
        dropdown2Ref.current &&
        !dropdown2Ref.current.contains(event.target)
      ) {
        setShowLegalMenu(false);
      }
    };

    const handleClick3Outside = (event) => {
      if (
        dropdown3Ref.current &&
        !dropdown3Ref.current.contains(event.target)
      ) {
        setShowLoginMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("mousedown", handleClick2Outside);
    document.addEventListener("mousedown", handleClick3Outside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("mousedown", handleClick2Outside);
      document.removeEventListener("mousedown", handleClick3Outside);
    };
  }, []);

  return (
    <div>
      <nav
        className={`${
          isLight ? "bg-white py-1" : "bg-black py-1"
        } absolute top-0 left-0 flex justify-center w-full navbar-2 z-[100]`}
      >
        <div className="hidden lg:flex container mx-auto py-3 justify-between">
          <Link
            className={`navbar-brand grid place-items-center fs-2 ${
              isLight ? "text-black" : "text-white"
            }`}
            to="/"
          >
            <div className="flex flex-col justify-center">
              <img src={isLight ? logoBlack : logoWhite} alt="zokudo logo" />
            </div>
          </Link>
          <div className="flex justify-end">
            <ul
              className={`flex gap-3 xl:gap-4 text-center align-items-center mb-0 ${
                isLight ? "text-black" : "text-white"
              }`}
            >
              <li className="nav-item text-sm xl:text-lg">
                <Link
                  className={`text-inherit no-underline  ${
                    pathname === "/" ? "fw-semibold " : ""
                  }`}
                  aria-current="page"
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li className="nav-item p-2">
                <Link
                  className={`text-inherit no-underline ${
                    pathname === "/about-us" ? "fw-semibold" : "fw-light"
                  }`}
                  to="/about-us"
                >
                  About Us
                </Link>
              </li>
              <li className="nav-item relative" ref={dropdownRef}>
                <div
                  className="d-flex gap-2 fw-light cursor-pointer"
                  onClick={toggleServices}
                >
                  <span
                    className={`${
                      ["/corporate-solutions", "/baas-solutions"].includes(pathname)
                        ? "fw-semibold"
                        : "fw-light"
                    }`}
                  >
                    Services
                  </span>
                  <FaAngleDown
                    className={`mt-1 duration-300 ${
                      showServicesMenu ? "rotate-180" : ""
                    } `}
                  />
                </div>
                {showServicesMenu && (
                    <DropdownMenu data={servicesMenuItems} />
                )}
              </li>
              <li className="nav-item dropdown" ref={dropdown2Ref}>
                <div
                  className="d-flex gap-2 cursor-pointer"
                  onClick={toggleLegal}
                  
                >
                  <span
                    className={`${
                      [
                        "/terms-conditions",
                        "/privacy-policy",
                        "/customer-grievance",
                        "/customer-liability-policy",
                        "/faq",
                        "/investor-relations"
                      ].includes(pathname)
                        ? "fw-semibold"
                        : "fw-light"
                    }`}
                  >
                    Legal
                  </span>
                  <FaAngleDown
                    className={`mt-1 duration-300 ${
                      showLegalMenu ? "rotate-180" : ""
                    } `}
                  />
                </div>
                {showLegalMenu && <DropdownMenu data={legalMenuItems} />}
              </li>
              <li className="nav-item p-2">
                <Link
                  className={`text-inherit no-underline ${
                    pathname === "/career" ? "fw-semibold" : "fw-light"
                  }`}
                  to="/career"
                >
                  Career
                </Link>
              </li>
              <li className="nav-item p-2">
                <Link
                  className={`text-inherit no-underline ${
                    pathname === "/contact-us" ? "fw-semibold" : "fw-light"
                  }`}
                  to="/contact-us"
                >
                  Contact
                </Link>
              </li>

              {/* <li className="nav-item p-2">
                <Link
                  className="rounded-full text-black login-btn px-8 py-1"
                  to="/logIn"
                >
                  <button>Login</button>
                </Link>
              </li> */}
              {/* <li className="nav-item p-2">
                <a
                  className="rounded-full text-black login-btn px-8 py-1"
                  // href="https://customer.zokudo.com"
                  // target="_blank"
                  rel="noopener noreferrer"
                >
                  <button>Login</button>
                </a>
              </li> */}
             {!isLoggedin && <li className="nav-item relative rounded-full text-black login-btn px-8 py-1" ref={dropdown3Ref}>
                <div
                  className="d-flex gap-2 fw-light cursor-pointer"
                  onClick={toggleLoginMenu}
                >
                  <span
                    className={`${
                      ["/admin", "/"].includes(pathname)
                        ? "fw-semibold"
                        : "fw-light"
                    }`}
                  >
                    Login
                  </span>
                  <FaAngleDown
                    className={`mt-1 duration-300 ${
                      showLoginMenu ? "rotate-180" : ""
                    } `}
                  />
                </div>
                {showLoginMenu && (
                    <DropdownMenu data={loginMenuItems} />
                )}
              </li>}
             {isLoggedin && <li className="nav-item p-2">
                  <div className="cursor-pointer" onClick={letLogOut}>Logout</div>
              </li>}
            </ul>
          </div>
        </div>
        <MobileNavMenu />
      </nav>
    </div>
  );
}

export default NavbarV2;
