export default function TermsTable2() {
    const fees = [
        {
          "Channel": "Ecomm",
          "MCC": "4814",
          "Description": "Calls Through Use of Magnetic Stripe - Reading Telephones",
          "Value": "1.10%"
        },
        {
          "Channel": "Ecomm",
          "MCC": "4900",
          "Description": "Utilities - Electric, Gas, Sanitary, Water",
          "Value": "1.10%"
        },
        {
          "Channel": "Ecomm",
          "MCC": "8211",
          "Description": "Elementary and Secondary Schools",
          "Value": "1.10%"
        },
        {
          "Channel": "Ecomm",
          "MCC": "8220",
          "Description": "Colleges, Universities, Professional Schools, and Junior colleges",
          "Value": "1.10%"
        },
        {
          "Channel": "Ecomm",
          "MCC": "8241",
          "Description": "Schools, Correspondence",
          "Value": "1.10%"
        },
        {
          "Channel": "Ecomm",
          "MCC": "8244",
          "Description": "Schools, Business and Secretarial",
          "Value": "1.10%"
        },
        {
          "Channel": "Ecomm",
          "MCC": "8249",
          "Description": "Trade and Vocational Schools",
          "Value": "1.10%"
        },
        {
          "Channel": "Ecomm",
          "MCC": "8299",
          "Description": "Schools and Educational Services - not elsewhere classified",
          "Value": "1.10%"
        },
        {
          "Channel": "Ecomm",
          "MCC": "9402",
          "Description": "Postal Services - Government Only",
          "Value": "1.10%"
        },
        {
          "Channel": "Ecomm",
          "MCC": "5411",
          "Description": "Grocery Stores and Supermarkets",
          "Value": "0.95%"
        },
        {
          "Channel": "Ecomm",
          "MCC": "5541",
          "Description": "Service Stations (With or without Ancillary Services)",
          "Value": "1.30%"
        },
        {
          "Channel": "Ecomm",
          "MCC": "5542",
          "Description": "Automated Fuel Dispensers",
          "Value": "1.30%"
        },
        {
          "Channel": "Ecomm",
          "MCC": "5983",
          "Description": "Fuel Dealers - Coal, Fuel Oil, Liquefied Petroleum, Wood",
          "Value": "1.30%"
        },
        {
          "Channel": "Ecomm",
          "MCC": "5960",
          "Description": "Direct Marketing - Insurance Services",
          "Value": "1.30%"
        },
        {
          "Channel": "Ecomm",
          "MCC": "6300",
          "Description": "Insurance Sales, Underwriting, and Premiums",
          "Value": "1.30%"
        },
        {
          "Channel": "Ecomm",
          "MCC": "9399",
          "Description": "Government Services - not elsewhere classified",
          "Value": "1.30%"
        },
        {
          "Channel": "Ecomm",
          "MCC": "6012",
          "Description": "Financial Institutions - Merchandise and Services",
          "Value": "1.30%"
        }
      ]
  
    return (
      <div className="w-full overflow-x-auto">
        <table className="min-w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-100">
              <th className="px-6 py-3 border border-gray-300 text-left text-sm font-semibold text-gray-700 min-w-[150px]">
                Channel
              </th>
              <th className="px-6 py-3 border border-gray-300 text-left text-sm font-semibold text-gray-700 min-w-[150px]">
                MCC
              </th>
              <th className="px-6 py-3 border border-gray-300 text-left text-sm font-semibold text-gray-700 min-w-[400px]">
                Description
              </th>
              <th className="px-6 py-3 border border-gray-300 text-left text-sm font-semibold text-gray-700 min-w-[150px]">
                Value
              </th>
            </tr>
          </thead>
          <tbody>
            {fees.map((fee, index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                <td className="px-6 py-4 border border-gray-300 text-sm text-gray-600">
                  {fee.Channel}
                </td>
                <td className="px-6 py-4 border border-gray-300 text-sm text-gray-600">
                 {fee.MCC}
                </td>
                <td className="px-6 py-4 border border-gray-300 text-sm text-gray-600">
                 {fee.Description}
                </td>
                <td className="px-6 py-4 border border-gray-300 text-sm text-gray-600">
                 {fee.Value}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
  