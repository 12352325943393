import React from "react";

import languageTranslate from "../assets/language-translate.svg";

import NavbarV2 from "./NavbarV2";
import Footer from "./common/Footer";

function CustomerLiablity() {
  return (
    <div className="mt-[40px] md:mt-[80px]">
      <div className="container py-4 text-left text-sm">
        <div className="row mt-5 flex-column flex-md-row">
          <div className=" col-12 col-md-6">
            <h3 className="text-warning">Customer Grievance Redressal</h3>
          </div>
         
          <div className="col-12 border border-gray-500 text-sm rounded-xl p-4">
            <p>
              The objective of this policy is to limit the liability of
              Customers in unauthorised/fraudulent transactions involving the
              semi-closed loop reloadable and gift cards.
            </p>

            <h6 className="text-md font-semibold">Reporting of Unauthorised Transactions by Customers to PEV</h6>
            <p>
              It is mandatory to provide the card number and mobile number of
              the beneficiary/user of the card. OTP confirmation is required at
              the point of redemption, and SMS alerts are sent to customers at
              the point of redemption.
              <br />
              It is mandatory for a cardholder to report an
              unauthorised/fraudulent transaction within one working day of such
              an event.
            </p>

            <h6 className="text-md font-semibold">(a) Zero Liability of a Customer</h6>
            <p>
              A customer’s entitlement to zero liability shall arise where the
              unauthorised transaction occurs in the following events:
            </p>
            <ul className="pl-0">
              <li>
                Contributory fraud/negligence/deficiency on the part of PEV.
              </li>
            </ul>

            <h6 className="text-md font-semibold">(b) Limited Liability of a Customer</h6>
            <p>
              A customer shall be liable for the loss occurring due to
              unauthorised transactions in the following cases:
            </p>
            <ul className="pl-0">
              <li>
                In cases where the loss is due to negligence by a customer, such
                as where he has shared the payment credentials, the customer
                will bear the entire loss until he reports the unauthorised
                transaction to PEV. Any loss occurring after the reporting of
                the unauthorised transaction shall be borne by PEV.
              </li>
              <li>
                In cases where the responsibility for the unauthorised
                electronic transaction lies neither with PEV nor with the
                customer, but lies elsewhere in the system and when there is a
                delay (of one working day after receiving the communication from
                PEV) on the part of the customer in notifying PEV of such a
                transaction, the per transaction liability of the customer shall
                be limited to the transaction value or the card load value,
                whichever is lower.
              </li>
            </ul>

            <h6 className="text-md font-semibold">(c) Indemnity</h6>
            <p>
              The Cardholder hereby agrees to indemnify and keep PEV indemnified
              from and against all actions, claims, demands, proceedings,
              losses, damages, personal injury, costs, charges, and expenses
              whatsoever which PEV may at any time incur, sustain, or suffer in
              respect of the cardholder's use of the Card or otherwise. Unless
              expressly provided herein, PEV disclaims any liability to the
              cardholder in this regard.
            </p>

            <h6 className="text-md font-semibold">Reporting & Escalation</h6>
            <p>
              Reporting of any unauthorized transaction must be made on:{" "}
              <a href="mailto:care@zokudo.com">care@zokudo.com</a>
            </p>
            <ul className="pl-0">
              <li className="font-semibold">
                Level-1 Escalation: Department Head / HOD /
                Functional Head: Amarprit Gill (Email:{" "}
                <a href="mailto:amarprit@zokudo.com">amarprit@zokudo.com</a> &
                Phone: +91-22-66756002 Ext: 137)
              </li>
              <li className="font-semibold">
                Level-2 Escalation: Business Head / CEO / COO:
                Ratnesh Jain (Email:{" "}
                <a href="mailto:ratneshkjain@hmgroupindia.com">
                  ratneshkjain@hmgroupindia.com
                </a>{" "}
                & Phone: +91-22-66756002 Ext: 128)
              </li>
              <li className="font-semibold">
                Level-3 Escalation: Directors / Managing
                Committee: Yash Mehta (Email:{" "}
                <a href="mailto:yashmehta@hmgroupindia.com">
                  yashmehta@hmgroupindia.com
                </a>{" "}
                & Phone: +91-22-66756002 Ext: 134)
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerLiablity;
