import React from "react";
import "../style/term-and-condition.css";
import NavbarV2 from "./NavbarV2";
import Footer from "./common/Footer";

import languageTranslate from "../assets/language-translate.svg";
import { AiFillCaretRight } from "react-icons/ai";
import TermsTable1 from "./ui/TermsTable1";
import TermsTable2 from "./ui/TermsTable2";
import TermsTable3 from "./ui/TermsTable3";
import GPCardsTerms from "./ui/GPCardsTerms";
import LanguageDropdown from "./ui/LanguageDropdown";
import TermsTable1Hindi from "./ui/TermsTable1Hindi";
import TermsTable2Hindi from "./ui/TermsTable2Hindi";
import TermsTable3Hindi from "./ui/TermsTable3Hindi";
import GPCardTermsHindi from "./ui/GPCardTermsHindi";

function TermsHindi() {
  const fees = [
    { service: "Card Issuance Fee", charges: "Rs. 250 (Excluding Taxes)" },
    { service: "Card Replacement Fees", charges: "Rs. 250 (Excluding Taxes)" },
    {
      service: "Card Block / Unblocking Fee",
      charges: "Rs. 50 (Excluding Taxes)",
    },
    { service: "Card Closure Fees", charges: "Rs. 100 (Excluding Taxes)" },
    { service: "Account Inactive Fee", charges: "Rs. 50 (Excluding Taxes)" },
    { service: "Annual Fee", charges: "Rs. 100 (Excluding Taxes)" },
    { service: "Registration fee", charges: "NIL" },
    { service: "Issuance fees", charges: "NIL" },
    { service: "Loading fees", charges: "NIL" },
    { service: "Plastic card fees", charges: "INR 200 per card" },
    { service: "Lost card fees", charges: "INR 200 per card" },
    { service: "Lost PIN fees", charges: "NIL" },
    { service: "Reissuance of digital card", charges: "NIL" },
    { service: "Refund fees", charges: "NIL" },
  ];
  return (
    <div className="mt-[40px] md:mt-[80px] terms-page">
      <div className="container py-4">
        <div className="row mt-5 flex-column flex-md-row">
          <div className="col-12 col-md-6 text-left">
            <h3 className="text-warning">नियम एवं शर्ते</h3>
          </div>
          <div className="col-12 col-md-6 d-flex justify-content-md-end my-3 m-md-0">
            <LanguageDropdown />
          </div>
        </div>
        <section>
          <div class="d-flex flex-column gap-2">
            <div
              class="d-flex gap-2"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <button
                class="custom-btn lg:min-w-[300px] active"
                id="v-pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-home"
                type="button"
                role="tab"
                aria-controls="v-pills-home"
                aria-selected="true"
              >
                गिफ्ट कार्ड की शर्ते
              </button>
              <button
                class="custom-btn custom-btn2 lg:min-w-[300px]"
                id="v-pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-profile"
                type="button"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected="false"
              >
                रीलोडेबल कार्ड के उद्देश्य से नियम तथा शर्तें
              </button>
            </div>
            <div
              class="text-gray-500 text-sm tab-content border rounded-xl p-4"
              id="v-pills-tabContent"
            >
              <div
                class="tab-pane fade show active text-start"
                id="v-pills-home"
                role="tabpanel"
                aria-labelledby="v-pills-home-tab"
                tabindex="0"
              >
                <h6 className="text-warning flex gap-1">
                  {" "}
                  <AiFillCaretRight className="text-[#E4C221]" />
                  गिफ्ट कार्ड की शर्ते
                </h6>
                <p>
                  यह Zokudo प्रीपेड उपहार कार्ड ("कार्ड") आपको (कार्डधारक)
                  प्रीमियम ईबिजनेस वेंचर प्राइवेट लिमिटेड ("PEV") द्वारा जारी
                  किया गया है, जो कंपनी अधिनियम, 1956 के तहत गठित एक कंपनी है,
                  जिसका पंजीकृत और कॉर्पोरेट कार्यालय 42, नवकेतन इंडस्ट्रियल
                  एस्टेट, महाकाली गुफा रोड, अंधेरी (पूर्व), मुंबई - 400093 पर
                  स्थित है।
                </p>

                <p>
                  नियम और शर्तें: ये नियम और शर्तें ("नियम") PEV द्वारा जारी किए
                  गए कार्डधारक के कार्ड के उपयोग को नियंत्रित करती हैं। ये नियम,
                  गोपनीयता नीति (https://www.zokudo.com/privacy-policy) और
                  ग्राहक शिकायत निवारण नीति
                  (https://www.zokudo.com/customer-grievance-redressal) और आवेदन
                  फॉर्म पर अतिरिक्त शर्तें (यदि कोई हों) के साथ मिलकर कार्ड के
                  उपयोग के लिए कार्डधारक और PEV के बीच संपूर्ण अनुबंध का निर्माण
                  करती हैं। हम आपसे वेबसाइट (www.zokudo.com) देखने या 8655000444
                  पर PEV से संपर्क करने का आग्रह करते हैं।
                </p>

                <p>
                  कार्डधारक इस बात से सहमत हैं कि नियमों का पालन करेंगे और कार्ड
                  के साथ लेनदेन करके या कार्ड की प्राप्ति की लिखित रूप में
                  स्वीकार करने या कार्ड के पीछे हस्ताक्षर करके (जैसा लागू हो) और
                  सभी नियमों को पढ़ने, समझने और स्वीकार करने के लिए माना जाएगा।
                  कार्डधारक स्वयं को प्रासंगिक सूचनाओं/दिशानिर्देशों/परिपत्रों
                  में किसी भी परिवर्तन/संशोधन से अपडेट रखने के लिए जिम्मेदार
                  होगा जो कार्ड के उपयोग को नियंत्रित करता है। PEV कार्डधारक
                  द्वारा प्रासंगिक सूचनाओं/दिशानिर्देशों/परिपत्रों के उल्लंघन के
                  कारण किसी भी देयता को अस्वीकार करता है जो कार्ड के उपयोग को
                  नियंत्रित करता है और समय-समय पर लागू होता है। यदि नियम
                  स्वीकार्य नहीं हैं, तो कार्डधारक को कार्ड का उपयोग नहीं करना
                  चाहिए और तुरंत कार्ड को PEV को वापस कर देना चाहिए।
                </p>

                <p>
                  ये शर्तें भारतीय रिज़र्व बैंक (''आरबीआई'') के दिशानिर्देशों
                  (जिनमें शामिल हैं पर इन तक सीमित नहीं हैं, 29 दिसंबर, 2017 को
                  जारी किए गए प्रीपेड भुगतान उपकरण जारी करने और संचालन पर मास्टर
                  डायरेक्शन, समय-समय पर संशोधित) और PEV की नीतियों के अनुरूप
                  तैयार की गई हैं। ये शर्तें कार्ड के उपयोग और उससे जुड़े नियमों
                  और सुविधाओं को समझने में मदद करेंगी।
                </p>

                <p>
                  कार्ड खाता विवरण तक पहुंचना: कार्ड खाते में उपलब्ध शेष ("कार्ड
                  शेष") सहित कार्ड से संबंधित जानकारी, Zokudo ऐप में लॉग इन करने
                  के बाद या आपके कार्ड के पीछे सूचीबद्ध फोन नंबर और/या ईमेल पते
                  पर कॉल/लेखन करके प्राप्त की जा सकती है और इन शर्तों में भी
                  पुन: प्रस्तुत किया गया है और वेबसाइट पर उपलब्ध है।
                </p>

                <div class="accordion tnc-accordion" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        1.परिभाषाएं:
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse show"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p>
                          <span>
                            इन शर्तों में, जब तक कि विपरीत इरादा प्रकट नहीं होता
                            है और / या संदर्भ में अन्यथा आवश्यकता होती है,
                            पूंजीगत शब्दों द्वारा परिभाषित किया जाता है: (i)
                            उद्धरणों में शामिल और / या कोष्ठक का वो अर्थ है,
                            जैसे कि बताया गया है; और (ii) निम्नलिखित शर्तों के
                            नीचे दिए गए अर्थ होंगे:
                          </span>
                        </p>
                        <ol className="list-decimal">
                          <li>
                            <span>
                              "कार्ड खाता" प्रीपेड खाते को संदर्भित करता है
                              जिसमे अकाउंट बैलेंस कार्ड पर उपलब्ध सीमाओं की
                              निगरानी के उद्देश्य से, कार्ड पर लोड राशि के बराबर
                              है।
                            </span>
                          </li>
                          <li>
                            <span>
                              "आवेदन पत्र" का अर्थ है, जैसा कि संदर्भ की अनुमति
                              या आवश्यकता हो, कार्डधारक द्वारा पीईवी के लिए
                              आवेदन करने और / या सभी आवश्यक जानकारी, विवरण,
                              स्पष्टीकरण और घोषणाओं के साथ कार्ड प्राप्त करने के
                              उद्देश्य से कार्ड आवेदनकर्ता द्वारा PEV पीईवी को
                              प्रस्तुत किया गया कार्ड फॉर्म, यदि कोई हो, जो
                              कार्डधारक द्वारा कार्ड के संबंध में समय-समय पर
                              दिया जाता है।
                            </span>
                          </li>
                          <li>
                            <span>
                              "कार्ड" का अर्थ है एक प्रीपेड इंस्ट्रूमेंट
                              ("PPIs") (कार्ड, भौतिक या आभासी के रूप में जारी
                              किया गया), एक उपहार साधन है जो सामान और सेवाओं की
                              खरीद की सुविधा देता है, जैसा कि प्रीपेड पेमेंट
                              इंस्ट्रूमेंट जारी करने और संचालन पर मास्टर
                              डायरेक्शन में परिभाषित किया गया है जो दिनांक 29
                              दिसंबर, 2017 को समय-समय पर संशोधित किए गए हैं।
                            </span>
                          </li>
                          <li>
                            <span>
                              "कार्डधारक" का अर्थ है कि आप किसी भी व्यक्ति,
                              सीमित देयता भागीदारी फर्म, साझेदारी, समाज, कंपनी
                              या लागू कानूनों के तहत किसी अन्य संगठन या संस्था
                              से हैं, जो पीईवी से पीपीआई प्राप्त करते हैं और ऐसे
                              पीपीआई पर संग्रहीत मूल्य के लिए सामान और सेवाओं की
                              खरीद के लिए उपयोग करते हैं।
                            </span>
                          </li>
                          <li>
                            <span>
                              "शुल्क" का अर्थ है, कार्ड के उपयोग के लिए पीईवी
                              द्वारा लगाए गए ऐसे लगान, लागत और प्रभार, जो
                              समय-समय पर संशोधित किए जाते हैं।
                            </span>
                          </li>
                          <li>
                            <span>
                              "ग्राहक सेवा केंद्र" पीईवी द्वारा प्रदान की गई
                              ग्राहक सेवा, कार्डधारक द्वारा की गई शिकायतों या
                              कार्ड के संबंध में कार्डधारक द्वारा मांगी गई किसी
                              भी जानकारी या जानकारी को संदर्भित करता है।
                            </span>
                          </li>
                          <li>
                            <span>
                              "केवाईसी" का अर्थ है नो योर कस्टमर के
                              दिशा-निर्देश, जो कार्डधारक की पहचान और सत्यापन के
                              लिए पीईवी द्वारा अपनाई गई दिशा-निर्देशों,
                              परिपत्रों और सूचनाओं के अनुसरण में आरबीआई द्वारा
                              समय-समय पर जारी किए जाते हैं, जिसमें शामिल हैं,
                              लेकिन इनमें सीमित नहीं, प्रीपेड पेमेंट
                              इंस्ट्रूमेंट्स के जारी करने और संचालन के समय-समय
                              पर संशोधित मास्टर निर्देश दिनांक 29 दिसंबर, 2017।
                            </span>
                          </li>
                          <li>
                            <span>
                              "व्यापारी प्रतिष्ठान" का मतलब ऐसे प्रतिष्ठानों से
                              है, जिनमे भारत में स्थित दुकान, दुकान, रेस्तरां,
                              होटल, POS शामिल हैं लेकिन ये सिर्फ इन्ही तक सीमित
                              नहीं हैं, जो RuPay नेटवर्क पर कार्ड या कोई भी
                              दूसरा कार्ड स्वीकार करते हैं।
                            </span>
                          </li>
                          <li>
                            <span>
                              "पर्सनल आइडेंटिफिकेशन नंबर (पिन)" एक संख्यात्मक
                              पासवर्ड है, जिसे कार्डधारक, पीपीआई के साथ PEV
                              पीईवी द्वारा प्रदान किए गए आधार निर्देशों द्वारा
                              निर्धारित करना होता है।
                            </span>
                          </li>
                          <li>
                            <span>
                              "पीओएस" या "प्वाइंट ऑफ सेल" का मतलब भारत में
                              व्यापारी प्रतिष्ठानों द्वारा बनाए गए इलेक्ट्रॉनिक
                              टर्मिनलों से है, जिस पर कार्डधारक सामान और सेवाओं
                              की खरीद के उद्देश्य से कार्ड का उपयोग कर सकता है।
                            </span>
                          </li>
                          <li>
                            <span>
                              "प्रोग्राम" का अर्थ PEV पीईवी के कार्डधारक को
                              कार्ड देने का कार्यक्रम है।
                            </span>
                          </li>
                          <li>
                            <span>
                              "ट्रांसैक्शन" का अर्थ है कार्डधारक द्वारा किसी भी
                              RuPay संबद्ध व्यापारी प्रतिष्ठान में कार्ड पर शुरू
                              किया गया कोई लेनदेन।
                            </span>
                          </li>
                          <li>
                            <span>
                              "वेबसाइट" का अर्थ है कि URL पर स्थित PEV पीईवी
                              द्वारा स्वामित्व, स्थापित और अनुरक्षित वेबसाइट:
                              www.zokudo.com समय-समय पर किसी भी संशोधन या संशोधन
                              सहित।
                            </span>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        2.योग्यता, उपयोग, सीमा और सुरक्षा :
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ol className="list-decimal">
                          <li>
                            <u>
                              <span>पात्रता</span>
                            </u>
                            <span>
                              : पीईवी द्वारा सेवाओं को पहले निलंबित या हटाए गए
                              किसी भी कार्डधारक के लिए सेवाएं उपलब्ध नहीं हैं।
                              कार्डधारक किसी व्यक्ति या इकाई, या मिथ्या स्थिति
                              या किसी व्यक्ति या संस्था के साथ पहचान, उम्र या
                              संबद्धता को गलत तरीके से प्रस्तुत नहीं करेगा।
                            </span>
                          </li>
                          <li>
                            <u>
                              <span>उपयोग</span>
                            </u>
                            <span>
                              : कार्ड का जारी होना और उपयोग पीईवी द्वारा समय-समय
                              पर जारी किए गए नियमों और विनियमों और आरबीआई द्वारा
                              जारी दिशा-निर्देशों के अधीन होगा। कार्डधारक भारत
                              में स्थित व्यापारी प्रतिष्ठानों से वस्तुओं और
                              सेवाओं को खरीदने या उनका लाभ उठाने के लिए केवल
                              भारत में कार्ड का उपयोग करेगा।
                            </span>
                          </li>
                          <li>
                            <u>
                              <span>वितरण</span>
                            </u>
                            <span>
                              : कार्डधारक बिना शर्त और अपरिवर्तनीय रूप से
                              कार्डधारक या इकाई (जैसे सह-ब्रांड साझेदार या
                              डिलीवरी सेवा) को कार्ड वितरित करने और सौंपने के
                              लिए पीईवी को अधिकृत करता है जो इसे कार्डधारक (ओं)
                              को वितरित करेगा। PEV पीईवी कार्डधारक को कार्ड की
                              डिलीवरी के संबंध में इकाई की ओर से किसी भी अधिनियम
                              या चूक के लिए उत्तरदायी या जिम्मेदार नहीं होगा।
                            </span>
                          </li>
                          <li>
                            <u>
                              <span>ट्रांसफ़रेबिलिटी</span>
                            </u>
                            <span>
                              : कार्डधारक किसी भी परिस्थिति में किसी भी व्यक्ति
                              को कार्ड ट्रांसफर या असाइन करने योग्य नहीं है।
                            </span>
                          </li>
                          <li>
                            <u>
                              <span>खोया और चोरी हुआ कार्ड</span>
                            </u>
                            <span>:&nbsp;</span>
                          </li>
                          <ul>
                            <ol>
                              <li>
                                <span>
                                  यदि कार्ड या उसका पिन खो जाता है, चोरी हो जाता
                                  है, दुरुपयोग होता है या डैमेज हो जाता है, तो
                                  कृपया तुरंत कार्ड के पीछे सूचीबद्ध टेलीफोन
                                  नंबरों पर कस्टमर केयर सेंटर से संपर्क करें और
                                  ये इन शर्तों में पुन: प्रस्तुत और वेबसाइट पर
                                  भी उपलब्ध हैं। &nbsp;
                                </span>
                              </li>
                              <li>
                                <span>
                                  कार्ड आपके नुकसान की रिपोर्ट करने के 24 घंटे
                                  (चौबीस) के भीतर अवरुद्ध हो जाएगा और आपके कार्ड
                                  की हानि की रिपोर्ट करने के समय से आपके पैसे की
                                  रक्षा का जोखिम शुरू हो जाएगा। कार्डधारक यह
                                  स्वीकार करता है कि एक बार कार्ड खो जाने, चोरी
                                  होने, डैमेज होने या दुरुपयोग होने की सूचना
                                  मिलने के बाद, और उसी को ब्लॉक कर देने पर, ऐसे
                                  कार्ड का फिर से उपयोग नहीं किया जा सकता है,
                                  भले ही बाद में (भौतिक कार्ड के मामले में) यह
                                  मिल गया हो। &nbsp;
                                </span>
                              </li>
                              <li>
                                <span>
                                  पीईवी अपने विवेकाधिकार पर और कार्डधारक की
                                  पहचान के सत्यापन पर, निर्धारित शुल्क लगाकर खोए
                                  कार्ड के बदले में एक नया कार्ड जारी कर सकता
                                  है, जैसा कि समय-समय पर इसके द्वारा निर्धारित
                                  किया जाता है, हालांकि, इन शर्तों पर हर समय।
                                </span>
                              </li>
                              <li>
                                <span>
                                  कार्ड पर किए गए किसी भी अनधिकृत लेन-देन के लिए
                                  पीईवी पर कोई देयता देय नहीं होगी, कार्डधारक
                                  द्वारा कार्ड खो जाने, चोरी, दुरुपयोग या डैमेज
                                  होने की सूचना के बाद और पीईवी द्वारा अवरुद्ध
                                  किए जाने से पहले। हालाँकि, कार्ड पर किए गए
                                  रिपोर्टिंग और / या लेनदेन / लेनदेन के समय से
                                  संबंधित किसी भी विवाद के मामले में, कार्ड की
                                  गुमशुदगी, चोरी, दुरुपयोग या डैमेज की
                                  रिपोर्टिंग के बाद, PEV पीईवी के सभी निर्णय
                                  अंतिम होंगे और कार्डधारक पर बाध्यकारी होंगे।
                                </span>
                              </li>
                            </ol>
                          </ul>
                          <li>
                            <u>
                              <span>कानूनीता</span>
                            </u>
                            <span>: कार्डधारक हर समय केवल </span>
                            <em>
                              <span>कानून के अधीन</span>
                            </em>
                            <span>,</span>
                            <span>
                              वैध तरीके से कानून के उद्देश्यों के लिए कार्ड का
                              उपयोग करेगा। कार्ड का उपयोग निषिद्ध या विरोधाभासी
                              उत्पादों या सेवाओं की खरीदारी करने के लिए नहीं
                              किया जा सकता है, जिनमें शामिल हैं, लेकिन, लॉटरी
                              टिकट, प्रतिबंधित या प्रतिबंधित पत्रिकाएं,
                              स्वीपस्टेक में भागीदारी, बिटकॉइन की खरीद, कॉल-बैक
                              सेवाओं के लिए भुगतान, आदि तक सीमित नहीं हैं।
                            </span>
                          </li>
                          <li>
                            <u>
                              <span>थर्ड पार्टी की और से उपयोग</span>
                            </u>
                            <span>
                              : कार्डधारक के हित को गैर-अधिकृत उपयोग से बचाने के
                              लिए, यह सलाह दी जाती है कि कार्डधारक हर समय कार्ड
                              और पिन को अपनी निजी हिरासत में रखे। &nbsp;
                            </span>
                          </li>
                          <li>
                            <u>
                              <span>पिन सुरक्षा</span>
                            </u>
                            <span>
                              : कार्डधारक सहमत है और पुष्टि करता है कि कार्ड
                              किसी भी रिश्तेदार या परिवार के सदस्यों या थर्ड
                              पार्टी को कार्डधारक द्वारा किसी भी परिस्थिति में
                              प्रकट नहीं किया जाएगा। कार्डधारक पिन के अनधिकृत
                              प्रकटीकरण और / या अनधिकृत उपयोग से उत्पन्न
                              परिणामों के लिए पूरी तरह से जिम्मेदार और उत्तरदायी
                              होगा। यदि कार्डधारक पिन भूल जाता है या गलत बताता
                              है, तो कार्डधारक को लिखित रूप में एक आवेदन करना
                              चाहिए या नए पिन के लिए तुरंत ग्राहक सेवा केंद्र से
                              संपर्क करना चाहिए। नया पिन कार्डधारक के पंजीकृत
                              ईमेल पते पर भेजा जाएगा (या पीईवी द्वारा समय-समय पर
                              इस तरह के अन्य मोड के माध्यम से निर्दिष्ट किया जा
                              सकता है), जो पीईवी को प्रदान या उपलब्ध है।
                            </span>
                          </li>
                          <li>
                            <u>
                              <span>कार्ड</span>
                            </u>
                            <span>
                              : कार्ड को नकद की तरह माना जाता है, और पीईवी कार्ड
                              के दुरुपयोग, हानि या चोरी के लिए उत्तरदायी नहीं
                              होगा। इन शर्तों या लागू कानून के उल्लंघन करने पर
                              ऐसे कार्ड की समाप्ति पर PEV पीईवी नए कार्ड को फिर
                              से जारी नहीं करेगा। पीईवी और व्यापारी प्रतिष्ठान,
                              कार्डधारक को किसी भी पूर्व सूचना के बिना, किसी भी
                              कारण से किसी भी समय कार्ड की स्वीकृति से इनकार
                              करने का अधिकार सुरक्षित रखते हैं।
                            </span>
                          </li>
                          <li>
                            <u>
                              <span>जवाबदेही</span>
                            </u>
                            <span>
                              : कार्डधारक द्वारा कार्ड के उपयोग से प्राप्त माल
                              या सेवाओं की गुणवत्ता, मूल्य, वारंटी, डिलीवरी में
                              देरी, गैर-वितरण, किसी भी प्रकार की रसीद सहित किसी
                              भी प्रकार के विवादों के लिए पीईवी किसी भी तरह से
                              जिम्मेदार नहीं होगा। यह स्पष्ट रूप से समझा जाना
                              चाहिए कि कार्ड सुविधाओं का लाभ उठाने के लिए
                              कार्डधारक के लिए कार्ड केवल एक सुविधा है और पीईवी
                              कोई वारंटी नहीं रखता है या गुणवत्ता, मात्रा,
                              मूल्य, वितरण या अन्यथा के बारे में कोई
                              प्रतिनिधित्व नहीं करता है, कार्ड या कार्ड का उपयोग
                              करके प्राप्त वस्तुओं या सेवाओं के बारे में, और ऐसे
                              किसी भी विवाद को कार्डधारक द्वारा संबंधित व्यापारी
                              प्रतिष्ठान या थर्ड पार्टी के साथ सीधे हल किया जाना
                              चाहिए।
                            </span>
                          </li>
                          <li>
                            <u>
                              <span>कार्ड बैलेंस में परिवर्तन</span>
                            </u>
                            <span>
                              : कार्डधारक लेन-देन के रिवर्सल की स्थिति में कार्ड
                              बैलेंस में क्रेडिट के लिए हकदार हो सकता है, और
                              कार्डधारक को कोई नकद वापस नहीं किया जाएगा। PEV
                              पीईवी कार्ड बैलेंस क्रेडिट करते समय, कार्ड से जुड़े
                              मर्चेंट प्रतिष्ठानों द्वारा लगाए गए लागू शुल्क को
                              वसूल करेगा और यह प्रक्रिया PEV पीईवी के लागू
                              नियमों, विनियमों और आंतरिक नीतियों के अधीन होगी।
                              कार्डधारक स्वीकार करता है कि किसी अन्य भुगतान साधन
                              का उपयोग करके कार्डधारक द्वारा किए गए विफल, लौटे,
                              अस्वीकृत या रद्द किए गए लेन-देन के मामले में सभी
                              रिफंड कार्ड बैलेंस में जमा नहीं किए जा सकते हैं।
                            </span>
                          </li>
                          <li>
                            <u>
                              <span>ट्रांसेक्शन सीमा</span>
                            </u>
                            <span>
                              : पॉइंट ऑफ़ सेल पर PEV पीईवी एक निर्धारित समय अवधि
                              ("ट्रांसेक्शन सीमा") के दौरान कार्ड के माध्यम से
                              दी जाने वाली मौद्रिक राशि और लेनदेन की संख्या पर
                              सीमा निर्धारित कर सकता है। ट्रांसेक्शन सीमाएँ इन
                              शर्तों पर निर्धारित की जाती हैं और कार्ड पर लागू
                              होंगी। पीईवी किसी भी समय, इस संबंध में RBI के
                              दिशानिर्देशों के अधीन हर समय किसी भी कारण से
                              ट्रांसेक्शन की सीमा को संशोधित कर सकता है।
                              कार्डधारक इन परिवर्तनों को वेबसाइट पर ऑनलाइन देख
                              सकता है या ग्राहक सेवा केंद्र से भी जान सकता है।
                              कार्डधारक को लागू कानून के अनुसार अधिसूचित किया
                              जाएगा।
                            </span>
                          </li>
                          <li>
                            <u>
                              <span>रिकॉर्ड्स</span>
                            </u>
                            <span>
                              : कार्डधारक एक मर्चेंट इस्टेब्लिशमेंट (जो लागू हो
                              सकता है) पर उपभोग किए गए प्रत्येक ट्रांसेक्शन के
                              लिए जेनरेट किए गए सभी चार्ज स्लिप्स पर हस्ताक्षर
                              करेगा और उन्हें रखेगा। PEV पीईवी कार्डधारक को
                              चार्ज स्लिप या ट्रांजेक्शन स्लिप की प्रतियां
                              प्रदान करने के लिए बाध्य नहीं होगा। कार्डधारक
                              द्वारा ऐसा कोई भी अनुरोध पीईवी के विवेकाधिकार पर
                              होगा और ऐसे अनुरोध कार्डधारक द्वारा ट्रांसेक्शन की
                              तारीख से 45 (पैंतालीस) दिनों के भीतर किए जाएंगे।
                              कार्डधारक इस बात से सहमत है कि PEV पीईवी चार्ज या
                              ट्रांजेक्शन स्लिप की प्रतियां प्रदान करने के लिए
                              अतिरिक्त लागत या शुल्क लेने का हकदार होगा।
                            </span>
                          </li>
                          <li>
                            <u>
                              <span>सूचना</span>
                            </u>
                            <span>
                              : कार्डधारक कार्ड का उपयोग करके किए गए सभी
                              ट्रांसेक्शन के लिए पीईवी से एसएमएस या ईमेल अलर्ट
                              प्राप्त करने के लिए सहमत है। पीईवी से प्राप्त
                              एसएमएस या ईमेल अलर्ट समय-समय पर डेबिट और क्रेडिट
                              ट्रांसेक्शन, कार्ड पर उपलब्ध शेष बैलेंस या पीईवी
                              द्वारा निर्धारित अन्य जानकारी या विवरण को
                              निर्धारित करेगा।
                            </span>
                          </li>
                          <li>
                            <u>
                              <span>अक्सर पूछे जाने वाले सवाल</span>
                            </u>
                            <span>
                              : कार्डधारक को वेबसाइट पर PEV पीईवी द्वारा अपलोड
                              किए गए, और समय-समय पर संशोधित अक्सर पूछे जाने वाले
                              प्रश्नों का एक्सेस होगा।
                            </span>
                          </li>
                          <li>
                            <u>
                              <span>थर्ड पार्टी सेवाएँ</span>
                            </u>
                            <span>
                              : कार्डधारक इस बात से सहमत है कि PEV पीईवी , अपने
                              विवेकाधिकार पर, बाहरी सेवा प्रदाता या एजेंट की
                              सेवाओं का उपयोग कर सकता है और इस तरह की शर्तों पर
                              जो आवश्यक हो अपनी सेवाओं के संबंध में।
                            </span>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        3.कार्ड बैलेंस और शर्तें:
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ol className="list-decimal">
                          <li>
                            <u>
                              <span>कार्ड बैलेंस</span>
                            </u>
                            <span>
                              : कार्डधारक कार्ड की शेष राशि का उचित उपयोग करके
                              इन शर्तों के अनुसार उपयोग कर सकता है। कार्ड बैलेंस
                              या कार्ड खाते पर कार्डधारक को कोई ब्याज देय नहीं
                              है। कार्डधारक को सलाह दी जाती है कि लागू शुल्क
                              सहित खरीद / लाभकारी सेवाओं की अपेक्षित राशि के लिए
                              ट्रंजैक्ट करें अन्यथा ट्रांसेक्शन को मान्य नहीं
                              किया जा सकता है।
                            </span>
                          </li>
                          <li>
                            <u>
                              <span>अनियमितताएँ</span>
                            </u>
                            <span>
                              : कार्डधारक किसी भी अनियमितता या विसंगतियों के लिए
                              PEV पीईवी को सूचित करेगा, जो संसाधित होने के 7
                              (सात) दिनों के भीतर एक व्यापारी प्रतिष्ठान में
                              ट्रांसेक्शन विवरण में मौजूद है। यदि इस दौरान कोई
                              सूचना प्राप्त नहीं होती है, तो PEV पीईवी
                              ट्रांसेक्शन की शुद्धता को मान लेगा। इस संबंध में,
                              कृपया ग्राहक शिकायत निवारण नीति यहां देखें :{" "}
                            </span>
                            <a href="https://www.zokudo.com/customer-grievance-redressal">
                              <span>
                                https://www.zokudo.com/customer-grievance-redressal
                              </span>
                            </a>
                            <span>
                              {" "}
                              पीईवी और / या कार्डधारक द्वारा भुगतान के लिए
                              प्राप्त कोई भी चार्ज या ट्रांजेक्शन स्लिप या अन्य
                              भुगतान आवश्यकता (चाहे इलेक्ट्रॉनिक रूप से या
                              अन्यथा) कार्ड के खो जाने, चोरी होने या धोखे से गलत
                              तरीके से इस्तेमाल किए जाने और चोरी होने तक ऐसे
                              चार्ज का निर्णायक प्रमाण होगा।{" "}
                            </span>
                          </li>
                          <li>
                            <span>कार्ड निम्नलिखित </span>
                            <u>
                              <span>शर्तों के अधीन होंगे </span>
                            </u>
                            <span>:</span>
                          </li>
                          <ol className="list-decimal">
                            <li>
                              <span>
                                प्रत्येक कार्ड का अधिकतम मूल्य INR 10,000 / -
                                (केवल भारतीय दस हजार) से अधिक नहीं होगा।
                              </span>
                            </li>
                            <li>
                              <span>
                                एक वित्तीय वर्ष में प्रति मोबाइल नंबर जारी किए
                                गए कार्डों का अधिकतम मूल्य INR 10,00,000/- (केवल
                                दस लाख रुपये) से अधिक नहीं होना चाहिए।&nbsp;
                              </span>
                            </li>
                            <li>
                              <span>कार्ड पुनः लोड नहीं होंगे।</span>
                            </li>
                            <li>
                              <span>
                                ऐसे कार्डों के लिए कैश-आउट, नकद निकासी, या
                                धनवापसी या धन हस्तांतरण की अनुमति नहीं होगी।{" "}
                              </span>
                            </li>
                            <li>
                              <span>
                                ऐसे कार्ड्स के खरीदारों का केवाईसी विवरण पीईवी
                                द्वारा रखा जाएगा। भारत में अपने बैंक खातों में
                                डेबिट हुए ऐसे जारी हुए उपकरण कार्डधारकों के लिए
                                अलग केवाईसी विवरण को नहीं रखा जा सकता है।{" "}
                              </span>
                            </li>
                            <li>
                              <span>
                                पीईवी की बोर्ड द्वारा अनुमोदित नीति के अनुसार
                                कार्ड को फिर से अमान्य (नए कार्ड जारी करने सहित)
                                किया जा सकता है।{" "}
                              </span>
                            </li>
                          </ol>
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        4.सूचना, रद्द और अवधि :
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ol className="list-decimal">
                          <li>
                            <span>
                              आपको जारी किया गया कार्ड जारी करने की तारीख से 1
                              (एक) वर्ष की अवधि के लिए वैध है। कार्ड, कार्ड पर
                              उल्लिखित समाप्ति तिथि पर एक्सपायर होगा। पीईवी
                              कार्ड के उपयोग को तुरंत रद्द या निलंबित कर सकता
                              है:{" "}
                            </span>
                          </li>
                          <ol className="list-decimal">
                            <li>
                              <span>
                                कार्डधारक इन शर्तों के अनुसार कार्ड के खोने की
                                सूचना देता है;{" "}
                              </span>
                            </li>
                            <li>
                              <span>
                                कार्डधारक को दिवालिया घोषित किया गया या उनकी
                                मृत्यु पर;
                              </span>
                            </li>
                            <li>
                              <span>
                                भारत में किसी भी नियामक या वैधानिक प्राधिकरण या
                                किसी भी जांच एजेंसी द्वारा जारी किए गए सक्षम
                                न्यायालय के आदेश या कार्डधारक पर लगाया गया कोई
                                प्रतिबंध;{" "}
                              </span>
                            </li>
                            <li>
                              <span>
                                निरंतर 90 (नब्बे) दिनों (या पीईवी के रूप में ऐसी
                                अन्य अवधि) के लिए कार्ड का गैर-उपयोग;
                              </span>
                            </li>
                            <li>
                              <span>
                                90 (नब्बे) दिनों (या पीईवी के रूप में ऐसी अन्य
                                अवधि निर्दिष्ट हो) की अवधि के लिए कार्ड में कोई
                                बैलेंस नहीं है;
                              </span>
                            </li>
                            <li>
                              <span>
                                कार्डधारक के लिए अन्यथा लागू नियमों, या नियमों
                                और शर्तों का कोई उल्लंघन;
                              </span>
                            </li>
                            <li>
                              <span>
                                कार्ड को रद्द करने या निलंबित करने के लिए
                                कॉर्पोरेट (यदि लागू हो) से विशिष्ट अनुरोध;
                                &nbsp;
                              </span>
                            </li>
                            <li>
                              <span>
                                (लागू कानूनों, नियमों, दिशानिर्देशों या
                                परिपत्रों के तहत अवैध हो रहा कार्यक्रम; &nbsp;
                              </span>
                            </li>
                            <li>
                              <span>कार्यक्रम समाप्त किया जा रहा है; या</span>
                            </li>
                            <li>
                              <span>
                                अपने उचित नियंत्रण से परे कारणों के लिए कार्ड के
                                संबंध में भुगतान की प्रक्रिया में असमर्थता,
                                लेकिन कानून या विनियमन द्वारा लगाए गए प्रतिबंधों
                                तक सीमित नहीं है।
                              </span>
                            </li>
                          </ol>
                          <li>
                            <span>
                              PEV पीईवी कार्डधारक के पंजीकृत मोबाइल नंबर पर
                              एसएमएस के माध्यम से कार्ड की समाप्ति से 45
                              (पैंतालीस) दिन पहले कार्डधारक को सूचित करेगा।
                              कार्डधारक को कार्ड की समाप्ति से पहले कार्ड पर
                              उपलब्ध संपूर्ण क्रेडिट बैलेंस का उपयोग करने की
                              आवश्यकता होती है। यदि कार्डधारक वैधता अवधि के भीतर
                              कार्ड पर उपलब्ध क्रेडिट बैलेंस का उपयोग नहीं करता
                              है, तो कार्डधारक भारतीय रिज़र्व बैंक के मौजूदा
                              नियमों के अनुपालन में बकाया राशि के रिफंड /
                              हस्तांतरण के लिए पीईवी से संपर्क कर सकता है।
                            </span>
                          </li>
                          <li>
                            <span>
                              ग्राहक 3 साल की अवधि समाप्त होने के बाद किसी भी
                              समय शेष राशि की वापसी की मांग कर सकता है। यदि
                              ग्राहक कार्ड की समाप्ति के बाद 3 साल के भीतर
                              धनवापसी का अनुरोध नहीं करता है, तो पीईवी शेष राशि
                              को अपने प्रॉफिट एंड लोस वही में ट्रांसफर कर देगा।
                            </span>
                          </li>
                          <li>
                            <span>
                              PEV पीईवी, कार्ड की समाप्ति से 3 (तीन) साल पहले,
                              भारतीय रिजर्व बैंक के मौजूदा नियमों के अनुसार
                              कार्ड पर किसी भी और सभी बकाया शेष राशि को अपने लाभ
                              और हानि खाते में स्थानांतरित करने का हकदार होगा इस
                              संबंध में।
                            </span>
                          </li>
                          <li>
                            <span>
                              कार्डधारक किसी भी थर्ड पार्टी को इसका उपयोग करने
                              से रोकने के लिए इसकी समाप्ति या पहले रद्द होने पर
                              कार्ड (भौतिक कार्ड के मामले में) को नष्ट करने के
                              लिए सहमत होता है।
                            </span>
                          </li>
                          <li>
                            <span>
                              कार्डधारक समझता है कि इस तरह का रद्दीकरण या
                              समाप्ति नहीं होगी (भौतिक कार्ड के मामले में) जब तक
                              कार्ड को शीर्ष दाएं हाथ के कोने से काटकर समाप्त
                              नहीं किया गया हो, यह सुनिश्चित करता है कि
                              होलोग्राम और चुंबकीय पट्टी दोनों को काट दिया गया
                              है और पीईवी द्वारा नष्ट या प्राप्त किया गया है।
                              कार्डधारक इस बात से सहमत है कि कार्ड की समाप्ति से
                              पहले कार्ड पर किए गए किसी भी चार्ज के लिए
                              कार्डधारक उत्तरदायी होगा, भले ही कार्डधारक को नष्ट
                              करने का दावा करता हो (भौतिक कार्ड के मामले में)।
                            </span>
                          </li>
                          <li>
                            <span>
                              PEV पीईवी, अपने विवेकाधिकार पर, अस्थायी रूप से या
                              स्थायी रूप से, या तो कार्ड पर विशेषाधिकारों को
                              वापस लेने और / या किसी भी कारण से कोई भी नोटिस दिए
                              बिना कार्ड को समाप्त करने का अधिकार सुरक्षित रखता
                              है। अस्थायी निकासी के मामले में, कार्ड से जुड़े
                              विशेषाधिकार पीईवी द्वारा अपने विवेकाधिकार पर बहाल
                              किए जाएंगे। स्थायी वापसी के मामले में, PEV पीईवी
                              को कार्ड को स्थायी रूप से रद्द करने का अधिकार है।
                              हालांकि, यह स्पष्ट है कि निकासी (अस्थायी या
                              स्थायी) कार्ड से जुड़े सभी लाभों, विशेषाधिकारों और
                              सेवाओं की स्वचालित वापसी का गठन करेगा। कार्डधारक
                              इस बात से सहमत है कि कार्ड के अस्थायी या स्थायी
                              रूप से वापस लेने की स्थिति में, कार्डधारक को ऐसे
                              आहरण से पहले कार्ड पर किए गए सभी चार्ज के लिए पूरी
                              तरह से उत्तरदायी होना होगा, साथ ही अन्य सभी लागू
                              चार्ज के साथ, जब तक कि पीईवी द्वारा निर्दिष्ट नहीं
                              किया जाता।
                            </span>
                          </li>
                          <li>
                            <span>
                              यदि PEV पीईवी अस्थायी रूप से या स्थायी रूप से,
                              विशेषाधिकार को हटा देता है या कार्ड को समाप्त कर
                              देता है, तो PEV पीईवी कार्डधारक को तुरंत सूचित
                              करने के लिए, सर्वोत्तम प्रयास के आधार पर, उसका
                              अधिकार सुरक्षित रखता है। पीईवी को ऐसी अधिसूचना के
                              लिए किसी भी देरी या अंतराल के लिए उत्तरदायी या
                              जिम्मेदार नहीं ठहराया जाएगा।
                            </span>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        5.शुल्क:
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ol className="list-decimal">
                          <li>
                            <span>शुल्क में शामिल होंगे:&nbsp;</span>
                          </li>
                          <ol className="list-decimal">
                            <li>
                              <span>
                                कार्ड के संबंध में PEV पीईवी द्वारा कोई शुल्क,
                                प्रतिस्थापन, नवीकरण, हैंडलिंग, सहित अन्य शुल्क,
                                यदि हो। ये शुल्क गैर-वापसी योग्य,
                                गैर-हस्तांतरणीय हैं।
                              </span>
                            </li>
                            <li>
                              <span>
                                विशिष्ट प्रकार के ट्रांसैक्शन पर सेवा शुल्क। इस
                                तरह के शुल्क की गणना का तरीका पीईवी द्वारा
                                समय-समय पर अपनी वेबसाइट पर अधिसूचित किया जाएगा।
                              </span>
                            </li>
                          </ol>

                          <li>
                            <span>
                              PEV पीईवी कार्ड बैलेंस से देय शुल्क तालिका में
                              (थर्ड पार्टी को देय राशि या शुल्क तालिका में
                              निर्धारित शुल्क सहित) सभी शुल्क काटेगा।
                            </span>
                          </li>
                          <li>
                            <span>
                              यह स्पष्ट किया गया है कि कुछ व्यापारी प्रतिष्ठानों
                              के मामले में, कुछ अतिरिक्त सेवा या अन्य सुविधा का
                              लाभ उठाने के लिए एक शुल्क शामिल हो सकता है।
                            </span>
                          </li>
                          <li>
                            <span>
                              किसी भी ट्रांसैक्शन के संबंध में व्यापारी
                              प्रतिष्ठान द्वारा लगाया गया कोई भी शुल्क या लागत,
                              कार्डधारक से सीधे व्यापारी प्रतिष्ठान द्वारा तय
                              किया जाएगा। मर्चेंट प्रतिष्ठान की ओर से किसी भी
                              कार्य या चूक के लिए या ट्रांसैक्शन के संबंध में
                              उनके द्वारा लगाए गए शुल्क या लागत के लिए पीईवी
                              उत्तरदायी या प्रत्यक्ष या अप्रत्यक्ष रूप से
                              उत्तरदायी नहीं होगा।
                            </span>
                          </li>
                          <li>
                            <span>
                              सभी शुल्क, प्रकट चूक की अनुपस्थिति में, कार्डधारक
                              पर अंतिम और बाध्यकारी और निर्णायक होंगे।
                            </span>
                          </li>
                          <li>
                            <span>
                              सभी वैधानिक कर, माल और सेवा कर, अन्य सभी आवेग,
                              शुल्क (स्टांप शुल्क और प्रासंगिक पंजीकरण शुल्क
                              कार्ड के संबंध में, यदि हो) और कर (किसी भी विवरण
                              का) जो भी समय- समय पर लगाया जा सकता है सरकार या
                              अन्य प्राधिकारी द्वारा कार्ड के संबंध में,
                              कार्डधारक को चुकाना होगा।
                            </span>
                          </li>

                          <li>
                            <strong>
                              <strong>प्रभार की अनुसूची:</strong>
                            </strong>
                          </li>

                          <TermsTable1Hindi />

                          <li>
                            <span>
                              इसमें कुछ भी, हालांकि, पीईवी को कार्ड के माध्यम से
                              किए गए किसी भी ट्रांसेक्शन से लागू कानूनों के तहत
                              आवश्यक रूप से स्रोत पर कर कटौती करने से रोक नहीं
                              सकता।
                            </span>
                          </li>
                          <li>
                            <span>
                              यह अनुसूची अन्यथा वेबसाइट पर भी प्रदर्शित की जा
                              सकती है, और एकमात्र पीईवी के विवेक पर समय-समय पर
                              संशोधित की जा सकती है।
                            </span>
                          </li>
                          <li></li>
                          <TermsTable2Hindi />
                        </ol>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSeven"
                        aria-expanded="false"
                        aria-controls="collapseSeven"
                      >
                        7.PEV पीईवी का दायित्व :
                      </button>
                    </h2>
                    <div
                      id="collapseSeven"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ol className="list-decimal">
                          <li>
                            <span>
                              पीईवी की देयता प्रासंगिक समय पर कार्डधारक के कार्ड
                              में पड़ी राशि की सीमा तक सीमित है।
                            </span>
                          </li>
                          <li>
                            <span>
                              कार्डधारक कार्ड में सभी व्यवहारों के संबंध में हर
                              समय अच्छी मंशा में कार्य करेगा। कार्डधारक शर्तों
                              के उल्लंघन में कार्ड के किसी भी अवैध या गलत उपयोग
                              के लिए पूरी जिम्मेदारी स्वीकार करता है। कार्डधारक
                              पीईवी को किसी भी और सभी नुकसान, क्षति, दावे, दंड,
                              लागत, शुल्क या व्यय (कानूनी परामर्श शुल्क सहित) की
                              क्षतिपूर्ति करने के लिए सहमत होता है, जो पीईवी को
                              प्रत्यक्ष और अप्रत्यक्ष रूप से, या इसके
                              परिणामस्वरूप भुगतना पड़ सकता है कार्डधारक के किसी
                              भी उल्लंघन से, या नियम और शर्तों के उल्लंघन से जो
                              लागू होती हैं।
                            </span>
                          </li>
                          <li>
                            <span>
                              कार्डधारक बिना किसी शर्त के पीईवी को नुकसान और
                              क्षति के लिए इंडेम्निफ़ाय करेगा अपर्याप्त कार्ड
                              बैलेंस के परिणामस्वरूप भुगतान निर्देशों को
                              अस्वीकृत करने की वजह से। कार्डधारक इस बात से सहमत
                              है कि PEV पीईवी , सीधे कार्ड बैलेंस से PEV पीईवी
                              को होने वाले ऐसे नुकसान या क्षति शुल्क काटने का
                              हकदार होगा।{" "}
                            </span>
                          </li>
                          <li>
                            <span>
                              कार्डधारक कार्ड के अनाधिकृत उपयोग के संबंध में या
                              उससे उत्पन्न पीईवी की सभी जिम्मेदारी और दायित्व का
                              खुलासा करता है और पीईवी द्वारा प्रत्यक्ष या
                              अप्रत्यक्ष रूप से प्रत्यक्ष या अप्रत्यक्ष, किसी भी
                              और सभी नुकसान के लिए पीईवी को इंडेम्निफ़ाय करेगा,
                              इस तरह के दुरुपयोग के परिणाम से।
                            </span>
                          </li>
                          <li>
                            <span>
                              पूर्वगामी के पक्षपात के बिना, पीईवी की कार्डधारक
                              या किसी थर्ड पार्टी के लिए कोई दायित्व या
                              जिम्मेदारी नहीं होगी, किसी भी नुकसान या क्षति के
                              संबंध में, प्रत्यक्ष या अप्रत्यक्ष रूप से: &nbsp;
                            </span>
                          </li>

                          <ol className="list-decimal">
                            <li>
                              <span>
                                व्यापारी प्रतिष्ठान द्वारा प्रदत्त या प्रदत्त
                                किसी भी सामान या सेवाओं में कोई दोष;
                              </span>
                            </li>
                            <li>
                              <span>
                                व्यापारी प्रतिष्ठान द्वारा प्रदत्त या प्रदत्त
                                किसी भी वस्तु या सेवाओं की गुणवत्ता, मूल्य
                                वारंटी, वितरण में देरी, गैर-वितरण, गैर-रसीद के
                                बारे में कोई विवाद;
                              </span>
                            </li>
                            <li>
                              <span>
                                कार्ड को हॉनर या स्वीकार करने के लिए किसी
                                व्यक्ति या व्यापारी का इनकार;
                              </span>
                            </li>
                            <li>
                              <span>
                                किसी भी कारण के लिए वांछित तरीके से कार्ड का
                                गैर-कामकाज;
                              </span>
                            </li>
                            <li>
                              <span>किसी भी कंप्यूटर टर्मिनल की खराबी;</span>
                            </li>
                            <li>
                              <span>कोई भी फ़ोर्स माजीउर घटना;</span>
                            </li>
                            <li>
                              <span>
                                कार्ड को किसी थर्ड पार्टी को हस्तांतरित करना;
                              </span>
                            </li>
                            <li>
                              <span>इन शर्तों के अनुसार कार्ड की समाप्ति;</span>
                            </li>
                            <li>
                              <span>
                                किसी कार्ड को आवंटित राशि में कोई विसंगति, जहां
                                कार्डधारक से इस संबंध में निर्देश प्राप्त होने
                                पर ऐसी राशि आवंटित की गई है;
                              </span>
                            </li>
                            <li>
                              <span>
                                पीईवी द्वारा कार्ड के प्रत्यावर्तन के कारण कथित
                                तौर पर कार्डधारक को कोई नुकसान या क्षति हुई है;
                              </span>
                            </li>
                            <li>
                              <span>
                                कार्डधारक के अनुरोध पर कार्ड पर किया गया कोई भी
                                उलटफेर।
                              </span>
                            </li>
                          </ol>
                        </ol>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseEight"
                        aria-expanded="false"
                        aria-controls="collapseEight"
                      >
                        8.पंजीकरण डेटा, डेटा संरक्षण और खाता सुरक्षा :
                      </button>
                    </h2>
                    <div
                      id="collapseEight"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ol className="list-decimal">
                          <li>
                            <span>
                              कार्डधारक सहमत है कि (i)उसके बारे में सटीक,
                              वर्तमान और पूर्ण जानकारी प्रदान करें जैसा कि
                              वेबसाइट या एप्लिकेशन फॉर्म ("पंजीकरण डेटा") पर
                              किसी भी पंजीकरण फॉर्म द्वारा इंगित किया जा सकता
                              है; (ii) पिन की सुरक्षा बनाए रखना; (iii) पंजीकरण
                              डेटा और पीईवी को प्रदान की जाने वाली किसी भी अन्य
                              जानकारी को बनाए रखना और तुरंत अपडेट करना; और (डी)
                              उसके कार्ड के उपयोग के लिए पूरी तरह से जिम्मेदार
                              है।&nbsp;
                            </span>
                          </li>
                          <li>
                            <span>
                              PEV पीईवी कार्डधारक के निर्देशों के अनुसार,
                              कार्डधारक के कार्ड या उसके उपयोग से संबंधित किसी
                              भी प्रश्न से निपटने के लिए, कार्डधारक के निर्देशों
                              के अनुसार एक कार्ड प्रदान करने के लिए कार्डधारक के
                              व्यक्तिगत डेटा (पंजीकरण डेटा सहित) को संसाधित
                              करेगा नियामक रिपोर्टिंग और धोखाधड़ी की रोकथाम के
                              उद्देश्य से।
                            </span>
                          </li>
                          <li>
                            <span>
                              पीईवी गोपनीय रूप से और केवल लागू डेटा संरक्षण और
                              बैंक गोपनीयता कानूनों के तहत अनुमत सीमा तक,
                              कार्डधारक के व्यक्तिगत डेटा (पंजीकरण डेटा सहित)
                              अन्य कंपनियों और थर्ड पार्टी को देगा, जिसमें आवेदन
                              प्रसंस्करण, धोखाधड़ी निगरानी, ग्राहक सेवा, कार्ड
                              उत्पादन और प्रौद्योगिकी प्रसंस्करण सेवाएं और उनके
                              प्रत्येक एजेंट और उपठेकेदार शामिल है।
                            </span>
                          </li>
                          <li>
                            <span>
                              PEV पीईवी कार्डधारक के निजी डेटा (पंजीकरण डेटा
                              सहित) को: (i) कार्डधारक, (ii) धोखाधड़ी की रोकथाम
                              करने वाली एजेंसियों को दे सकता है यदि झूठी या गलत
                              जानकारी दी गई है और धोखाधड़ी का संदेह है, और (iii)
                              वैधानिक / नियामक अधिकारियों, कानून प्रवर्तन या
                              धोखाधड़ी निरोधक एजेंसियां जहां हमें लागू कानून या
                              अदालत के आदेश से ऐसा करने की आवश्यकता होती है। PEV
                              पीईवी उच्च गुणवत्ता सेवा और सुरक्षा, डेटा संग्रह
                              और प्रशिक्षण उद्देश्यों के लिए या लागू कानून
                              द्वारा आवश्यक रूप में बनाए रखने में मदद करने के
                              लिए टेलीफोन कॉल रिकॉर्ड और / या निगरानी कर सकता
                              है। सभी रिकॉर्डिंग PEV पीईवी की हैं। कार्डधारक
                              पीईवी द्वारा अपने व्यक्तिगत डेटा (पंजीकरण डेटा
                              सहित) के प्रसंस्करण (स्थानांतरण सहित), और ऊपर बताए
                              गए उद्देश्यों के लिए ऊपर बताए गए अन्य
                              प्राप्तकर्ताओं को सहमति देता है।
                            </span>
                          </li>
                          <li>
                            <span>
                              कार्डधारक पीईवी और / या उसके सहयोगियों को कार्ड
                              (पंजीकरण डेटा सहित) के संबंध में सभी डेटा, रिकॉर्ड
                              और इतिहास को बनाए रखने के लिए सहमति देता है और
                              उन्हें डेटा से संबंधित सभी अधिकार प्रदान करता है,
                              जिसमें उनकी प्रति संग्रहीत करके ऐसे डेटा का उपयोग
                              करना शामिल है। किसी थर्ड पार्टी के साथ ऐसे डेटा को
                              डी-आइडेंटिफायड रूप में साझा करने का अधिकार। पीईवी,
                              इसके सहयोगी या किसी भी थर्ड पार्टी जिनके साथ इस
                              तरह का डेटा साझा किया जाता है, सांख्यिकीय,
                              पूर्वानुमेय बनाने के लिए डेटा के एकत्रीकरण के
                              माध्यम से वाणिज्यिक और अनुसंधान उद्देश्यों सहित
                              किसी भी उद्देश्य के लिए डेटा की पहचान की प्रक्रिया
                              का उपयोग कर सकते हैं, नैदानिक या अन्य डेटा मॉडल या
                              कोई अन्य तरीके से।{" "}
                            </span>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseNine"
                        aria-expanded="false"
                        aria-controls="collapseNine"
                      >
                        9.संचार नीति :
                      </button>
                    </h2>
                    <div
                      id="collapseNine"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ol className="list-decimal">
                          <li>
                            <span>
                              कार्डधारक स्वीकार करता है कि पीईवी कार्डधारक
                              द्वारा प्रदान किए गए मोबाइल फोन नंबर पर अपेक्षित
                              अलर्ट भेज सकता है, सेवाओं का लाभ उठाने के लिए या
                              कार्डधारक द्वारा सूचित किए गए किसी भी नंबर पर
                              पंजीकरण किया जा सकता है। कार्डधारक यह स्वीकार करता
                              है कि अलर्ट तभी प्राप्त होंगे जब मोबाइल फोन एसएमएस
                              के माध्यम से अलर्ट प्राप्त करने के लिए "चालू" मोड
                              में हो। यदि मोबाइल फोन "बंद" मोड में है, तो
                              कार्डधारक ऐसी अवधि के दौरान भेजे गए किसी भी अलर्ट
                              को देरी के बाद प्राप्त नहीं कर सकता है।
                            </span>
                          </li>
                          <li>
                            <span>
                              कार्डधारक एसएमएस, ईमेल और फोन कॉल के माध्यम से
                              उपयोग के इतिहास के आधार पर पीईवी को उनके हित में
                              जानकारी प्राप्त कराने के लिए सहमत है। इसमें ऑफ़र,
                              छूट और सामान्य जानकारी शामिल है। यदि कार्डधारक ऐसी
                              जानकारी नहीं चाहता है, तो उन्हें स्पष्ट रूप से
                              अनुरोध करना चाहिए।&nbsp;
                            </span>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTen"
                        aria-expanded="false"
                        aria-controls="collapseTen"
                      >
                        10.कैशबैक:
                      </button>
                    </h2>
                    <div
                      id="collapseTen"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ul className="list-decimal">
                          <li>
                            <strong>
                              <strong>कैशबैक के लिए नियम और शर्तें:</strong>
                            </strong>
                          </li>
                          <ol className="list-decimal">
                            <li>
                              <span>
                                उन व्यापारी प्रतिष्ठानों से कैशबैक प्रदान किया
                                जाएगा जो आवेदन / वेबसाइट के प्रस्तावों / ब्रांड
                                पृष्ठों पर दिखाए जाते हैं या इसके पोर्टल / स्टोर
                                पर विशिष्ट व्यापारी प्रतिष्ठान द्वारा विज्ञापित
                                किए जाते हैं।{" "}
                              </span>
                            </li>
                            <li>
                              <span>
                                कैशबैक मूल्य ऑफ़र के एक हिस्से के रूप में दिखाया
                                गया है और यह ट्रांसेक्शन राशि (उदाहरण 5% (पांच
                                प्रतिशत)) या फ्लैट मूल्य (उदाहरण INR 150 (भारतीय
                                रुपए एक सौ पचास)) के आधार पर प्रतिशत मूल्य हो
                                सकता है।
                              </span>
                            </li>
                            <li>
                              <span>
                                कैशबैक तत्काल कैशबैक है जिसका अर्थ है कि जैसे ही
                                प्राधिकरण सफल होता है उसे तुरंत कार्ड में जमा कर
                                दिया जाएगा।
                              </span>
                            </li>
                            <li>
                              <span>
                                कैशबैक कार्ड बैलेंस / पॉकेट के लिए है जिसे
                                सामान्य बैलेंस के रूप में पुन: उपयोग किया जा
                                सकता है।
                              </span>
                            </li>
                            <li>
                              <span>
                                खरीदारी करने के लिए उपयोग किए जाने वाले कार्ड
                                में कैशबैक प्रदान किया जाएगा।
                              </span>
                            </li>
                            <li>
                              <span>
                                कैशबैक उसी पॉकेट में प्रदान किया जाएगा, जिसका
                                उपयोग खरीदारी करने के लिए किया जाता है। - उदाहरण
                                यात्रा पॉकेट।
                              </span>
                            </li>
                            <li>
                              <span>
                                कैशबैक की गणना ट्रांसेक्शन राशि पर की जाती है, न
                                कि बिलिंग राशि (जिसमें कर, जीएसटी, आदि शामिल
                                हैं) पर।
                              </span>
                            </li>
                            <li>
                              <span>
                                धनवापसी या उत्क्रमण के मामले में पूर्ण कैशबैक
                                रिवर्स हो जाएगा, भले ही रिफंड आंशिक मूल्य के लिए
                                हो।
                              </span>
                            </li>
                            <li>
                              <span>
                                धनवापसी या रिवर्सल के मामले में कैशबैक वसूलने
                                में किसी भी तरह की कमी के कारण कार्ड / पॉकेट के
                                बकाया राशि में कटौती की जाएगी।
                              </span>
                            </li>
                            <li>
                              <span>
                                यह स्पष्ट किया गया है कि कैशबैक का प्रावधान हर
                                समय पीईवी के एकमात्र और विवेक के पूर्ण अधीन
                                होगा। पीईवी के खिलाफ इस तरह के कैशबैक के संबंध
                                में कार्डधारक के पास कोई दावा नहीं होगा।
                              </span>
                            </li>
                          </ol>
                          <li>
                            <strong>
                              <strong>
                                यदि सीमा पार कर दी जाती है तो कैशबैक कैसे प्रदान
                                किया जाएगा, इस पर विवरण:
                              </strong>
                            </strong>
                          </li>

                          <ol>
                            <li>
                              <strong>पॉकेट्स की सीमा:</strong>
                            </li>

                            <ol className="list-decimal">
                              <ol className="list-decimal">
                                <li>
                                  <span>
                                    कैशबैक उसी पॉकेट को प्रदान किया जाता है
                                    जिसका उपयोग ट्रांसेक्शन करने के लिए किया गया
                                    है।
                                  </span>
                                </li>
                                <li>
                                  <span>
                                    भले ही (पॉकेट बैलेंस + कैशबैक राशि) &gt;
                                    अधिकतम पॉकेट सीमा। पीईवी कैशबैक प्रदान
                                    करेगा। साथ ही, भविष्य के उपयोग के लिए पॉकेट
                                    बैलेंस को अपडेट किया जाएगा।
                                  </span>
                                </li>
                              </ol>
                            </ol>
                          </ol>
                          <p>
                            <span>
                              इन शर्तों के उद्देश्य के लिए, " पॉकेट" का अर्थ
                              समान व्यापारी प्रतिष्ठानों के समूह से होगा, जो
                              व्यापारी श्रेणी कोड ("एमसीसी") के आधार पर समूहीकृत
                              होते हैं। जेब में एमसीसी के साथ व्यापारी
                              प्रतिष्ठानों से ट्रांसेक्शन विशिष्ट पॉकेट बैलेंस
                              तक सीमित हैं। एक कार्ड में या तो एक डिफ़ॉल्ट कार्ड
                              बैलेंस होगा (जिसे एमसीसी के लिए इस्तेमाल किया जा
                              सकता है) या पॉकेट से बैलेंस।&nbsp;
                            </span>
                          </p>
                          <p>
                            <span>उदाहरण के लिए :</span>
                          </p>
                          <p>
                            <span>
                              एक कॉरपोरेट चाहता है कि उसका उपयोगकर्ता कुछ
                              निश्चित MCC पर कार्ड का उपयोग केवल भोजन, यात्रा
                              आदि के लिए ही कर सकता है, फिर वे इन पॉकेट्स को
                              परिभाषित करके कार्ड पर शेष राशि अपलोड कर सकते हैं।
                              &nbsp;
                            </span>
                          </p>
                          <ol className="list-decimal">
                            <li>
                              <span>पॉकेट ए - खाद्य - 2000/ -</span>
                            </li>
                            <li>
                              <span>पॉकेट बी - यात्रा - 6000/ -</span>
                            </li>
                            <li>
                              <span>पॉकेट सी - शॉपिंग - 2000/ -</span>
                            </li>
                          </ol>
                        </ul>
                        <p>
                          <span>
                            अधिक जानकारी के लिए, कृपया ग्राहक सेवा केंद्र से
                            संपर्क साधें।
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseEleven"
                        aria-expanded="false"
                        aria-controls="collapseEleven"
                      >
                        11.रिडम्शन:
                      </button>
                    </h2>
                    <div
                      id="collapseEleven"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p>
                          <span>
                            कार्ड को किसी भी वेबसाइट या मर्चेंट इंस्टॉलेशन पर
                            रिडीम किया जा सकता है जो रुपे इंस्ट्रूमेंट्स और
                            भुगतान के माध्यम स्वीकार करते हैं। हालाँकि, कुछ
                            एमसीसी हैं, जैसा कि नीचे उल्लेख किया गया है, जिसमें
                            कार्ड को रिडीम नहीं किया जा सकता है।
                          </span>
                        </p>
                        <TermsTable3Hindi />
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOneTwo"
                        aria-expanded="false"
                        aria-controls="collapseOneTwo"
                      >
                        12.दुसरे:
                      </button>
                    </h2>
                    <div
                      id="collapseOneTwo"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ol className="list-decimal">
                          <li>
                            <u>
                              <span className="font-semibold">संशोधन</span>
                            </u>
                            <span>
                              : पीईवी, ग्राहक को नोटिस के साथ (या वेबसाइट पर या
                              किसी अन्य तरीके से होस्ट करके) किसी भी समय और लागू
                              कानून के अधीन, किसी भी प्रावधान को बदल सकता है या
                              हटा सकता है, जोड़ सकता है या बदल सकता है (बढ़ाना या
                              घटाना शामिल)) किसी भी शुल्क, लाभ, विशेषाधिकारों,
                              सुविधाओं, दरों, ऑफ़र, या अन्यथा इन शर्तों में कोई
                              भी परिवर्तन कर सकता है। कार्डधारक ज़िम्मेदार होगा,
                              और वेबसाइट पर उपलब्ध होने के 24 घंटे (चौबीस) के
                              बाद परिवर्तन को देखा और स्वीकार किया जाएगा। PEV
                              पीईवी किसी भी समय किसी भी नियम में संशोधन करने का
                              अधिकार सुरक्षित रखता है।
                            </span>
                          </li>
                          <li>
                            <u>
                              <span className="font-semibold">
                                गवर्निंग लॉ{" "}
                              </span>
                            </u>
                            <span>
                              : ये नियम और यहां उत्पन्न सभी मामले भारतीय कानूनों
                              द्वारा शासित होंगे और कार्डधारक और पीईवी दोनों
                              मुंबई की अदालतों के विशेष क्षेत्राधिकार के अधीन
                              होंगे।
                            </span>
                          </li>
                          <li>
                            <u>
                              <span className="font-semibold">
                                विवाद समाधान
                              </span>
                            </u>
                            <span>
                              : पीईवी और कार्डधारक के बीच विवाद, अंतर या दावे की
                              स्थिति में उपरोक्त कुछ भी पूर्वाग्रह के बिना, इन
                              शर्तों या उसके निर्माण या व्याख्या से उत्पन्न या
                              अन्यथा संबंधित, कार्डधारक और पीईवी पहले एक पक्ष से
                              दूसरे पक्ष को प्राप्त नोटिस के 15 (पंद्रह) दिनों
                              के भीतर आपसी चर्चा से इस तरह के अंतर, विवाद, दावे
                              या प्रश्न को निपटाने का प्रयास करेगा, जो विफल हो
                              तो अंत में मध्यस्थता के प्रावधानों के अनुसार
                              सुलझाया जाएगा। और सुलह अधिनियम, 1996 में समय-समय
                              पर संशोधन या फिर से अधिनियमित किया जा सकता है।
                              विवाद एक मध्यस्थ न्यायाधिकरण द्वारा हल किया जाएगा
                              जो पीईवी और कार्डधारक दोनों के आपसी समझौते द्वारा
                              चयनित एकमात्र मध्यस्थ से बना होगा। मध्यस्थता
                              कार्यवाही अंग्रेजी भाषा में आयोजित की जाएगी।
                              मध्यस्थता की कार्यवाही को मुंबई में किया जाएगा, और
                              न्यायाधिकरण के अवार्ड का तर्क दिया जाना चाहिए जो
                              अंतिम होगा।
                            </span>
                          </li>
                          <li>
                            <u>
                              <span className="font-semibold">
                                वस्तुओं और सेवाओं की गुणवत्ता{" "}
                              </span>
                            </u>
                            <span>
                              : किसी भी व्यापारी द्वारा खरीदे गए सामान या सेवाओं
                              के बारे में किसी भी व्यापारी प्रतिष्ठान के साथ कोई
                              विवाद या शिकायत, सीधे व्यापारी द्वारा व्यापारी
                              प्रतिष्ठान के साथ हल की जानी चाहिए।
                            </span>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOneThree"
                        aria-expanded="false"
                        aria-controls="collapseOneThree"
                      >
                        13.हेल्प लाइन :
                      </button>
                    </h2>
                    <div
                      id="collapseOneThree"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p>
                          <span>
                            हमें हमारे कस्टमर केयर सेंटर नंबर 8655000444 पर कॉल
                            करें या हमें{" "}
                          </span>
                          <a
                            href="mailto:care@zokudo.com"
                            className="no-underline"
                          >
                            <span className="text-green-500 no-underline">
                              care@zokudo.com
                            </span>
                          </a>
                          <span>
                            {" "}
                            पर ई-मेल करें या 42, नवकेतन इंडस्ट्रियल एस्टेट,
                            महाकाली केव्स रोड, अंधेरी (पूर्व), मुंबई - 400 093
                            पर हमें पत्र लिखें।
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOneFour"
                        aria-expanded="false"
                        aria-controls="collapseOneFour"
                      >
                        14.नोटिस:
                      </button>
                    </h2>
                    <div
                      id="collapseOneFour"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ol className="list-decimal">
                          <li>
                            <span>
                              पीईवी द्वारा दिए जाने वाले किसी भी नोटिस को
                              कार्डधारक द्वारा नोटिस की तारीख से 7 (सात) दिनों
                              के भीतर (मेलिंग पते पर भौतिक रूप से भेजे जाने पर)
                              या ईमेल के माध्यम से भेजे जाने पर तुरंत माना जाएगा
                              जब PEV पीईवी के साथ उपलब्ध या उपलब्ध कराए गए
                              पंजीकृत ईमेल से भेजा जाएगा।
                            </span>
                          </li>
                          <li>
                            <span>
                              कार्डधारक द्वारा पीईवी को दी जाने वाली कोई भी
                              सूचना यहाँ दी जाएगी, जो पीईवी द्वारा अपने कॉरपोरेट
                              ऑफिस के पते पर पीईवी द्वारा इस नोटिस की प्राप्ति
                              की सूचना पर प्राप्त की जाएगी -42, नवकेतन
                              इंडस्ट्रियल एस्टेट, महाकवि गुफाओं रोड, अंधेरी
                              (पूर्व), मुंबई 400093। कार्डधारक के यहाँ से कोई भी
                              नोटिस या संचार पीईवी पर बाध्यकारी नहीं होगा जब तक
                              कि लिखित में वही न हो और पीईवी द्वारा या पंजीकृत
                              डाक, पावती कार्ड द्वारा सर्व की गई हो और स्वीकार
                              नहीं की गई हो।
                            </span>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="v-pills-profile"
                role="tabpanel"
                aria-labelledby="v-pills-profile-tab"
                tabindex="0"
              >
                <GPCardTermsHindi />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default TermsHindi;
