import React from 'react'
import cp18 from "../../assets/Logo/cp18.png";
import cp19 from "../../assets/Logo/cp19.png";
import cp20 from "../../assets/Logo/cp20.png";
import cp21 from "../../assets/Logo/cp21.png";
import cp22 from "../../assets/Logo/cp22.png";
import cp23 from "../../assets/Logo/cp23.png";
import cp24 from "../../assets/Logo/cp24.png";
import cp25 from "../../assets/Logo/cp25.png";
import cp26 from "../../assets/Logo/cp26.png";
import cp27 from "../../assets/Logo/cp27.png";
import cp28 from "../../assets/Logo/cp28.png";
import cp29 from "../../assets/Logo/cp29.png";
import cp30 from "../../assets/Logo/cp30.png";
import cp31 from "../../assets/Logo/cp31.png";
import cp32 from "../../assets/Logo/cp32.png";
import cp33 from "../../assets/Logo/cp33.png";
import cp34 from "../../assets/Logo/cp34.png";
import cp35 from "../../assets/Logo/cp35.png";
import cp36 from "../../assets/Logo/cp36.png";
import cp37 from "../../assets/Logo/cp37.png";



export default function () {
    const images = [cp18, cp19, cp20,
        cp21, cp22, cp23, cp24, cp25, cp26, cp27, cp28, cp29, cp30,
        cp31, cp32, cp33, cp34, cp35, cp36, cp37
      ];
  return (
    <>
   {
     images.map((item, i) => {
        return (
            <img className={`shadow-md m-3 p-2 object-contain w-[100px] h-[50px] duration-300 hover:scale-[1.5] bg-white`} src={item} key={i}/>
        )
    })
   }
         
        </>
  )
}
