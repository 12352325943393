import React from 'react'
export default function Marquee({children}) {
  return (
    <div class="marquee-container">
  <div class="marquee">
               {children}
    <div class="marquee-clone">
    {children}
    </div>
  </div>
</div>
  )
}
