import React, { useEffect, useState } from "react";
import JobsList from "./JobsList";
import AddNewJobForm from "./AddNewJobForm";
import "../style/career.css";
import { checkSession } from "../helper";
import { useNavigate } from "react-router-dom";
export default function JobPostings() {
  const [addNewJob, setAddNewJob] = useState(false);
  const [isChekingSession, setIsChekingSession] = useState(true);
  const navigate = useNavigate();
  const postJob = () => {
    setAddNewJob(!addNewJob);
  };


  useEffect(() => {
    if (!checkSession()) {
      navigate("/");
    }
    setIsChekingSession(false);
  }, []);
  if (isChekingSession) {
    return <div>Loading...</div>;
  }
  return (
    <div className="mt-[70px] md:mt-[150px] rounded-lg mb-20 w-full mx-4 md:w-4/5 lg:w-3/5 mx-auto border border-gray-200 overflow-hidden">
      <div className="bg-[#F7F7F7] py-3 text-center">
        <p className="font-medium text-md mb-0">Job Postings</p>
      </div>
      <div className="p-6">
        {addNewJob ? (
          <AddNewJobForm close={() => setAddNewJob(false)} />
        ) : (
          <JobsList postJob={postJob} />
        )}
      </div>
    </div>
  );
}
