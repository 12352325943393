import React from "react";
import { Link,useLocation } from "react-router-dom";
export default function DropdownMenu({ data }) {
  const location = useLocation();
  const { pathname } = location;
  return (
    // <ul className="services-menu absolute z-[100] left-0 bg-white text-left w-[220px] border  rounded px-2 py-3 top-[140%] border-neutral-800">
    //   <div className="hidden lg:blockabsolute w-4 h-4 rotate-45 bg-white left-3 top-[-5px]"></div>
    //   {data.map((item, index) => {
    //     return (
    //       <li className="hover:bg-gray-100 rounded p-2">
    //         <Link className="nav-link text-sm text-black font-medium" to={item.linkTo}>
    //           <span className={`${item.linkTo === pathname ? 'fw-semibold' : 'fw-light'}`}>{item.text}</span>
    //         </Link>
    //       </li>
    //     );
    //   })}
    // </ul>


    <ul className="services-menu absolute z-[100] left-0 bg-white text-left w-[220px] border rounded px-2 py-3 top-[140%] border-neutral-800">
    <div className="hidden lg:blockabsolute w-4 h-4 rotate-45 bg-white left-3 top-[-5px]"></div>
        {data.map((item, index) => {
          return (
            <li key={index} className="hover:bg-gray-100 rounded p-2">
              {item.isExternal ? (
                <a
                  className="nav-link text-sm text-black font-medium"
                  href="http://customer.zokudo.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="fw-light">{item.text}</span>
                </a>
              ) : (
                <Link
                  className="nav-link text-sm text-black font-medium"
                  to={item.linkTo}
                >
                  <span className={`${item.linkTo === pathname ? 'fw-semibold' : 'fw-light'}`}>
                    {item.text}
                  </span>
                </Link>
              )}
            </li>
          );
        })}
    </ul>


  );
}
