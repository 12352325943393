export default function TermsTable3() {
    const fees = [
        {
            "MCC Codes": "820",
            "Category": "Fertilizer Dealers"
          },
          {
            "MCC Codes": "821",
            "Category": "Pesticides/Insecticides"
          },
          {
            "MCC Codes": "822",
            "Category": "Seeds"
          },
          {
            "MCC Codes": "823",
            "Category": "Farm Equipment"
          },
          {
            "MCC Codes": "825",
            "Category": "Other Agri Products"
          },
          {
            "MCC Codes": "3020",
            "Category": "Air India"
          },
          {
            "MCC Codes": "4112",
            "Category": "Passenger Railways"
          },
          {
            "MCC Codes": "4814",
            "Category": "Telecom"
          },
          {
            "MCC Codes": "4829",
            "Category": "Money Transfer"
          },
          {
            "MCC Codes": "4900",
            "Category": "Utilities (Public & Private)"
          },
          {
            "MCC Codes": "5411",
            "Category": "Supermarket"
          },
          {
            "MCC Codes": "5541",
            "Category": "Service stations"
          },
          {
            "MCC Codes": "5542",
            "Category": "Automated Fuel Dispensers"
          },
          {
            "MCC Codes": "5932",
            "Category": "Antique Shops Sales, Repairs, and Restoration Services"
          },
          {
            "MCC Codes": "5933",
            "Category": "Pawn Shops"
          },
          {
            "MCC Codes": "5937",
            "Category": "Antique Reproductions"
          },
          {
            "MCC Codes": "5960",
            "Category": "Direct marketing; Insurance sales"
          },
          {
            "MCC Codes": "5970",
            "Category": "Artists Supply and Craft Shops"
          },
          {
            "MCC Codes": "5971",
            "Category": "Art Dealers and Galleries"
          },
          {
            "MCC Codes": "5972",
            "Category": "Stamp and Coin Stores"
          },
          {
            "MCC Codes": "5973",
            "Category": "Religious Goods Stores"
          },
          {
            "MCC Codes": "5983",
            "Category": "Fuel Dealers"
          },
          {
            "MCC Codes": "5993",
            "Category": "Cigar Stores and Stands"
          },
          {
            "MCC Codes": "6010",
            "Category": "Financial Institutions\" Manual Cash Disbursements"
          },
          {
            "MCC Codes": "6011",
            "Category": "Financial Institutions\" Automated Cash Disbursements"
          },
          {
            "MCC Codes": "6012",
            "Category": "Financial Instruments & Merchandise service"
          },
          {
            "MCC Codes": "6051",
            "Category": "Non-Financial Institutions\" Foreign Currency, Money Orders (not wire transfer), and Travelers™ Cheques"
          },
          {
            "MCC Codes": "6211",
            "Category": "Security Brokers and Dealers"
          },
          {
            "MCC Codes": "6300",
            "Category": "Insurance Sales, Underwriting, and Premiums"
          },
          {
            "MCC Codes": "6513",
            "Category": "Real Estate Agents and Managers\" Rentals"
          },
          {
            "MCC Codes": "6540",
            "Category": "Funding Transactions"
          },
          {
            "MCC Codes": "7012",
            "Category": "Timeshares"
          },
          {
            "MCC Codes": "7273",
            "Category": "Dating and Escort Services"
          },
          {
            "MCC Codes": "7277",
            "Category": "Counselling Services \"Debt, Marriage, Personal"
          },
          {
            "MCC Codes": "7297",
            "Category": "Massage Parlours"
          },
          {
            "MCC Codes": "7299",
            "Category": "Miscellaneous Personal Services (Not Elsewhere Classified)"
          },
          {
            "MCC Codes": "7321",
            "Category": "Consumer Credit Reporting Agencies"
          },
          {
            "MCC Codes": "7361",
            "Category": "Employment Agencies and Temporary Help Services"
          },
          {
            "MCC Codes": "7375",
            "Category": "Information Retrieval Services"
          },
          {
            "MCC Codes": "7393",
            "Category": "Detective Agencies, Protective Agencies, and Security Services, including Armored Cars and Guard Dogs"
          },
          {
            "MCC Codes": "7399",
            "Category": "Business Services (Not Elsewhere Classified)"
          },
          {
            "MCC Codes": "7995",
            "Category": "Betting, including Lottery Tickets, Casino Gaming Chips, Off-Track Betting, and Wagers at Racetracks"
          },
          {
            "MCC Codes": "8204",
            "Category": "Agricultural Machinery"
          },
          {
            "MCC Codes": "8211",
            "Category": "Elementary and Secondary school"
          },
          {
            "MCC Codes": "8220",
            "Category": "Colleges and universities"
          },
          {
            "MCC Codes": "8241",
            "Category": "Correspondence Courses"
          },
          {
            "MCC Codes": "8244",
            "Category": "Business and Secretarial school"
          },
          {
            "MCC Codes": "8249",
            "Category": "Trade and Vocational schools"
          },
          {
            "MCC Codes": "8651",
            "Category": "Political Organizations"
          },
          {
            "MCC Codes": "9211",
            "Category": "Courts costs, including alimony and child support"
          },
          {
            "MCC Codes": "9222",
            "Category": "Fines"
          },
          {
            "MCC Codes": "9311",
            "Category": "Taxpayers"
          },
          {
            "MCC Codes": "9399",
            "Category": "Government Services, Intra Government Purchases, Government only"
          },
          {
            "MCC Codes": "9400",
            "Category": "Government Services, Intra Government Purchases, Government only"
          },
          {
            "MCC Codes": "9401",
            "Category": "Government Services, Intra Government Purchases, Government only"
          },
          {
            "MCC Codes": "9402",
            "Category": "Government Services, Intra Government Purchases, Government only"
          },
          {
            "MCC Codes": "9403",
            "Category": "Government Services, Intra Government Purchases, Government only"
          },
          {
            "MCC Codes": "9404",
            "Category": "Government Services, Intra Government Purchases, Government only"
          },
          {
            "MCC Codes": "9405",
            "Category": "Government Services, Intra Government Purchases, Government only"
          }
      ]
  
    return (
      <div className="w-full overflow-x-auto">
        <table className="min-w-full border-collapse border border-gray-300">
          <thead className="bg-[#FCDE9E]">
            <tr className="bg-gray-100">
              <th className="px-6 py-3 border border-gray-300 text-left text-sm font-semibold text-gray-700 min-w-[150px]">
                MCC Codes
              </th>
              <th className="px-6 py-3 border border-gray-300 text-left text-sm font-semibold text-gray-700 text-center min-w-[250px]">
                Category
              </th>
            </tr>
          </thead>
          <tbody>
            {fees.map((fee, index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                <td className="px-6 py-4 border border-gray-300 text-sm text-gray-600">
                  {fee["MCC Codes"]}
                </td>
                <td className="px-6 py-4 border border-gray-300 text-sm text-gray-600 text-center">
                 {fee["Category"]}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
  