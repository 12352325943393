export default function TermsTable2Hindi() {
    const fees = [
        {
            "Channel": "ईकॉमर्स",
            "MCC": "4814",
            "Description": "चुंबकीय पट्टी के उपयोग के माध्यम से कॉल - टेलीफोन पढ़ना",
            "Value": "1.10%"
        },
        {
            "Channel": "ईकॉमर्स",
            "MCC": "4900",
            "Description": "यूटिलिटीज - बिजली, गैस, सैनिटरी, पानी",
            "Value": "1.10%"
        },
        {
            "Channel": "ईकॉमर्स",
            "MCC": "8211",
            "Description": "प्राथमिक और माध्यमिक विद्यालय",
            "Value": "1.10%"
        },
        {
            "Channel": "ईकॉमर्स",
            "MCC": "8220",
            "Description": "कॉलेज, विश्वविद्यालय, व्यावसायिक स्कूल और जूनियर कॉलेज",
            "Value": "1.10%"
        },
        {
            "Channel": "ईकॉमर्स",
            "MCC": "8241",
            "Description": "स्कूल, पत्राचार",
            "Value": "1.10%"
        },
        {
            "Channel": "ईकॉमर्स",
            "MCC": "8244",
            "Description": "स्कूल, व्यापार और सचिवालय",
            "Value": "1.10%"
        },
        {
            "Channel": "ईकॉमर्स",
            "MCC": "8249",
            "Description": "व्यापार और व्यावसायिक स्कूल",
            "Value": "1.10%"
        },
        {
            "Channel": "ईकॉमर्स",
            "MCC": "8299",
            "Description": "स्कूल और शैक्षिक सेवाएँ - अन्यत्र वर्गीकृत नहीं",
            "Value": "1.10%"
        },
        {
            "Channel": "ईकॉमर्स",
            "MCC": "9402",
            "Description": "डाक सेवाएं - केवल सरकार",
            "Value": "1.10%"
        },
        {
            "Channel": "ईकॉमर्स",
            "MCC": "5411",
            "Description": "किराना स्टोर और सुपरमार्केट",
            "Value": "0.95%"
        },
        {
            "Channel": "ईकॉमर्स",
            "MCC": "5541",
            "Description": "सर्विस स्टेशन (अनcillary सेवाओं के साथ या बिना)",
            "Value": "1.30%"
        },
        {
            "Channel": "ईकॉमर्स",
            "MCC": "5542",
            "Description": "स्वचालित ईंधन डिस्पेंसर",
            "Value": "1.30%"
        },
        {
            "Channel": "ईकॉमर्स",
            "MCC": "5983",
            "Description": "ईंधन डीलर - कोयला, ईंधन तेल, तरलीकृत पेट्रोलियम, लकड़ी",
            "Value": "1.30%"
        },
        {
            "Channel": "ईकॉमर्स",
            "MCC": "5960",
            "Description": "प्रत्यक्ष विपणन - बीमा सेवाएं",
            "Value": "1.30%"
        },
        {
            "Channel": "ईकॉमर्स",
            "MCC": "6300",
            "Description": "बीमा बिक्री, अंडरराइटिंग और प्रीमियम",
            "Value": "1.30%"
        },
        {
            "Channel": "ईकॉमर्स",
            "MCC": "9399",
            "Description": "सरकारी सेवाएँ - अन्यत्र वर्गीकृत नहीं",
            "Value": "1.30%"
        },
        {
            "Channel": "ईकॉमर्स",
            "MCC": "6012",
            "Description": "वित्तीय संस्थान - माल और सेवाएँ",
            "Value": "1.30%"
        }
      ]
  
    return (
      <div className="w-full overflow-x-auto">
        <table className="min-w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-100">
              <th className="px-6 py-3 border border-gray-300 text-left text-sm font-semibold text-gray-700 min-w-[150px]">
              चैनल


              </th>
              <th className="px-6 py-3 border border-gray-300 text-left text-sm font-semibold text-gray-700 min-w-[150px]">
                MCC
              </th>
              <th className="px-6 py-3 border border-gray-300 text-left text-sm font-semibold text-gray-700 min-w-[400px]">
              विवरण
              </th>
              <th className="px-6 py-3 border border-gray-300 text-left text-sm font-semibold text-gray-700 min-w-[150px]">
              मूल्य
              </th>
            </tr>
          </thead>
          <tbody>
            {fees.map((fee, index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                <td className="px-6 py-4 border border-gray-300 text-sm text-gray-600">
                  {fee.Channel}
                </td>
                <td className="px-6 py-4 border border-gray-300 text-sm text-gray-600">
                 {fee.MCC}
                </td>
                <td className="px-6 py-4 border border-gray-300 text-sm text-gray-600">
                 {fee.Description}
                </td>
                <td className="px-6 py-4 border border-gray-300 text-sm text-gray-600">
                 {fee.Value}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
  