import React, { useState, useRef, useEffect } from "react";
import { BsArrowLeft } from "react-icons/bs";
import "../style/career.css";
import axios from "axios";

export default function AddNewJobForm({ close }) {
  const [formData, setFormData] = useState({
    jobTitle: "",
    location: "",
    workplaceType: "",
    jobType: "",
    department: "",
    experienceLevel: "",
    salaryRange: "",
    jobDescription: "",
    skillsRequired: "",
    educationalQualification: "",
    howToApply: "",
    status: "",
    tags: "",
  });

  const [selectedSkills, setSelectedSkills] = useState([]);
  const [showSkills, setShowSkills] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [error, setError] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const addSkill = (skill) => {
    if (skill === "Other") {
      setShowSkills(false);
      inputRef.current.focus();
      return;
    }
    let updatedSkills = [...selectedSkills];
    if (updatedSkills.includes(skill)) {
      updatedSkills = updatedSkills.filter((item) => item !== skill);
    } else {
      updatedSkills.push(skill);
    }
    setSelectedSkills(updatedSkills);

    // Update skillsRequired as a single comma-separated string
    setFormData((prevState) => ({
      ...prevState,
      skillsRequired: updatedSkills.join(","),
    }));
  };

  const handleChangeSkills = (e) => {
    const inputValue = e.target.value;
    setFormData((prevState) => ({
      ...prevState,
      skillsRequired: inputValue,
    }));
    setSelectedSkills(inputValue.split(",").map((skill) => skill.trim()));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const result = await axios.post(
        "https://admin.zokudo.com/customer-service/appuser/api/v1/jobPosition",
        formData
      );

      setResponseMessage("Job details have been successfully submitted!");
      window.location.pathname = "/job-postings";
      setError("");
      setIsSubmitted(true);
    } catch (err) {
      setError(
        err.response?.data?.details || "Something went wrong! Please try again."
      );
      setResponseMessage("");
      setIsSubmitted(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        inputRef.current &&
        !dropdownRef.current &&
        !inputRef.current.contains(event.target)
      ) {
        setShowSkills(false);
      }
    };

    const handleClick2Outside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowSkills(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("mousedown", handleClick2Outside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("mousedown", handleClick2Outside);
    };
  }, []);

  return (
    <div className="add-new-job-form">
      <div className="flex text-gray-500 text-left pl-8 gap-2 mb-4">
        <BsArrowLeft onClick={() => close()} className="mt-1 cursor-pointer" />
        <p className="mb-0">Job Details</p>
      </div>
      <div className="bg-[#FFFCF4] p-4 w-[94%] rounded-lg mx-auto border border-gray-200">
        <form class="space-y-6">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="flex flex-col text-left">
              <label class="text-sm font-medium text-gray-700 mb-1">
                Job Title*
              </label>
              <input
                type="text"
                name="jobTitle"
                value={formData.jobTitle}
                onChange={handleChange}
                placeholder="Enter Title"
                class="border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                required
              />
            </div>
            <div className="flex flex-col text-left">
              <label class="text-sm font-medium text-gray-700 mb-1">
                Location*
              </label>
              <input
                type="text"
                name="location"
                value={formData.location}
                onChange={handleChange}
                placeholder="Enter Location"
                class="border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                required
              />
            </div>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="flex flex-col text-left">
              <label class="text-sm font-medium text-gray-700 mb-1">
                Workplace Type*
              </label>
              <select
                className="border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                name="workplaceType"
                value={formData.workplaceType}
                onChange={handleChange}
                required
              >
                <option value="">Select Workplace Type</option>
                <option value="On-Site">On-Site</option>
                <option value="Remote">Remote</option>
                <option value="Hybrid">Hybrid</option>
              </select>
            </div>
            <div className="flex flex-col text-left">
              <label class="text-sm font-medium text-gray-700 mb-1">
                Job Type*
              </label>
              <select
                className="border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                name="jobType"
                value={formData.jobType}
                onChange={handleChange}
                required
              >
                <option value="">Select Job Type</option>
                <option value="Full-time">Full-time</option>
                <option value="Part-time">Part-time</option>
                <option value="Contract">Contract</option>
              </select>
            </div>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="flex flex-col text-left">
              <label class="text-sm font-medium text-gray-700 mb-1">
                Department*
              </label>
              <select
                className="border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                name="department"
                value={formData.department}
                onChange={handleChange}
                required
              >
                <option value="">Select Department</option>
                <option value="Product Management">Product Management</option>
                <option value="Engineering">Engineering</option>
                <option value="Design">Design</option>
              </select>
            </div>
            <div className="flex flex-col text-left">
              <label class="text-sm font-medium text-gray-700 mb-1">
                Experience Level*
              </label>
              <select
                class="border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                name="experienceLevel"
                value={formData.experienceLevel}
                required
                onChange={handleChange}
              >
                <option value="">Select Experience Level</option>
                <option>1-3 Years</option>
                <option>3-5 Years</option>
                <option>5+ Years</option>
              </select>
            </div>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="flex flex-col text-left">
              <label class="text-sm font-medium text-gray-700 mb-1">
                Salary Range (Optional)
              </label>
              <input
                type="text"
                name="salaryRange"
                value={formData.salaryRange}
                onChange={handleChange}
                placeholder="Enter Range"
                class="border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
            </div>
            <div className="flex flex-col text-left">
              <label class="text-sm font-medium text-gray-700 mb-1">
                Job Description*
              </label>
              <textarea
                placeholder="Enter Description"
                name="jobDescription"
                value={formData.jobDescription}
                onChange={handleChange}
                required
                class="border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
              ></textarea>
            </div>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="flex flex-col text-left relative">
              <label class="text-sm font-medium text-gray-700 mb-1">
                Skills Required
              </label>
              <input
                type="text"
                placeholder="Add Required Skills"
                class="border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                required
                value={formData.skillsRequired}
                onChange={handleChangeSkills}
                // onClick={() => setShowSkills(!showSkills)}
                ref={inputRef}
              />
              <div
                ref={dropdownRef}
                className={`absolute left-0 top-[102%] w-full border skill-dropdown rounded overflow-hidden ${
                  showSkills ? "block" : "hidden"
                }`}
              >
                <div className="p-2 bg-[#F4ECD6]">
                  <p className="text-sm font-light text-gray-600 mb-0">
                    Select Skills
                  </p>
                </div>
                <ul className="bg-white pl-0 mb-0">
                  <li
                    className="flex text-gray-400 text-sm justify-between p-2 bg-white hover:bg-gray-300 cursor-pointer"
                    onClick={() => addSkill("SQL")}
                  >
                    <span>SQL</span>
                    <input
                      type="checkbox"
                      checked={selectedSkills.includes("SQL")}
                      readOnly
                    />
                  </li>
                  <li
                    className="flex text-gray-400 text-sm justify-between p-2 bg-white hover:bg-gray-300 cursor-pointer"
                    onClick={() => addSkill("Java")}
                  >
                    <span>Java</span>
                    <input
                      type="checkbox"
                      checked={selectedSkills.includes("Java")}
                      readOnly
                    />
                  </li>
                  <li
                    className="flex text-gray-400 text-sm justify-between p-2 bg-white hover:bg-gray-300 cursor-pointer"
                    onClick={() => addSkill("React")}
                  >
                    <span>React</span>
                    <input
                      type="checkbox"
                      checked={selectedSkills.includes("React")}
                      readOnly
                    />
                  </li>
                  {/* <li
                    className="flex text-gray-500 text-sm justify-between p-2 bg-white"
                    onClick={() => addSkill("Other")}
                  >
                    <span>Other</span>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="flex flex-col text-left">
              <label class="text-sm font-medium text-gray-700 mb-1">
                Educational Qualification*
              </label>
              <select
                className="border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                name="educationalQualification"
                required
                value={formData.educationalQualification}
                onChange={handleChange}
              >
                <option value="">Select Qualification</option>
                <option value="Bachelor">Bachelor</option>
                <option value="Master">Master</option>
                <option value="PhD">PhD</option>
              </select>
            </div>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="flex flex-col text-left">
              <label class="text-sm font-medium text-gray-700 mb-1">
                How to Apply*
              </label>
              <textarea
                name="howToApply"
                required
                value={formData.howToApply}
                onChange={handleChange}
                placeholder="Enter instructions (e.g., Send your resume to careers@company.com)"
                class="border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
            </div>
            <div className="flex flex-col text-left">
              <label class="text-sm font-medium text-gray-700 mb-1">
                Status*
              </label>
              <select
                className="border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                name="status"
                required
                value={formData.status}
                onChange={handleChange}
              >
                <option value="">Select Status</option>
                <option value="Active">Active</option>
                <option value="Closed">Closed</option>
              </select>
            </div>
          </div>

          <div className="flex flex-col text-left">
            <label class="text-sm font-medium text-gray-700 mb-1">
              Tags (Optional)
            </label>
            <input
              type="text"
              name="tags"
              value={formData.tags}
              onChange={handleChange}
              placeholder="Fintech, Product Management, Remote Jobs"
              class="border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
          </div>
          <div>
            <button
              type="button"
              className="text-black font-medium py-2 px-4 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
              disabled={isSubmitted}
              onClick={handleSubmit}
            >
              Post a Job
            </button>
          </div>
        </form>
        {responseMessage && (
          <p className="text-green-500 mt-2">{responseMessage}</p>
        )}
        {error && <p className="text-red-500 mt-2">{error}</p>}
      </div>
    </div>
  );
}
