export default function TermsTable3Hindi() {
  const fees = [
    {
      "MCC Codes": "820",
      Category: "उर्वरक डीलर",
    },
    {
      "MCC Codes": "821",
      Category: "कीटनाशक/कीटनाशक",
    },
    {
      "MCC Codes": "822",
      Category: "बीज",
    },
    {
      "MCC Codes": "823",
      Category: "कृषि उपकरण",
    },
    {
      "MCC Codes": "825",
      Category: "अन्य कृषि उत्पाद",
    },
    {
      "MCC Codes": "3020",
      Category: "एयर इंडिया",
    },
    {
      "MCC Codes": "4112",
      Category: "यात्री रेलवे",
    },
    {
      "MCC Codes": "4814",
      Category: "दूरसंचार",
    },
    {
      "MCC Codes": "4829",
      Category: "पैसे का हस्तांतरण",
    },
    {
      "MCC Codes": "4900",
      Category: "यूटिलिटीज (सार्वजनिक और निजी)",
    },
    {
      "MCC Codes": "5411",
      Category: "सुपरमार्केट",
    },
    {
      "MCC Codes": "5541",
      Category: "सर्विस स्टेशन",
    },
    {
      "MCC Codes": "5542",
      Category: "स्वचालित ईंधन डिस्पेंसर",
    },
    {
      "MCC Codes": "5932",
      Category: "एंटीक दुकानों की बिक्री, मरम्मत और बहाली सेवाएं",
    },
    {
      "MCC Codes": "5933",
      Category: "गिरवी दुकानें",
    },
    {
      "MCC Codes": "5937",
      Category: "एंटीक प्रतिकृतियां",
    },
    {
      "MCC Codes": "5960",
      Category: "प्रत्यक्ष विपणन; बीमा बिक्री",
    },
    {
      "MCC Codes": "5970",
      Category: "कलाकार आपूर्ति और शिल्प की दुकानें",
    },
    {
      "MCC Codes": "5971",
      Category: "कला डीलर और गैलरी",
    },
    {
      "MCC Codes": "5972",
      Category: "स्टाम्प और सिक्का स्टोर",
    },
    {
      "MCC Codes": "5973",
      Category: "धार्मिक सामान की दुकानें",
    },
    {
      "MCC Codes": "5983",
      Category: "ईंधन डीलर",
    },
    {
      "MCC Codes": "5993",
      Category: "सिगार स्टोर और स्टैंड",
    },
    {
      "MCC Codes": "6010",
      Category: "वित्तीय संस्थानों की मैनुअल नकद संवितरण",
    },
    {
      "MCC Codes": "6011",
      Category: "वित्तीय संस्थानों का स्वचालित नकद संवितरण",
    },
    {
      "MCC Codes": "6012",
      Category: "वित्तीय साधन और माल सेवा",
    },
    {
      "MCC Codes": "6051",
      Category:
        "गैर-वित्तीय संस्थानों की विदेशी मुद्रा, मनी ऑर्डर (वायर ट्रांसफर नहीं), और ट्रैवलर्स चेक",
    },
    {
      "MCC Codes": "6211",
      Category: "सिक्योरिटी ब्रोकर्स और डीलर्स",
    },
    {
      "MCC Codes": "6300",
      Category: "बीमा बिक्री, अंडरराइटिंग और प्रीमियम",
    },
    {
      "MCC Codes": "6513",
      Category: "रियल एस्टेट एजेंट और मैनेजर का किराया",
    },
    {
      "MCC Codes": "6540",
      Category: "फंडिंग लेनदेन",
    },
    {
      "MCC Codes": "7012",
      Category: "टाइमशेयर",
    },
    {
      "MCC Codes": "7273",
      Category: "डेटिंग और एस्कॉर्ट सेवाएं",
    },
    {
      "MCC Codes": "7277",
      Category: 'काउंसलिंग सेवाएं "ऋण, विवाह, व्यक्तिगत',
    },
    {
      "MCC Codes": "7297",
      Category: "मसाज पार्लर",
    },
    {
      "MCC Codes": "7299",
      Category: "विविध व्यक्तिगत सेवाएं (अन्यत्र वर्गीकृत नहीं)",
    },
    {
      "MCC Codes": "7321",
      Category: "उपभोक्ता क्रेडिट रिपोर्टिंग एजेंसियां",
    },
    {
      "MCC Codes": "7361",
      Category: "रोजगार एजेंसियां और अस्थायी सहायता सेवाएं",
    },
    {
      "MCC Codes": "7375",
      Category: "सूचना पुनर्प्राप्ति सेवाएं",
    },
    {
      "MCC Codes": "7393",
      Category:
        "डिटेक्टिव एजेंसियां, सुरक्षा एजेंसियां और सुरक्षा सेवाएं, जिसमें बख्तरबंद कार और गार्ड डॉग शामिल हैं",
    },
    {
      "MCC Codes": "7399",
      Category: "व्यवसायिक सेवाएं (अन्यत्र वर्गीकृत नहीं)",
    },
    {
      "MCC Codes": "7995",
      Category:
        "सट्टेबाजी, जिसमें लॉटरी टिकट, कैसीनो गेमिंग चिप्स, ऑफ-ट्रैक बेटिंग और रेसट्रैक पर दांव शामिल हैं",
    },
    {
      "MCC Codes": "8204",
      Category: "कृषि मशीनरी",
    },
    {
      "MCC Codes": "8211",
      Category: "प्राथमिक और माध्यमिक स्कूल",
    },
    {
      "MCC Codes": "8220",
      Category: "कॉलेज और विश्वविद्यालय",
    },
    {
      "MCC Codes": "8241",
      Category: "पत्राचार पाठ्यक्रम",
    },
    {
      "MCC Codes": "8244",
      Category: "व्यापार और सचिवालय स्कूल",
    },
    {
      "MCC Codes": "8249",
      Category: "व्यापार और व्यावसायिक स्कूल",
    },
    {
      "MCC Codes": "8651",
      Category: "राजनीतिक संगठन",
    },
    {
      "MCC Codes": "9211",
      Category: "अदालती लागत, जिसमें गुजारा भत्ता और बच्चे का समर्थन शामिल है",
    },
    {
      "MCC Codes": "9222",
      Category: "जुर्माना",
    },
    {
      "MCC Codes": "9311",
      Category: "करदाता",
    },
    {
      "MCC Codes": "9399",
      Category: "सरकारी सेवाएं, अंतर-सरकारी खरीद, केवल सरकार",
    },
    {
      "MCC Codes": "9400",
      Category: "सरकारी सेवाएं, अंतर-सरकारी खरीद, केवल सरकार",
    },
    {
      "MCC Codes": "9401",
      Category: "सरकारी सेवाएं, अंतर-सरकारी खरीद, केवल सरकार",
    },
    {
      "MCC Codes": "9402",
      Category: "सरकारी सेवाएं, अंतर-सरकारी खरीद, केवल सरकार",
    },
    {
      "MCC Codes": "9403",
      Category: "सरकारी सेवाएं, अंतर-सरकारी खरीद, केवल सरकार",
    },
    {
      "MCC Codes": "9404",
      Category: "सरकारी सेवाएं, अंतर-सरकारी खरीद, केवल सरकार",
    },
    {
      "MCC Codes": "9405",
      Category: "सरकारी सेवाएं, अंतर-सरकारी खरीद, केवल सरकार",
    },
  ];

  return (
    <div className="w-full overflow-x-auto">
      <table className="min-w-full border-collapse border border-gray-300">
        <thead className="bg-[#FCDE9E]">
          <tr className="bg-gray-100">
            <th className="px-6 py-3 border border-gray-300 text-left text-sm font-semibold text-gray-700 min-w-[150px]">
              MCC कोड
            </th>
            <th className="px-6 py-3 border border-gray-300 text-left text-sm font-semibold text-gray-700 text-center min-w-[250px]">
              श्रेणी
            </th>
          </tr>
        </thead>
        <tbody>
          {fees.map((fee, index) => (
            <tr
              key={index}
              className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
            >
              <td className="px-6 py-4 border border-gray-300 text-sm text-gray-600">
                {fee["MCC Codes"]}
              </td>
              <td className="px-6 py-4 border border-gray-300 text-sm text-gray-600 text-center">
                {fee["Category"]}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
