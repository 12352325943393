import React from "react";
import rectange from "../../assets/rectangle.svg";
import smCircle from "../../assets/small-circle.svg";
import triangle from "../../assets/triangle.svg";

export default function CustomCard({ item }) {
  return (
    <div className={`group relative w-[45%] lg:w-[25%] custom-design-card ${"lg:"+item.customClasses}`}>
      <img
        className="m-auto lg:absolute lg:left-[50%] lg:top-[-20px] lg:-translate-x-2/4 icon-img group-hover:scale-[1.3] duration-300"
        src={item.icon}
      />
      <img className={`${item.rotateHorizontal ? 'transform rotate-y-180' :''} hidden lg:block`} src={item.rectange} />
      <div className="lg:absolute lg:left-[50%] lg:top-[42%] lg:-translate-x-2/4  lg:-translate-y-2/4 lg:-translate-y-[40%] info-div">
        <h6 className="text-[10px] xl:text-[12px] 2xl:text-sm mt-2">{item.name}</h6>
        <p className="text-[10px] 2xl:text-[12px] mb-4 lg:mb-0 text-gray-500 card-desc group-hover:text-gray-600 duration-300">{item.desc}</p>
      </div>
      {
        !item.hideTriangle && <img className={`hidden lg:block ${item.triangleClasses}`} src={triangle} />
      }
      {!item.hideCircle && (
        <img className={`hidden lg:block ${item.circleClasses}`} src={smCircle} />
      )}
    </div>
  );
}
