import React from "react";
import '../App.css'

function Demo() {
    return (
        <div>
            <div className="container ">
                <div className="">
                    <div className=""> 
                        box1
                    </div>

                    <div className=""> 
                        box2
                    </div>

                    <div className=""> 
                        box3
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Demo;