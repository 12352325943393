import React, { useEffect } from "react";
import baasBanner2 from "../assets/baas-banner-2.png";
import baasBanner1 from "../assets/lines.svg";
import dots from "../assets/dots-4.svg";
import "../style/bass-solution.css";
import circle from "../assets/circle.svg";
import rewardCard from "../assets/Rewards Cards.svg";
import triangle from "../assets/triangle.svg";
import expense from "../assets/Expense.svg";
import giftCard from "../assets/GC Card.svg";
import studentCard from "../assets/Students Cards.svg";
import payrolCard from "../assets/Payroll Cards.svg";
import mealCard from "../assets/Meal Cards.svg";
import prePaid from "../assets/Prepaid.svg";
import bassBanner1 from "../assets/baas-banner-1.svg";
import ko1 from "../assets/ko1.svg";
import ko2 from "../assets/ko2.svg";
import ko3 from "../assets/ko3.svg";
import ko4 from "../assets/ko4.svg";
import ko5 from "../assets/ko5.svg";
import ko6 from "../assets/ko6.svg";
import jifyImg from "../assets/jify2.png";
import advantageClubImg from "../assets/advantage-club.svg";
import rewardCardImg from "../assets/reward-kart.svg";
import vergadoLogo from "../assets/vergado.svg";
import tick from "../assets/tick.svg";
import baas1Bottom from "../assets/baas-1-bottom.svg";
import baas2Bottom from "../assets/baas-2-bottom.svg";
import baasFaqBg from "../assets/baas-faq-banner.svg";
import rectange from "../assets/rectangle.svg";
import smCircle from "../assets/small-circle.svg";
import rctLeft from "../assets/rctLeft.svg";
import rctRight from "../assets/rctRight.svg";
import rctDown from "../assets/rctDown.svg";
import { Link } from "react-router-dom";
import Footer from "./common/Footer";
import CustomCard from "./ui/CustomCard";
import Faq from "./ui/Faq";
import Collaborate from "./Collaborate";
import increaseRevenueImg from "../assets/revenue.svg";
import upiImg from "../assets/upi-image.svg";
import endToendImg from "../assets/end-to-end-img.svg";
import justTimeImg from "../assets/just-time-img.svg";

import fastTagImg from "../assets/Fastag.svg";
import { FaArrowRightLong } from "react-icons/fa6";

import { motion } from "framer-motion";
function BaasSolution() {
  const t1TopClasses =
    "absolute right-4 bottom-10 xl:right-[24px] 2xl:right-8 xl:bottom-[52px] 2xl:bottom-16 t-top";
  const data = [
    {
      icon: giftCard,
      desc: "Create and manage customised corporate gift cards for employees. These corporate gift cards work all over India for millions of online and offline merchants.",
      name: "Corporate Gift Cards",
      hideCircle: false,
      hideTriangle: false,
      rectange: rctLeft,
      rotateHorizontal: false,
      triangleClasses: t1TopClasses,
      circleClasses: "absolute left-[42%] -translate-x-[42%] bottom-[-20px]",
      customClasses: "",
    },
    {
      icon: expense,
      desc: "Track and control spending in real-time, making expense management and reimbursement processes more efficient.",
      name: "Expense Management",
      hideCircle: true,
      hideTriangle: false,
      rectange: rectange,
      rotateHorizontal: false,
      triangleClasses: t1TopClasses,
      circleClasses: "absolute left-[42%] -translate-x-[42%] bottom-[-20px]",
      customClasses: "",
    },
    {
      icon: rewardCard,
      desc: "Design and implement customisable reward programs that recognise and incentivise employee performance.",
      name: "Rewards Cards",
      hideCircle: true,
      hideTriangle: false,
      rectange: rectange,
      rotateHorizontal: false,
      triangleClasses: t1TopClasses,
      circleClasses: "absolute left-[42%] -translate-x-[42%] bottom-[-20px]",
      customClasses: "",
    },
    {
      icon: studentCard,
      desc: "Students can receive special discounts, rewards, prizes, and other benefits directly on their IDs through prepaid student cards, thanks to university partnerships.",
      name: "Student Cards",
      hideCircle: false,
      hideTriangle: true,
      rectange: rctRight,
      rotateHorizontal: true,
      triangleClasses: t1TopClasses,
      circleClasses: "absolute left-[58%] -translate-x-[58%] bottom-[-20px]",
      customClasses: "",
    },
    {
      icon: payrolCard,
      desc: "Disburse salaries directly onto prepaid cards, bypassing bank transfers and promoting financial inclusion for unbanked employees",
      name: "Payroll Cards",
      hideCircle: true,
      hideTriangle: false,
      rectange: rctDown,
      rotateHorizontal: true,
      triangleClasses:
        "absolute right-5 top-[-8px] xl:right-6 xl:top-[-2px] rotate-[90deg] 2xl:right-8 2xl:top-0 t-bottom",
      circleClasses: "absolute left-[58%] -translate-x-[58%] bottom-[-20px]",
      customClasses: "mt-[-30px] 2xl:mt-[-40px]",
    },
    {
      icon: mealCard,
      desc: "These prepaid cards can be issued to employees, allowing them to purchase food and beverages conveniently from partner brands.",
      name: "Meal Cards/Wallets",
      hideCircle: true,
      hideTriangle: false,
      rectange: rctDown,
      rotateHorizontal: true,
      triangleClasses:
        "absolute right-5 top-[-8px] xl:right-6 xl:top-[-2px] rotate-[90deg] 2xl:right-8 2xl:top-0 t-bottom",
      circleClasses: "absolute left-[58%] -translate-x-[58%] bottom-[-20px]",
      customClasses: "mt-[-30px] 2xl:mt-[-40px]",
    },
    {
      icon: mealCard,
      desc: "Streamline the distribution of health benefits, ensuring that employees have the necessary resources for their healthcare needs.",
      name: "Prepaid Health Insurance Cards",
      hideCircle: true,
      hideTriangle: false,
      rectange: rctDown,
      rotateHorizontal: true,
      triangleClasses:
        "absolute right-5 top-[-8px] xl:right-6 xl:top-[-2px] rotate-[90deg] 2xl:right-8 2xl:top-0 t-bottom",
      circleClasses: "absolute left-[58%] -translate-x-[58%] bottom-[-20px]",
      customClasses: "mt-[-30px] 2xl:mt-[-40px]",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="container-fluid baas-banner pt-10 pb-4 md:py-20">
        <div className="container py-[10px] md:py-[40px] lg:py-[80px] 2xl:py-[120px] grid grid-cols-1 gap-4 md:grid-cols-2 relative">
          <img className="absolute bottom-5 right-10" src={dots} />
          <img
            className="lines-img absolute hidden xl:block top-2 left-[50%]"
            src={baasBanner1}
          />
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 20 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
            className="text-left baas-banner-left"
          >
            <h3 className="font-semibold">
              Your <span className="text-warning">BaaS Partner</span>
              <br></br>
              for Prepaid Payment Services
            </h3>
            <p className="text-[#A6A6A6]">
              Access a range of interoperable network cards and wallets designed
              to streamline payment processes and enhance customer and employee
              experiences.
            </p>
            <a href="/contact-us">
              <button className="fs-14 text-dark bg-[#E4C221] rounded-2 border-0 py-2 px-3 fs-600 pulse align-self-start">
                Get Started
              </button>
            </a>
          </motion.div>
          <div className="relative grid place-items-center pt-2 md:pt-0">
            <motion.img
              initial={{ opacity: 0, scale: 0.8 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
              className="top-0 w-4/5 lg:w-4/5 2xl:w-3/5 m-auto baas-bn-img"
              src={baasBanner2}
            />
          </div>
        </div>
      </div>

      <div className="solution-container custom-card-container pt-10 pb-4 md:py-10 lg:py-20">
        <div className="container">
          <motion.div className="flex flex-column text-center gap-10">
            <motion.button
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
              className="m-auto solution-btn text-warning fs-600 align-self-start p-2 rounded mb-3"
            >
              Solutions
            </motion.button>
            <motion.h3
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
              className="text-warning font-semibold"
            >
              {" "}
              Co-Brand Cards with Zokudo{" "}
            </motion.h3>
            <motion.p
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
              className="text-sm md:text-md w-full lg:w-4/5 xl:w-3/5 2xl:w-2/5 m-auto text-[#AFAFAF]"
            >
              Explore how co-branding cards with Zokudo allows you to provide a
              seamless payment experience for your customers, enhancing
              engagement and boosting brand visibility.
            </motion.p>
          </motion.div>
        </div>
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.5 }}
          viewport={{ once: true }}
          className="container flex justify-center flex-wrap md:pb-5 rotated-rects"
        >
          {data.map((item, index) => {
            return <CustomCard key={index} item={item} />;
          })}
        </motion.div>
      </div>

      <div className="container-fluid baas-bg bg-black py-10 md:py-20 md:mt-5 bg-prepaid-bass-banner">
        <div className="container">
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
            className="flex flex-col text-center gap-10"
          >
            <button className="btn btn-dark w-auto align-self-center">
              <span className="text-gray-600">Solution</span>
            </button>
            <h3 className="text-warning">
              Key Offerings in the Zokudo BaaS Ecosystem{" "}
            </h3>
            <p className="text-gray-300 md:w-4/5 lg:w-2/5 mx-auto">
              Explore how exactly our BaaS services help streamline your
              business needs with prepaid cards in India.
            </p>
          </motion.div>
        </div>
        <div className="md:hidden block flex justify-end arrow-right-icon">
          <FaArrowRightLong className="mb-[-16px] mr-2 text-white opacity-50" />
        </div>
        <div className="container px-0 md:px-auto baas-solution mt-3 md:mt-5">
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
            className="md:w-[90%] mx-auto"
          >
            <nav className="flex justify-center">
              <div
                className="nav nav-tabs bs-tabs text-white"
                id="nav-tab"
                role="tablist"
              >
                <button
                  className="nav-link text-white active"
                  id="nav-saas-based-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-saas-based"
                  type="button"
                  role="tab"
                  aria-controls="nav-home"
                  aria-selected="true"
                >
                  Saas Based
                </button>
                <button
                  className="nav-link text-white"
                  id="nav-issuance-card-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-issuance-card"
                  type="button"
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected="false"
                >
                  Issuance on Card
                </button>
                <button
                  className="nav-link text-white"
                  id="nav-transact-card-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-transact-card"
                  type="button"
                  role="tab"
                  aria-controls="nav-contact"
                  aria-selected="false"
                >
                  Transact Card
                </button>
                <button
                  className="nav-link text-white"
                  id="nav-user-analytic-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-user-analytic"
                  type="button"
                  role="tab"
                  aria-controls="nav-disabled"
                  aria-selected="false"
                >
                  User Analytics
                </button>
                <button
                  className="nav-link text-white"
                  id="nav-user-friendly-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-user-friendly"
                  type="button"
                  role="tab"
                  aria-controls="nav-home"
                  aria-selected="true"
                >
                  User Friendly
                </button>
                <button
                  className="nav-link text-white"
                  id="nav-cashback-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-cashback"
                  type="button"
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected="false"
                >
                  Cashback
                </button>

                <button
                  className="nav-link text-white"
                  id="nav-logistic-ecosystem-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-logistic-ecosystem"
                  type="button"
                  role="tab"
                  aria-controls="nav-disabled"
                  aria-selected="false"
                >
                  Logistics Ecosystem
                </button>
                <button
                  className="nav-link text-white"
                  id="increase-revenue-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#increase-tab"
                  type="button"
                  role="tab"
                  aria-controls="increase-tab"
                  aria-selected="false"
                >
                  Increase Revenue
                </button>
                <button
                  className="nav-link text-white"
                  id="upi"
                  data-bs-toggle="tab"
                  data-bs-target="#upi-tab"
                  type="button"
                  role="tab"
                  aria-controls="upi-tab"
                  aria-selected="false"
                >
                  UPI and UPI Scan Solution
                </button>
                <button
                  className="nav-link text-white"
                  id="end"
                  data-bs-toggle="tab"
                  data-bs-target="#end-tab"
                  type="button"
                  role="tab"
                  aria-controls="end-tab"
                  aria-selected="false"
                >
                  End-to-End Program Management
                </button>
                <button
                  className="nav-link text-white"
                  id="just-time"
                  data-bs-toggle="tab"
                  data-bs-target="#just-time-tab"
                  type="button"
                  role="tab"
                  aria-controls="just-time-tab"
                  aria-selected="false"
                >
                  Just-In-Time Funding
                </button>
                <button
                  className="nav-link text-white"
                  id="fast-tag"
                  data-bs-toggle="tab"
                  data-bs-target="#fast-tag-tab"
                  type="button"
                  role="tab"
                  aria-controls="fast-tag-tab"
                  aria-selected="false"
                >
                  Fastag and e-Rupee Solutions
                </button>
              </div>
            </nav>
            <div className="bg-gradient-to-b from-[#E4C221] to-black p-[2px]">
              <div className="tab-content bg-black" id="nav-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="nav-saas-based"
                  role="tabpanel"
                  aria-labelledby="nav-saas-based-tab"
                  tabindex="0"
                >
                  <div className="grid grid-cols-1 md:grid-cols-2 pt-4 lg:pt-10 xl:pt-0">
                    <div className="text-left flex flex-col justify-center">
                      <div className="pl-4 lg:pl-10 pr-3 py-4">
                        <h4 className="text-warning">Saas based</h4>
                        <p className="text-sm text-gray-400">
                          Our SaaS model ensures that businesses can easily
                          integrate and deploy prepaid card solutions without
                          the need for extensive IT infrastructure or
                          maintenance. This cloud-based approach allows for
                          real-time updates and seamless scalability, enabling
                          businesses to adapt quickly to changing needs and
                          expand their card programs as required.
                        </p>
                        <p className="text-sm text-gray-400">
                          With easy-to-integrate APIs that go seamlessly with
                          your existing tech infrastructure, you can efficiently
                          manage card issuance, transactions, and reporting with
                          minimal technical overhead.
                        </p>
                        {/* <div className="flex justify-start">
                          <Link to="/corporate">
                            <button className="bg-white text-black align-self-start border-0 p-1 rounded px-3">
                              Know More{" "}
                              <i className="bi bi-arrow-right fw-bold"></i>
                            </button>
                          </Link>
                        </div> */}
                      </div>
                    </div>
                    <div className="col text-center grid place-items-center">
                      <img src={ko1} />
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="increase-tab"
                  role="tabpanel"
                  aria-labelledby="increase-revenue"
                >
                  <div className="grid grid-cols-1 md:grid-cols-2 pt-4 lg:pt-10 xl:pt-0">
                    <div className="text-left flex flex-col justify-center">
                      <div className="pl-4 lg:pl-10 pr-3 py-4">
                        <h4 className="text-warning">Increase Revenue</h4>
                        <p className="text-secondary">
                          Unlock additional revenue streams with Zokudo by
                          leveraging interchange fees and Merchant Discount Rate
                          (MDR) on wallets. Our innovative prepaid card
                          solutions enable you to benefit from every transaction
                          processed, allowing your business to generate
                          consistent income without significant overhead.
                        </p>
                        <p className="text-secondary">
                          As users engage more with your offerings, you not only
                          enhance their experience but also boost your bottom
                          line.
                        </p>
                      </div>
                    </div>
                    <div className="col text-center grid place-items-center">
                      <img src={increaseRevenueImg} />
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="upi-tab"
                  role="tabpanel"
                  aria-labelledby="upi"
                >
                  <div className="grid grid-cols-1 md:grid-cols-2 pt-4 lg:pt-10 xl:pt-0">
                    <div className="text-left flex flex-col justify-center">
                      <div className="pl-4 lg:pl-10 pr-3 py-4">
                        <h4 className="text-warning">
                          UPI and UPI Scan Solution
                        </h4>
                        <p className="text-secondary">
                          Empower your prepaid cards and wallets with UPI
                          payment (Unified Payments Interface) capabilities. Our
                          UPI solution allows users to perform seamless
                          transactions, facilitating effortless reloading of
                          prepaid cards and making payments more accessible than
                          ever.
                        </p>
                        <p className="text-secondary">
                          Whether it's splitting bills, making purchases, or
                          managing day-to-day expenses, the convenience of UPI
                          payment enhances user engagement and satisfaction.
                        </p>
                      </div>
                    </div>
                    <div className="col text-center grid place-items-center">
                      <img src={upiImg} />
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="end-tab"
                  role="tabpanel"
                  aria-labelledby="end"
                >
                  <div className="grid grid-cols-1 md:grid-cols-2 pt-4 lg:pt-10 xl:pt-0">
                    <div className="text-left flex flex-col justify-center">
                      <div className="pl-4 lg:pl-10 pr-3 py-4">
                        <h4 className="text-warning">
                          End-to-End Program Management
                        </h4>
                        <p className="text-secondary">
                          We seamlessly integrate every aspect of your card
                          program, from initial card issuance to ongoing
                          transaction monitoring and detailed reporting. This
                          holistic approach ensures that you maintain full
                          oversight of your operations, facilitating smooth
                          workflows and promoting transparency at every stage.
                        </p>
                        <p className="text-secondary">
                          With Zokudo, you can easily manage user accounts,
                          track spending patterns, and generate reports,
                          allowing you to make informed decisions.
                        </p>
                      </div>
                    </div>
                    <div className="col text-center grid place-items-center">
                      <img src={endToendImg} />
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="just-time-tab"
                  role="tabpanel"
                  aria-labelledby="just-time"
                >
                  <div className="grid grid-cols-1 md:grid-cols-2 pt-4 lg:pt-10 xl:pt-0">
                    <div className="text-left flex flex-col justify-center">
                      <div className="pl-4 lg:pl-10 pr-3 py-4">
                        <h4 className="text-warning">Just-In-Time Funding</h4>
                        <p className="text-secondary">
                          No more hassle of maintaining account balances. With
                          Zokudo’s Just-in-Time (JIT) account funding, funds are
                          transferred instantly, giving you immediate access to
                          the financial resources you need precisely when you
                          need them.
                        </p>
                        <p className="text-secondary">
                          This flexible funding approach streamlines cash flow
                          management and optimises your ability to allocate
                          resources effectively.
                        </p>
                      </div>
                    </div>
                    <div className="col text-center grid place-items-center">
                      <img src={justTimeImg} />
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="fast-tag-tab"
                  role="tabpanel"
                  aria-labelledby="fast-tag"
                >
                  <div className="grid grid-cols-1 md:grid-cols-2 pt-4 lg:pt-10 xl:pt-0">
                    <div className="text-left flex flex-col justify-center">
                      <div className="pl-4 lg:pl-10 pr-3 py-4">
                        <h4 className="text-warning">
                          Fastag and e-Rupee Solutions
                        </h4>
                        <p className="text-secondary">
                          Stay tuned for our upcoming FastTag and e-Rupee
                          solutions, designed to enhance payment efficiency for
                          tolls and other services. These innovations will
                          further expand your payment options, making
                          transactions quicker and easier than ever.
                        </p>
                      </div>
                    </div>
                    <div className="col text-center grid place-items-center">
                      <img src={fastTagImg} className="w-[180px] object-contain" />
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-issuance-card"
                  role="tabpanel"
                  aria-labelledby="nav-issuance-card-tab"
                  tabindex="0"
                >
                  <div className="grid grid-cols-1 md:grid-cols-2 pt-4 lg:pt-10 xl:pt-0">
                    <div className="text-left flex flex-col justify-center">
                      <div className="pl-4 lg:pl-10 pr-3 py-4">
                        <h4 className="text-warning">
                          Issuance on Card and Wallet
                        </h4>
                        <p className="text-sm text-gray-400">
                          Issue prepaid cards and digital wallets preloaded with
                          funds, offering employees seamless access and reducing
                          the need for complex claims processing and paperwork.
                        </p>
                        <p className="text-sm text-gray-400">
                          This system simplifies the distribution of corporate
                          benefits, making it easier for companies to allocate
                          budgets and manage employee programs through a single
                          platform.
                        </p>
                        {/* <div className="flex justify-start">
                          <Link to="/corporate">
                            <button className="bg-white text-black align-self-start border-0 p-1 rounded px-3">
                              Know More{" "}
                              <i className="bi bi-arrow-right fw-bold"></i>
                            </button>
                          </Link>
                        </div> */}
                      </div>
                    </div>
                    <div className="col text-center grid place-items-center">
                      <img src={ko2} />
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-transact-card"
                  role="tabpanel"
                  aria-labelledby="nav-transact-card-tab"
                  tabindex="0"
                >
                  <div className="grid grid-cols-1 md:grid-cols-2 pt-4 lg:pt-10 xl:pt-0">
                    <div className="text-left flex flex-col justify-center">
                      <div className="pl-4 lg:pl-10 pr-3 py-4">
                        <h4 className="text-warning">Transact Cards</h4>
                        <p className="text-sm text-gray-400">
                          Our prepaid cards serve as a secure and efficient way
                          to handle various financial transactions for
                          businesses. You can streamline your vendor payments,
                          manage employee reimbursements, and handle
                          business-to-business transactions with ease.
                        </p>
                        <p className="text-sm text-gray-400">
                          Incorporating transact cards into your operations will
                          boost payment efficiency, cut transaction costs, and
                          improve overall financial management.
                        </p>
                        {/* <div className="flex justify-start">
                          <Link to="/corporate">
                            <button className="bg-white text-black align-self-start border-0 p-1 rounded px-3">
                              Know More{" "}
                              <i className="bi bi-arrow-right fw-bold"></i>
                            </button>
                          </Link>
                        </div> */}
                      </div>
                    </div>
                    <div className="col text-center grid place-items-center">
                      <img src={ko3} />
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-user-analytic"
                  role="tabpanel"
                  aria-labelledby="nav-user-analytic-tab"
                  tabindex="0"
                >
                  <div className="grid grid-cols-1 md:grid-cols-2 pt-4 lg:pt-10 xl:pt-0">
                    <div className="text-left flex flex-col justify-center">
                      <div className="pl-4 lg:pl-10 pr-3 py-4">
                        <h4 className="text-warning">User Analytics</h4>
                        <p className="text-sm text-gray-400">
                          Our analytics tools help you track and analyse card
                          usage, identify trends, and understand how users
                          interact with their prepaid programs. This approach
                          enables businesses to optimise their card offerings,
                          tailor reward programs, and make informed decisions
                          based on user preferences.
                        </p>
                        <p className="text-sm text-gray-400">
                          By leveraging these insights, you can enhance user
                          experiences, improve engagement, and drive better
                          financial outcomes.
                        </p>
                        {/* <div className="flex justify-start">
                          <Link to="/corporate">
                            <button className="bg-white text-black align-self-start border-0 p-1 rounded px-3">
                              Know More{" "}
                              <i className="bi bi-arrow-right fw-bold"></i>
                            </button>
                          </Link>
                        </div> */}
                      </div>
                    </div>
                    <div className="col text-center grid place-items-center">
                      <img src={bassBanner1} className="w-3/5" />
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-user-friendly"
                  role="tabpanel"
                  aria-labelledby="nav-user-friendly-tab"
                  tabindex="0"
                >
                  <div className="grid grid-cols-1 md:grid-cols-2 pt-4 lg:pt-10 xl:pt-0">
                    <div className="text-left flex flex-col justify-center">
                      <div className="pl-4 lg:pl-10 pr-3 py-4">
                        <h4 className="text-warning">User Friendly</h4>
                        <p className="text-sm text-gray-400">
                          Our intuitive interface ensures businesses can set up
                          and oversee their prepaid solutions without requiring
                          extensive technical expertise. The platform’s
                          user-friendly design simplifies tasks such as
                          card/wallet issuance, transaction monitoring, and
                          reporting, making it accessible for users of all
                          technical backgrounds.
                        </p>
                        <p className="text-sm text-gray-400">
                          Our prepaid card app also makes it easy for employees
                          to access all the benefits while the limitations
                          prevent misuse.
                        </p>
                        {/* <div className="flex justify-start">
                          <Link to="/corporate">
                            <button className="bg-white text-black align-self-start border-0 p-1 rounded px-3">
                              Know More{" "}
                              <i className="bi bi-arrow-right fw-bold"></i>
                            </button>
                          </Link>
                        </div> */}
                      </div>
                    </div>
                    <div className="col text-center grid place-items-center">
                      <img src={ko4} />
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-cashback"
                  role="tabpanel"
                  aria-labelledby="nav-cashback-tab"
                  tabindex="0"
                >
                  <div className="grid grid-cols-1 md:grid-cols-2 pt-4 lg:pt-10 xl:pt-0">
                    <div className="text-left flex flex-col justify-center">
                      <div className="pl-4 lg:pl-10 pr-3 py-4">
                        <h4 className="text-warning">
                          Cashback and AI-Driven Rewards
                        </h4>
                        <p className="text-sm text-gray-400">
                          Our cashback programs offer a customisable, instantly
                          scalable solution that requires zero integration.
                          Businesses can create unique networks of brands,
                          tailor rewards to their specific use case, and manage
                          it all through our merchant portal. You can also
                          integrate instant cashback rewards into your prepaid
                          card offerings, giving users immediate incentives on
                          purchases.
                        </p>
                        <p className="text-sm text-gray-400">
                          Also, our AI technology analyses user spending habits
                          to deliver personalised rewards, enhancing
                          satisfaction and providing you with insights to
                          optimise your card programs.
                        </p>
                        {/* <div className="flex justify-start">
                          <Link to="/corporate">
                            <button className="bg-white text-black align-self-start border-0 p-1 rounded px-3">
                              Know More{" "}
                              <i className="bi bi-arrow-right fw-bold"></i>
                            </button>
                          </Link>
                        </div> */}
                      </div>
                    </div>
                    <div className="col text-center grid place-items-center">
                      <img src={ko5} />
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="nav-logistic-ecosystem"
                  role="tabpanel"
                  aria-labelledby="nav-logistic-ecosystem-tab"
                  tabindex="0"
                >
                  <div className="grid grid-cols-1 md:grid-cols-2 pt-4 lg:pt-10 xl:pt-0">
                    <div className="text-left flex flex-col justify-center">
                      <div className="pl-4 lg:pl-10 pr-3 py-4">
                        <h4 className="text-warning">Logistics Ecosystem</h4>
                        <p className="text-sm text-gray-400">
                          Our end-to-end solution ensures that businesses can
                          focus on growth and strategy while we take care of the
                          operational complexities. By managing the logistical
                          aspects of prepaid card programs, Zokudo helps
                          businesses streamline their financial operations,
                          reduce administrative burdens, and maintain control
                          over their card programs.
                        </p>
                        <p className="text-sm text-gray-400">
                          Zokudo is an efficient solution for businesses looking
                          to implement and manage prepaid instruments with
                          minimal hassle.
                        </p>
                        {/* <div className="flex justify-start">
                          <Link to="/corporate">
                            <button className="bg-white text-black align-self-start border-0 p-1 rounded px-3">
                              Know More{" "}
                              <i className="bi bi-arrow-right fw-bold"></i>
                            </button>
                          </Link>
                        </div> */}
                      </div>
                    </div>
                    <div className="col text-center grid place-items-center">
                      <img src={ko6} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="container">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0 }}
            viewport={{ once: true }}
            className="flex flex-col text-center gap-10 mt-5"
          >
            <button className="m-auto solution-btn text-warning fs-600 align-self-start p-2 rounded mb-3">
              Customers
            </button>
            <h3 className="text-warning font-semibold"> Our SaaS Clients </h3>
            <p className="text-gray-500 mx-auto text-sm md:w-4/5 xl:w-3/5 2xl:w-2/5">
              Trusted by businesses across various industries, our technology
              helps enhance your financial operations, improve customer
              engagement, and drive growth.
            </p>
          </motion.div>
        </div>

        <div className="container">
          <div className="md:w-4/5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[30px] mx-auto mt-4">
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 1 * 0.1 }}
              viewport={{ once: true }}
              className="bg-[#F7F7F7] p-4 rounded-xl shadow-md"
            >
              <img className="object-contain" src={jifyImg} />
              <p className="text-sm text-gray-500 text-left mt-4 mb-0">
              Jify is building employee financial wellness via earned salary access and better money management tools.
              Zokudo Powers the Jify Salary Card product through our <span className="text-[#E4C221]">CMS and API stack</span>
              </p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 2 * 0.1 }}
              viewport={{ once: true }}
              className="bg-[#F7F7F7] p-4 rounded-xl shadow-md"
            >
              <img className="object-contain" src={advantageClubImg} />
              <p className="text-sm text-gray-500 text-left mt-4 mb-0">
              Zokudo powers AdvantageClub’s semi-closed loop settlement network so that they can expand their One-Click payment network to companies currently outside their RnR platform
              </p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 3 * 0.1 }}
              viewport={{ once: true }}
              className="bg-[#F7F7F7] p-4 rounded-xl shadow-md"
            >
              <img className="object-contain" src={vergadoLogo} />
              <p className="text-sm text-gray-500 text-left mt-4 mb-0">
              Vergado focuses on simplifying employee finance with automated solutions for payroll, benefits, tax savings, and expenses using Zokudo’s <span className="text-[#E4C221]">Reloadable Cards and JIT Funding</span> stack. They enhance efficiency, transparency, and convenience, letting employees focus on their core roles.
              </p>
            </motion.div>
          </div>
        </div>
      </div>

      <div className="container pt-10 pb-0 md:py-10 lg:py-20 px-3 overflow-hidden">
        <motion.h3
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0 }}
          viewport={{ once: true }}
          className="text-warning font-semibold"
        >
          Zokudo’s Prepaid Ecosystem Capabilities
        </motion.h3>
        <motion.p
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0 }}
          viewport={{ once: true }}
          className="text-center text-gray-500 text-sm lg:text-md md:w-3/5 lg:w-2/5 mx-auto"
        >
          Explore how our integrated capabilities can transform your financial
          operations and elevate your prepaid card strategies, all while
          ensuring security, efficiency, and scalability.
        </motion.p>

        <motion.div
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0 }}
          viewport={{ once: true }}
          class="overflow-x-auto"
        >
          <table className="min-w-full service-table mt-2 md:mt-5">
            <tr className="text-left text-[12px] rounded-[10px] md:rounded-[20px] font-medium">
              <td className="bg-warning rounded-tl-[10px] md:rounded-tl-[30px] px-8">
                PPI Type
              </td>
              <td className="bg-warning normal-child">
                Issuance Physical/ Virtual/ Wallet
              </td>
              <td className="bg-warning normal-child">
                Distribution Online + Retail
              </td>
              <td className="bg-warning normal-child">
                Technology Processing Platform
              </td>
              <td className="bg-warning rounded-tr-[30px] normal-child">
                Settlement
              </td>
              <td className="bg-warning special-child rounded-tr-[10px]">
                Issuance to Settlement
              </td>
            </tr>
            <tr className="text-sm text-gray-500">
              <td className="text-left">Closed Loop</td>
              <td>
                {" "}
                <img src={tick} />{" "}
              </td>
              <td>
                {" "}
                <img src={tick} />{" "}
              </td>
              <td>
                {" "}
                <img src={tick} />{" "}
              </td>
              <td>
                {" "}
                <img src={tick} />{" "}
              </td>
            </tr>
            <tr className="bg-[#F8F0D2] text-sm text-gray-500">
              <td className="text-left rounded-l-[10px] md:rounded-l-[35px]">
                Semi-Closed Loop
              </td>
              <td className="md:rounded-r-[0px] rounded-r-[10px]">
                {" "}
                <img src={tick} />{" "}
              </td>
              <td>
                {" "}
                <img src={tick} />{" "}
              </td>
              <td>
                {" "}
                <img src={tick} />{" "}
              </td>
              <td className="rounded-r-[35px]">
                {" "}
                <img src={tick} />{" "}
              </td>
            </tr>
            <tr className="text-sm text-gray-500">
              <td className="text-left rounded-l-[35px]">
                Interoperable Open Loop
              </td>
              <td className="md:rounded-r-[0px] rounded-r-[10px]">
                {" "}
                <img src={tick} />{" "}
              </td>
              <td>
                {" "}
                <img src={tick} />{" "}
              </td>
              <td>
                {" "}
                <img src={tick} />{" "}
              </td>
              <td className="rounded-r-[35px]">
                {" "}
                <img src={tick} />{" "}
              </td>
            </tr>
            <tr className="bg-[#F8F0D2] text-sm text-gray-500">
              <td className="text-left rounded-l-[10px] md:rounded-l-[35px]">
                Interoperable UPI (WIP)
              </td>
              <td className="md:rounded-r-[0px] rounded-r-[10px]">
                {" "}
                <img src={tick} />{" "}
              </td>
              <td>
                {" "}
                <img src={tick} />{" "}
              </td>
              <td>
                {" "}
                <img src={tick} />{" "}
              </td>
              <td className="rounded-r-[35px]">
                {" "}
                <img src={tick} />{" "}
              </td>
            </tr>
            <tr className="text-sm text-gray-500">
              <td className="text-left rounded-l-[35px]">NCMC + Wearables</td>
              <td className="md:rounded-r-[0px] rounded-r-[10px]">
                {" "}
                <img src={tick} />{" "}
              </td>
              <td>
                {" "}
                <img src={tick} />{" "}
              </td>
              <td>
                {" "}
                <img src={tick} />{" "}
              </td>
              <td className="rounded-r-[35px]">
                {" "}
                <img src={tick} />{" "}
              </td>
            </tr>
            <tr className="bg-[#F8F0D2] text-sm text-gray-500">
              <td className="text-left rounded-l-[10px] md:rounded-l-[35px]">
                FASTTag (WIP)
              </td>
              <td className="md:rounded-r-[0px] rounded-r-[10px]">
                {" "}
                <img src={tick} />{" "}
              </td>
              <td>
                {" "}
                <img src={tick} />{" "}
              </td>
              <td>
                {" "}
                <img src={tick} />{" "}
              </td>
              <td className="rounded-r-[35px]">
                {" "}
                <img src={tick} />{" "}
              </td>
            </tr>
          </table>
        </motion.div>
      </div>

      <Collaborate />

      <div className="container-fluid mt-5 faq-banner">
        <div className="container py-5 px-0 md:px-initial">
          <div className="d-flex flex-column align-items-center">
            <h6 className="text-gray-300 bg-[#151515] py-3 px-4 rounded">
              FAQ
            </h6>
            <h3 className="text-warning">Frequently Asked Questions</h3>
            <p className="text-gray-400 md:w-2/5 m-auto">
              Confused? Find answers to all your queries below, or get in touch
              if you have a specific question in mind.
            </p>
          </div>

          <div
            className="accordion accordion-container w-full md:w-[80%] bg-black text-white p-4 text-left rounded-xl py-5 mt-4"
            id="accordionExample"
          >
            <h5 className="py-2 md:py-4">
              You have questions? We have answers.
            </h5>
            {/* Accordion Item 1 */}
            <div className="accordion-item mt-3">
              <h2 className="accordion-header">
                <button
                  className="accordion-button fw-bold border-0"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <li> How quickly can I be taken Live?</li>
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Naturally, Go-Live times will vary depending on the level of
                  customization desired and other external factors such as
                  approvals and dispatch times with printers. But rest assured,
                  we have logistics and printing covered to get you up and
                  running within a month.
                </div>
              </div>
            </div>

            {/* Accordion Item 2 */}
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed fw-bold"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  <li>How will the JIT funding work?</li>
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Just-in-Time (JIT) funding allows you to access funds
                  instantly without the need to maintain a minimum balance. When
                  you require additional funds, they can be accessed
                  immediately, enabling efficient cash flow management and
                  flexibility in your financial operations. Reach out to us for
                  more details on how JIT funding can be used to streamline your
                  payments flow.
                </div>
              </div>
            </div>

            {/* Accordion Item 3 */}
            <div className="accordion-item ">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed fw-bold "
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  <li> What is the modularity of the platform?</li>
                </button>
              </h2>
              <div
                id="collapseThree"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Our platform is designed to be highly modular, allowing you to
                  customise your offerings according to your needs. You can
                  start with basic card functionality and easily integrate
                  additional features such as wallet capabilities and UPI
                  transactions on the same product stack, providing a
                  comprehensive solution that grows with your business.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BaasSolution;
