import React, { useState } from "react";
import axios from "axios";

export default function Form() {
  let [formData, setFormData] = useState({
    name: "",
    email: "",
    mobileNo: "",
    subject: "",
    category: "",
    message: "",
  });

  const [responseMessage, setResponseMessage] = useState("");
  const [error, setError] = useState("");

  const handleChange = (e) => {
    let { name, value } = e.target;
    
    if (name === "mobileNo") {
      let sanitizedData = value;

      sanitizedData = sanitizedData.replace(/\D/g, "");
      if ((sanitizedData.length > 10)) {
        sanitizedData = sanitizedData.slice(0,10);
      } 

      setFormData((prevState) => ({
        ...prevState,
        [name]: sanitizedData,
      }));
      return;
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const result = await axios.post(
        "https://admin.zokudo.com/customer-service/appuser/api/v1/contactUs",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true, // For cookies or sessions if required
        }
      );

      setResponseMessage("We have received your inquiry, and our team will get in touch with you soon!");
      setError(""); // Clear any previous errors
      setFormData({
        name: "",
        email: "",
        mobileNo: "",
        subject: "",
        category: "",
        message: ""
      });
    } catch (err) {
      setError(err.response.data.details);
      setResponseMessage(""); // Clear any previous success messages
    }
  };

  return (
    <form
      className="d-flex flex-column align-item-center gap-2 form"
      onSubmit={handleSubmit}
    >
      <p className="font-medium text-md text-left mb-2 capitalize">
        Reach out to us/Raise a Complaint
      </p>
      <input
        className="form-control input-field"
        type="text"
        placeholder="Your Name"
        name="name"
        value={formData.name}
        onChange={handleChange}
      />
      <input
        className="form-control input-field"
        type="email"
        placeholder="Your email"
        name="email"
        value={formData.email}
        onChange={handleChange}
      />
      <input
        className="form-control input-field"
        type="text"
        placeholder="Mobile Number"
        name="mobileNo"
        value={formData.mobileNo}
        onChange={handleChange}
      />
      <input
        className="form-control input-field"
        type="text"
        placeholder="Subject"
        name="subject"
        value={formData.subject}
        onChange={handleChange}
      />
      <select
        className="form-control input-field"
        name="category"
        value={formData.category}
        onChange={handleChange}
      >
        <option value="" disabled>
          Select Category
        </option>
        <option value="Corporate Solution">Corporate Solution</option>
        <option value="BaaS Solution">Baas Solution</option>
        <option value="Sales Enquiry">Sales Enquiry</option>
        <option value="Application Support">Application Support</option>
        <option value="Tech Support">Tech Support</option>
        <option value="Lodge a Complaint">Lodge a Complaint</option>
        <option value="Others">Others</option>
      </select>
      <textarea
        className="form-control input-field"
        placeholder="Message"
        name="message"
        value={formData.message}
        onChange={handleChange}
      />

      <button type="submit" className="btn btn-warning">
        Send
      </button>

      {responseMessage && <p className="text-success">{responseMessage}</p>}
      {error && <p className="text-danger">{error}</p>}
    </form>
  );
}
