import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { FiUpload } from "react-icons/fi";
import axios from "axios";

export default function ApplyForCareerModal({ show, close }) {
  const [formData, setFormData] = useState({
    customerFullName: "",
    email: "",
    mobileNo: "",
    positionForApplying: "",
    resume: null,
    coverLetter: null,
    message: "",
    reasonForJoining: "",
    linkedInProfile: "",
    jobDescription: "",
  });

  const [responseMessage, setResponseMessage] = useState("");
  const [error, setError] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  // Handle input changes for text and select fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle file input changes
  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: files[0],
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent page reload

    const data = new FormData();
    Object.keys(formData).forEach((key) => {
      if(formData[key]){
        data.append(key, formData[key]);
      }
    });

    try {
      const result = await axios.post(
        "https://admin.zokudo.com/customer-service/appuser/api/v1/jobDescription",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setResponseMessage("Your application has been submitted successfully!");
      setError(""); // Clear any previous errors
      setIsSubmitted(true); // Disable the button after successful submission
    } catch (err) {
      setError(err.response?.data?.details || "Something went wrong! Please try again.");
      setResponseMessage(""); // Clear any previous success messages
      setIsSubmitted(false); // Keep the button enabled for retries
    }
  };

  // Handle page refresh
  const handlePageRefresh = () => {
    window.location.reload(); // Refresh the page
  };

  return (
    <div
      className={`modal fade show apply-job ${show ? "block" : "hidden"}`}
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-[#FFFDF8] p-8 relative">
          {/* Close Button */}
          <IoMdClose
            className="absolute top-4 right-4 cursor-pointer"
            onClick={close}
          />

          {/* Modal Header */}
          <p className="text-lg font-medium text-gray-800 mb-1">
            Apply for a Career at Zokudo
          </p>
          <p className="text-gray-400 mb-6 text-sm w-4/5 mx-auto font-light">
            Join our innovative Fintech team! Please fill out the form below to
            apply for exciting opportunities with us.
          </p>

          {/* Form */}
          <div className="bg-white p-4 w-[94%] rounded-lg mx-auto border border-gray-200">
            <form className="space-y-4 text-left" onSubmit={handleSubmit}>
              {/* Full Name and Email */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="flex flex-col">
                  <label className="text-sm font-medium text-gray-700 mb-1">
                    Full Name*
                  </label>
                  <input
                    type="text"
                    name="customerFullName"
                    value={formData.customerFullName}
                    onChange={handleChange}
                    placeholder="Enter Full Name"
                    className="border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                    required
                  />
                </div>
                <div className="flex flex-col">
                  <label className="text-sm font-medium text-gray-700 mb-1">
                    Email Address*
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Enter Email Address"
                    className="border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                    required
                  />
                </div>
              </div>

              {/* Phone Number and Position */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="flex flex-col">
                  <label className="text-sm font-medium text-gray-700 mb-1">
                    Phone Number*
                  </label>
                  <input
                    type="tel"
                    name="mobileNo"
                    maxLength={10}
                    value={formData.mobileNo}
                    onChange={handleChange}
                    placeholder="Enter Phone Number"
                    className="border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                    required
                  />
                </div>
                <div className="flex flex-col">
                  <label className="text-sm font-medium text-gray-700 mb-1">
                    Position Applying For*
                  </label>
                  <select
                    name="positionForApplying"
                    value={formData.positionForApplying}
                    onChange={handleChange}
                    className="border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                    required
                  >
                    <option value="">Select job</option>
                    <option>Frontend Developer</option>
                    <option>Backend Developer</option>
                    <option>Operation Executive</option>
                    <option>Operation Manager</option>
                    <option>Operation</option>
                    <option>Sales</option>
                    <option>Full-Stack Developer</option>
                    <option>QA</option>
                    <option>Flutter Developer</option>
                  </select>
                </div>
              </div>

              {/* Resume and Cover Letter */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="flex flex-col relative">
                  <label className="text-sm font-medium text-gray-700 mb-1">
                    Upload Resume*
                  </label>
                  <div className="bg-[#FDF4DE] custom-file-input rounded-lg h-[80px] relative grid place-items-center">
                    <input
                      type="file"
                      name="resume"
                      onChange={handleFileChange}
                      accept=".docx,.pdf"
                      className="border h-[80px] z-40 w-full border-gray-300 rounded-lg absolute top-0 left-0 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-yellow-400 opacity-0"
                      required
                    />
                    <div className="flex gap-2 opacity-50 relative z-10">
                      {formData.resume ? (
                        <p className="mb-0">{formData.resume.name}</p>
                      ) : (
                        <>
                          <FiUpload className="mt-1" />
                          <p className="mb-0">Upload your Resume</p>
                        </>
                      )}
                    </div>
                  </div>
                  <p className="text-[12px] text-gray-400">
                    Upload your resume as docx or pdf with a maximum size of 10
                    MB.
                  </p>
                </div>
                <div className="flex flex-col relative">
                  <label className="text-sm font-medium text-gray-700 mb-1">
                    Upload Cover Letter (Optional)
                  </label>
                  <div className="bg-[#FDF4DE] custom-file-input rounded-lg h-[80px] relative grid place-items-center">
                    <input
                      type="file"
                      name="coverLetter"
                      accept=".docx,.pdf"
                      onChange={handleFileChange}
                      className="border z-40 h-[80px] w-full border-gray-300 rounded-lg absolute top-0 left-0 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-yellow-400 opacity-0"
                    />
                    <div className="flex gap-2 opacity-50 relative z-10">
                      {formData.coverLetter ? (
                        <p className="mb-0">{formData.coverLetter.name}</p>
                      ) : (
                        <>
                          <FiUpload className="mt-1" />
                          <p className="mb-0">Upload Cover Letter</p>
                        </>
                      )}
                    </div>
                  </div>
                  <p className="text-[12px] text-gray-400">
                    Upload your letter as docx or pdf with a maximum size of 10
                    MB.
                  </p>
                </div>
              </div>

              {/* Comments and Why Zokudo */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="flex flex-col">
                  <label className="text-sm font-medium text-gray-700 mb-1">
                    Additional Comments (Optional)
                  </label>
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Enter Your Comments"
                    className="border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                    rows="3"
                  ></textarea>
                </div>
                <div className="flex flex-col">
                  <label className="text-sm font-medium text-gray-700 mb-1">
                    Why do you want to join Zokudo?
                  </label>
                  <textarea
                    name="reasonForJoining"
                    value={formData.reasonForJoining}
                    onChange={handleChange}
                    placeholder="Enter your brief statement"
                    className="border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                    rows="3"
                    required
                  ></textarea>
                </div>
              </div>

              {/* Job Description */}
              <div className="flex flex-col">
                <label className="text-sm font-medium text-gray-700 mb-1">
                  Job Description
                </label>
                <input
                  name="jobDescription"
                  value={formData.jobDescription}
                  onChange={handleChange}
                  placeholder="Enter Job Description"
                  className="border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                />
              </div>

              {/* LinkedIn Profile */}
              <div className="flex flex-col">
                <label className="text-sm font-medium text-gray-700 mb-1">
                  LinkedIn Profile (Optional)
                </label>
                <input
                  type="url"
                  name="linkedInProfile"
                  value={formData.linkedInProfile}
                  onChange={handleChange}
                  placeholder="Enter LinkedIn Profile URL"
                  className="border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                />
              </div>

              {/* Submit and Close Buttons */}
              <div className="flex justify-between items-center">
                <button
                  type="submit"
                  className={`w-[120px] bg-yellow-400 text-black font-normal py-2 rounded-lg transition ${
                    isSubmitted
                      ? "cursor-not-allowed opacity-50"
                      : "hover:bg-yellow-500"
                  }`}
                  disabled={isSubmitted}
                >
                  {isSubmitted ? "Submitted" : "Send"}
                </button>
                <button
                  type="button"
                  onClick={handlePageRefresh}
                  className="w-[120px] bg-gray-400 text-black font-normal py-2 rounded-lg transition hover:bg-gray-500"
                >
                  Close
                </button>
              </div>
            </form>

            {/* Success or Error Message */}
            {responseMessage && (
              <p className="text-green-500 text-center mt-4">
                {responseMessage}
              </p>
            )}
            {error && <p className="text-red-500 text-center mt-4">{error}</p>}
          </div>
        </div>
      </div>
    </div>
  );
}
