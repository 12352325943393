import React, { useState, useRef, useEffect } from "react";
import "../style/log-in.css";

import loginBg from "../assets/login-bg.svg";
import axios from "axios";
import JSEncrypt from "jsencrypt";

import CryptoJS from "crypto-js";

import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import { checkSession } from "../helper";
function LogIn() {
  const recaptcha = useRef();

  const [formData, setFormData] = useState({
    password: "",
    userName: "",
  });
  const navigate = useNavigate();
  const saveSessionData = (email) => {
    const secretKey = "mySecretKey";
    const encryptedData = CryptoJS.AES.encrypt(email, secretKey).toString();

    sessionStorage.setItem("email", email);
    sessionStorage.setItem("token", encryptedData);
  };

  var encryptionEngine = new JSEncrypt();

  const [encryptedText, setEncryptedText] = useState("");
  const [error, setError] = useState("");
  const [isChekingSession, setIsChekingSession] = useState(true);
  const handleChange = (e) => {
    let { name, value } = e.target;

    if (name == "password") {
      encryptionEngine.setPublicKey(
        "MIIFIjANBgkqhkiG9w0BAQEFAAOCBQ8AMIIFCgKCBQEA0CmZp9kzU1vJ7NnaEiQqcnIKlAmB7mLjn22f5dfbHPzRrT9PPECkyYESwzUfcCWGH6NcTZrPE9r0I8TmgU1NHvACVEQEoSbVx2nrdbpyJ+C/RXoJUv4jDrnB1UsL9lYnFFakapJMr06CzbcDqpjVkReQffV8Sa1xRRbZmbHxS62MPxLFNzKPlL3LjHhA32LzQsl5QqcgDlItuAbURIAlDA6UgzvgGLLyM2sgvnnlFKhGFGfdT/eldnxB35zISt4vpPAaHUOi9CxhkggxpHpzixvhe7XNZJSsL+/1+r7WxmLNCpQEDZ6+bXI0jYwxW6fKV6+sPHtiU8jru8hQBrIwMnGYotGkgRJ4E/mSr+XSiiRhDY2EWF2XqxqeCp01+lDkHJjAPFU4vwG6ANfJQQUEYRi9dkP/iqMx9UnITVFxZaZEF2KVBf8rIVy0catLj+4fgr4wWYhDv7qFnE99sQJgtFPAT4YqDjseG3keJiz5WUMTwhlt0ERXRwVsAI/jmoG1+B5cGgMT3lS8B33ekjw/xT7TfzWMgoMrS8YfAIFlFHvJwaSz8ewFSx2YpX/+oFitoiHtEPq61EKwDH977nUiRfkQvjerkIma/DeErwBLTssIrwdvUsfUF7CSRc0bvfK/Jl7/BGqCOfput9r3IzNpNZwSQA/SZ3xvWGTscV0a3w8UTivSUbkJQdABMRRVuKYCZl6l1/MMeGAfFsqbUHCKT/upkEzdns44WryhTXF4sImH8BHISRk7NE/erZ7sFTcjKeAh0TajGQrbGo6hy0ilRYId2eTt12EPvMiTY3cywHOH6binv37PEr1U6se18kjkjddODbb2zPAoaXrNIqtuB52/dja3lzp3r0Z+iww4wuP4F9HbIZkXax2zzm8uJ6Qw/RDC/6SaeXWtkwPJq1enpCV5/A8qLYp4bRi9LpNzlqeKcRCoqK1aEADr9foNinDHLYS5tvAuN35JZ5das5HM9GAdYe3PIuLygpQDgSpVOWvPDd3g2jhiVBmxYdRT8G3bXBsI1bCsgc7Onor5Zyk3VielDYPtgutedJj0tnYQ43ODJ0B+cDcpgS+vnUwQ+SBj6CQsF4hNwYQI+74d1I4FUeTmOaFYkOB7HHd6XTzVYnMC9oo831bsr4+c/jvOEABqwiG7cwLxc2u66MN7xkj2iOq1k2EXoWh5rPqH+K8Q4X7ssdVmsNUuXvut6fx9CdHxIFfeK84RtPzf06gaiZQStrjQTK5/lyKHws3H891JIzmBNYX4RBWYiAL6zQT66HHrK855mpsYBDD8XY/4A3KdbTXtyHTkOD41I83x8q/eEhkdBlqdaSuYfdQ8h1vplOFlBSwmHvY238s/eduhXp27hEgUDKFV03zpVSZrM5DO8riCKJhlLTShO7DddFv3FiY/EDAhRMMxH2HDXDQ1L6Exn3Bwc3P5UWZEsgtG6ZEHy6PZ+eZ5DVCRmXXUTSau6y0SIUC7b+p66JQ3zNGINsTqP/G/pt9CxYOH1LKmhvRCHzFfRa67ogIwH95DRlhQHdCcZkQuo3en+te2t58EY83fPsW2gfcQXxSyez8B/CrY6hOq3EiLk5eS91/IC8VR49DlQ94CfZEFtjUqTdKVRuRCEkWCRWn72YmKUehZs2i3uKArKTaPyDbdq3UnpvySBi6w0H5rZ1ogHRb4ncrHEBX49BPy83rIMLT/pcdOjFAWCN0CAwEAAQ=="
      );
      var encryptedData = encryptionEngine.encrypt(value);
      value = encryptedData;
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  useEffect(() => {
    if (checkSession()) {
      navigate("/");
    }
    setIsChekingSession(false);
  }, []);
  if (isChekingSession) {
    return <div>Loading...</div>;
  }
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent page reload

    console.log("captchaValue 0");
    const captchaValue = recaptcha.current.getValue();

    console.log("captchaValue ", captchaValue);

    if (!captchaValue) {
      alert("Please verify the reCAPTCHA!");
      return;
    }

    try {
      const response = await axios.post(
        "https://admin.zokudo.com/product-service/mss/api/v1/product/sendOTP",
        // "http://localhost:9090/product-service/mss/api/v1/product/login",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("response ", response);

      // Check response status
      if (response.data.status === "OK") {
        saveSessionData(formData.userName);
        setError("");
        // localStorage.setItem("email",formData.userName);
        // localStorage.setItem("token",encryptionEngine.encrypt(formData.userName));
        window.location.pathname = "/job-postings";
      }
    } catch (err) {
      setError(err.response.data.message);
    }
  };

  return (
    <div>
      <section className="container mb-20 mt-[140px]">
        <div className="login-wrapper md:w-4/5 m-auto rounded-xl d-flex flex-column flex-md-row justify-content-center">
          <div className="col-12 col-md-6 grid place-items-center ">
            <img className="md:w-[90%] m-auto" src={loginBg} />
          </div>
          <div className="login col-12 col-md-6 text-left bg-white py-5 px-4 rounded border border-gray-400">
            <h4 className="font-bold">Welcome Back!</h4>
            <p className="text-gray-500">
              Please enter your details to proceed with the platform.
            </p>
            <form
              onSubmit={handleSubmit}
              className="d-flex flex-column align-item-center gap-2 form p-0"
            >
              <input
                className="form-control input-field mb-4"
                type="text"
                placeholder="Enter User Name"
                name="userName"
                onChange={handleChange}
              />
              <input
                className="form-control input-field"
                type="password"
                placeholder="Enter Password"
                name="password"
                onChange={handleChange}
              />

              <ReCAPTCHA
                className="mt-3"
                ref={recaptcha}
                sitekey="6Ld9XBMnAAAAAE_GeS7Dx5gttTYvFlG8IVNpFiJK"
              />

              <p className="text-danger text-end">{error}</p>
              <button
                className="btn btn-warning md:
              py-3"
              >
                Login
              </button>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}

export default LogIn;
