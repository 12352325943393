import React, {useState} from "react";
import "../style/term-and-condition.css";
import NavbarV2 from "./NavbarV2";
import Footer from "./common/Footer";

import languageTranslate from "../assets/language-translate.svg";
import { AiFillCaretRight } from "react-icons/ai";
import TermsTable1 from "./ui/TermsTable1";
import TermsTable2 from "./ui/TermsTable2";
import TermsTable3 from "./ui/TermsTable3";
import GPCardsTerms from "./ui/GPCardsTerms";
import LanguageDropdown from "./ui/LanguageDropdown";

function TermAndCondition() {
  const fees = [
    { service: "Card Issuance Fee", charges: "Rs. 250 (Excluding Taxes)" },
    { service: "Card Replacement Fees", charges: "Rs. 250 (Excluding Taxes)" },
    {
      service: "Card Block / Unblocking Fee",
      charges: "Rs. 50 (Excluding Taxes)",
    },
    { service: "Card Closure Fees", charges: "Rs. 100 (Excluding Taxes)" },
    { service: "Account Inactive Fee", charges: "Rs. 50 (Excluding Taxes)" },
    { service: "Annual Fee", charges: "Rs. 100 (Excluding Taxes)" },
    { service: "Registration fee", charges: "NIL" },
    { service: "Issuance fees", charges: "NIL" },
    { service: "Loading fees", charges: "NIL" },
    { service: "Plastic card fees", charges: "INR 200 per card" },
    { service: "Lost card fees", charges: "INR 200 per card" },
    { service: "Lost PIN fees", charges: "NIL" },
    { service: "Reissuance of digital card", charges: "NIL" },
    { service: "Refund fees", charges: "NIL" },
  ];


  
  return (
    <div className="mt-[40px] md:mt-[80px] terms-page">
      <div className="container py-4">
        <div className="row mt-5 flex-column flex-md-row">
          <div className="col-12 col-md-6 text-left">
            <h3 className="text-warning">Terms & Conditions</h3>
          </div>
          <div className="col-12 col-md-6 d-flex justify-content-md-end my-3 m-md-0">
            <LanguageDropdown/>
          </div>
        </div>
        <section>
          <div class="d-flex flex-column gap-2">
            <div
              class="d-flex gap-2"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <button
                class="custom-btn lg:min-w-[300px] active"
                id="v-pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-home"
                type="button"
                role="tab"
                aria-controls="v-pills-home"
                aria-selected="true"
              >
                Gift Card
              </button>
              <button
                class="custom-btn custom-btn2 lg:min-w-[300px]"
                id="v-pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-profile"
                type="button"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected="false"
              >
                General Purpose Reloadable Card
              </button>
            </div>
            <div
              class="text-gray-500 text-sm tab-content border rounded-xl p-4"
              id="v-pills-tabContent"
            >
              <div
                class="tab-pane fade show active text-start"
                id="v-pills-home"
                role="tabpanel"
                aria-labelledby="v-pills-home-tab"
                tabindex="0"
              >
                <h6 className="text-warning flex gap-1">
                  {" "}
                  <AiFillCaretRight className="text-[#E4C221]" />
                   Gift Card Terms & Conditions{" "}
                </h6>
                <p>
                  This Zokudo Prepaid Gift Card (the “Card”) is issued to you
                  (the “Cardholder”) by Premium eBusiness Venture Private
                  Limited (“PEV”), a company incorporated under the Companies
                  Act, 1956 having its Registered and Corporate office at 42,
                  Navketan Industrial Estate, Mahakali Caves Road, Andheri
                  (East), Mumbai - 400093.{" "}
                </p>
                <p>
                  Terms & Conditions : These terms and conditions (“Terms”)
                  govern usage of the Card held by the Cardholder and issued by
                  PEV. These Terms, read with the privacy policy (
                  https://www.zokudo.com/privacy-policy ) and the customer
                  grievance redressal policy (
                  https://www.zokudo.com/customer-grievance-redressal ) and
                  additional terms (if any) on the Application Form, form the
                  entire contract between the Cardholder and PEV for usage of
                  the Card. We urge you to go through the website (
                  www.zokudo.com ) or contact PEV at 8655000444.{" "}
                </p>
                <p>
                  The Cardholder hereby agrees to abide by the Terms and the
                  Cardholder shall be deemed to have unconditionally agreed to
                  and accepted the Terms by performing a transaction with the
                  Card or acknowledging receipt of the Card in writing or by
                  signing on the reverse of the Card (as may be applicable) and
                  shall also be deemed to have read, understood and agreed to
                  all the Terms. The Cardholder shall be responsible for keeping
                  himself/herself updated with any changes/ amendments in the
                  relevant notifications/ guidelines/circulars governing usage
                  of the Card. PEV disclaims all liability on account of any
                  breach by the Cardholder of the relevant
                  notifications/guidelines/circulars governing usage of the
                  Cards in force and from time to time. If the Terms are not
                  acceptable, the Cardholder must not use the Card and
                  immediately return the Card to PEV.{" "}
                </p>
                <p>
                  These Terms have been framed in line with the Reserve Bank of
                  India’s (“RBI”) guidelines (including but not limited to the
                  Master Direction on Issuance and Operation of Prepaid Payment
                  Instruments dated December 29, 2017, as amended from time to
                  time) and basis PEV’s policies. The Terms will help in
                  understanding usage of the Card and associated terms and
                  features.{" "}
                </p>
                <p>
                  Accessing Card Account Detail : Information concerning the
                  Card, including the available balance (“Card Balance”) in the
                  Card Account may be accessed after due log-in to the Zokudo
                  App or may be accessed by calling/writing to the Customer Care
                  Centre at the phone number and/or email address listed on the
                  back of your Card and also reproduced in these Terms and
                  available on the Website.{" "}
                </p>

                <div class="accordion tnc-accordion" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        1.Definitions
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse show"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p>
                          In these Terms, unless the contrary intention appears
                          and/or the context otherwise requires, capitalized
                          terms defined by: (i) inclusion in quotations and/ or
                          parenthesis have the meanings so ascribed; and (ii)
                          the following terms shall have the meanings assigned
                          to them herein below:{" "}
                        </p>
                        <ol class="list-decimal">
                          <li>
                            <strong>Card Account</strong>: Refers to a prepaid
                            account with a balance equal to the amount loaded on
                            the Card. It's used to track spending limits.
                          </li>
                          <li>
                            <strong>Application Form</strong>: The form
                            submitted by you (the Cardholder) to PEV to apply
                            for the Card. It includes all necessary information,
                            details, and any declarations you provide.
                          </li>
                          <li>
                            <strong>Card</strong>: A Prepaid Payment Instrument
                            (PPI) issued as a physical or virtual card. It's a
                            gift instrument used to purchase goods and services,
                            as defined by regulations.
                          </li>
                          <li>
                            <strong>Cardholder</strong>: You! This refers to any
                            individual, company, or other entity who obtains a
                            PPI from PEV and uses it to purchase goods and
                            services.
                          </li>
                          <li>
                            <strong>Charges</strong>: Fees levied by PEV for
                            using the Card. These charges may change over time.
                          </li>
                          <li>
                            <strong>Customer Care Centre</strong>: PEV's
                            customer service department. They can address your
                            queries, complaints, and requests for information
                            about the Card.
                          </li>
                          <li>
                            <strong>KYC (Know Your Customer)</strong>:
                            Guidelines adopted by PEV to identify and verify
                            your identity. This follows regulations set by the
                            RBI (Reserve Bank of India).
                          </li>
                          <li>
                            <strong>Merchant Establishments</strong>: Stores,
                            shops, restaurants, hotels, and Point-of-Sale (POS)
                            terminals located in India that accept your Card or
                            any RuPay network card.
                          </li>
                          <li>
                            <strong>
                              Personal Identification Number (PIN)
                            </strong>
                            : A numeric password you set following PEV's
                            instructions when you receive the PPI.
                          </li>
                          <li>
                            <strong>POS (Point of Sale)</strong>: Electronic
                            terminals at Indian Merchant Establishments where
                            you can use your Card to purchase goods and
                            services.
                          </li>
                          <li>
                            <strong>Program</strong>: PEV's program that offers
                            the Card to you (the Cardholder).
                          </li>
                          <li>
                            <strong>Transaction</strong>: Any purchase you make
                            using your Card at a RuPay affiliated Merchant
                            Establishment.
                          </li>
                          <li>
                            <strong>Website</strong>: The website owned and
                            maintained by PEV located at www.zokudo.com. This
                            includes any future modifications or changes to the
                            website.
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        2.Eligibility, Usage, Limits And Security:
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ol className="list-decimal">
                          <li>
                            <strong>Eligibility:</strong> The services are not
                            available to any Cardholder previously suspended or
                            removed from services by PEV. The Cardholder shall
                            not impersonate any person or entity, or falsely
                            state or otherwise misrepresent identity, age or
                            affiliation with any person or entity.
                          </li>
                          <li>
                            <strong>Usage:</strong> The issue and use of the
                            Card shall be subject to the rules and regulations
                            as issued by PEV from time to time and the
                            guidelines issued by the RBI. The Cardholder shall
                            use the Card only in India to purchase or avail
                            goods and services from Merchant Establishments
                            located in India.
                          </li>
                          <li>
                            <strong>Delivery:</strong> The Cardholder
                            unconditionally and irrevocably authorizes PEV to
                            deliver and handover the Card to the Cardholder or
                            the entity (such as a co-brand partner or a delivery
                            service) which will distribute it to the
                            Cardholder(s). PEV shall not be liable or
                            responsible for any act or omission on the part of
                            the entity, in connection with the delivery of the
                            Card to the Cardholder.
                          </li>
                          <li>
                            <strong>Transferability:</strong> The Card is not
                            transferable or assignable by the Cardholder to any
                            person under any circumstances.
                          </li>
                          <li>
                            <strong>Lost & Stolen Cards :</strong>
                            <br />
                            <ol className="list-decimal">
                              <li>
                                If the Card or its PIN is lost, stolen, misused,
                                or damaged, please immediately contact the
                                Customer Care Centre on the telephone numbers
                                listed on the backside of the Card. These
                                numbers are also reproduced in these Terms and
                                available on the Website.
                              </li>
                              <li>
                                The Card shall be blocked within 24
                                (twenty-four) hours of reporting, and the risk
                                of protecting your money starts from the time
                                you report the loss of the Card. The Cardholder
                                acknowledges that once the Card is reported
                                lost, stolen, damaged, or misused and
                                subsequently blocked, it cannot be used again,
                                even if found later (in case of physical cards).
                              </li>
                              <li>
                                PEV may, at its sole discretion and upon
                                verification of the Cardholder's identity, issue
                                a new Card in lieu of the lost Card. This may
                                involve a prescribed fee, as determined by PEV
                                from time to time, and is subject to these Terms
                                at all times.
                              </li>
                              <li>
                                No liability shall accrue upon PEV for any
                                unauthorized transactions made on the Card after
                                it has been reported lost, stolen, misused, or
                                damaged by the Cardholder, and before the Card
                                has been blocked by PEV. However, in case of any
                                dispute regarding the time of reporting and/or
                                transactions made on the Card after reporting it
                                lost, stolen, misused, or damaged, all decisions
                                of PEV shall be final and binding on the
                                Cardholder.
                              </li>
                            </ol>
                          </li>
                          <li>
                            <strong>Legalities</strong>: The Cardholder shall
                            only use the card for bona fide, lawful purposes in
                            a lawful manner, subject at all times to applicable
                            law. The Card cannot be used for making purchases of
                            prohibited or contraband products or services,
                            including but not limited to, lottery tickets,
                            banned or prohibited magazines, participation in
                            sweepstakes, purchase of bitcoins, payment for
                            call-back services, etc.
                          </li>
                          Copy
                          <li>
                            <strong>Use by third party</strong>: To protect the
                            interest of the Cardholder from un-authorised use,
                            it is advised that the Cardholder shall keep the
                            Card and the PIN under their personal custody at all
                            times.
                          </li>
                          <li>
                            <strong>PIN safety</strong>: The Cardholder agrees
                            and confirms that the PIN shall under no
                            circumstances be revealed by the Cardholder to any
                            relative or family members or third party. The
                            Cardholder shall be solely responsible and liable
                            for the consequences arising out of such
                            unauthorized disclosure of PIN and/or unauthorized
                            usage of the Card. If the Cardholder forgets or
                            misplaces the PIN, the Cardholder should make an
                            application in writing or contact the Customer Care
                            Centre for a new PIN immediately. The new PIN will
                            be sent to the Cardholder's registered email address
                            (or through such other mode as may be specified by
                            PEV from time to time), provided to or available
                            with the PEV.
                          </li>
                          <li>
                            <strong>Cards</strong>: Cards are treated like cash,
                            and PEV shall not be liable for the misuse, loss or
                            theft of Cards. PEV will not reissue new Card in
                            case of expiration of such Card in contravention of
                            these Terms or applicable law. PEV and the Merchant
                            Establishments, reserve the right to refuse the
                            acceptance of the Card at any time for any reasons
                            whatsoever, without any prior intimation to the
                            Cardholder.
                          </li>
                          <li>
                            <strong>Responsibility</strong>: PEV shall in any
                            manner not be responsible for any disputes regarding
                            goods and service received by the Cardholder against
                            use of the Card including the quality, value,
                            warranty, delay of delivery, non-delivery,
                            non-receipt of any goods or services. It must be
                            clearly understood that the Card is only a facility
                            to the Cardholder to avail facilities and PEV holds
                            out no warranty or makes no representation about
                            quality, quantity, value, delivery or otherwise,
                            howsoever regarding goods or services availed using
                            the Card, and any such disputes should be resolved
                            by the Cardholder with the concerned Merchant
                            Establishment or third party directly.
                          </li>
                          <li>
                            <strong>Changes in Card Balance</strong>: The
                            Cardholder may be entitled for credit in the Card
                            Balance in case of reversal of transaction, and no
                            cash shall be refunded to the Cardholder. PEV shall
                            recover applicable charges imposed by the respective
                            Merchant Establishments while crediting the Card
                            Balance and the process will be subject to the
                            applicable rules, regulations and internal policies
                            of PEV. The Cardholder acknowledges that all refunds
                            in case of failed, returned, rejected or cancelled
                            Transactions done by the Cardholder using any other
                            payment instrument cannot be credited in the Card
                            Balance.
                          </li>
                          <li>
                            <strong>Transaction Limits</strong>: PEV at points
                            of sale may set limits on the monetary amount and
                            number of transactions allowed through a Card during
                            a set time period ("Transaction Limits").
                            Transaction Limits are set out in these Terms and
                            will apply to the Card. PEV may, at any time, modify
                            the Transaction Limits for any reason whatsoever,
                            subject at all times to the RBI's guidelines in this
                            regard. The Cardholder can view these changes online
                            on the Website or may also call the Customer Care
                            Centre. The Cardholder will be notified in
                            accordance with applicable law.
                          </li>
                          <li>
                            <strong>Records</strong>: The Cardholder shall sign
                            and retain all the charge slips generated for each
                            of the Transaction consummated at a Merchant
                            Establishment (as may be applicable). PEV shall not
                            be obliged to provide copies of the charge slips or
                            Transaction slips to the Cardholder. Any such
                            request by the Cardholder will be at the sole
                            discretion of PEV and provided such requests have
                            been made by the Cardholder within 45 (forty five)
                            days from the date of the Transaction. The
                            Cardholder agrees that PEV shall be entitled to
                            charge additional cost or charge for providing
                            copies of the charge or Transaction slips.
                          </li>
                          <li>
                            <strong>Intimation</strong>: The Cardholder hereby
                            agrees to receive SMS or emailing alerts from PEV
                            for all Transactions done using the Card. The SMS or
                            emailing alerts from PEV shall stipulate debit and
                            credit Transactions, balance available or remaining
                            on the Card or such other information or details as
                            stipulated by PEV, from time to time.
                          </li>
                          <li>
                            <strong>FAQs</strong>: The Cardholder shall have
                            access to Frequently Asked Questions as uploaded by
                            PEV on its Website, and as amended from time to
                            time.
                          </li>
                          <li>
                            <strong>Third-party services</strong>: The
                            Cardholder agrees that PEV may, at its sole
                            discretion, utilize the services of external service
                            provider/s or agent/s and on such terms as required
                            or necessary, in relation to its services.
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        3.Card Balance And Conditions:
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ol className="list-decimal">
                          <li>
                            <strong>Card Balance</strong>: the Cardholder may
                            utilise the Card Balance by due and proper use of
                            the Card, in accordance with these Terms. No
                            interest is payable to the Cardholder on the Card
                            Balance or the Card Account. The Cardholder is
                            advised to transact for requisite amount of
                            purchase/ availing services including applicable
                            charges otherwise the Transaction may not be
                            honoured.
                          </li>
                          Copy
                          <li>
                            <strong>Irregularities</strong>: The Cardholder will
                            inform PEV for any irregularities or discrepancies
                            that exist in the Transaction details at a Merchant
                            Establishment within 7 (seven) days of the
                            Transaction being processed. If no such notice is
                            received during this time, PEV will assume the
                            correctness of the Transaction. In this regard,
                            please see the customer grievance redressal policy
                            available here:
                            https://www.zokudo.com/customer-grievance-redressal
                            . Any Charge or Transaction slip or other payment
                            requisition (whether electronically or otherwise)
                            received by PEV and/or the Cardholder for payment
                            shall be conclusive proof of such Charge, unless the
                            Card is lost, stolen or fraudulently misused and
                            evidenced by the Cardholder.
                          </li>
                          <li>
                            <strong>
                              Cards shall be subject to the following conditions
                            </strong>
                            :<br />
                            <ol className="list-decimal">
                              <li>
                                Maximum value of each Card shall not exceed{" "}
                                <strong>
                                  INR 10,000/- (Indian Rupees Ten Thousand only)
                                </strong>
                                .
                              </li>
                              Copy
                              <li>
                                Maximum value of Cards issued per mobile number
                                shall not exceed{" "}
                                <strong>
                                  INR 10,00,000/- (Rupees Ten Lacs only)
                                </strong>{" "}
                                in a financial year.
                              </li>
                              <li>
                                Cards shall <strong>not be reloadable</strong>.
                              </li>
                              <li>
                                <strong>
                                  Cash-out, cash withdrawal, or refund or funds
                                  transfer
                                </strong>{" "}
                                shall not be permitted for such Cards.
                              </li>
                              <li>
                                <strong>KYC details</strong> of the purchasers
                                of such Cards shall be maintained by PEV.
                                Separate KYC may not be maintained for
                                Cardholders who are issued such instruments
                                against debit to their bank accounts in India.
                              </li>
                              <li>
                                The Card may be <strong>revalidated</strong>{" "}
                                (including through issuance of new Card) as per
                                the board approved policy of PEV.
                              </li>
                            </ol>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        4.Expiration, Cancellation And Termination:
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ol class="list-decimal">
                          <li>
                            The Card issued to you is valid for a period of 1
                            (one) year from the date of issuance. The Card will
                            expire on the expiration date mentioned on the Card.
                            PEV may cancel or suspend usage of the Card
                            immediately upon:
                            <br />
                            <ol class="list-decimal">
                              <li>
                                The Cardholder{" "}
                                <strong>intimating the loss of Card</strong> in
                                accordance with these Terms;
                              </li>
                              Copy
                              <li>
                                The Cardholder being declared{" "}
                                <strong>insolvent or upon their death</strong>;
                              </li>
                              <li>
                                Any <strong>restriction imposed</strong> on the
                                Cardholder by an order of a competent court or
                                order issued by any regulatory or statutory
                                authority in India or any investigating agency;
                              </li>
                              <li>
                                <strong>Non-usage of the Card</strong> for
                                continuous <strong>90 (ninety) days</strong> (or
                                such other period as PEV may specify);
                              </li>
                              <li>
                                <strong>No balance in the Card</strong> for a
                                period of <strong>90 (ninety) days</strong> (or
                                such other period as PEV may specify);
                              </li>
                              <li>
                                Any <strong>breach of Terms</strong>, or terms
                                and conditions otherwise applicable to the
                                Cardholder;
                              </li>
                              <li>
                                Specific request from the{" "}
                                <strong>corporate</strong> (if applicable) to
                                cancel or suspend the Card;
                              </li>
                              <li>
                                The Program becoming <strong>illegal</strong>{" "}
                                under the applicable laws, rules, guidelines or
                                circulars;
                              </li>
                              <li>
                                The Program being <strong>terminated</strong>;
                                or
                              </li>
                              <li>
                                Inability to process payments in relation to the
                                Card for reasons{" "}
                                <strong>beyond its reasonable control</strong>,
                                including but not limited to restrictions
                                imposed by law or regulation.
                              </li>
                            </ol>
                          </li>
                          <li>
                            PEV will inform or intimate the Cardholder{" "}
                            <strong>
                              45 (forty five) days prior to the expiry of the
                              Card
                            </strong>{" "}
                            through SMS on the registered mobile number of the
                            Cardholder. Cardholder needs to utilize the entire
                            credit balance available on the Card prior to the
                            expiry of the Card. In case the Cardholder does not
                            utilize the credit balance available on the Card
                            within the validity period, the Cardholder can
                            approach PEV for refund/transfer of outstanding
                            balance in compliance with extant regulations of the
                            Reserve Bank of India.
                          </li>
                          Copy
                          <li>
                            The customer can ask for a refund of remaining
                            balance amount any time after the expiry period is
                            over for <strong>3 years</strong>. If the customer
                            does not ask for refund within 3 years after expiry
                            of the card, then PEV will transfer the remaining
                            amount to its P & L.
                          </li>
                          <li>
                            The Cardholder agrees and undertakes to{" "}
                            <strong>destroy the Card</strong> (in case of
                            physical Card) upon its expiry or earlier
                            cancellation to prevent any third party from using
                            it.
                          </li>
                          <li>
                            The Cardholder understands that such cancellation or
                            expiry will not take effect (in case of physical
                            Cards) until the Card has been defaced by{" "}
                            <strong>
                              cutting off the top right-hand corner
                            </strong>
                            , ensuring that both the hologram and the magnetic
                            strip have been cut and has been destroyed or
                            received by PEV. The Cardholder agrees that the
                            Cardholder shall continue to be liable for any
                            Charges incurred on the Card prior to the
                            termination of the Card, irrespective of the fact
                            whether the Cardholder has, or claims to have
                            destroyed the Card (in case of physical Cards).
                          </li>
                          <li>
                            PEV, at its sole discretion, reserves the right to,
                            either temporarily or permanently,{" "}
                            <strong>withdraw the privileges</strong> on the Card
                            and/or terminate the Card at any time without giving
                            any notice or assigning any reason thereof. In case
                            of a temporary withdrawal, the privileges attached
                            to the Card shall be reinstated by PEV at its sole
                            discretion. In case of a permanent withdrawal, PEV
                            has a right to cancel the Card permanently. However,
                            it is made distinctly clear that withdrawal
                            (temporary or permanent) shall constitute automatic
                            withdrawal of all benefits, privileges and services
                            attached to the Card. The Cardholder agrees that in
                            the event of temporary or permanent withdrawal of
                            the Card, the Cardholder shall continue to be fully
                            liable for all Charges incurred on the Card prior to
                            such withdrawal, together with all other applicable
                            Charges thereon, unless otherwise specified by PEV.
                          </li>
                          <li>
                            If PEV temporarily or permanently, withdraw the
                            privileges or terminates the Card, PEV reserves its
                            right, on best effort basis, to{" "}
                            <strong>promptly notify</strong> the Cardholder. PEV
                            shall not be held liable or responsible for any such
                            delays or laches in receipt of such notification.
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        5.Charges:
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ol class="list-decimal">
                          <li>
                            Charges shall include: <br />
                            <ol class="list-decimal">
                              <li>
                                Any <strong>fees charged by PEV</strong> in
                                respect of the Card, including replacement,
                                renewal, handling, and other fees, if any. These
                                fees are{" "}
                                <strong>
                                  non-refundable, non-transferrable and
                                  non-assignable
                                </strong>{" "}
                                in nature.
                              </li>
                              Copy
                              <li>
                                <strong>Service charges</strong> on specific
                                types of Transactions. The method of computation
                                of such Charges will be as notified by PEV from
                                time to time on its Website.
                              </li>
                            </ol>
                          </li>
                          <li>
                            PEV will deduct all{" "}
                            <strong>fees and Charges</strong> due (including
                            applicable charges set out in the fee table in the
                            supplement, or due to third parties) from the Card
                            Balance.
                          </li>
                          Copy
                          <li>
                            It is clarified that charges incurred may, in case
                            of certain <strong>Merchant Establishments</strong>,
                            include a charge for availing certain additional
                            service or other facility being provided by such
                            Merchant Establishments.
                          </li>
                          <li>
                            Any <strong>charge or cost</strong> levied by the
                            Merchant Establishment in relation to any
                            Transaction, shall be directly settled by the
                            Cardholder with the Merchant Establishment. PEV
                            shall not be liable or responsible, either directly
                            or indirectly, for any act or omission on the part
                            of the Merchant Establishment or the charges or cost
                            levied by them in relation to the Transaction.
                          </li>
                          <li>
                            All Charges, in the absence of manifest error, shall
                            be <strong>final and binding</strong> on the
                            Cardholder and shall be conclusive in nature.
                          </li>
                          <li>
                            All{" "}
                            <strong>
                              statutory taxes, goods and service tax, all other
                              imposts, duties
                            </strong>{" "}
                            (including stamp duty and relevant registration
                            charges, if any, in connection with the Card) and
                            taxes (of any description whatsoever) as may be
                            levied from time to time by the government or other
                            authority in respect of or in connection with the
                            Card, will be borne by the Cardholder.
                          </li>
                          <li>
                            <strong>Schedule of Charges:</strong>
                            <br />
                            <TermsTable1 />
                          </li>
                          <li>
                            Nothing contained herein shall, however, prevent PEV
                            from deducting tax deductible at source as required
                            under the applicable laws, from any transaction made
                            through the Card.
                          </li>
                          <li>
                            This schedule may be also be otherwise displayed on
                            the Website, and may be amended from time to time at
                            PEV’s sole discretion.
                          </li>
                          <li>
                            <br />
                            <TermsTable2 />
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSeven"
                        aria-expanded="false"
                        aria-controls="collapseSeven"
                      >
                        7.Liability Of Pev:
                      </button>
                    </h2>
                    <div
                      id="collapseSeven"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ol class="list-decimal">
                          <li>
                            The liability of PEV is restricted to the extent of
                            the amount lying in the Card of the Cardholder at
                            the relevant point of time.
                          </li>
                          <li>
                            The Cardholder undertakes to act in good faith at
                            all times in relation to all dealings in relation to
                            the Card. The Cardholder accepts full responsibility
                            for any illegal or wrongful use of the Card in
                            contravention of the Terms. The Cardholder
                            undertakes and agrees to indemnify PEV against any
                            and all loss, damage, claim, penalty, cost, charges
                            or expenses (including legal counsel fees) that PEV
                            may incur and/or suffer, whether directly or
                            indirectly, as a result of the Cardholder committing
                            any breach of the Terms contained herein, or terms
                            and conditions otherwise applicable to it.
                          </li>
                          <li>
                            The Cardholder shall unconditionally keep PEV
                            indemnified against any and all loss or damage
                            caused to PEV on account of dishonouring the payment
                            instructions as a result of insufficient Card
                            Balance. The Cardholder agrees that PEV shall also
                            be entitled to deduct the amount of such loss or
                            damage caused to PEV, directly from the Card
                            Balance.
                          </li>
                          <li>
                            The Cardholder disclaims all responsibility and
                            liability of PEV arising out of or in connection
                            with the unauthorized usage of the Card and/or the
                            Cardholder shall indemnify PEV for any and all
                            losses or damages, whether direct or indirect,
                            incurred by PEV as a result of such misuse.
                          </li>
                          <li>
                            Without prejudice to the foregoing, PEV shall be
                            under no liability or responsibility to the
                            Cardholder or any third party, in respect of any
                            loss or damage arising, directly or indirectly out
                            of:
                            <br />
                            <ol class="list-decimal">
                              <li>
                                Any defect in any goods or services provided or
                                supplied by a Merchant Establishment;
                              </li>
                              <li>
                                Any disputes regarding the quality, value
                                warranty, delay of delivery, non-delivery,
                                non-receipt of any goods or services provided or
                                supplied by a Merchant Establishment;
                              </li>
                              <li>
                                The refusal of any person or merchant to honour
                                or accept the Card;
                              </li>
                              <li>
                                Non-functioning of Card in the desired manner
                                for whatsoever reason;
                              </li>
                              <li>The malfunction of any computer terminal;</li>
                              <li>Any force majeure events;</li>
                              <li>Transfer of the Card to any third party;</li>
                              <li>
                                Termination of the Card in accordance with these
                                Terms;
                              </li>
                              <li>
                                Any discrepancy in the amount allocated to a
                                Card, where such amount has been allocated upon
                                receipt of instructions in this regard from the
                                Cardholder;
                              </li>
                              <li>
                                Any loss or damage caused to the Cardholder
                                alleged to have been caused by the repossession
                                of the Card by PEV;
                              </li>
                              <li>
                                Any reversals done on the Card on request of the
                                Cardholder.
                              </li>
                            </ol>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseEight"
                        aria-expanded="false"
                        aria-controls="collapseEight"
                      >
                        8.Registration Data, Data Protection And Account
                        Security:
                      </button>
                    </h2>
                    <div
                      id="collapseEight"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ol class="list-decimal">
                          <li>
                            The Cardholder agrees to:
                            <br />
                            <ol className="list-[upper-roman]">
                              <li>
                                provide accurate, current and complete
                                information about him as may be prompted by any
                                registration forms on the Website or the
                                Application Form (“Registration Data”);
                              </li>
                              <li>maintain the security of the PIN;</li>
                              <li>
                                maintain and promptly update the Registration
                                Data and any other information that he provides
                                to PEV; and
                              </li>
                              <li>
                                be fully responsible for all use of his Card.
                              </li>
                            </ol>
                          </li>
                          <li>
                            PEV will process the Cardholder’s personal data
                            (including Registration Data) in order to provide a
                            Card in accordance with the Cardholder’s
                            instructions, to administer the Card, to deal with
                            any queries the Cardholder has concerning the Card
                            or its use, for statistical and regulatory reporting
                            and fraud prevention purposes.
                          </li>

                          <li>
                            PEV will confidentially and only to the extent
                            permitted under applicable data protection and bank
                            secrecy laws, give Cardholder’s personal data
                            (including Registration Data) to other companies and
                            third parties including those providing application
                            processing, fraud monitoring, customer service, card
                            production and technology processing services and
                            each of their agents and subcontractors.
                          </li>
                          <li>
                            PEV may disclose the Cardholder’s personal data
                            (including Registration Data) to:
                            <ol className="list-[upper-roman]">
                              <li>the Cardholder,</li>
                              <li>
                                fraud prevention agencies if false or inaccurate
                                information is provided and fraud is suspected,
                                and
                              </li>
                              <li>
                                statutory/regulatory authorities, law
                                enforcement or fraud prevention agencies where
                                we are required to do so by applicable law or
                                court order.
                              </li>
                            </ol>
                          </li>
                          <li>
                            PEV may record and/or monitor telephone calls to
                            help maintain high quality service and for security,
                            data collection and training purposes, or as
                            required by applicable law. All recordings belong to
                            PEV.
                          </li>
                          <li>
                            The Cardholder consents to the processing (including
                            transfer) of its personal data (including
                            Registration Data) by PEV, and the other recipients
                            identified above, for the purposes set out above.
                          </li>
                          <li>
                            The Cardholder hereby consents to PEV and/or its
                            affiliates retaining all data, records and history
                            in relation to the Card (including Registration
                            Data) and grant them all rights related to the data,
                            including accessing such data by archiving its copy
                            and the right to share such data in de-identified
                            form with any third party. PEV, its affiliates or
                            any third party with whom such data is shared may
                            process and use the data in perpetuity in
                            de-identified form for any purpose, including for
                            commercial and research purposes, by way of
                            aggregation of data to create statistical,
                            predictive, clinical or other data models or any
                            other way.
                          </li>
                        </ol>{" "}
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseNine"
                        aria-expanded="false"
                        aria-controls="collapseNine"
                      >
                        9.Communication Policy:
                      </button>
                    </h2>
                    <div
                      id="collapseNine"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ol class="list-decimal">
                          <li>
                            The Cardholder accepts that PEV may send requisite
                            alerts to the mobile phone number provided by the
                            Cardholder while registering for availing the
                            services or to any such number replaced as informed
                            by the Cardholder. The Cardholder acknowledges that
                            the alerts will be received only if the mobile phone
                            is in “ON” mode to receive the alerts via SMS. If
                            the mobile phone is in “OFF” mode then the
                            Cardholder may not receive or receive after delay
                            any alerts sent during such period.
                          </li>
                          <li>
                            The Cardholder also agrees to receive information
                            regarding what PEV perceives to be of their interest
                            based on usage history via SMS, email & phone call.
                            This includes offers, discounts and general
                            information. In case the Cardholder does not want
                            such information, they have to explicitly ask to be
                            excluded.
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTen"
                        aria-expanded="false"
                        aria-controls="collapseTen"
                      >
                        10.Cashback:
                      </button>
                    </h2>
                    <div
                      id="collapseTen"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ol className="list-decimal">
                          <li>
                            <strong>Terms & conditions for Cashback:</strong>
                            <br />
                            <ol class="list-decimal">
                              <li>
                                Cashback will be provided against Merchant
                                Establishments which are shown on the
                                offers/brand pages of the application/Website or
                                as advertised by the specific Merchant
                                Establishment on its portal/store.
                              </li>
                              <li>
                                Cashback value is shown as a part of the offer
                                and can be a percentage value based on
                                transaction amount (Example 5% (Five Per Cent))
                                or flat value (Example INR 150 (Indian Rupees
                                One Hundred Fifty)).
                              </li>
                              <li>
                                Cashback is instant cashback which means as soon
                                as the authorization is successful it will be
                                credited to the Card instantly.
                              </li>
                              <li>
                                Cashback is to the Card balance/pocket which can
                                be reused as normal balance.
                              </li>
                              <li>
                                Cashback will be provided in the same Card that
                                is used to make the purchase.
                              </li>
                              <li>
                                Cashback will be provided in the same pocket
                                that is used to make the purchase –Example,
                                travel pocket.
                              </li>
                              <li>
                                Cashback is calculated on transaction amount and
                                not the billing amount (which includes taxes,
                                GST, etc).
                              </li>
                              <li>
                                In case of refund or reversal the complete
                                cashback will be reversed, even if the refund is
                                for a partial value.
                              </li>
                              <li>
                                In case of refund or reversal any shortfall in
                                recovering cashback will be deducted against the
                                outstanding balance of the Card/pocket.
                              </li>
                              <li>
                                It is clarified that the provision of cashback
                                shall at all times be subject to the sole and
                                absolute discretion of PEV. The Cardholder shall
                                have no claims whatsoever in respect of such
                                cashback against PEV.
                              </li>
                            </ol>
                          </li>
                          <li>
                            <strong>
                              Details on how cashback will be provided if limits
                              are crossed:
                            </strong>
                            <br />
                            <ol className="list-decimal">
                              <li>
                                <strong>Pockets Limit:</strong>
                                <br />
                                <ol class="list-decimal">
                                  <li>
                                    Cashback is provided to the same pocket
                                    which has been used to do the transaction.
                                  </li>
                                  <li>
                                    Even if (pocket balance + cashback amount)
                                    max pocket limit, PEV will provide the
                                    cashback. Also, the pocket balance will be
                                    updated for future use.
                                  </li>
                                </ol>
                                <p>
                                  For the purpose of these Terms, “pocket” shall
                                  mean a group of similar Merchant
                                  Establishments, which are grouped based on the
                                  merchant category codes (“MCC”). Transaction
                                  against the Merchant Establishments with the
                                  MCC in the pocket are limited to the specific
                                  pocket balance. A Card will have either a
                                  default Card Balance (which can be used across
                                  MCC’s) or balance by pockets.{" "}
                                </p>
                                <p>
                                  <strong>Illustratively:</strong>
                                </p>
                                <p>
                                  A corporate wants their user to use the Card
                                  at certain MCC only like food, travel and so
                                  on, then they can upload the balance to the
                                  Card by defining these pockets.{" "}
                                </p>
                                <ol class="list-decimal">
                                  <li>Pocket A – Food – 2000/-</li>
                                  <li>Pocket B – Travel – 6000/-</li>
                                  <li>Pocket C – Shopping – 2000/-</li>
                                </ol>
                                <p>
                                  For more details, please reach out to the
                                  Customer Care Centre.
                                </p>
                              </li>
                            </ol>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseEleven"
                        aria-expanded="false"
                        aria-controls="collapseEleven"
                      >
                        11.Redemption:
                      </button>
                    </h2>
                    <div
                      id="collapseEleven"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p>
                          The Cards can be redeemed at any of the websites or
                          Merchant Establishments which accept RuPay instruments
                          as, and by way of payment. However, there are a few
                          MCCs, as mentioned below, wherein the Cards cannot be
                          redeemed.
                        </p>
                        <TermsTable3 />
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOneTwo"
                        aria-expanded="false"
                        aria-controls="collapseOneTwo"
                      >
                        12.Miscellaneous:
                      </button>
                    </h2>
                    <div
                      id="collapseOneTwo"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ol class="list-decimal">
                          <li>
                            <strong>Amendments</strong>: PEV may, with notice to
                            the Customer (or by hosting on the Website or in any
                            other manner), at any time and subject to applicable
                            law, change or delete any provision, add or change
                            (including to increase or decrease) any Charges,
                            benefits, privileges, features, rates, offers, or
                            otherwise make any changes to these Terms. The
                            Cardholder shall be responsible, and will be deemed
                            to have seen and accepted the changes 24 (twenty
                            four) hours after they are available on the Website.
                            PEV reserves the right to amend any Terms at any
                            time.
                          </li>
                          <li>
                            <strong>Governing Law</strong>: These Terms and all
                            matters arising hereunder shall be governed by
                            Indian laws and both the Cardholder and PEV submit
                            to the exclusive jurisdiction of the courts of
                            Mumbai.
                          </li>
                          <li>
                            <strong>Dispute Resolution</strong>: Without
                            prejudice to anything contained above, in the event
                            of a dispute, difference or claim between PEV and
                            the Cardholder, arising out of these Terms or the
                            construction or interpretation hereof or otherwise
                            in relation hereto, the Cardholder and PEV shall
                            first endeavour to settle such difference, dispute,
                            claim or question by mutual discussion within 15
                            (fifteen) days of notice received from one of the
                            parties to the other, failing which the same shall
                            be finally settled in accordance with the provisions
                            of the Arbitration and Conciliation Act, 1996 as may
                            be amended or re-enacted from time to time. The
                            dispute shall be resolved by an arbitral tribunal
                            composed of a sole arbitrator selected by the mutual
                            agreement of both PEV and the Cardholder. The
                            arbitration proceedings shall be conducted in the
                            English language. The arbitration proceedings shall
                            be seated in Mumbai, and the award of the tribunal
                            should be reasoned and shall be final.
                          </li>
                          <li>
                            <strong>Quality of Goods and Services</strong>: Any
                            dispute with or complaint against any Merchant
                            Establishment regarding any goods purchased or
                            services availed must be directly resolved by the
                            Cardholder with the Merchant Establishment.
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOneThree"
                        aria-expanded="false"
                        aria-controls="collapseOneThree"
                      >
                        13.Help Line:
                      </button>
                    </h2>
                    <div
                      id="collapseOneThree"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p>
                          Call us at our Customer Care Centre No.{" "}
                          <a
                            href="telto:8655000444"
                            className="text-green-500 font-medium"
                          >
                            8655000444
                          </a>{" "}
                          or e-mail us at{" "}
                          <a
                            href="mailto:care@zokudo.com"
                            className="text-green-500 font-medium"
                          >
                            care@zokudo.com
                          </a>{" "}
                          or write to us at 42, Navketan Industrial estate,
                          Mahakali Caves Road, Andheri (east), Mumbai – 400 093.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOneFour"
                        aria-expanded="false"
                        aria-controls="collapseOneFour"
                      >
                        14.Notices:
                      </button>
                    </h2>
                    <div
                      id="collapseOneFour"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ol class="list-decimal">
                          <li>
                            Any notice to be given by PEV hereunder shall be
                            deemed to have been received by the Cardholder,
                            within 7 (seven) days from the date of the notice
                            (when sent physically on the mailing address) or
                            immediately when sent via email at the registered
                            email provided to or available with PEV.
                          </li>
                          <li>
                            Any notice to be given by the Cardholder to PEV
                            hereunder shall be deemed to have been received by
                            PEV only upon acknowledgement of receipt of such
                            notice by PEV at its Corporate Office Address at
                            –42, Navketan Industrial estate, Mahakali Caves
                            Road, Andheri (East), Mumbai 400093. Any notice or
                            communication from the Cardholder hereunder shall
                            not be binding on PEV unless the same is in writing
                            and shall have been served and acknowledged by PEV
                            or if by registered post, acknowledgement card.
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="v-pills-profile"
                role="tabpanel"
                aria-labelledby="v-pills-profile-tab"
                tabindex="0"
              >
                <GPCardsTerms />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default TermAndCondition;
