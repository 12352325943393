import React from 'react'

export default function Faq() {
  return (
    <div className="container-fluid mt-5 faq-banner">
        <div className="container py-5 px-0 md:px-initial">
        <div className="d-flex flex-column align-items-center">
          <h6 className="text-gray-300 bg-[#151515] py-3 px-4 rounded">FAQ</h6>
          <h3 className="text-warning">Frequently Asked Questions</h3>
          <p className="text-gray-400 md:w-3/5 m-auto text-sm">
            Confused? Find answers to all your queries below, or get in touch if
            you have a specific question in mind.
          </p>
        </div>

        <div
          className="accordion accordion-container w-full md:w-[80%] bg-black text-white p-4 text-left rounded-xl py-5 mt-4"
          id="accordionExample"
        >
          <h5 className="py-2 md:py-4">
            You have questions? We have answers.
          </h5>
          {/* Accordion Item 1 */}
          <div className="accordion-item mt-3">
            <h2 className="accordion-header">
              <button
                className="accordion-button fw-bold border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <li></li> What is the Zokudo General Purpose Reloadable (GPR)
                card?
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <strong> Zokudo’s General Purpose Reloadable (GPR)</strong> card
                is a versatile prepaid card issued under the Rupay network,
                accepted widely at various merchant outlets, and can be reloaded
                and hold a balance of up to ₹2,00,000 at any given time.
              </div>
            </div>
          </div>

          {/* Accordion Item 2 */}
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed fw-bold"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <li></li> Where can I use the Zokudo GPR card?
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                The Zokudo GPR card can be used for INR transactions at any
                point-of-sale (POS) terminal or online merchant that displays
                the Rupay card logo. It supports swipe and chip-based
                transactions, making it a flexible choice for various purchases
                across India.
              </div>
            </div>
          </div>

          {/* Accordion Item 3 */}
          <div className="accordion-item ">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed fw-bold "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <li></li> What is a Zokudo Gift Card?
              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                A Zokudo Gift Card is a prepaid card that can be used for
                purchases at various merchants. It offers flexibility and
                convenience for gifting and is a single-load instrument with a
                10,000 INR limit per card.
              </div>
            </div>
          </div>

          {/* Accordion Item 4 */}
          <div className="accordion-item ">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed fw-bold "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                <li></li> How does Zokudo’s prepaid card integration work?
              </button>
            </h2>
            <div
              id="collapseFour"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Our platform offers user-friendly APIs that enable seamless
                integration with your existing systems. Once integrated, you can
                easily manage card issuance, fund allocation, and spending
                limits. Our team can support you throughout the integration
                process to ensure a smooth setup.
              </div>
            </div>
          </div>

          {/* Accordion Item 5 */}
          <div className="accordion-item ">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed fw-bold "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                <li></li> How can we track and manage prepaid card transactions?
              </button>
            </h2>
            <div
              id="collapseFive"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Our comprehensive reporting tools allow businesses to monitor
                and track prepaid card transactions in real time. You can access
                detailed summary transaction reports, analyse spending patterns,
                and adjust budgets as needed. These tools help you maintain
                control over expenses and optimise your spending strategies.
              </div>
            </div>
          </div>
          {/* Accordion Item 6 */}
          <div className="accordion-item ">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed fw-bold "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSix"
                aria-expanded="false"
                aria-controls="collapseSix"
              >
                <li></li> What support does Zokudo offer for managing prepaid
                card programs?
              </button>
            </h2>
            <div
              id="collapseSix"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                We offer dedicated support to assist with all aspects of
                managing your prepaid card program. Our team is available to
                help with setup, integration, and ongoing management, ensuring
                that your card program runs smoothly. We are here for you,
                always.
              </div>
            </div>
          </div>
          {/* Accordion Item 7 */}
          <div className="accordion-item ">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed fw-bold "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSeven"
                aria-expanded="false"
                aria-controls="collapseSeven"
              >
                <li></li> Are there any limitations on the reloadable amounts
                for prepaid GPR cards?
              </button>
            </h2>
            <div
              id="collapseSeven"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                While there is no minimum reload amount, the maximum amount that
                can be loaded onto a single card in a day is ₹2,00,000.
                Additionally, the card balance cannot exceed this limit at any
                given time.
              </div>
            </div>
          </div>
          {/* Accordion Item 8 */}
          <div className="accordion-item ">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed fw-bold "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseEight"
                aria-expanded="false"
                aria-controls="collapseEight"
              >
                <li></li> What are the limits for ATM cash withdrawal using the
                Gift Card?
              </button>
            </h2>
            <div
              id="collapseEight"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                You can withdraw cash from ATMs using your Zokudo GPR Card, with
                a maximum withdrawal limit of ₹2,000 per transaction and a daily
                limit of ₹10,000.
              </div>
            </div>
          </div>
          {/* Accordion Item 9 */}
          <div className="accordion-item ">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed fw-bold "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseNine"
                aria-expanded="false"
                aria-controls="collapseNine"
              >
                <li></li> Can I use my GPR Card for UPI payments?
              </button>
            </h2>
            <div
              id="collapseNine"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Yes, Zokudo GPR Cards can be linked to UPI for seamless
                payments, allowing you to make transactions at any UPI-enabled
                merchant.
              </div>
            </div>
          </div>
          {/* Accordion Item 10 */}
          <div className="accordion-item ">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed fw-bold "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTen"
                aria-expanded="false"
                aria-controls="collapseTen"
              >
                <li></li> How do I set my PIN for the Gift or GPR Card?
              </button>
            </h2>
            <div
              id="collapseTen"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                You can set your PIN through the Zokudo App. Simply log in,
                select your card, and follow the prompts to set your desired
                PIN.
              </div>
            </div>
          </div>
          {/* Accordion Item 11 */}
          <div className="accordion-item ">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed fw-bold "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOneOne"
                aria-expanded="false"
                aria-controls="collapseOneOne"
              >
                <li></li> How can I check the balance on my Gift or GPR Card?
              </button>
            </h2>
            <div
              id="collapseOneOne"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                You can easily check your card balance by logging into the
                Zokudo App. Your balance will be displayed on the dashboard for
                quick access.
              </div>
            </div>
          </div>
          {/* Accordion Item 12 */}
          <div className="accordion-item ">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed fw-bold "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOneTwo"
                aria-expanded="false"
                aria-controls="collapseOneTwo"
              >
                <li></li> What should I do if I need to block my Gift or GPR
                Card?
              </button>
            </h2>
            <div
              id="collapseOneTwo"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                If your card is lost or stolen, you can block it immediately
                through the Zokudo App. Simply navigate to your card settings
                and select the option to block the card or contact support team.
              </div>
            </div>
          </div>
          {/* Accordion Item 13 */}
          <div className="accordion-item ">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed fw-bold "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOneThree"
                aria-expanded="false"
                aria-controls="collapseOneThree"
              >
                <li></li>Can I order a physical Gift Card?
              </button>
            </h2>
            <div
              id="collapseOneThree"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Yes, you can order a physical Zokudo Gift Card through the
                Zokudo App. Just select the option for ordering physical cards
                and follow the instructions.
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
  )
}
