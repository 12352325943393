import React from "react";

import NavbarV2 from "./NavbarV2";
import Footer from "./common/Footer";

import languageTranslate from "../assets/language-translate.svg";

function FAQ() {
  return (
    <div className="mt-[40px] md:mt-[80px]">
      <div className="container py-4 text-left text-sm">
        <div className="row flex-column flex-md-row mt-5">
          <div className="col-12 col-md-6">
            <h3 className="text-warning">FAQ's</h3>
          </div>
          
        </div>
        <section>
          <div class="d-flex flex-column gap-2">
            <div
              class="d-flex gap-2 flex-wrap"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <button
                class="custom-btn active"
                id="v-pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-home"
                type="button"
                role="tab"
                aria-controls="v-pills-home"
                aria-selected="true"
              >
                FAQ’s for Gift Cards
              </button>
              <button
                class="custom-btn"
                id="v-pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-profile"
                type="button"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected="false"
              >
                FAQ's for GPR Cards
              </button>
            </div>
            <div
              class="tab-content border p-4 border-gray-500 rounded-xl"
              id="v-pills-tabContent"
            >
              <div
                class="tab-pane fade show active text-start"
                id="v-pills-home"
                role="tabpanel"
                aria-labelledby="v-pills-home-tab"
                tabindex="0"
              >
                <h5 className="text-warning">FAQ’s for Gift Cards</h5>
                <h6 className="font-semibold mt-3">
                  1. What is Zokudo Rupay Gift Card?
                </h6>
                <p>
                  a. This is a prepaid gift card, an alternative to any gift for
                  an individual in your organization. It is a prepaid card which
                  can be used at any Rupay Merchant in India. This card will
                  come pre-loaded with the amount gifted to you.
                </p>

                <h6 className="font-semibold mt-3">
                  2. Where can I use this card?
                </h6>
                <p>
                  a. Zokudo card can be a swipe card or chip-based card, just
                  like any debit or credit card. This card can be used at any
                  POS at the brand/merchant outlet in India by swiping it. It
                  can also be used online.
                </p>

                <h6 className="font-semibold mt-3">
                  3. Is there a usage limit?
                </h6>
                <p>
                  a. Usage limit is restricted to the balance available in the
                  card and until the card validity has expired.
                </p>

                <h6 className="font-semibold mt-3">
                  4. Is there a load amount limit?
                </h6>
                <p>
                  a. Card balance cannot exceed the amount of Rs. 10,000/- per
                  card at any given point. For example, if one wants a 15,000/-
                  worth gift card, the card will be split into two cards for
                  10,000/- & 5,000/- denomination to give a total of 15,000/-.
                </p>

                <h6 className="font-semibold mt-3">
                  5. How to check the expiry of my card?
                </h6>
                <p>
                  a. The expiry details are mentioned on the front of the card.
                  The expiry can also be checked on the Zokudo App where you add
                  the card details.
                </p>

                <h6 className="font-semibold mt-3">
                  6. How can you check the balance on the card?
                </h6>
                <p>
                  a. Once you have registered on the Zokudo app, you need to log
                  in to the app with the registered mobile number/email ID. On
                  successful login, you can see your card balance details on the
                  dashboard. If you have added multiple cards, you can select
                  the card and see the individual card balances.
                  <br />
                  b. You can also write to us at{" "}
                  <a href="mailto:care@zokudo.com">care@zokudo.com</a> to get
                  the balance details.
                </p>

                <h6 className="font-semibold mt-3">
                  7. What happens if my card gets lost?
                </h6>
                <p>
                  a. Kindly call our customer service center at +91-86550-00444
                  and immediately request to block the card & request a new card
                  or write to us at{" "}
                  <a href="mailto:care@zokudo.com">care@zokudo.com</a> for
                  blocking the card.
                </p>

                <h6 className="font-semibold mt-3">
                  8. What is the fee for issuance of the card?
                </h6>
                <p>
                  a. The fee for re-issuance of the card is Rs. 250/- plus GST
                  as applicable. The amount will be deducted from your gift card
                  balance.
                </p>
              </div>

              <div
                class="tab-pane fade"
                id="v-pills-profile"
                role="tabpanel"
                aria-labelledby="v-pills-profile-tab"
                tabindex="0"
              >
                <h5 className="text-warning">FAQ’s for GPR Cards</h5>
                <h6 className="font-semibold mt-3">
                  1. What is Zokudo General Purpose Reloadable (GPR) card?
                </h6>
                <p>
                  a. Zokudo General purpose reloadable (GPR) prepaid cards are
                  reloadable prepaid cards and typically open-loop cards issued
                  under Rupay with a wider acceptance at merchant outlets. They
                  are safer than carrying cash & are accepted at almost
                  everywhere.
                </p>

                <h6 className="font-semibold mt-3">
                  2. Where can I use this card?
                </h6>
                <p>
                  a. Zokudo General purpose reloadable (GPR) card can be a swipe
                  card or Chip based card. This card can be used at any POS at
                  the brand/merchant outlet in India displaying Rupay Logo & for
                  online transactions as well.
                </p>

                <h6 className="font-semibold mt-3">
                  3. Is there a usage limit?
                </h6>
                <p>
                  a. Usage limit is restricted to the balance available in the
                  card and until the card validity has expired.
                </p>

                <h6 className="font-semibold mt-3">
                  4. Is there a load amount limit?
                </h6>
                <p>
                  a. There is no minimum amount however the maximum amount that
                  can be loaded in a day is Rs.2,00,000 per card.
                </p>

                <h6 className="font-semibold mt-3">
                  5. How many times the GPR card be reloaded?
                </h6>
                <p>
                  a. There is no restriction on the number of re-loadings on a
                  card. However, the balance on the card cannot exceed INR
                  2,00,000/- at any given point of time
                </p>

                <h6 className="font-semibold mt-3">
                  6. Can I reload the Zokudo GPR Card?
                </h6>
                <p>
                  a.Yes, the Zokudo GPR cards are reloadable in nature and can
                  be loaded only by the Corporate that has issued it to the
                  user. The card holder cannot load his / her personal funds on
                  it.
                </p>

                <h6 className="font-semibold mt-3">
                  7. What is the security features I have on my GPR Card?
                </h6>
                <p>
                  a. Zokudo Reloadable Prepaid Card comes with PIN based
                  authentication for all Merchant based transactions and is
                  powered by Rupay for all your online purchases. Hence, the
                  card cannot not be misused by anyone else even if it is lost.
                  The Pin has to be set on the Zokudo App. Once the pin is set
                  the card will be activated.
                </p>

                <h6 className="font-semibold mt-3">
                  8. What is fee for issuance of the card?
                </h6>
                <p>
                  a. The fees for re-issuance of card is Rs. 250/- plus GST as
                  applicable. The amount will be deducted from your gift card
                  balance.
                </p>
                <h6 className="font-semibold mt-3">
                  9. What are the possible reasons for transaction being
                  declined?
                </h6>
                <p>There are several possibilities:</p>
                <p>a. Your card has not been activated</p>
                <p>
                  b. The amount of the purchase exceeds the available balance
                </p>
                <p>c. Your card has expired</p>
                <p>d. Your card has been reported lost or stolen</p>
                <p>e. You have entered your PIN incorrectly</p>
                <p>f. Any other technical issues.</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default FAQ;
