import React from "react";
import NavbarV2 from "./NavbarV2";
import Footer from "./common/Footer";
import "../style/contact-us.css";

import contactUsPhone from "../assets/contact-us-phone.svg";
import contactDeskImg from "../assets/contact-us-desk.svg";

import fb from "../assets/contact-fb.png";
import insta from "../assets/contact-insta.png";

import phone from "../assets/phone.png";
import mail from "../assets/mail.png";

import contactUsBanner from "../assets/contect-us-banner.webp";
import Form from "./ui/Form";
import { MdOutlinePhoneInTalk } from "react-icons/md";
import { IoIosMail } from "react-icons/io";
import { motion } from "framer-motion";
import twitter from "../assets/contact-twitter.png";
function ContactUs() {
  return (
    <div>
      <img className="img-fluid contact-bnr-img" src={contactUsBanner} />

      <div className="contact-form-wrapper d-flex justify-content-center container-fluid">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0 }}
          viewport={{ once: true }}
          className="container contact-banner flex gap-3 mt-0 rounded-2xl p-4"
        >
          <div className="h-full grid place-items-center">
            <div className="w-[60px] h-[60px] bg-[#FCEAB6] rounded-full border border-gray-100 p-2">
              <img className="object-contain m-auto" src={contactUsPhone} />
            </div>
          </div>
          <div className="text-start">
            <h4 className="font-semibold mb-0">Contact Us</h4>
            <p className="text-gray-600 mb-0">
              Enquire about our Rupay prepaid card and corporate gift cards.
              We're here to help with your financial management needs.
            </p>
          </div>
        </motion.div>
      </div>
      <div className="mt-5 mb-4 container">
        <div className="flex lg:flex-row flex-col gap-5">
          <div className="w-full lg:w-[65%] flex border-50">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 1 * 0.1 }}
              viewport={{ once: true }}
              className="row d-flex bg-black rounded-xl flex-column flex-md-row align-items-center text-left"
            >
              <div className="col-12 col-md-7 text-left p-5">
                <p className="text-warning text-xl font-bold mb-1">
                Got Questions? Let’s Connect!
                </p>
                <p className="text-gray-400 text-sm font-light">
                Your queries matter to us. Whether you’re exploring partnership opportunities, need assistance, or want to learn more about our banking-as-a-service, we’re here to help.
                </p>
                <a
                  href="telto:+91 75060 48939"
                  className="no-underline text-gray-200"
                >
                  <div className="bg-gray-900 d-line p-2 flex gap-2 mb-2 rounded-[40px]">
                    <div className="grid place-items-center bg-[#E4C221] rounded-full w-8 h-8">
                      <MdOutlinePhoneInTalk className="text-xl text-gray-800" />
                    </div>
                    <div className="flex flex-col justify-center">
                      <p className="mb-0">+91 75060 48939</p>
                    </div>
                  </div>
                </a>
                <a
                  href="mailto:care@zokudo.com"
                  className="no-underline text-gray-200"
                >
                  <div className="bg-gray-900 p-2 flex gap-2 rounded-[40px]">
                    <div className="grid place-items-center bg-[#E4C221] rounded-full w-8 h-8">
                      <IoIosMail className="text-xl text-gray-800" />
                    </div>
                    <div className="flex flex-col justify-center">
                      <p className="mb-0">care@zokudo.com</p>
                    </div>
                  </div>
                </a>
                <div className="flex justify-start pl-1 mt-3">
                  <div className="flex gap-3">
                    <img src={fb} />
                    <img src={twitter} />
                    <img src={twitter} />
                    <img src={insta} />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-5 text-center">
                <img className="img-fluid" src={contactDeskImg} />
              </div>
            </motion.div>
          </div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 2 * 0.1 }}
            viewport={{ once: true }}
            className="grow border rounded-xl"
          >
            <Form />
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
