import React, { useState, useEffect } from "react";
import Footer from "./common/Footer";
import NavbarV2 from "./NavbarV2";
import "../style/corporate-solution.css";
import { Link } from "react-router-dom";
import corporateSolutionSvg1 from "../assets/corporate-style-1.svg";
import corporateSolBanner from "../assets/corp-ban2.webp";

import rewardSvg from "../assets/reward-program.svg";
import payrolSvg from "../assets/payroll.svg";
import prepaidHealth from "../assets/prepaid-health.svg";
import mealSvg from "../assets/meal-card.svg";
import petrolSvg from "../assets/petrol.svg";

import incentiveSvg from "../assets/incentive.svg";
import travelSvg from "../assets/travel.svg";
import studentSvg from "../assets/Students Cards.svg";
import giftCard from "../assets/gift.svg";
import healthCard from "../assets/health.svg";

import instantIssuance from "../assets/icon-iip.png";
import cardGray from "../assets/card-gray.svg";
import handGray from "../assets/hand-gray.svg";
import handYellow from "../assets/hand-yellow.svg";
import gateGray from "../assets/gate-gray.svg";
import secureGray from "../assets/secure-gray.svg";
import gateYellow from "../assets/gate-yellow.svg";
import awardYellow from "../assets/award-yellow.svg";
import endYellow from "../assets/end-yellow.svg";
import increaseYellow from "../assets/increase-yellow.svg";
import timeYellow from "../assets/save-time-yellow.svg";
import fastTagYellow from "../assets/fast-tag-yellow.svg";
import upiYellow from "../assets/upi-yellow.svg";
import awardGray from "../assets/award-gray.svg";
import increaseGray from "../assets/increase-gray.svg";
import timeGray from "../assets/save-time-gray.svg";
import endGray from "../assets/end-gray.svg";
import upiGray from "../assets/upi-gray.svg";
import secureYellow from "../assets/secure-yellow.svg";
import earnInstant from "../assets/icon-eic.png";
import outlet from "../assets/icon-am.png";
import aiDriven from "../assets/icon-ai.png";
import fullySecured from "../assets/icon-fs.png";

import instantImg from "../assets/cp-solution.png";
import upiImg from "../assets/upi-image.svg";
import justTimeImg from "../assets/just-time-img.svg";
import endToendImg from "../assets/end-to-end-img.svg";
import earnInstantCashBack from "../assets/cashback.svg";
import outetSvg from "../assets/outlet-1.svg";
import aiDriven1 from "../assets/ai-driven-1.svg";
import fullySec from "../assets/full-secure2.svg";

import corporateCard from "../assets/corporate-card.svg";
import multiWallet from "../assets/multi-wallet.svg";
import upiReady from "../assets/upi-ready.svg";
import userFriendly from "../assets/user-friendly.svg";
import dostImg from "../assets/dots.svg";

import baas1Bottom from "../assets/baas-1-bottom.svg";
import baas2Bottom from "../assets/baas-2-bottom.svg";

import corp1 from "../assets/corp-1.svg";
import corp2 from "../assets/corp-2.svg";
import corp3 from "../assets/corp-3.svg";
import corp4 from "../assets/corp-4.svg";

import corp5 from "../assets/corp-5.svg";
import corp6 from "../assets/corp-6.svg";
import corp7 from "../assets/corp-7.svg";
import corp8 from "../assets/corp-8.svg";

import corp9 from "../assets/corp-9.svg";
import corp10 from "../assets/corp-10.svg";
import corp11 from "../assets/corp-11.svg";
import corp12 from "../assets/corp-12.svg";

import vt1 from "../assets/video-tutorial-1.svg";
import vt2 from "../assets/video-tutorial-2.svg";
import vt3 from "../assets/video-tutorial-3.svg";
import lines1 from "../assets/lines1.svg";
import lines2 from "../assets/lines2.svg";

import howitwork from "../assets/howitwork.png";
import { FaPlay } from "react-icons/fa";
import Faq from "./ui/Faq";
import Collaborate from "./Collaborate";
import { motion } from "framer-motion";
import { FaArrowRightLong } from "react-icons/fa6";
import Marquee from "./ui/Marquee";
import CorporatePartners from "./ui/CorporatePartners";
import CorporatePartners2 from "./ui/CorporatePartners2";

function CorporateSolution() {
  const rewards = [
    {
      imgSrc: rewardSvg,
      name: "Reward Programs",
    },
    {
      imgSrc: payrolSvg,
      name: "Payroll & Corporate Disbursements",
    },
    {
      imgSrc: prepaidHealth,
      name: "Prepaid Health/ Insurance Cards",
    },
    {
      imgSrc: mealSvg,
      name: "Meal Card",
    },
    {
      imgSrc: petrolSvg,
      name: "Petrol, Medical, Jewelry Card",
    },
    {
      imgSrc: incentiveSvg,
      name: "Incentive Solutions",
    },
    {
      imgSrc: travelSvg,
      name: "Travel & Expense",
    },
    {
      imgSrc: studentSvg,
      name: "Students Cards/ Wearables",
    },
    {
      imgSrc: giftCard,
      name: "Gift Cards",
    },
    {
      imgSrc: healthCard,
      name: "Health Savings Account Cards",
    },
  ];
  const [active, setActive] = useState("instant");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  
  
  return (
    <div>
      <div className="bg-[#FEFBF4]">
        <div className="relative">
          <img
            src={lines1}
            className="absolute top-16 w-[200px] object-contain"
          />
          <img
            src={lines2}
            className="absolute right-0  bottom-4 w-[200px] object-contain"
          />
          <div className="container pt-[100px] pb-[40px] md:py-[100px] lg:py-[120px] grid grid-cols-1 md:grid-cols-2 corporate-hero">
            <div className="text-left left-section">
              <img
                src={corporateSolutionSvg1}
                alt="Corporate Solution"
                className="md:w-[100px] md:h-[100px] w-[60px] md:mb-0 mb-2"
              />
              <motion.h1
                initial={{ opacity: 0, y: 10 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0 * 0.1 }}
                className="leading-[55px]"
              >
                <b className="text-[#E4C221]">Corporate Payments with <br/> Prepaid Card Flexibility
                </b>
              </motion.h1>
              <motion.p
                initial={{ opacity: 0, y: 10 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 1 * 0.1 }}
                className="mt-3"
              >
                Looking for secure, efficient, and flexible payment solutions? Zokudo offers the perfect choice with our network-branded Rupay prepaid cards, designed to meet a wide range of corporate needs.

              </motion.p>
              <motion.p
                initial={{ opacity: 0, y: 10 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 2 * 0.1 }}
              >
                Our prepaid cards provide a seamless and customizable way to manage payments, helping businesses streamline operations while maintaining control over finances. Whether you're looking to reward employees, engage customers, or simplify financial processes, our solutions are here to make it easier.
              </motion.p>
              <motion.p
                initial={{ opacity: 0, y: 10 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 3 * 0.1 }}
              >
                Additionally, our customizable corporate gift cards and National Common Mobility Card (NCMC) cards offer the flexibility to personalize rewards and enhance customer experiences. Choose Zokudo today for a reliable and versatile payment solution that supports your business goals.
              </motion.p>
              <a href="/contact-us">
                <motion.button
                  initial={{ opacity: 0, y: 10 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 4 * 0.1 }}
                  className="fs-14 text-dark bg-[#E4C221] rounded-2 border-0 py-2 px-3 fs-600 pulse align-self-start"
                >
                  Talk to our Expert
                </motion.button>
              </a>
            </div>
            <div className="flex justify-center relative">
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 1 * 0.1 }}
                className="rounded shadow-md text-center p-2 bg-white absolute customer-count left-[15%] top-[50%]"
              >
                <p className="font-bold text-md mb-0">25k</p>
                <p className="font-semibold text-sm mb-0 text-gray-300">
                  Happy Customers
                </p>
              </motion.div>
              <motion.img
                initial={{ opacity: 0, scale: 0.8 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0 * 0.1 }}
                src={corporateSolBanner}
                alt="Corporate Solution Banner"
                className="object-contain banner"
              />
            </div>
          </div>
        </div>
      </div>

      <section class="solution-container py-4 md:py-5 mb-4 md:mb-5">
        <div className="container-fluid text-center pb-2 md:pb-5">
          <motion.button
            initial={{ opacity: 0, y: 10 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0 * 0.1 }}
            className="solution-btn text-warning fs-600 align-self-start p-2 rounded mb-3"
          >
            Solutions
          </motion.button>
          <motion.h1
            initial={{ opacity: 0, y: 10 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 1 * 0.1 }}
            className="text-warning mb-2"
          >
            Corporate Solution
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 10 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 2 * 0.1 }}
            className="md:text-md text-sm w-full md:w-4/5 lg:w-3/5 xl:w-2/5 mx-auto"
          >
            We offer a suite of corporate solutions that help you manage
            expenses, reward employees, and streamline payments—all with the
            convenience of RuPay prepaid cards in India.
          </motion.p>
        </div>

        <div className="container flex justify-center gap-1 md:gap-2 flex-wrap text-center">
          {rewards.map((item, i) => {
            return (
              <motion.div
                initial={{ opacity: 0, y: 0 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: i * 0.1 }}
                key={i}
                className={`duration-300  hover:shadow-xl img-box w-[150px] text-sm md:text-md h-[150px] text-center md:w-[250px] md:h-[180px]]] d-flex flex-column justify-content-center ${
                  i % 2 == 0 ? "bg-[#FCF5E6]" : "bg-[#FFFDF8]"
                } p-3`}
              >
                <span className="img-wrapper">
                  <img src={item.imgSrc} />
                </span>
                <p className="text-sm md:text-md">{item.name}</p>
              </motion.div>
            );
          })}
        </div>
      </section>

      <motion.section
        initial={{ opacity: 0, y: 0 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.5, delay: 0.3 }}
        className="bg-prepaid-bass-banner"
      >
        <div className="container-fluid py-3 md:py-5 px-0">
          <div className="container pt-4 md:pt-5">
            {/* Header Section */}
            <div className="header text-center">
              <h3 className="font-semibold text-warning w-full lg:w-[60%] 2xl:w-2/5 mb-3 mx-auto">
                Benefits of Our Corporate Prepaid Cards
              </h3>
              <p className="text-gray-300 lg:w-3/5 mx-auto">
                Explore the ease that our prepaid cards bring to your business.
                From insurance and security to cashback and rewards, our prepaid
                cards have everything you need and more—accepted at most
                merchants and outlets nationwide, both online and offline.
              </p>
            </div>
          </div>
          <div className="container cp-tabs-container">
            {/* Tab Navigation */}
            <div className="md:hidden block flex justify-end arrow-right-icon">
              <FaArrowRightLong className="mb-[-5px] mr-2 text-white opacity-50" />
            </div>
            <div className="md:rounded-[40px] overflow-hidden">
              <ul
                className="cp-tabs nav nav-pills mb-3 d-flex justify-content-around mt-2 md:mt-5 rounded-pill bg-dark pb-1 px-2 pt-[14px]"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active link-item rounded-pill padding-10 border-none font-size-15 d-flex d-flex"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                    onClick={() => setActive("instant")}
                  >
                    <img
                      className={`icon-width ${
                        active === "instant" ? "p-0" : "p-[8px]"
                      }`}
                      src={active === "instant" ? instantIssuance : cardGray}
                      alt="Instant Issuance"
                    />
                    <div className="text-secondary tab-head text-black">
                      Instant Issuance Platform
                    </div>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link link-item rounded-pill padding-10  border-none font-size-15 d-flex d-flex"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                    onClick={() => setActive("hand")}
                  >
                    <img
                      className={`icon-width ${
                        active === "instant" ? "p-0" : "p-[8px]"
                      }`}
                      src={active === "hand" ? handYellow : earnInstant}
                      alt="Earn Instant Cashback"
                    />
                    <div className="text-secondary tab-head flex flex-col justify-center text-black">
                      {" "}
                      Earn Instant Cashback
                    </div>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link link-item rounded-pill padding-10  border-none font-size-15 d-flex"
                    id="pills-contact-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-contact"
                    type="button"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="false"
                    onClick={() => setActive("gate")}
                  >
                    <img
                      className={`icon-width ${
                        active === "instant" ? "p-0" : "p-[8px]"
                      }`}
                      src={active === "gate" ? gateYellow : outlet}
                      alt="Accepted at Millions of Outlets"
                    />
                    <div className="text-secondary tab-head flex flex-col justify-center text-black">
                      Accepted at Millions of Outlets Online & Offline
                    </div>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link link-item rounded-pill padding-10  border-none font-size-15 d-flex"
                    id="pills-about-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-about"
                    type="button"
                    role="tab"
                    aria-controls="pills-about"
                    aria-selected="false"
                    onClick={() => setActive("award")}
                  >
                    <img
                      className={`icon-width ${
                        active === "instant" ? "p-0" : "p-[8px]"
                      }`}
                      src={active === "award" ? awardYellow : aiDriven}
                      alt="AI Driven Rewards"
                    />
                    <div className="text-secondary tab-head flex flex-col justify-center text-black">
                      {" "}
                      AI Driven Rewards{" "}
                    </div>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link link-item rounded-pill padding-10  border-none font-size-15 d-flex"
                    id="pills-service-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-service"
                    type="button"
                    role="tab"
                    aria-controls="pills-service"
                    aria-selected="false"
                    onClick={() => setActive("secure")}
                  >
                    <img
                      className="icon-width"
                      src={active === "secure" ? secureYellow : fullySecured}
                      alt="Fully Secured"
                    />
                    <div className="text-secondary tab-head flex flex-col justify-center text-black">
                      {" "}
                      Fully Secured{" "}
                    </div>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          {/* Tab Content */}
          <div className="container pb-5">
            <div
              className="tab-content text-left md:mt-4"
              id="pills-tabContent"
            >
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div className="row mt-2 md:mt-5 d-flex justify-content-between flex-wrap">
                  <div className="col-sm-12 col-md-6 col-lg-8 text-white flex flex-col justify-center">
                    <h4 className="text-white">Instant Issuance Platform</h4>
                    <p className="text-secondary">
                      Through our instant issuance platform, you can load funds
                      onto prepaid cards yourself, giving employees instant
                      access to their allocated budgets, whether for business
                      travel, meals, or other work-related costs.
                    </p>
                    <p className="text-secondary">
                      This system streamlines the payment process, eliminating
                      the need for complicated manual intervention when loading
                      cards and allowing employees faster access to their funds.
                    </p>
                    {/* <div className="flex justify-start">
                      <Link to="/contact-us">
                        <button className="bg-white text-black text-sm py-2 px-4 align-self-start border-0 rounded font-medium">
                          Talk To Us
                        </button>
                      </Link>
                    </div> */}
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-3 text-center">
                    <img
                      src={instantImg}
                      alt="Instant Issuance"
                      className="md:w-auto w-4/5 mx-auto"
                    />
                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <div className="row mt-2 md:mt-5 d-flex justify-content-between">
                  <div className="col-sm-12 col-md-6 col-lg-8 text-white flex flex-col justify-center">
                    <h4 className="text-white">Earn Instant Cashback</h4>
                    <p className="text-secondary">
                      Our prepaid cards offer a compelling incentive for both
                      businesses and employees: the ability to earn instant
                      cashback on purchases. This feature adds significant value
                      to corporate disbursements, employee rewards, and other
                      expenses.
                    </p>
                    <p className="text-secondary">
                      By integrating cashback into your corporate spending
                      strategy, Zokudo helps you create a more engaging and
                      cost-effective rewards program that aligns with your
                      company’s financial goals.
                    </p>
                    {/* <div className="flex justify-start">
                      <Link to="/contact-us">
                        <button className="bg-white text-black text-sm py-2 px-4 align-self-start border-0 rounded font-medium">
                          Talk To Us
                        </button>
                      </Link>
                    </div> */}
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-3 pt-4 flex justify-center">
                    <img
                      src={earnInstantCashBack}
                      className="md:w-auto w-4/5 mx-auto"
                      alt="Earn Instant Cashback"
                    />
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-contact"
                role="tabpanel"
                aria-labelledby="pills-contact-tab"
              >
                <div className="row mt-2 md:mt-5 d-flex justify-content-between">
                  <div className="col-sm-12 col-md-6 col-lg-8 text-white flex flex-col justify-center">
                    <h4 className="text-white">
                      Accepted at Millions of Outlets (Online and Offline)
                    </h4>
                    <p className="text-secondary">
                      This extensive reach ensures that your employees can use
                      their cards for various purchases. Whether you need to
                      manage business travel expenses, provide meal allowances,
                      or support other corporate expenditures, Zokudo’s prepaid
                      cards ensure your employees are never left without a
                      payment option.
                    </p>
                    <p className="text-secondary">
                      This convenience translates to a smoother, more efficient
                      operation, where employees can focus on their tasks
                      without worrying about payment limitations.
                    </p>
                    {/* <div className="flex justify-start">
                      <Link to="/contact-us">
                        <button className="bg-white text-black text-sm py-2 px-4 align-self-start border-0 rounded font-medium">
                          Talk To Us
                        </button>
                      </Link>
                    </div> */}
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-3 mt-4 flex justify-center">
                    <img
                      src={outetSvg}
                      alt="Accepted at Millions of Outlets"
                      className="md:w-auto w-4/5 mx-auto"
                    />
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-about"
                role="tabpanel"
                aria-labelledby="pills-about-tab"
              >
                <div className="row mt-2 md:mt-5 d-flex justify-content-between">
                  <div className="col-sm-12 col-md-6 col-lg-8 text-white flex flex-col justify-center">
                    <h4 className="text-white">AI Driven Rewards</h4>
                    <p className="text-secondary">
                      By analysing spending patterns and preferences, Zokudo’s
                      AI can recommend rewards that are most likely to resonate
                      with each employee, making them feel valued and
                      appreciated.
                    </p>
                    <p className="text-secondary">
                      This level of personalisation is a game-changer for
                      engagement. When employees receive rewards that truly
                      matter to them, their satisfaction and motivation
                      increases, leading to higher productivity and loyalty.
                    </p>
                    {/* <div className="flex justify-start">
                      <Link to="/contact-us">
                        <button className="bg-white text-black text-sm py-2 px-4 align-self-start border-0 rounded font-medium">
                          Talk To Us
                        </button>
                      </Link>
                    </div> */}
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-3 pt-4 flex justify-center">
                    <img
                      src={aiDriven1}
                      alt="AI Driven Rewards"
                      className="md:w-auto w-4/5 mx-auto"
                    />
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-service"
                role="tabpanel"
                aria-labelledby="pills-service-tab"
              >
                <div className="row mt-2 md:mt-5 d-flex justify-content-between">
                  <div className="col-sm-12 col-md-6 col-lg-8 text-white flex flex-col justify-center">
                    <h4 className="text-white">Fully Secured</h4>

                    <p className="text-secondary">
                      Our prepaid card platform is built with advanced security
                      measures to protect both company and employee data.
                      Zokudo’s system is PCI-DSS-compliant, adhering to the
                      highest standards of payment security to ensure that all
                      transactions are safe and secure.
                    </p>
                    <p className="text-secondary">
                      Zokudo incorporates multiple layers of security, including
                      encryption, fraud detection, and secure access controls,
                      to safeguard your funds and sensitive information.
                    </p>
                    {/* <div className="flex justify-start">
                      <Link to="/contact-us">
                        <button className="bg-white text-black text-sm py-2 px-4 align-self-start border-0 rounded font-medium">
                          Talk To Us
                        </button>
                      </Link>
                    </div> */}
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-3 pt-4 flex justify-center">
                    <img
                      src={fullySec}
                      alt="Fully Secured"
                      className="md:w-auto w-4/5 mx-auto "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.section>

      <div className="container md:mt-5 grid grid-cols-1 md:grid-cols-2 py-10 md:py-20">
        <motion.div
          initial={{ opacity: 0, y: 0 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 0.3 }}
          className="text-left"
        >
          <span className="bg-[#FCF5E6] text-[#E4C221] px-3 py-1 align-self-start">
            Solution
          </span>
          <h3 className="font-semibold text-warning mt-3">
            Precise Budget Allocation, Zero Misuse
          </h3>
          <p>
            With Zokudo’s prepaid cards, your company maintains full control
            over card usage and funds, ensuring there’s no room for misuse.
          </p>
          <p>
            This means you can allocate specific budgets for various
            expenses—such as travel, meals, or health benefits—while ensuring
            that funds are used only for their intended purposes, reducing the
            risk of misuse on gambling, or other non-business-related
            activities.
          </p>
          <div className="flex justify-start">
            <Link to="/contact-us">
              <button className="custom-button align-self-start border-0 p-1 rounded px-3">
                Talk To Us <i className="bi bi-arrow-right fw-bold"></i>
              </button>
            </Link>
          </div>
        </motion.div>
        <div className="relative grid place-items-center w-full lg:w-[80%] lg:w-auto lg:h-auto h-[350px] lg:mt-0 crp-4">
          <div className="h-[180px] w-[60px] z-[1] rounded bg-[#FBB640] vertical-rect opacity-30 absolute z-10 left-[40%]"></div>
          <div className="circle-blurred w-[200px] z-10 h-[200px] rounded-full absolute bg-[#FBB640] blur-[200px]"></div>
          <motion.img
            initial={{ opacity: 0, scale: 0.8 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.3 }}
            className="z-20 w-[200px]"
            src={corporateCard}
          />
          <img
            className="dots-img absolute top-[-15%] w-[100px] h-[100px] object-contain left-0"
            src={dostImg}
          />
          <img
            className="dots-img absolute bottom-[-15%] w-[100px] h-[100px] object-contain right-[10%]"
            src={dostImg}
          />
          <motion.div
            initial={{ opacity: 0, y: 0 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0 * 0.1 }}
            className="absolute top-[-10px] card-sup1 z-[5] left-[20%] text-center"
          >
            <img
              className="bg-[#FCF5E6] p-3 mb-1 m-auto rounded-xl w-[60px]"
              src={userFriendly}
            />
            <p className="font-medium text-[12px]">User Friendly App</p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 0 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 1 * 0.1 }}
            className="absolute card-sup2 top-[18px] right-[20%] text-center"
          >
            <img
              className="bg-[#FCF5E6] p-3 mb-1 m-auto rounded-xl  w-[60px]"
              src={userFriendly}
            />
            <p className="font-medium text-sm">Ecosystem</p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 0 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 2 * 0.1 }}
            className="absolute card-sup3 bottom-[-30px] right-[28%]  text-center"
          >
            <img
              className="bg-[#FCF5E6] p-3 mb-1 m-auto rounded-xl  w-[60px]"
              src={multiWallet}
            />
            <p className="font-medium text-sm">MultiWallet</p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 0 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 3 * 0.1 }}
            className="absolute card-sup4 bottom-0 left-[130px]  text-center"
          >
            <img
              className="bg-[#FCF5E6] p-3 mb-1 m-auto rounded-xl  w-[60px]"
              src={upiReady}
            />
            <p className="font-medium text-sm">UPI Ready</p>
          </motion.div>
        </div>
      </div>

      <section className="pt-5 pb-3 md:py-5 md:mb-5">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            <div className="grid place-items-center">
              <motion.img
                initial={{ opacity: 0, scale: 0.8 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0 * 0.1 }}
                src={howitwork}
                className="img-fluid w-[400px]"
              />
            </div>
            <div className="text-left mt-3 md:mt-5 md:mt-0">
              <motion.h3
                initial={{ opacity: 0, y: 10 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0 * 0.1 }}
                className="text-warning font-semibold"
              >
                How Does It Work?
              </motion.h3>
              <motion.p
                initial={{ opacity: 0, y: 10 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 1 * 0.1 }}
                className="mb-3 md:mb-5"
              >
                <strong>Zokudo App: Manage All Your Cards in One Place</strong>{" "}
                <br />
                Track balances,monitor transactions, set usage limits, and even
                reload cards—all from your smartphones. Additionally, users can
                view cashback rewards, set up PINs, and check expiry dates with
                a few taps. The Zokudo App is designed to streamline card
                management for both businesses and individuals, keeping
                everything digital and hassle-free.
              </motion.p>
              <motion.p
                initial={{ opacity: 0, y: 0 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 2 * 0.1 }}
                className="mb-3 md:mb-5"
              >
                <strong>
                  Program Management Portal: Full Control at Every Level
                </strong>{" "}
                <br />
                Whether you need one or up to three tiers of authority, our
                portal makes managing your card programs easier than ever. This
                hierarchy structure allows you to extend our platform to
                clients, distribution partners, or branches, giving them their
                own set of authority features and control. You can customise
                permissions at each level, ensuring secure and efficient
                management of your prepaid card programs.
              </motion.p>
            </div>
          </div>
        </div>
      </section>

      <div className="container pt-2 md:pt-5 text-center">
        <h3 className="text-warning font-semibold">
          Key Corporate Solutions Customers
        </h3>
        {/* <p className="text-gray-400">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. <br></br> Lorem Ipsum has been the industry's standard dummy
          text.
        </p> */}
      </div>

      <Marquee>
        <CorporatePartners/>
      </Marquee>
      <Marquee>
        <CorporatePartners2/>
      </Marquee>

      <div className="container md:py-5">
        <div className="grid grid-cols-1 gap-3">
          <div className="text-left flex flex-col justify-center relative text-center">
            <div className="circle-blurred w-[150px] z-10 h-[150px] rounded-full absolute bg-[#FBB640] blur-[150px]"></div>
            <motion.div
              initial={{ opacity: 0, y: 0 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0 * 0.1 }}
            >
              <h3 className="text-warning font-semibold">
                Watch Our Video Tutorials
              </h3>
              <p className="text-sm mx-auto lg:w-3/5 md:w-4/5 w-full">
                Our video tutorials walk you through every step of our prepaid
                card platform to help you get the most out of our prepaid cards
                in India. Get a clear and engaging overview of how Zokudo’s
                prepaid cards can streamline your payment processes and enhance
                your business operations.
              </p>
              <button className="btn btn-outline-warning hover:text-white group">
                <a
                  href="https://www.youtube.com/@PEV_Zokudo"
                  className="no-underline text-[#E4C221] group-hover:text-white"
                  target="_blank"
                >
                  <p className="flex mb-0 gap-2">
                    Learn How to Use Prepaid Cards
                  </p>
                </a>
              </button>
            </motion.div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 text-left md:mt-0 mt-4">
            <div className="shadow-md">
              <iframe
                width="100%"
                height="250"
                src="https://www.youtube.com/embed/DSemXawmNdc?si=lkJ53rWisR0V8e3U"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
            <div className="shadow-md">
              <iframe
                width="100%"
                height="250"
                src="https://www.youtube.com/embed/rJ9mj4gAogs?si=hJStspkWquHzha_z"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      <Collaborate />

      <Faq />
    </div>
  );
}

export default CorporateSolution;
